import React, { Component } from "react";
import Button from "@material-ui/core/Button";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogTitle from "@material-ui/core/DialogTitle";
import Slide from "@material-ui/core/Slide";
// import DialogContent from "@material-ui/core/DialogContent";
// import DialogContentText from "@material-ui/core/DialogContentText";

class AlertDialog extends Component {
  state = {
    open: this.props.blockAlert,
  };

  handleRequestClose = () => {
    this.props.CancelBlockMode();
    this.props.closeBlockAlert();
  };

  render() {
    const { groupID, subscribeAlert, blockAlert } = this.props;
    console.log('alert props', this.props)

    return (
      <div>
        <Dialog
          open={this.state.open}
          TransitionComponent={Slide}
          onClose={this.handleRequestClose}
        >
          <DialogTitle>
            {blockAlert
              ? "Are you sure you want to de-activate ?"
              : subscribeAlert
              ? "Are you sure you want to unsubscribe this group ?"
              : "Are you sure you want to block this group ?"}
          </DialogTitle>
          <DialogActions>
            <Button
              onClick={blockAlert ? () => this.props.closeBlockAlert() : () => this.props.CancelBlockMode()}
              color="secondary"
            >
              No
            </Button>
            {blockAlert ? (
              <Button
                onClick={() => this.props.handleDeactive()}
                color="primary"
              >
                Yes
              </Button>
            ) : (
              <Button
                onClick={
                  subscribeAlert
                    ? () => this.props.handleSubscription()
                    : () => this.props.BlockGroup(groupID)
                }
                color="primary"
              >
                Yes
              </Button>
            )}
          </DialogActions>
        </Dialog>
      </div>
    );
  }
}

export default AlertDialog;
