import React from 'react';
import {Redirect, Route, Switch} from 'react-router-dom';
import asyncComponent from '../../../util/asyncComponent';

const Leads = ({match}) => (
  <div className="app-wrapper">
    <Switch>
        <Route path={`${match.url}/newleads`} component={asyncComponent(() => import('./routes/newleads/index'))}/>
       <Route path={`${match.url}/completedleads`} component={asyncComponent(() => import('./routes/completedleads/index'))}/>
 	  <Route path={`${match.url}/ignoredleads`} component={asyncComponent(() => import('./routes/ignoredleads/index'))}/>
        <Route component={asyncComponent(() => import('app/routes/extraPages/routes/404'))}/>

    </Switch>
  </div>
);

export default Leads;