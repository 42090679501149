import React from 'react';
import {Redirect, Route, Switch} from 'react-router-dom';
import asyncComponent from '../../../util/asyncComponent';

const Blockout = ({match}) => (
  <div className="app-wrapper">
    <Switch>
  
       <Route path={`${match.url}/newBlock`} component={asyncComponent(() => import('./routes/newblockout'))}/>
       <Route path={`${match.url}/listblocks`} component={asyncComponent(() => import('./routes/listblockout/index'))}/>
	   <Route path={`${match.url}/listrecurringblocks`} component={asyncComponent(() => import('./routes/recurringBlockouts/index'))}/>
       <Route component={asyncComponent(() => import('app/routes/extraPages/routes/404'))}/>
    </Switch>
  </div>
);

export default Blockout;