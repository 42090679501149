import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import Modal from "@material-ui/core/Modal";
import Backdrop from "@material-ui/core/Backdrop";
import Fade from "@material-ui/core/Fade";
import EditBox from "../EditBox";
import { Button, Typography, Card } from "@material-ui/core";



const useStyles = makeStyles((theme) => ({
  modal: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },
  root: {
    padding: "20px",
    width: "50%",
    [theme.breakpoints.down("sm")]: {
      width: "90%",
    },
  },
  cancelBtn: {
    marginTop: "10px",
    float: "right",
  },
  edit:{
    marginBottom: "20px",
    color: theme.palette.primary
  }
}));

const EditPostItem = (props) => {
  const classes = useStyles();

   //console.log('props edit post item', props);

  return (
    <Modal
      aria-labelledby="transition-modal-title"
      aria-describedby="transition-modal-description"
      className={classes.modal}
      open={props.open}
      onClose={props.handleClose}
      closeAfterTransition
      BackdropComponent={Backdrop}
      BackdropProps={{
        timeout: 500,
      }}
    >
      <Fade in={props.open}>
        <Card className={classes.root}>
            <Typography className={classes.edit}> Edit Post</Typography>
          <EditBox addPost={props.editPost} post={props.post} />
          <Button
            variant="contained"
            color="primary"
            onClick={props.handleClose}
            className={classes.cancelBtn}
          >
            Cancel
          </Button>
        </Card>
      </Fade>
    </Modal>
  );
};

export default EditPostItem;
