import React from 'react';
import Avatar from '@material-ui/core/Avatar'
import Button from '@material-ui/core/Button';
import { Link } from 'react-router-dom'


const NotificationItem = ({notification}) => {
  const {icon, image, title, customlink} = notification;
  return (
    <li className="media">
        <div className="media-body align-self-center">
        <p className="sub-heading mb-0">{title}
	  	
	  	<Link to={{
							  pathname: customlink,
							  state: [{val:''}]
							}}
							 className="jr-btn jr-btn-xs mb-0" variant="contained" color="primary" >
						   View Bookings <i
          className={`zmdi ${icon} zmdi-hc-fw`}/></Link> 
	  
		</p>
      </div>
    </li>
  );
};

export default NotificationItem;
