import React, { Component } from "react";
import Avatar from "@material-ui/core/Avatar";
import Button from "@material-ui/core/Button";
import { Modal, ModalHeader } from "reactstrap";
import TextField from "@material-ui/core/TextField";
import Dropzone from "react-dropzone";
import Divider from "@material-ui/core/Divider";
// import classes from "*.module.css";
import { Card, withStyles } from "@material-ui/core";
import AlertDialog from "../PostList/AlertDialog";
import { httpClient } from "../../../appUtility/Api";
import {
  NotificationManager,
  NotificationContainer,
} from "react-notifications";
import parse from 'html-react-parser';
import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.snow.css';


const styles = theme => ({
  card: {
  }
});


const qs = require("qs");
const socialData = JSON.parse(window.localStorage.getItem("login"));


class EditBox extends Component {
  state = {
    postTitle: this.props.post && this.props.post.postTitle,
    groupID: this.props.post && (this.props.post.groupId || this.props.post.groupID),
    previewVisible: false,
    previewImage: "",
    fileList: (this.props.post && this.props.post.images) || [],
    rejected: [],
    isOpen: false,
    profileImage: socialData && socialData.img,
    name: socialData && socialData.fname + " " + socialData.lname,
    deleteAlert: false,
    imageID: ""
  };


  handleCancel = () => this.setState({ previewVisible: false });

  handlePreview = (file) => {
    console.log("previewImage", file);
    this.setState({
      previewImage: file.url || file.thumbUrl,
      previewVisible: true,
    });
  };

  onDrop = (files) => {
    console.log("file-->", files);
    // this.setState({
    //   fileList: this.props.post && this.props.post.images
    // })
    var imgArr = files.map((img, index) => {
      return img;
    });
    if (this.state.fileList.length > 0) {
      this.setState({
        ...this.state,
        fileList: imgArr.concat(this.state.fileList),
      });
    } else {
      this.setState({
        fileList: imgArr,
      });
    }

  };

  CancelDeletePost = () => {
    this.setState({ ...this.state, deleteAlert: false });
  };

  handleRemoveImage = (file) => {
    this.setState({ ...this.state, deleteAlert: true, imageID: file.imageID })
    console.log('file is', file)

  }

  handleDeleteImage = () => {

    var imgArr = (this.state.fileList).filter((list, index) => {
      return (
        list.imageID !== this.state.imageID
      );
    });
    this.setState({ ...this.state, fileList: imgArr });


    const PostQuery = {
      userID: socialData && socialData.uid,
      userCode: socialData && socialData.memtoken,
      appID: 1,
      companyID: socialData && socialData.cid,
      postID: this.props.post.postID,
      imageID: this.state.imageID,
    };

    httpClient
      .post(
        "api/social/posts_image_save.php",
        qs.stringify({
          action: "image_delete",
          source: PostQuery,
        })
      )
      .then((res) => {
        if (res.data.success !== "true") {
          NotificationManager.warning(
            "Sorry couldn't delete image right now !"
          );
          this.CancelDeletePost();
        } else {
          NotificationManager.success(res.data.msg);
          this.CancelDeletePost();
        }
      });


  };

  handleChange = ({ fileList }) => {
    // console.log("fileList", fileList);
    this.setState({ fileList });
  };

  handleClickImage() {
    this.setState((previousState) => ({
      isOpen: !previousState.isOpen,
    }));
  }

  handleAddPost() {
    console.log("am i here??")
    if (this.state.postTitle !== "") {
      this.props.addPost(
        this.state.postTitle,
        this.state.fileList
      );
      //   this.setState({
      //     post_text: "",
      //     previewVisible: false,
      //     previewImage: "",
      //     fileList: [],
      //     isOpen: false,
      //   });
    }
  }

  // onChange(value) {
  //   this.setState({ postTitle: value });
  // }
  
  onChange(event) {
    const { value } = event.target;
    this.setState({ postTitle: value });
  }
  

  componentDidMount() {
    // var newStr = this.state.postTitle.replace(/\r\n/g, "");
    // var withoutHTMLTags = newStr.replace(/<\/?(p|b|i|u|h1|h2|h3|h4|h5|h6|strong|em|span)[^>]*>/g, "");
    // var currTitle = withoutHTMLTags.replaceAll("<br />", "");
    var currTitle = this.state.postTitle;
    // Now, currTitle should contain the string without the specified HTML tags.
  
    const parser = new DOMParser();
    const doc = parser.parseFromString(this.state.postTitle, 'text/html');
    const plainText = doc.body.textContent;
    
    this.setState({
      ...this.state,
      postTitle: plainText
    })
  }

  render() {
    const { previewVisible, previewImage, fileList } = this.state;
    const isEnabled = fileList.length === 0  && this.state.postTitle === "";

    const { classes } = this.props;

    console.log('state inn edit post', this.state);

    return (
      <>
        {this.state.deleteAlert && (
          <AlertDialog
            postID={this.props.post.postID}
            imageId={this.state.imageID}
            deleteAlert={this.state.deleteAlert}
            CancelDeletePost={this.CancelDeletePost}
            handleDeletePost={this.handleDeleteImage}
          // image={this.props.post}
          />
        )}

        <div className={classes.card}>
          <div className="media mb-3">
            <Avatar
              className="size-50 mr-3"
              src={this.state.profileImage}
              alt={this.state.name}
            />
            <div className="media-body">
              <TextField
                id="exampleTextarea"
                placeholder="Edit Post"
                label="Edit Post"
                //value={parse(this.state.postTitle)}
                value={this.state.postTitle}
                onChange={(e) => this.onChange(e)}
                multiline
                fullWidth
                className="jr-wall-textarea"
                margin="none"
                variant="outlined"
                rows="4"
              />
              {/* <ReactQuill
                    placeholder="Edit Post"
                    value={parsedContent}
                    onChange={(value) => this.onChange(value)}
                    modules={{
                      toolbar: [
                        ['bold', 'italic', 'underline', 'strike'],
                        [{ 'header': 1 }, { 'header': 2 }],
                        [{ 'list': 'ordered' }, { 'list': 'bullet' }],
                        ['link'],['image'],
                        ['clean'],
                      ],
                    }}
                    formats={[
                      'header',
                      'bold', 'italic', 'underline', 'strike',
                      'link', 'image',
                      'list', 'bullet',
                    ]}
                  /> */}

            </div>
          </div>

          <div className="jr-clearfix">
            {this.state.isOpen === true || this.props.post ? (
              <div className="d-flex flex-row">
                <ul className="list-inline mb-3 mr-2">
                  {fileList.length != 0 && fileList[0] != ""
                    && fileList.map((user, index) => (
                      <li
                        className="mb-1 mr-0 list-inline-item align-top relative"
                        key={index}
                      >
                        <img
                          alt="..."
                          className="size-60 rounded"
                          src={user.preview || user.imageURL}
                        />
                        <span
                          className="cross_icon"
                          onClick={() => this.handleRemoveImage(user)}
                        >
                          <i className="zmdi zmdi-close" />
                        </span>
                      </li>
                    ))}
                </ul>
                {/* <Dropzone
                className="jr-wall-dropzone"
                onDrop={this.onDrop}
                multiple
              >
                <i className="zmdi zmdi-collection-image" />
              </Dropzone> */}
              </div>
            ) : null}
            <Divider />

            {/* <Modal isOpen={previewVisible} toggle={this.handleCancel}>
            <ModalHeader toggle={this.toggle}>Slide Show</ModalHeader>
            <img alt="example" style={{ width: "100%" }} src={previewImage} />
          </Modal> */}
          </div>

          <div className="d-flex flex-row align-items-center mt-2">
            {/* <div className="pointer" onClick={this.handleClickImage.bind(this)}>
            <i className="zmdi zmdi-camera mr-2 jr-fs-xl d-inline-flex align-middle" />
            <span className="jr-fs-sm"> Add Photos/Album </span>
          </div> */}

            <Button
              color="primary"
              size="small"
              className="ml-auto mb-0"
              disabled={isEnabled ? true : false}

              onClick={this.handleAddPost.bind(this)}
            >
              UPDATE
            </Button>
          </div>
        </div>
      </>
    );
  }
}

export default withStyles(styles)(EditBox);
