import React, { useState, useEffect } from "react";
import {
  Dialog,
  DialogContent,
  DialogContentText,
  DialogActions,
  Button,
} from "@material-ui/core";

const AttentionDialog = (props) => {
  const [details, setDetails] = useState({
    open: props && props.open,
  });

  const handleClose = () => {
    setDetails({
      ...details,
      open: false,
    });
    localStorage.setItem("attention_detail", JSON.stringify("false"));
  };

  const handleMark = () => {
    setDetails({
      ...details,
      open: false,
    });
    localStorage.setItem("attention_detail", JSON.stringify("false"));

    window.location = "/app/notifications/bookings";
  };

  props.sendTo(details);

  return (
    <Dialog open={details.open} onClose={handleClose}>
      <div className="p-2">
        <DialogContent>
          <DialogContentText style={{ color: "#444" }}>
            You have more than 30 bookings to be completed. These bookings need
            to be completed to ensure your data flows through to your profit &
            loss. Excessive number of bookings to be completed can cause data
            integrity issues, therefore please complete these bookings asap.
            <br /><br />
            If you require assistance, please log a ticket
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button variant="contained" color="secondary" onClick={handleClose}>
            Cancel
          </Button>
          <Button variant="contained" color="primary" onClick={handleMark}>
            Go to bookings to be completed
          </Button>
        </DialogActions>
      </div>
    </Dialog>
  );
};

export default AttentionDialog;
