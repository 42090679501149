import React from "react";
import Button from "@material-ui/core/Button";
import Snackbar from "@material-ui/core/Snackbar";
import IconButton from "@material-ui/core/IconButton";
import CloseIcon from "@material-ui/icons/Close";
// import IntlMessages from "util/IntlMessages";
// import { isIOS, isMobile } from "react-device-detect";
import { httpClient } from "appUtility/Api";
import GlobalURL from "appUtility/global";
import featureServer from "./../../appUtility/featureServer";
import FooterAlert from "./footerAlert";
import RefreshIcon from "@material-ui/icons/Refresh";
// import localforage from 'localforage';
import Tooltip from "@material-ui/core/Tooltip";
import LoopIcon from "@material-ui/icons/Loop";
import moment from "moment";
import { NotificationManager } from "react-notifications";
import MuiAlert from "@material-ui/lab/Alert";
import { Link } from "react-router-dom";
import AttentionDialog from "../../app/routes/dashboard/routes/CRM/AttentionDialog.js";
import FirstMessage from "../../app/routes/dashboard/routes/CRM/FirstMessage.js";
import StockMessage from "../../app/routes/dashboard/routes/CRM/StockMessage.js";
import TandCDialog from "../../containers/TandCDialog.js";

const Alert = React.forwardRef(function Alert(props, ref) {
  return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
});

let menuText = "";

const qs = require("qs");
const loginData = JSON.parse(localStorage.getItem("login"));
const stock = JSON.parse(localStorage.getItem("stockTake"));
// if(loginData.enable2fa === "0"){
//   window.location = "/app/myProfile/profile";
// }

// var loginData;
//INdexed DB
// async function asyncCall() {
//     let result = await localforage.getItem('login');
//     console.log('inside', result);
//     return result;
// }
// (async () => {
//   loginData = await asyncCall();
//   return loginData;
// })()

// loginData = asyncCall();

// function call(){
//   return new Promise(function(resolve){
//     localforage.getItem('login').then((data) => {
//       resolve(data)
//     })
//   })
// }

// function back(){
//   return new Promise(function(resolve){
//     call().then((res) => {
//       resolve(res)
//     })
//     .then(res => resolve(res))
//   })
// }

// console.log('result >>', back())

// setTimeout(() => {
// if(loginData != "" || loginData != null || loginData != undefined){
//   console.log('login data >>', loginData);
// }
// },5000)

var modifiedData;
var attentionCount = 0;

var aid, cid, uid, param, loginas, mobiledevice;

loginas = loginData && loginData.loginas;
mobiledevice = loginData && loginData.ismobiledevice;
aid = loginData && loginData.aid;
cid = loginData && loginData.cid;
uid = loginData && loginData.uid;

param = aid + "_" + cid + "_" + uid + "_" + loginas + "_" + mobiledevice;

modifiedData = loginData && loginData.syncLastModified;

// console.log('parmans in footett', param);

// const logoutoptions = ["refresh", "logout"];
var loggedDate, today, todayDate;
var todayDate = new Date();
var today = moment(todayDate).format("YYYY-MM-DD HH:mm");
// var today = ("02/10/22");


class Footer extends React.Component {
  constructor() {
    super();
    this.state = {
      currentCount: 10,
      footerAlertVisible: false,
      useraction: false,
      logoutRequired: false,
      refreshRequired: false,
      open: false,
      booking: "",
      bookingArchived: "",
      bookingCancelled: "",
      bookingCompleted: "",
      calendarEntries: "",
      customers: "",
      pets: "",
      syncModified: "",
      refresh: false,
      loading: false,
      allApiCount: "",
      openAttentionDialog: false,
      openStockMessage: false,
      openFirstMessage: false,
      openTermsDialog: false,
      stockNotification: false,
      showButton: true,
    };
  }

  handleOpen = () => {
    this.setState({
      open:
        this.state.booking === 1 ||
          this.state.bookingArchived === 1 ||
          this.state.bookingCancelled === 1 ||
          this.state.bookingCompleted === 1 ||
          this.state.calendarEntries === 1 ||
          this.state.customers === 1 ||
          this.state.pets === 1
          ? true
          : false,
    });
  };
  handleClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    this.setState({
      open: false,
    });
  };

  refreshPage() {
    window.location.reload(false);
  }

  requestDesktop = () => {
    let loginMobileSite = localStorage.getItem("login");
    let loginMobileSiteDetail = JSON.parse(loginMobileSite);
    loginMobileSiteDetail.mobilesite = 0;
    localStorage.setItem("login", JSON.stringify(loginMobileSiteDetail));
  };

  callbackFunctionFromAlert = (childData) => {
    this.setState(
      {
        useraction: childData.useraction,
        logoutRequired: childData.logoutRequired,
        refreshRequired: childData.refreshRequired,
        footerAlertVisible: false,
        openDialog: false,
      },
      this.doAction
    );
  };

  doAction() {
    if (this.state.logoutRequired === false) {
      if (this.state.refreshRequired === true) {
        window.location.reload(false);
      }
    } else if (this.state.logoutRequired === true) {
      window.location.reload(false);
      window.location = "/logout";
    }
  }

  componentDidMount() {
    this.checkTime = this.checkTime.bind(this);
    this.checkTime();
    this.intervalId = setInterval(this.checkTime, 60000);


    let loginMobileSite = localStorage.getItem("login");
    let loginMobileSiteDetail = JSON.parse(loginMobileSite);

    var socialParams = {
      appID: loginMobileSiteDetail.aid,
      companyID: loginMobileSiteDetail.cid,
      userID: loginMobileSiteDetail.uid,
    };

    httpClient
      .post(
        "stockTake.php",
        qs.stringify({
          action: "notification",
          source: socialParams,
        })
      )
      .then(
        ({ data }) => {
          if (data.success) {
            if (loginData && loginData.loginFromAdmin === 1) {
              this.setState({
                ...this.state,
                stockNotification: "false",
              });
            } else {
              this.setState({
                ...this.state,
                stockNotification: data.needStockTake,
              });
            }
            // localStorage.setItem("stockTake", data.needStockTake)

          } else {
            console.log("stock take notification failed");
          }
        },

        // error handler
        (error) => {
          this.setState({
            isLoaded: true,
            error,
          });
        }
      );


    // featureServer &&


    // setInterval(function () {
    //   if (loginData.enable2fa === "0" && window.location.pathname !== "/app/myProfile/profile" && window.location.pathname !== "/app/dashboard/overview") {
    //     setTimeout(function () {
    //       //window.location.reload(true);
    //       window.location = "/app/myProfile/profile";
    //     }, 1000);
    //   }
    // }, 500);

    // setInterval(function () {

    //   if ((stock == "true" || stock == true) && window.location.pathname !== "/app/inventory/stock_take" && window.location.pathname !== "/app/dashboard/overview") {

    //     setTimeout(function () {
    //       window.location = "/app/dashboard/overview";
    //     }, 1000);
    //   }
    // }, 500);


    this.intervalId1 = setInterval(() => {
      const { stockNotification } = this.state;
      if (loginData && loginData.enable2fa === "0" && window.location.pathname !== "/app/myProfile/profile" && window.location.pathname !== "/app/dashboard/overview" && !((stockNotification == "true" || stockNotification == true) && window.location.pathname === "/app/inventory/stock_take")) {
        setTimeout(() => {
          window.location = "/app/myProfile/profile";
        }, 1000);
      }
    }, 500);

    this.intervalId2 = setInterval(() => {
      const { stockNotification, showButton } = this.state; // Ensure these are in state
      if ((stockNotification == "true" || stockNotification == true) && showButton === false && window.location.pathname !== "/app/inventory/stock_take" && window.location.pathname !== "/app/dashboard/overview" && !(loginData && loginData.enable2fa === "0" && window.location.pathname === "/app/myProfile/profile")) {
        setTimeout(() => {
          window.location = "/app/dashboard/overview";
        }, 1000);
      }
    }, 500);

    this.intervalId3 = setInterval(() => {
      const { stockNotification } = this.state;
      if (loginData && loginData.acceptTsAndCs === "0" && window.location.pathname !== "/app/dashboard/overview" && !((stockNotification == "true" || stockNotification == true) && window.location.pathname === "/app/inventory/stock_take")) {
        setTimeout(() => {
          window.location = "/app/dashboard/overview";
        }, 1000);
      }
    }, 500);


    if (loginMobileSiteDetail.mobilesite === 0) menuText = "Mobile";
    else menuText = "Desktop";

    this.intervalId = setInterval(this.timer.bind(this), 60000);


    // featureServer && (setInterval(this.loadRecords, 50000));
    featureServer &&
      this.state.open != true &&
      setInterval(this.loadRecords, 60000);
    // setInterval(this.loadRecords, 120000);
    // this.handleAttention();
    setInterval(this.logout);



    const lastOpenTime = localStorage.getItem('firstMessageLastOpenTime');
    const avoid = localStorage.getItem('neveropenfirstmessage');
    const loginDetails = JSON.parse(localStorage.getItem("login"))


    // Check if 24 hours have passed since the last opening

    if (loginDetails.enable2fa === "0" && this.is10SecondPassed(new Date(lastOpenTime))) {
      this.setState({ openFirstMessage: true });
    }


    if (loginDetails.acceptTsAndCs == "0") {
      this.setState({ openTermsDialog: true });
    }

    // if (featureServer && (loginDetails && loginDetails.acceptTsAndCs == "0") && this.is10SecondPassed(new Date(lastOpenTime))) {
    //   this.setState({ openTermsDialog: true });
    // }


    // else if (loginDetails && loginDetails.enable2fa === "0" && !avoid && (!lastOpenTime || this.is24HoursPassed(new Date(lastOpenTime)))) {
    //   this.setState({ openFirstMessage: true });
    //   localStorage.setItem('firstMessageLastOpenTime', new Date().toString());
    // }

  }

  is24HoursPassed(lastOpenTime) {
    const twentyFourHoursInMillis = 24 * 60 * 60 * 1000; // 24 hours in milliseconds
    const currentTime = new Date().getTime();
    return currentTime - lastOpenTime.getTime() >= twentyFourHoursInMillis;
  }

  is10SecondPassed(lastOpenTime) {
    const twentyFourHoursInMillis = 10 * 1000; // 24 hours in milliseconds
    const currentTime = new Date().getTime();
    return currentTime - lastOpenTime.getTime() >= twentyFourHoursInMillis;
  }


  handleAttention = () => {
    //Attention Code
    let bookingObj = localStorage.getItem("booking_active");

    let todaysDate = new Date();
    let dd = ("0" + todaysDate.getDate()).slice(-2); //todaysDate.getDate();
    let mm = ("0" + (todaysDate.getMonth() + 1)).slice(-2); //todaysDate.getMonth() + 1;
    let y = todaysDate.getFullYear();
    let hours = todaysDate.getHours();
    let minutes = todaysDate.getMinutes();

    todaysDate = y + "/" + mm + "/" + dd + " " + hours + ":" + minutes;

    //let requiredTodaysDate = new Date(todaysDate.replace(' ', 'T'));
    let requiredTodaysDate = new Date(todaysDate);
    if (bookingObj != null) {
      let bookingListDetail = JSON.parse(bookingObj);
      if (bookingListDetail.length != 0) {
        bookingListDetail.map((book) => {
          let bookingDate = book.bdate.split("-").join("/") + " " + book.btime;
          let requiredBookingDate = new Date(bookingDate);
          if (requiredBookingDate < requiredTodaysDate) {
            attentionCount = attentionCount + 1;
          }
        });
      }
    }
  };

  sendTo = (child) => {
    if (child.open == false) {
      attentionCount = 0;
      this.setState({
        ...this.state,
        openAttentionDialog: false,
      });
    }
  };

  sendToFirst = (child) => {
    if (child.open == false) {
      //attentionCount = 0;
      this.setState({
        ...this.state,
        openFirstMessage: false,
      });
    }
  };

  sendToStock = (child) => {
    if (child.open == false) {
      //attentionCount = 0;
      this.setState({
        ...this.state,
        openStockMessage: false,
      });
    }
  };

  callbackTandC = (call) => {
    var loginData = localStorage.getItem("login");
    var localData = JSON.parse(loginData);

    if (call.open === false && call.success === true) {
      this.setState({
        openTermsDialog: false,
      });

      localData.acceptTsAndCs = "1";
      localStorage.setItem("login", JSON.stringify(localData));
    }
  };


  handleCheckNewData = () => {
    this.loadRecords();
    NotificationManager.info("Checking for new data");
  };

  componentWillUnmount() {
    clearInterval(this.intervalId);
    clearInterval(this.intervalId1);
    clearInterval(this.intervalId2);
    clearInterval(this.intervalId3);
  }

  checkTime() {
    const now = new Date();
    const options = {
      timeZone: 'Australia/Sydney',
      year: 'numeric',
      month: '2-digit',
      day: '2-digit',
      hour: '2-digit',
      minute: '2-digit',
      second: '2-digit',
      hour12: false
    };

    const formatter = new Intl.DateTimeFormat('en-AU', options);
    const parts = formatter.formatToParts(now);
    const dateParts = parts.reduce((acc, part) => {
      acc[part.type] = part.value;
      return acc;
    }, {});

    const currentDate = new Date(
      `${dateParts.year}-${dateParts.month}-${dateParts.day}T${dateParts.hour}:${dateParts.minute}:${dateParts.second}+10:00`
    );

    const day = currentDate.getUTCDate();

    // Check if the day is between 1 and 14 (inclusive)
    if (day >= 1 && day <= 14) {
      this.setState({ showButton: true });
    } else {
      this.setState({ showButton: false });
    }
  }


  loadRecords = () => {
    if (this.state.open != true) {
      let archivedBookingLastModified = "";
      let activeBookingLastModified = "";
      let customerLastModified = "";
      let cancelledBookingLastModified = "";
      let calendarLastModified = "";
      let completedBookingLastModified = "";
      let petLastModified = "";

      const loginValues = JSON.parse(localStorage.getItem("login"));

      var time = loginValues.syncLastModified;
      time &&
        time.map((sync) => {
          if (sync.archivedBookingLastModified) {
            archivedBookingLastModified = sync.archivedBookingLastModified;
          }
          if (sync.activeBookingLastModified) {
            activeBookingLastModified = sync.activeBookingLastModified;
          }
          if (sync.calendarLastModified) {
            calendarLastModified = sync.calendarLastModified;
          }
          if (sync.customerLastModified) {
            customerLastModified = sync.customerLastModified;
          }
          if (sync.cancelledBookingLastModified) {
            cancelledBookingLastModified = sync.cancelledBookingLastModified;
          }
          if (sync.completedBookingLastModified) {
            completedBookingLastModified = sync.completedBookingLastModified;
          }
          if (sync.petLastModified) {
            petLastModified = sync.petLastModified;
          }
        });

      var updatedData = {
        appID: loginData.aid,
        companyID: loginData.cid,
        bookingActiveDate: activeBookingLastModified,
        bookingArchivedDate: archivedBookingLastModified,
        bookingCancelledDate: cancelledBookingLastModified,
        bookingCompletedDate: completedBookingLastModified,
        calendarDate: calendarLastModified,
        customerDate: customerLastModified,
        petDate: petLastModified,
      };

      this.setState({
        ...this.state,
        loading: true,
      });
      httpClient
        .post(
          "getServerLatestData.php",
          qs.stringify({
            action: "lastUpdatedDateTime",
            source: updatedData,
          })
        )
        .then(({ data }) => {
          if (data.success === false) {
            this.setState({
              ...this.state,
              loading: true,
            });
          } else {
            let booking = [];
            let bookingCancelled = [];
            let bookingArchived = [];
            let bookingCompleted = [];
            let calendarEntries = [];
            let customers = [];
            let pets = [];

            data.map((res) => {
              if (res.title === "Booking") {
                booking = res;
              }
              if (res.title === "BookingCancelled") {
                bookingCancelled = res;
              }
              if (res.title === "BookingArchived") {
                bookingArchived = res;
              }
              if (res.title === "BookingCompleted") {
                bookingCompleted = res;
              }
              if (res.title === "CalendarEntries") {
                calendarEntries = res;
              }
              if (res.title === "Customers") {
                customers = res;
              }
              if (res.title === "Pets") {
                pets = res;
              }
            });

            this.setState(
              {
                open:
                  booking.refreshVal === 1 ||
                  bookingArchived.refreshVal === 1 ||
                  bookingCancelled.refreshVal === 1 ||
                  bookingCompleted.refreshVal === 1 ||
                  calendarEntries.refreshVal === 1 ||
                  customers.refreshVal === 1 ||
                  pets.refreshVal === 1,
                booking: booking.refreshVal,
                bookingArchived: bookingArchived.refreshVal,
                bookingCancelled: bookingCancelled.refreshVal,
                bookingCompleted: bookingCompleted.refreshVal,
                calendarEntries: calendarEntries.refreshVal,
                customers: customers.refreshVal,
                pets: pets.refreshVal,
                loading: false,
              },
              this.handleOpen()
            );
          }
        });
    }
  };

  timer() {
    this.setState({
      currentCount: this.state.currentCount - 1,
    });
    if (this.state.currentCount < 1) {
      this.setState({
        currentCount: 10,
        isLoadedRequiredCount: 0,
        loadCompleted: 0,
      });
    }
  }

  handleActiveBooking = () => {
    //Now retreive all active bookings from system for this customer
    this.setState({
      ...this.state,
      allApiCount: "",
    });
    httpClient
      .post(
        "getActiveBookings.php",
        qs.stringify({
          action: "booking_active",
          source: param,
        })
      )
      .then(({ data }) => {
        if (data) {
          var activeBookingLastMod = {
            activeBookingLastModified: data.activeBookingLastModified,
          };
          var dataIndex = modifiedData.findIndex(
            (arr, i) => arr.activeBookingLastModified
          );
          modifiedData[dataIndex] = activeBookingLastMod;

          localStorage.setItem("booking_active", JSON.stringify(data.data));

          this.setState({
            syncModified: modifiedData,
            refresh: true,
            allApiCount: "true",
          });
          if (this.state.syncModified.length > 0) {
            var loginData = JSON.parse(localStorage.getItem("login"));
            loginData.syncLastModified = this.state.syncModified;
            loginData.syncLastModified !== [] &&
              localStorage.setItem("login", JSON.stringify(loginData));
          }
        } else {
        }
      });
  };

  handleArchivedBooking = () => {
    //Now retreive all archived bookings from system for this customer
    httpClient
      .post(
        "getArchivedBookings.php",
        qs.stringify({
          action: "booking_archived",
          source: param,
        })
      )
      .then(
        ({ data }) => {
          if (data) {
            var archivedBookingLastMod = {
              archivedBookingLastModified: data.archivedBookingLastModified,
            };
            var dataIndex = modifiedData.findIndex(
              (arr, i) => arr.archivedBookingLastModified
            );
            modifiedData[dataIndex] = archivedBookingLastMod;
            localStorage.setItem("booking_archived", JSON.stringify(data.data));

            this.setState({
              syncModified: modifiedData,
              refresh: true,
              allApiCount: "true",
            });
            if (this.state.syncModified.length > 0) {
              var loginData = JSON.parse(localStorage.getItem("login"));
              loginData.syncLastModified = this.state.syncModified;
              loginData.syncLastModified !== [] &&
                localStorage.setItem("login", JSON.stringify(loginData));
            }
          } else {
          }
        }

        // error handler
      );
  };

  handleCancelledBooking = () => {
    //Now retreive all Completed bookings from system for this customer
    httpClient
      .post(
        "getCancelledBookings.php",
        qs.stringify({
          action: "booking_cancelled",
          source: param,
        })
      )
      .then(
        ({ data }) => {
          if (data) {
            var cancelledBookingLastMod = {
              cancelledBookingLastModified: data.cancelledBookingLastModified,
            };
            var dataIndex = modifiedData.findIndex(
              (arr, i) => arr.cancelledBookingLastModified
            );
            modifiedData[dataIndex] = cancelledBookingLastMod;
            localStorage.setItem(
              "booking_cancelled",
              JSON.stringify(data.data)
            );

            this.setState({
              syncModified: modifiedData,
              refresh: true,
              allApiCount: "true",
            });
            if (this.state.syncModified.length > 0) {
              var loginData = JSON.parse(localStorage.getItem("login"));
              loginData.syncLastModified = this.state.syncModified;
              loginData.syncLastModified !== [] &&
                localStorage.setItem("login", JSON.stringify(loginData));
            }
          } else {
          }
        }

        // error handler
      );
  };

  handleCompletedBooking = () => {
    //Now retreive all Completed bookings from system for this customer
    httpClient
      .post(
        "getCompletedBookings.php",
        qs.stringify({
          action: "booking_completed",
          source: param,
        })
      )
      .then(
        ({ data }) => {
          if (data) {
            var completedBookingLastMod = {
              completedBookingLastModified: data.completedBookingLastModified,
            };
            var dataIndex = modifiedData.findIndex(
              (arr, i) => arr.completedBookingLastModified
            );
            modifiedData[dataIndex] = completedBookingLastMod;
            localStorage.setItem(
              "booking_completed",
              JSON.stringify(data.data)
            );

            this.setState({
              syncModified: modifiedData,
              refresh: true,
              allApiCount: "true",
            });
            if (this.state.syncModified.length > 0) {
              var loginData = JSON.parse(localStorage.getItem("login"));
              loginData.syncLastModified = this.state.syncModified;
              loginData.syncLastModified !== [] &&
                localStorage.setItem("login", JSON.stringify(loginData));
            }
          } else {
          }
        }

        // error handler
      );
  };

  handleCalendar = () => {
    //Now retreive calendar entries for this user based on date. retrieve -6 month + 6 month
    httpClient
      .post(
        "getCalendarEntries.php",
        qs.stringify({
          action: "calendar_entries",
          source: param,
        })
      )
      .then(
        ({ data }) => {
          if (data) {
            var calendarLastMod = {
              calendarLastModified: data.calendarLastModified,
            };
            var dataIndex =
              modifiedData &&
              modifiedData.findIndex((arr, i) => arr.calendarLastModified);
            modifiedData[dataIndex] = calendarLastMod;


            localStorage.setItem("calendar_entries", JSON.stringify(data.data));


            this.setState({
              syncModified: modifiedData,
              refresh: true,
              allApiCount: "true",
            });
            if (this.state.syncModified.length > 0) {
              var loginData = JSON.parse(localStorage.getItem("login"));
              loginData.syncLastModified = this.state.syncModified;
              loginData.syncLastModified !== [] &&
                localStorage.setItem("login", JSON.stringify(loginData));
            }
          } else {
          }
        }
        // error handler
      );
  };

  handleCustomerPets = () => {
    httpClient
      .post(
        "getCustomers.php",
        qs.stringify({
          action: "all_customers",
          source: param,
        })
      )
      .then(
        ({ data }) => {
          if (data) {
            let custLastModified = {
              customerLastModified: data.customerLastModified,
            };
            var dataIndex = modifiedData.findIndex(
              (arr, i) => arr.customerLastModified
            );
            modifiedData[dataIndex] = custLastModified;
            localStorage.setItem("customers", JSON.stringify(data.data));

            this.setState({
              syncModified: modifiedData,
              refresh: true,
              allApiCount: "true",
            });
            if (this.state.syncModified.length > 0) {
              var loginData = JSON.parse(localStorage.getItem("login"));
              loginData.syncLastModified = this.state.syncModified;
              loginData.syncLastModified !== [] &&
                localStorage.setItem("login", JSON.stringify(loginData));
            }
          } else {
          }
        }
        // error handler
      );

    //this.worker = new WebWorker(worker);

    //Now retreive all pets from system for this customer
    httpClient
      .post(
        "getPets.php",
        qs.stringify({
          action: "all_pets",
          source: param,
        })
      )
      .then(
        ({ data }) => {
          if (data) {
            let petLastMod = { petLastModified: data.petLastModified };
            var dataIndex = modifiedData.findIndex(
              (arr, i) => arr.petLastModified
            );
            modifiedData[dataIndex] = petLastMod;
            localStorage.setItem("pets", JSON.stringify(data.data));

            this.setState({
              syncModified: modifiedData,
              refresh: true,
              allApiCount: "true",
            });
            if (this.state.syncModified.length > 0) {
              var loginData = JSON.parse(localStorage.getItem("login"));
              loginData.syncLastModified = this.state.syncModified;
              loginData.syncLastModified !== [] &&
                localStorage.setItem("login", JSON.stringify(loginData));
            }
          } else {
          }
        }
        // error handler
      );
  };

  handleRetrieveAllData = () => {
    //now retrieve all Customers. (Need to move this to Webworker)
    this.setState({
      ...this.state,
      allApiCount: 0,
    });
    httpClient
      .post(
        "getCustomers.php",
        qs.stringify({
          action: "all_customers",
          source: param,
        })
      )
      .then(
        ({ data }) => {
          if (data) {
            let custLastModified = {
              customerLastModified: data.customerLastModified,
            };
            var dataIndex = modifiedData.findIndex(
              (arr, i) => arr.customerLastModified
            );
            modifiedData[dataIndex] = custLastModified;
            localStorage.setItem("customers", JSON.stringify(data.data));

            this.setState({
              syncModified: modifiedData,
              refresh: true,
              allApiCount: this.state.allApiCount + 1,
            });
            if (this.state.syncModified.length > 0) {
              var loginData = JSON.parse(localStorage.getItem("login"));
              loginData.syncLastModified = this.state.syncModified;
              loginData.syncLastModified !== [] &&
                localStorage.setItem("login", JSON.stringify(loginData));
            }
          } else {
          }
        }
        // error handler
      );

    //this.worker = new WebWorker(worker);

    //Now retreive all pets from system for this customer
    httpClient
      .post(
        "getPets.php",
        qs.stringify({
          action: "all_pets",
          source: param,
        })
      )
      .then(
        ({ data }) => {
          if (data) {
            let petLastMod = { petLastModified: data.petLastModified };
            var dataIndex = modifiedData.findIndex(
              (arr, i) => arr.petLastModified
            );
            modifiedData[dataIndex] = petLastMod;
            localStorage.setItem("pets", JSON.stringify(data.data));

            this.setState({
              syncModified: modifiedData,
              refresh: true,
              allApiCount: this.state.allApiCount + 1,
            });
            if (this.state.syncModified.length > 0) {
              var loginData = JSON.parse(localStorage.getItem("login"));
              loginData.syncLastModified = this.state.syncModified;
              loginData.syncLastModified !== [] &&
                localStorage.setItem("login", JSON.stringify(loginData));
            }
          } else {
          }
        }
        // error handler
      );

    //Now retreive all active bookings from system for this customer
    httpClient
      .post(
        "getActiveBookings.php",
        qs.stringify({
          action: "booking_active",
          source: param,
        })
      )
      .then(({ data }) => {
        if (data) {
          var activeBookingLastMod = {
            activeBookingLastModified: data.activeBookingLastModified,
          };
          var dataIndex = modifiedData.findIndex(
            (arr, i) => arr.activeBookingLastModified
          );
          modifiedData[dataIndex] = activeBookingLastMod;
          localStorage.setItem("booking_active", JSON.stringify(data.data));

          this.setState({
            syncModified: modifiedData,
            refresh: true,
            allApiCount: this.state.allApiCount + 1,
          });
          if (this.state.syncModified.length > 0) {
            var loginData = JSON.parse(localStorage.getItem("login"));
            loginData.syncLastModified = this.state.syncModified;
            loginData.syncLastModified !== [] &&
              localStorage.setItem("login", JSON.stringify(loginData));
          }
        } else {
        }
      });

    //Now retreive calendar entries for this user based on date. retrieve -6 month + 6 month
    httpClient
      .post(
        "getCalendarEntries.php",
        qs.stringify({
          action: "calendar_entries",
          source: param,
        })
      )
      .then(
        ({ data }) => {
          if (data) {
            var calendarLastMod = {
              calendarLastModified: data.calendarLastModified,
            };

            var dataIndex =
              modifiedData &&
              modifiedData.findIndex((arr, i) => arr.calendarLastModified);
            modifiedData[dataIndex] = calendarLastMod;

            localStorage.setItem("calendar_entries", JSON.stringify(data.data));


            this.setState({
              syncModified: modifiedData,
              refresh: true,
              allApiCount: this.state.allApiCount + 1,
            });
            if (this.state.syncModified.length > 0) {
              var loginData = JSON.parse(localStorage.getItem("login"));
              loginData.syncLastModified = this.state.syncModified;
              loginData.syncLastModified !== [] &&
                localStorage.setItem("login", JSON.stringify(loginData));
            }
          } else {
          }
        }
        // error handler
      );

    //Now retreive all Completed bookings from system for this customer
    httpClient
      .post(
        "getCompletedBookings.php",
        qs.stringify({
          action: "booking_completed",
          source: param,
        })
      )
      .then(
        ({ data }) => {
          if (data) {
            var completedBookingLastMod = {
              completedBookingLastModified: data.completedBookingLastModified,
            };
            var dataIndex = modifiedData.findIndex(
              (arr, i) => arr.completedBookingLastModified
            );
            modifiedData[dataIndex] = completedBookingLastMod;
            localStorage.setItem(
              "booking_completed",
              JSON.stringify(data.data)
            );

            this.setState({
              syncModified: modifiedData,
              refresh: true,
              allApiCount: this.state.allApiCount + 1,
            });
            if (this.state.syncModified.length > 0) {
              var loginData = JSON.parse(localStorage.getItem("login"));
              loginData.syncLastModified = this.state.syncModified;
              loginData.syncLastModified !== [] &&
                localStorage.setItem("login", JSON.stringify(loginData));
            }
          } else {
          }
        }

        // error handler
      );

    //Now retreive all Completed bookings from system for this customer
    httpClient
      .post(
        "getCancelledBookings.php",
        qs.stringify({
          action: "booking_cancelled",
          source: param,
        })
      )
      .then(
        ({ data }) => {
          if (data) {
            var cancelledBookingLastMod = {
              cancelledBookingLastModified: data.cancelledBookingLastModified,
            };
            var dataIndex = modifiedData.findIndex(
              (arr, i) => arr.cancelledBookingLastModified
            );
            modifiedData[dataIndex] = cancelledBookingLastMod;
            localStorage.setItem(
              "booking_cancelled",
              JSON.stringify(data.data)
            );

            this.setState({
              syncModified: modifiedData,
              refresh: true,
              allApiCount: this.state.allApiCount + 1,
            });
            if (this.state.syncModified.length > 0) {
              var loginData = JSON.parse(localStorage.getItem("login"));
              loginData.syncLastModified = this.state.syncModified;
              loginData.syncLastModified !== [] &&
                localStorage.setItem("login", JSON.stringify(loginData));
            }
          } else {
          }
        }

        // error handler
      );

    //Now retreive all archived bookings from system for this customer
    httpClient
      .post(
        "getArchivedBookings.php",
        qs.stringify({
          action: "booking_archived",
          source: param,
        })
      )
      .then(({ data }) => {
        if (data) {
          var archivedBookingLastMod = {
            archivedBookingLastModified: data.archivedBookingLastModified,
          };
          var dataIndex = modifiedData.findIndex(
            (arr, i) => arr.archivedBookingLastModified
          );
          modifiedData[dataIndex] = archivedBookingLastMod;
          localStorage.setItem("booking_archived", JSON.stringify(data.data));

          this.setState({
            syncModified: modifiedData,
            refresh: true,
            allApiCount: this.state.allApiCount + 1,
          });
          if (this.state.syncModified.length > 0) {
            var loginData = JSON.parse(localStorage.getItem("login"));
            loginData.syncLastModified = this.state.syncModified;
            loginData.syncLastModified !== [] &&
              localStorage.setItem("login", JSON.stringify(loginData));
          }
        } else {
        }
      });
  };

  handleRefresh = () => {
    window.location.reload();
  };
  handleRefreshClose = () => {
    this.setState({
      refresh: false,
    });
  };





  render() {
    // this.handleAttention();

    let bookingObj = localStorage.getItem("booking_active");

    let todaysDate = new Date();
    let dd = ("0" + todaysDate.getDate()).slice(-2); //todaysDate.getDate();
    let mm = ("0" + (todaysDate.getMonth() + 1)).slice(-2); //todaysDate.getMonth() + 1;
    let y = todaysDate.getFullYear();
    let hours = todaysDate.getHours();
    let minutes = todaysDate.getMinutes();

    todaysDate = y + "/" + mm + "/" + dd + " " + hours + ":" + minutes;

    //let requiredTodaysDate = new Date(todaysDate.replace(' ', 'T'));
    let requiredTodaysDate = new Date(todaysDate);
    if (bookingObj != null) {
      let bookingListDetail = JSON.parse(bookingObj);
      if (bookingListDetail.length != 0) {
        attentionCount = 0;
        bookingListDetail.map((book) => {
          let bookingDate = book.bdate.split("-").join("/") + " " + book.btime;
          let requiredBookingDate = new Date(bookingDate);
          if (requiredBookingDate < requiredTodaysDate) {
            attentionCount = attentionCount + 1;
          }
        });
      }
    }

    const attValue = JSON.parse(localStorage.getItem("attention_detail"));

    const loginData1 = JSON.parse(localStorage.getItem("login"));
    // const stock = JSON.parse(localStorage.getItem("stockTake"))
    // const stockAtt = JSON.parse(localStorage.getItem("stock_attention"));
    // console.log('att value', attValue);

    if (
      attentionCount >= 30 &&
      attValue == "true" &&
      window.location.pathname !== "/app/notifications/bookings"
    ) {
      this.state.openAttentionDialog = true;
    }

    if (loginData1 && loginData1.acceptTsAndCs == "0") {
      this.state.openTermsDialog = true;
    }

    // if ((stock === true) && stockAtt == "true") {
    //   this.state.openStockMessage = true;
    // }



    // console.log("footer attention count", attentionCount);
    //console.log('Stock dialog', this.state.openStockMessage);
    //console.log('att dia', this.state.openAttentionDialog);

    //logout when next day

    // todayDate = new Date();
    // today = moment(todayDate).format("YYYY-MM-DD");

    // loggedDate = JSON.parse(localStorage.getItem("loginDate"));
    // if (today != null && loggedDate != null) {
    //   if (today == loggedDate) {
    //     // console.log("true");
    //   } else {
    //     localStorage.clear();
    //     window.location = "/logout";
    //   }
    // }

    loggedDate = JSON.parse(localStorage.getItem("loginDate"));
    // console.log('login date', loggedDate);
    if (loggedDate !== null) {
      var iscurrentDate = true;
      // console.log('current date', iscurrentDate);
      iscurrentDate = moment(loggedDate).isSame(new Date(), "week");
      // console.log('is same week', iscurrentDate);

      if (iscurrentDate === false) {
        // console.log('insideeeee date', iscurrentDate);

        //logout code here!
        localStorage.clear();
        window.location = "/logout";
      }
    }

    //logout when greater than 24 hours
    // var currentDate = moment(JSON.parse(localStorage.getItem("loginDate")));
    // var newDate = moment(new Date());
    // if (currentDate < newDate) {
    //   var diffInHrs = newDate.diff(currentDate, "hours");
    //   if (diffInHrs >= 24) {
    //     localStorage.clear();
    //     window.location = "/";
    //   }
    // }

    let openDialog = 0;
    openDialog = this.state.footerAlertVisible;

    let useraction = "";
    useraction = this.state.useraction;

    if (
      this.state.currentCount === 0 &&
      window.location.hostname !== "localhost"
    ) {
      //console.log("current count is 0");
      httpClient
        .post(
          "getversion.php",
          qs.stringify({
            action: "version_number",
            source: param,
          })
        )
        .then(
          ({ data }) => {
            if (data !== "") {
              if (this.state.footerAlertVisible === false) {
                if (data.version !== GlobalURL[0].version) {
                  this.setState(
                    {
                      logoutRequired: data.logout,
                      refreshRequired: data.refresh,
                    },
                    function () {
                      this.setState({ footerAlertVisible: true });
                    }
                  );
                }
              }
            } else {
            }
          },
          (error) => {
            this.setState({
              isLoaded: true,
              error,
            });
          }
        );
    } else {
      // console.log(this.state.currentCount);
    }

    if (window.location.pathname !== "/app/dashboard/overview") {
      let loginsss = JSON.parse(localStorage.getItem("login"));
      modifiedData = loginsss && loginsss.syncLastModified;
    }

    const {
      booking,
      bookingArchived,
      bookingCancelled,
      bookingCompleted,
      calendarEntries,
      customers,
      pets,
    } = this.state;

    // console.log('location fooyterrr', window.location);
    // console.log('state footey', this.state);
    // this.state.open = (booking === 1|| bookingArchived === 1 || bookingCancelled === 1 || bookingCompleted === 1 || calendarEntries === 1 || customers === 1 || pets === 1)

    // let currentCall;
    // let newArray = [booking,
    //   bookingArchived,
    //   bookingCancelled,
    //   bookingCompleted,
    //   calendarEntries,
    //   customers,
    //   pets];

    // if(booking === 1 && bookingArchived === 1 && bookingCancelled === 1 && bookingCompleted === 1 && calendarEntries === 1 && customers === 1 && pets === 1){
    //   currentCall = this.handleRetrieveAllData
    // }else if(booking === 1 && bookingArchived === 1 && bookingCancelled === 1 && bookingCompleted === 1 && calendarEntries === 1){
    //   currentCall = this.handleActiveBooking, this.handleArchivedBooking, this.handleCancelledBooking, this.handleCompletedBooking, this.handleCalendar
    // }else if(booking === 1 && bookingArchived === 1 && bookingCancelled === 1 && bookingCompleted === 1){
    //   currentCall = this.handleActiveBooking, this.handleArchivedBooking, this.handleCancelledBooking, this.handleCompletedBooking
    // }else if(booking === 1 && bookingArchived === 1 && bookingCancelled === 1){
    //   currentCall = this.handleActiveBooking, this.handleArchivedBooking, this.handleCancelledBooking
    // }else if(booking === 1 && bookingArchived === 1){
    //   currentCall = this.handleActiveBooking, this.handleArchivedBooking
    // }

    // else if(bookingArchived === 1 && bookingCancelled === 1 && bookingCompleted === 1 && calendarEntries === 1 && customers === 1 && pets === 1){
    //   currentCall = this.handleArchivedBooking, this.handleCancelledBooking, this.handleCompletedBooking, this.handleCalendar, this.handleCustomerPets
    // }else if(bookingArchived === 1 && bookingCancelled === 1 && bookingCompleted === 1 && calendarEntries === 1){
    //   currentCall = this.handleArchivedBooking, this.handleCancelledBooking, this.handleCompletedBooking, this.handleCalendar
    // }else if(bookingArchived === 1 && bookingCancelled === 1 && bookingCompleted === 1){
    //   currentCall = this.handleArchivedBooking, this.handleCancelledBooking, this.handleCompletedBooking
    // }else if(bookingArchived === 1 && bookingCancelled === 1){
    //   currentCall = this.handleArchivedBooking, this.handleCancelledBooking
    // }

    // else if(booking === 1 && bookingCancelled === 1 && bookingCompleted === 1 && calendarEntries === 1 && customers === 1 && pets === 1){
    //   currentCall = this.handleActiveBooking, this.handleCancelledBooking, this.handleCompletedBooking, this.handleCalendar, this.handleCustomerPets
    // }else if(booking === 1 && bookingCancelled === 1 && bookingCompleted === 1 && calendarEntries === 1){
    //   currentCall = this.handleActiveBooking, this.handleCancelledBooking, this.handleCompletedBooking, this.handleCalendar
    // }else if(booking === 1 && bookingCancelled === 1 && bookingCompleted === 1){
    //   currentCall = this.handleActiveBooking, this.handleCancelledBooking, this.handleCompletedBooking
    // }else if(booking === 1 && bookingCancelled === 1){
    //   currentCall = this.handleActiveBooking, this.handleCancelledBooking
    // }

    // else if(bookingArchived === 1){
    //   currentCall = this.handleArchivedBooking
    // }
    // else if(booking === 1){
    //   currentCall = this.handleActiveBooking
    // }

    // console.log('calendar enteie', calendarEntries)
    // let openSnack = this.handleOpen();






    return (
      <footer className="app-footer">
        <span className="d-inline-block">
          Copyright FranchiseCare &copy; {new Date().getFullYear()}
        </span>

        {loginData && loginData.exMember != "1" && (
          <Tooltip title="Check for new Data">
            <IconButton
              className="icon-btn"
              style={{
                position: "absolute",
                top: "20px",
                right: "10px",
                zIndex: "1111",
                padding: "5px",
              }}
              disabled={this.state.loading ? true : false}
              onClick={this.handleCheckNewData}
            >
              <LoopIcon fontSize="small" style={{ color: "#fff" }} />
            </IconButton>
          </Tooltip>
        )}

        {featureServer && (
          // window.location.pathname === "/app/dashboard/overview" &&
          <Snackbar
            anchorOrigin={{
              vertical: "bottom",
              horizontal: "center",
            }}
            open={this.state.open}
            autoHideDuration={null}
            onClose={this.handleClose}
            message={"New Records found on Server"}
            action={
              <React.Fragment>
                <Button
                  color="secondary"
                  size="small"
                  onClick={
                    // (booking === 1 && this.handleActiveBooking) || (bookingArchived === 1 && this.handleArchivedBooking) || (bookingCancelled === 1 && this.handleCancelledBooking) ||
                    // (bookingCompleted === 1 && this.handleCompletedBooking) || (calendarEntries === 1 && this.handleCalendar) || ((pets === 1 || customers === 1) && this.handleCustomerPets) ||
                    this.handleRetrieveAllData
                  }
                >
                  Retrieve Data from Server
                </Button>
                <IconButton
                  size="small"
                  aria-label="close"
                  color="inherit"
                  onClick={this.handleClose}
                >
                  <CloseIcon fontSize="small" />
                </IconButton>
              </React.Fragment>
            }
          />
        )}

        {featureServer &&
          window.location.pathname === "/app/bookings/activebook" &&
          booking === 1 && (
            <Snackbar
              anchorOrigin={{
                vertical: "bottom",
                horizontal: "center",
              }}
              open={this.state.open}
              autoHideDuration={null}
              onClose={this.handleClose}
              message={"New Records found on Active Booking"}
              action={
                <React.Fragment>
                  <Button
                    color="secondary"
                    size="small"
                    onClick={this.handleActiveBooking}
                  >
                    Retrieve Data from Active Booking
                  </Button>
                  <IconButton
                    size="small"
                    aria-label="close"
                    color="inherit"
                    onClick={this.handleClose}
                  >
                    <CloseIcon fontSize="small" />
                  </IconButton>
                </React.Fragment>
              }
            />
          )}

        {featureServer &&
          window.location.pathname === "/app/bookings/archivedbook" &&
          bookingArchived === 1 && (
            <Snackbar
              anchorOrigin={{
                vertical: "bottom",
                horizontal: "center",
              }}
              open={this.state.open}
              autoHideDuration={null}
              onClose={this.handleClose}
              message={"New Records found on Archive Booking"}
              action={
                <React.Fragment>
                  <Button
                    color="secondary"
                    size="small"
                    onClick={this.handleArchivedBooking}
                  >
                    Retrieve Data from Archive Booking
                  </Button>
                  <IconButton
                    size="small"
                    aria-label="close"
                    color="inherit"
                    onClick={this.handleClose}
                  >
                    <CloseIcon fontSize="small" />
                  </IconButton>
                </React.Fragment>
              }
            />
          )}

        {featureServer &&
          window.location.pathname === "/app/bookings/cancelledbook" &&
          bookingCancelled === 1 && (
            <Snackbar
              anchorOrigin={{
                vertical: "bottom",
                horizontal: "center",
              }}
              open={this.state.open}
              autoHideDuration={null}
              onClose={this.handleClose}
              message={"New Records found on Cancelled Booking"}
              action={
                <React.Fragment>
                  <Button
                    color="secondary"
                    size="small"
                    onClick={this.handleCancelledBooking}
                  >
                    Retrieve Data from Cancelled Booking
                  </Button>
                  <IconButton
                    size="small"
                    aria-label="close"
                    color="inherit"
                    onClick={this.handleClose}
                  >
                    <CloseIcon fontSize="small" />
                  </IconButton>
                </React.Fragment>
              }
            />
          )}

        {featureServer &&
          window.location.pathname === "/app/bookings/completedbook" &&
          bookingCompleted === 1 && (
            <Snackbar
              anchorOrigin={{
                vertical: "bottom",
                horizontal: "center",
              }}
              open={this.state.open}
              autoHideDuration={null}
              onClose={this.handleClose}
              message={"New Records found on Completed Booking"}
              action={
                <React.Fragment>
                  <Button
                    color="secondary"
                    size="small"
                    onClick={this.handleCompletedBooking}
                  >
                    Retrieve Data from Completed Booking
                  </Button>
                  <IconButton
                    size="small"
                    aria-label="close"
                    color="inherit"
                    onClick={this.handleClose}
                  >
                    <CloseIcon fontSize="small" />
                  </IconButton>
                </React.Fragment>
              }
            />
          )}

        {featureServer &&
          window.location.pathname === "/app/calendar/dnd" &&
          calendarEntries === 1 && (
            <Snackbar
              anchorOrigin={{
                vertical: "bottom",
                horizontal: "center",
              }}
              open={this.state.open}
              autoHideDuration={null}
              onClose={this.handleClose}
              message={"New Records found on Calendar"}
              action={
                <React.Fragment>
                  <Button
                    color="secondary"
                    size="small"
                    onClick={this.handleCalendar}
                  >
                    Retrieve Data from Calendar
                  </Button>
                  <IconButton
                    size="small"
                    aria-label="close"
                    color="inherit"
                    onClick={this.handleClose}
                  >
                    <CloseIcon fontSize="small" />
                  </IconButton>
                </React.Fragment>
              }
            />
          )}

        {featureServer &&
          window.location.pathname === "/app/contact" &&
          (customers === 1 || pets === 1) && (
            <Snackbar
              anchorOrigin={{
                vertical: "bottom",
                horizontal: "center",
              }}
              open={this.state.open}
              autoHideDuration={null}
              onClose={this.handleClose}
              message={
                customers === 1 && pets === 1
                  ? "New Records found on customer and pet lists"
                  : customers === 1
                    ? "New Records found on customer lists"
                    : pets === 1
                      ? "New records found on pet lists"
                      : ""
              }
              action={
                <React.Fragment>
                  <Button
                    color="secondary"
                    size="small"
                    onClick={this.handleCustomerPets}
                  >
                    {customers === 1 && pets === 1
                      ? "Retrieve Data from Customer & Pet"
                      : customers === 1
                        ? "Retrieve Data from Customers"
                        : pets === 1
                          ? "Retrieve Data from Pets"
                          : ""}
                  </Button>
                  <IconButton
                    size="small"
                    aria-label="close"
                    color="inherit"
                    onClick={this.handleClose}
                  >
                    <CloseIcon fontSize="small" />
                  </IconButton>
                </React.Fragment>
              }
            />
          )}

        {/* {featureServer &&
          window.location.pathname === "/app/blockout/listblocks" &&
          pets.refreshVal === 1 && (
            <Snackbar
              anchorOrigin={{
                vertical: "bottom",
                horizontal: "center",
              }}
              open={this.state.open}
              autoHideDuration={null}
              onClose={this.handleClose}
              message={"New Records found on Pets lists"}
              action={
                <React.Fragment>
                  <Button
                    color="secondary"
                    size="small"
                    onClick={this.handleCustomerPets}
                  >
                    Retrieve Data from Pets Lists
                  </Button>
                  <IconButton
                    size="small"
                    aria-label="close"
                    color="inherit"
                    onClick={this.handleClose}
                  >
                    <CloseIcon fontSize="small" />
                  </IconButton>
                </React.Fragment>
              }
            />
          )} */}

        {featureServer &&
          this.state.refresh === true &&
          (this.state.allApiCount === "true" ||
            this.state.allApiCount === 7) && (
            <Snackbar
              anchorOrigin={{
                vertical: "bottom",
                horizontal: "right",
              }}
              open={this.state.refresh}
              autoHideDuration={30000}
              onClose={this.handleRefreshClose}
              message={"You need to Refresh Page"}
              action={
                <React.Fragment>
                  <Button
                    color="secondary"
                    size="small"
                    onClick={this.handleRefresh}
                    disabled={
                      this.state.allApiCount === "true" ||
                        this.state.allApiCount === 7
                        ? false
                        : true
                    }
                  >
                    {this.state.allApiCount === "true" ||
                      this.state.allApiCount === 7 ? (
                      <>
                        <span>Refresh </span>
                        <RefreshIcon
                          fontSize="small"
                          style={{ marginLeft: "10px" }}
                        />
                      </>
                    ) : (
                      <span
                        style={{
                          color: "#fff",
                          fontSize: "12px",
                          marginRight: "5px",
                        }}
                      >
                        Fetching...
                      </span>
                    )}
                  </Button>
                  <IconButton
                    size="small"
                    aria-label="close"
                    color="inherit"
                    onClick={this.handleRefreshClose}
                  >
                    <CloseIcon fontSize="small" />
                  </IconButton>
                </React.Fragment>
              }
            />
          )}

        {this.state.openAttentionDialog && (
          <AttentionDialog
            sendTo={this.sendTo}
            open={this.state.openAttentionDialog}
          />
        )}

        {/* {this.state.openStockMessage && window.location.pathname !== "/app/inventory/stock_take" && window.location.pathname !== "/app/dashboard/overview" && featureServer && (
          <StockMessage
            sendToStock={this.sendToStock}
            open={this.state.openStockMessage}
          />
        )} */}

        {this.state.openFirstMessage && (
          <FirstMessage
            sendTo={this.sendToFirst}
            open={this.state.openFirstMessage}
          />
        )}

        {/* && window.location.pathname !== "/app/inventory/stock_take" */}



        {/* {this.state.openTermsDialog && featureServer && (
          <TandCDialog
            localData={loginData}
            open={this.state.openTermsDialog}
            callbackTandC={this.callbackTandC} />
        )} */}



        {(() => {
          if (openDialog === true) {
            return (
              <FooterAlert
                parentCallback={this.callbackFunctionFromAlert}
                passToChild={this.state}
                useraction={useraction}
              />
            );
          }
        })()}
        {/* <NotificationContainer /> */}
      </footer>
    );
  }
}

export default Footer;
