import React from "react";
import Widget from "components/Widget";

const Biography = props => {
  const { groupInfo } = props;


  return (
    <Widget styleName="jr-card-profile">
      <div className="">
        <h3 className="card-title mb-2 mb-md-3">About</h3>
        {props.groupMode ? (
          <>
            {/* <h3 className="jr-font-weight-light">
              Some description about the group:
            </h3> */}
            <p className="text-grey jr-fs-md mb-0">
              {groupInfo && groupInfo.groupDescription}
            </p>
            <p className="text-grey jr-fs-sm mt-3 mb-0" style={{textAlign: "right"}}>
              Owner Name - {groupInfo && groupInfo.groupOwnerName}
            </p>
          </>
        ) : (
          <>
            <p className="text-grey jr-fs-sm mb-0">No description ...</p> 
          </>
        )}
      </div>
    </Widget>
  );
};

export default Biography;
