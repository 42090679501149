import React, { useState, useEffect } from "react";
import { WebView } from 'react';
import CardBox from 'components/CardBox/index';
import ContainerHeader from 'components/ContainerHeader/index';
import {

    Grid,

} from "@material-ui/core";
import { NotificationContainer, NotificationManager } from 'react-notifications';
import { httpClient } from "../../../appUtility/Api";
import Accordion from '@material-ui/core/Accordion';
import AccordionSummary from '@material-ui/core/AccordionSummary';
import AccordionDetails from '@material-ui/core/AccordionDetails';
import Typography from '@material-ui/core/Typography';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import moment from "moment";

const qs = require("qs");

let loginOBJ = localStorage.getItem("login");
let logindetail = JSON.parse(loginOBJ);

const Versions = ({ match }) => {
    const [response, setResponse] = useState([]);
    const [expandedAccordion, setExpandedAccordion] = useState(null);
    const [loading, setLoading] = useState(false);

    useEffect(() => {

        getLogs();

    }, []);

    const getLogs = () => {
        setLoading(true);
        httpClient
            .post(
                "getVersions.php",
                qs.stringify({
                    action: "changelog",
                })
            )
            .then((res) => {
                if (res.data.data === "" || null) {
                    NotificationManager.warning(
                        "No Logs Available"
                    );
                    setLoading(false);
                } else {
                    setResponse(res.data.data);
                    setLoading(false);
                }
            });
    };

    const handleChangeAccordion = (panel) => (event, isExpanded) => {
        setExpandedAccordion(isExpanded ? panel : null);
    };


    return (

        loading ?
            <div style={{ padding: "20px" }}>Loading ...</div> :

            <div className="animated slideInUpTiny animation-duration-3 jr-card ">

                <ContainerHeader title={"Version Update"} match={match} />


                <div className="row">
                    <div className="col-12">
                        {Object.entries(response).map(([date, versions], index) => {
                            const dateObj = new Date(date);
                            // Get month and year
                            const month = dateObj.toLocaleString('default', { month: 'long' });
                            const year = dateObj.getFullYear();
                            // Formatted date string
                            const formattedDate = `${month} ${year}`;

                            return (
                                <Accordion
                                    expanded={expandedAccordion === `panel${index}`}
                                    onChange={handleChangeAccordion(`panel${index}`)}
                                    key={index}>
                                    <AccordionSummary
                                        expandIcon={<ExpandMoreIcon />}
                                        aria-controls={`panel${index}-content`}
                                        id={`panel${index}-header`}
                                    >
                                        <Typography>{formattedDate}</Typography>
                                    </AccordionSummary>
                                    <AccordionDetails>
                                        <Grid container spacing={2}>
                                            {versions.map((version, subIndex) => (
                                                <React.Fragment key={subIndex}>
                                                    <Grid item md={1}>
                                                        <div style={{ padding: "10px" }}>
                                                            <Typography style={{ fontStyle: "italic", fontSize: "14px" }}>#{version.versionNumber}</Typography>
                                                        </div>
                                                    </Grid>
                                                    <Grid item md={11}>
                                                        <div style={{ padding: "10px" }}>
                                                            {version.versionDescription.map((ver, index) => (
                                                                <Typography key={index} style={{ fontSize: "14px" }}> - {ver}</Typography>
                                                            ))}
                                                        </div>
                                                    </Grid>
                                                </React.Fragment>
                                            ))}
                                        </Grid>
                                    </AccordionDetails>


                                </Accordion>
                            );
                        })}


                    </div>
                </div>




                <NotificationContainer />
            </div>

    );
};

export default Versions;

