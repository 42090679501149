import React from "react";
// import PropTypes from 'prop-types';
import keycode from "keycode";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableFooter from "@material-ui/core/TableFooter";
import TableHead from "@material-ui/core/TableHead";
import TablePagination from "@material-ui/core/TablePagination";
import TableRow from "@material-ui/core/TableRow";

import Paper from "@material-ui/core/Paper";

import { httpClient } from "appUtility/Api";
import Button from "@material-ui/core/Button";
import {
  NotificationContainer,
  NotificationManager,
} from "react-notifications";
// import IntlMessages from 'util/IntlMessages';
import CardBox from "components/CardBox/index";
import AppModuleHeader from "components/AppModuleHeader/index";
import InventoryDialog from "./InventoryDialog";

let counter = 0;

const loginOBJ = localStorage.getItem("login");
const logindetail = JSON.parse(loginOBJ);

const qs = require("qs");

var param;

let entryid,
  title,
  desc,
  cid,
  date,
  amnt,
  active,
  recur = "";

let ArchivedOBJ = localStorage.getItem("customers_archived");

let archivedObjDetail = "";
if (ArchivedOBJ != null) archivedObjDetail = JSON.parse(ArchivedOBJ);
else archivedObjDetail = "";

const columnData = [
  // { id: "check", align: false, disablePadding: true, label: "" },
  { id: "serviceID", align: false, disablePadding: true, label: "Service ID" },
  { id: "serviceName", align: false, disablePadding: true, label: "Service Name" },
  { id: "inventoryUsage", align: true, disablePadding: false, label: "Inventory Items" },
  { id: "action", align: true, disablePadding: false, label: "Action" },
];

class DataTableHead extends React.Component {
  render() {
    return (
      <TableHead>
        <TableRow>
          <TableCell padding="checkbox"></TableCell>
          {columnData.map((column) => {
            return <TableCell key={column.id}>{column.label}</TableCell>;
          }, this)}
        </TableRow>
      </TableHead>
    );
  }
}

class DataTable extends React.Component {
  handleRequestSort = (event, property) => {
    const orderBy = property;
    let order = "desc";

    if (this.state.orderBy === property && this.state.order === "desc") {
      order = "asc";
    }

    const data =
      order === "desc"
        ? this.state.data.sort((a, b) => (b[orderBy] < a[orderBy] ? -1 : 1))
        : this.state.data.sort((a, b) => (a[orderBy] < b[orderBy] ? -1 : 1));

    this.setState({ data, order, orderBy });
  };
  handleSelectAllClick = (event, checked) => {
    if (checked) {
      this.setState({ selected: this.state.data.map((n) => n.id) });
      return;
    }
    this.setState({ selected: [] });
  };
  handleKeyDown = (event, id) => {
    if (keycode(event) === "space") {
      this.handleClick(event, id);
    }
  };
  handleClick = (event, id) => {
    const { selected } = this.state;
    const selectedIndex = selected.indexOf(id);
    let newSelected = [];

    if (selectedIndex === -1) {
      newSelected = newSelected.concat(selected, id);
    } else if (selectedIndex === 0) {
      newSelected = newSelected.concat(selected.slice(1));
    } else if (selectedIndex === selected.length - 1) {
      newSelected = newSelected.concat(selected.slice(0, -1));
    } else if (selectedIndex > 0) {
      newSelected = newSelected.concat(
        selected.slice(0, selectedIndex),
        selected.slice(selectedIndex + 1)
      );
    }

    this.setState({ selected: newSelected });
  };
  handleChangePage = (event, page) => {
    this.setState({ page });
  };
  handleChangeRowsPerPage = (event) => {
    this.setState({ rowsPerPage: event.target.value, page: 0 });
  };
  isSelected = (id) => this.state.selected.indexOf(id) !== -1;

  state = {
    anchorEl: undefined,
    open: false,
    edit: false,
    editid: entryid,
    title: title,
    desc: desc,
    catid: cid,
    catname: "",
    date: date,
    amnt: amnt,
    active: active,
    recurring: recur,
  };

  constructor(props, context) {
    super(props, context);

    this.state = {
      order: "asc",
      selected: [],
      page: 0,
      rowsPerPage: 25,
      isLoaded: false,
      archivedObjDetail: "",
      error: "",
      openDialog: false,
      useraction: "",
      actionRecord: "",
      appID: logindetail.aid,
      memberID: logindetail.uid,
      companyID: logindetail.cid,
      mcode: logindetail.mCode,
      filterOption: "AllRecords",
      recordList: "",
      includeBooking: "",
      archiveCount: "",
      loadingReactive: false,
      openInventoryDialog: false,
      inventoryDetails: {}
    };
  }

  handleInventoryUseage = (n) => {
    console.log('selected service', n);
    this.setState({
      ...this.state,
      openInventoryDialog: true,
      inventoryDetails: n
    })
  }

  sendInventoryData = call => {
    if(call.open === false){
      this.setState({
        ...this.state,
        openInventoryDialog: false,
        inventoryDetails: {}
      })
      this.componentDidMount();
    }
  }
  
  // getCalendarEntries = () => {
  //   let aid = logindetail.aid;
  //   let cid = logindetail.cid;
  //   let uid = logindetail.uid;
  //   let loginas = logindetail.loginas;
  //   let mobiledevice = logindetail.ismobiledevice;

  //   let param = aid + "_" + cid + "_" + uid + "_" + loginas + "_" + mobiledevice;

  //   httpClient
  //     .post(
  //       "getCalendarEntries.php",
  //       qs.stringify({
  //         action: "calendar_entries",
  //         source: param,
  //       })
  //     )
  //     .then(
  //       ({ data }) => {
  //         if (data) {
  //           localStorage.removeItem("calendar_entries");
  //           localStorage.setItem("calendar_entries", JSON.stringify(data.data));
  //           this.setState({
  //             loadCompleted: 1,
  //           });

  //           var loginTempOBJ = JSON.parse(localStorage.getItem("login"));

  //           loginTempOBJ.autoExtend = 0;
  //           localStorage.setItem("login", JSON.stringify(loginTempOBJ));
  //         } else {
  //         }
  //       }
  //     )
  // }

  updateContactUser(e) {
    this.setState({
      searchUser: e.target.value,
    });
    this.filterContact(e.target.value);
  }

  filterContact = (userName) => {
    const { filterOption } = this.state;
    if (userName === "") {
      this.setState({ recordList: this.state.allRecords });
    } else {
      const filterContact = this.state.allRecords.filter(
        (contact) =>
          (contact.serviceName).toLowerCase().indexOf(userName.toLowerCase()) >
          -1
      );
      if (filterOption === "AllRecords") {
        this.setState({
          recordList: filterContact,
          page: 0
        });
      } else if (filterOption === "Frequently contacted") {
        this.setState({
          recordList: filterContact.filter((contact) => contact.frequently),
          page: 0
        });
      } else if (filterOption === "Starred contacts") {
        this.setState({
          recordList: filterContact.filter((contact) => contact.starred),
          page: 0
        });
      }
    }
  };


  componentDidMount() {
    //alert('a');

    var aid, cid, uid;
    aid = logindetail.aid;
    cid = logindetail.cid;
    uid = logindetail.uid;
    param = aid + "_" + cid + "_" + uid;

    var newParams = {
      appID: aid,
      companyID: cid,
      memberID: uid
    }

    httpClient
      .post(
        "serviceInventoryUsages.php",
        qs.stringify({
          action: "servicelist",
          source: newParams,
        })
      )
      .then(
        ({ data }) => {
          if (data) {
            this.setState({
              isLoaded: true,
              allRecords: data,
              recordList: data,
            });
          } else {
            
          }
        }
      )
  }

  newCustomer = () => {
    window.location = "/app/newcontact";
  };

  handleArchive = (id) => {

    this.setState({
      openDialog: true,
      actionRecord: id,
    });

    // this.archiveBookingCount(id);

  };


  render() {
    console.log('state in service inventry', this.state);
    let recordsFound = 0;
    const { data, order, orderBy, selected, rowsPerPage, page } = this.state;
    const { error, isLoaded } = this.state;
    const { recordList } = this.state;
    let openDialog = 0;
    openDialog = this.state.openDialog;
    let useraction = "";
    useraction = this.state.useraction;

    if (recordList.length !== 0) recordsFound = 1;
    else recordsFound = 0;

    /*
	  if(archivedObjDetail.length!=0)
		recordsFound=1;
	  else
		  recordsFound=0;
	  */

    if (error) {
      return <div className="jr-customers">Error: {error.message}</div>;
    } 
    // else if (recordsFound == 0) {
    //   return <div className="jr-customers">No records found</div>;
    // }
    else if (!isLoaded) {
      return <div className="jr-customers">Loading ...</div>;
    } else {
      return (
        <div className="animated slideInUpTiny animation-duration-3">
          <CardBox styleName="form-full-width-lawa LawaCardBoxTopMargin">
            <div className="row form-full-width-lawa row-full-width">
              <div className="col-md-6 col-lg-6 col-sm-6">
                <div className="module-box-header">
                  <AppModuleHeader
                    placeholder="Search Service"
                    notification={false}
                    apps={false}
                    user={this.state.user}
                    onChange={(e) => this.updateContactUser(e)}
                    value={this.state.searchUser}
                  />
                </div>
              </div>
              {/* <div className="col-md-6 col-lg-6 col-sm-6 LawaAlignRight">
                <Button
                  variant="contained"
                  className="jr-btn bg-white LawaTopButtonAlignment"
                  onClick={() => {
                    this.newCustomer();
                  }}
                >
                  <i className="zmdi zmdi-plus zmdi-hc-fw" />
                  <span>New Customer</span>
                </Button>
              </div> */}
            </div>
          </CardBox>
          <CardBox
            styleName="col-12 fullWidthLawaNoPadding"
            cardStyle=" p-0"
            headerOutside
          >
            <Paper>
              <div className="flex-auto">
                <div className="table-responsive-material">
                  <Table>
                    <DataTableHead
                      numSelected={recordList.length}
                      order={order}
                      orderBy={orderBy}
                      onSelectAllClick={this.handleSelectAllClick}
                      onRequestSort={this.handleRequestSort}
                      rowCount={recordList.length}
                    />
                    <TableBody>
                      {recordsFound ? (
                        recordList
                          .slice(
                            page * rowsPerPage,
                            page * rowsPerPage + rowsPerPage
                          )
                          .map((n) => {
                            return (
                              <TableRow
                                id={"row_" + n.serviceID}
                                key={n.serviceID}
                              >
                                <TableCell padding="checkbox"></TableCell>
                                <TableCell >
                                  {n.serviceID}
                                </TableCell>
                                <TableCell >
                                  {n.serviceName}
                                </TableCell>
                                <TableCell>
                                  {n.inventoryUsage.length}
                                </TableCell>
                                <TableCell>
                                  <span
                                    id={n.serviceID}
                                    style={{ cursor: "pointer", pointerEvents: this.state.loadingReactive ? "none" : "auto" }}
                                    className="text-primary"
                                    onClick={() =>
                                      this.handleInventoryUseage(n)
                                    }
                                  >
                                    View Inventory Usage
                                    {/* {(this.state.loadingReactive && (this.state.actionRecord === n.serviceID)) ? "Loading..." : "Archive Customer"} */}
                                  </span>
                                </TableCell>
                              </TableRow>
                            );
                          })
                      ) : (
                        <TableRow>
                          <TableCell padding="none" align="center" colSpan={5}>
                            No Services Found
                          </TableCell>
                        </TableRow>
                      )}
                    </TableBody>
                    {recordsFound ? (
                      <TableFooter>
                        <TableRow>
                          <TablePagination
                            count={recordList.length}
                            rowsPerPage={rowsPerPage}
                            page={page}
                            onChangePage={this.handleChangePage}
                            onChangeRowsPerPage={this.handleChangeRowsPerPage}
                          />
                        </TableRow>
                      </TableFooter>
                    ) : ""}
                  </Table>
                </div>
              </div>


              <NotificationContainer />
            </Paper>
          </CardBox>


          {this.state.openInventoryDialog && (
            <InventoryDialog inventoryDetails={this.state.inventoryDetails} sendInventoryData={this.sendInventoryData} />
          )}

        </div>
      );
    }
  }
}

export default DataTable;
