import React, { useState, useEffect } from "react";
import Button from "@material-ui/core/Button";
import FriendsCard from "./FriendsCard";
import SearchBox from "../SearchBox";
import CircularProgress from "@material-ui/core/CircularProgress";
import { DetailsOutlined } from "@material-ui/icons";
import { httpClient } from "../../../appUtility/Api";
import {
  NotificationManager,
  NotificationContainer,
} from "react-notifications";
import AlertDialog from "./AlertDialog";

const qs = require("qs");
const socialData = JSON.parse(window.localStorage.getItem("login"));

const TotalFriends = (props) => {
  const { allFriends, profileMode, groupSector, members, friendList, groupInfo } = props;

  const [alertBlock, setAlertBlock] = useState(false);
  const [memberID, setMemberID] = useState(" ");
  const [blockSuccess, setBlockSuccess] = useState(false);
  const [inviteData, setInviteData] = useState([]);
  const [userValid, setUserValid] = useState(false);

  const [teamState, setTeamState] = useState({
    searchText: "",
    teamList: [],
    emptySearch: false,
  });

  useEffect(() => {
    setTeamState({ ...teamState, teamList: members || friendList });
    (groupInfo && groupInfo.groupOwnerID === (socialData && socialData.uid)) ? setUserValid(true) : setUserValid(false);
  }, [friendList]);

  const handleAlertOpen = (detail) => {
    setMemberID(detail.memberID);
    setAlertBlock(true);
  };

  const closeAlert = () => {
    setAlertBlock(false);
  };


  const handleBackButton = () => {
    allFriends(false);
  };

  const handleSearchText = (e) => {
    setTeamState({ ...teamState, searchText: e.target.value });
  };

  const handleKick = () => {
    const blockInfo = {
      userID: socialData && socialData.uid,
      userCode: socialData && socialData.memtoken,
      appID: 1,
      companyID: socialData && socialData.cid,
      groupID: props.groupID,
      subscriberID: memberID,
    };

    httpClient
      .post(
        "api/social/group_block.php",
        qs.stringify({
          action: "block_user",
          source: blockInfo,
        })
      )
      .then((res) => {
        if (res.data.success === "false") {
          NotificationManager.warning(res.data.msg);
        } else {
          NotificationManager.success(res.data.msg);
          if(res.data.success === "true"){
            setBlockSuccess(true);
            setAlertBlock(false);
            props.getAllMembers();
            setTeamState({
              teamList: friendList,
            });
          }
          
        }
      });
  };

  const handleUnblock = () => {
    const blockInfo = {
      userID: socialData && socialData.uid,
      userCode: socialData && socialData.memtoken,
      appID: 1,
      companyID: socialData && socialData.cid,
      groupID: props.groupID,
      subscriberID: memberID,
    };

    httpClient
      .post(
        "api/social/group_block.php",
        qs.stringify({
          action: "unblock_user",
          source: blockInfo,
        })
      )
      .then((res) => {
        if (res.data.success === "false") {
          NotificationManager.warning(res.data.msg);
        } else {
          NotificationManager.success(res.data.msg);
          // if(res.data.success === "true"){
            setBlockSuccess(false);
            setAlertBlock(false);
            props.getAllMembers();
            setTeamState({
              teamList: friendList,
            });
          // }
          
        }
      });
  }

  const handleInvite = (data) => {
    console.log("invite", data);
    const inviteInfo = {
      userID: socialData && socialData.uid,
      userCode: socialData && socialData.memtoken,
      appID: 1,
      companyID: socialData && socialData.cid,
      groupID: props.groupID,
      subscriberID: data.memberID,
    };

    httpClient
      .post(
        "api/social/group_invite.php",
        qs.stringify({
          action: "invite_user",
          source: inviteInfo,
        })
      )
      .then((res) => {
        if (res.data.success === "false") {
          NotificationManager.warning(res.data.msg);
        } else {
          NotificationManager.success(res.data.msg);
          console.log("data invite", res.data);
          if(res.data.success === "true"){
            setInviteData(res.data);
            // setBlockSuccess(true);
            props.getAllMembers();
            setTeamState({
              teamList: friendList,
            });
          }
          
        }
      });
  };

  const findSearchResult = (e) => {
    if (e.key === "Enter") {
      let filter_member = teamState.teamList.filter(
        (item) =>
          item.firstName
            .toLowerCase()
            .includes(teamState.searchText.toLowerCase()) ||
          item.lastName
            .toLowerCase()
            .includes(teamState.searchText.toLowerCase()) ||
          item.serviceLocation
            .toLowerCase()
            .includes(teamState.searchText.toLowerCase())
      );

      setTimeout(() => {
        if (filter_member && filter_member.length === 0) {
          console.log("Filter result::>>", filter_member);
          setTeamState({
            ...teamState,
            teamList: filter_member,
            emptySearch: true,
          });
        } else {
          setTeamState({
            ...teamState,
            teamList: filter_member,
            emptySearch: false,
          });
        }
      }, 1000);
    }
  };

  const handleDefaultList = (e) => {
    if (e.key === "Backspace") {
      setTeamState({
        ...teamState,
        teamList: members || friendList,
        emptySearch: false,
      });
    }
    if (e.key === "Enter") {
      setTeamState({
        ...teamState,
        teamList: members || friendList,
        emptySearch: false,
      });
    }
  };


  return (
    <>
      {alertBlock && (
        <AlertDialog
          alertBlock={alertBlock}
          blockSuccess={blockSuccess}
          closeAlert={closeAlert}
          handleKick={handleKick}
          handleUnblock={handleUnblock}
        />
      )}
      <div>
        {!profileMode && (
          <Button variant="contained" onClick={handleBackButton}>
            <i
              className={`zmdi zmdi-long-arrow-left jr-fs-xxl d-inline-flex align-middle`}
            />
            <span style={{ marginLeft: "6px" }}>Back</span>
          </Button>
        )}

        <div className="jr-card" style={{ marginTop: "2%" }}>
          <div className="jr-card-header d-flex">
            <div className="mr-auto">
              {groupSector ? (
                <h5 className="card-heading">All Members</h5>
              ) : (
                <h5 className="card-heading">Team Members List</h5>
              )}
            </div>
            <SearchBox
              styleName="d-lg-block"
              placeholder="Search Members"
              value={teamState.searchText}
              onChange={(e) => handleSearchText(e)}
              onKeyPress={(e) => findSearchResult(e)}
              onKeyDown={(e) => handleDefaultList(e)}
            />
          </div>
          <div className="row">
            {teamState.teamList && teamState.teamList && teamState.teamList.length > 0 ? (
              teamState.teamList.map((detail, index) => (
                <FriendsCard
                  key={index}
                  detail={detail && detail}
                  userInvites={props.userInvites}
                  handleAlertOpen={handleAlertOpen}
                  handleInvite={handleInvite}
                  handleUnblock={handleUnblock}
                  blockSuccess={blockSuccess}
                  inviteData={inviteData.newState}
                  userValid={userValid}
                />
              ))
            ) : (
              <>
                {teamState.emptySearch ? (
                  <div>
                    <h4 className="card-heading">Sorry, No results found...</h4>
                  </div>
                ) : (
                  <CircularProgress style={{ marginLeft: "5%" }} />
                )}
              </>
            )}
          </div>
        </div>
      </div>
    </>
  );
};
export default TotalFriends;
