import React, { Component } from "react";
import Avatar from "@material-ui/core/Avatar";
// import Button from "@material-ui/core/Button";
import moment from "moment";
import { XCircle } from "react-bootstrap-icons";
import { httpClient } from "appUtility/Api";
import AlertDialog from "./AlertDialog";
import IconButton from "@material-ui/core/IconButton";
import {
  NotificationManager,
  NotificationContainer
} from "react-notifications";
import parse from "html-react-parser";
import CommentImageBox from "./CommentImageBox";
import { Edit } from "@material-ui/icons";
import EditCommentDialog from "./EditCommentDialog";
import featureServer from "../../../appUtility/featureServer";

const qs = require("qs");
const socialData = JSON.parse(window.localStorage.getItem("login"));

class CommentBox extends Component {
  state = {
    isComment: false,
    commentData: {},
    addComment: false,
    userID: socialData && socialData.uid,
    deleteAlert: false,
    editAlert: false,
    parentCommentID: "",
    postID: ""
  };

  _handleKeyPress = e => {
    e.preventDefault();
    if (e.key === "Enter") {
      this.handleCommentToggle();
    }
  };

  componentWillMount() {
    this.setState({
      ...this.state,
      commentData: this.props.commentData,
      parentCommentID: this.props.commentData.parentCommentID,
      deleteAlert: this.props.deleteAlert,
      postID: this.props.postID
    });
  }

  // handleLikeToggle() {
  //   this.setState(previousState => ({
  //     commentData: {
  //       ...previousState.commentData,
  //       isLike: !previousState.commentData.isLike,
  //       likeCount:
  //         previousState.commentData.isLike === true
  //           ? previousState.commentData.likeCount - 1
  //           : previousState.commentData.likeCount + 1
  //     }
  //   }));
  // }

  RequestSubCommentEdit = () => {
    this.setState({
      ...this.state,
      editAlert: true
    })
  }

  RequestSubCommentEditClose = () => {
    this.setState({
      ...this.state,
      editAlert: false
    })
  }

  editSubComment = (data) => {
    // console.log('sub comment edit !!!!!!!', data);
    this.props.editSubComment(data);
  }

  RequestCommentDelete = () => {
    this.setState({ ...this.state, deleteAlert: true });
  };

  CancelCommentDelete = () => {
    this.setState({ ...this.state, deleteAlert: false });
  };

  // handleCommentDelete = (postID, commentID) => {
  //   const deleteQuery = {
  //     userID: socialData && socialData.uid,
  //     userCode: socialData && socialData.memtoken,
  //     appID: 1,
  //     companyID: socialData && socialData.cid,
  //     postID: postID,
  //     commentID: commentID,
  //     parentCommentID: this.state.parentCommentID
  //   };

  //   httpClient
  //     .post(
  //       "api/social/posts_comments_save.php",
  //       qs.stringify({
  //         action: "comments_delete ",
  //         source: deleteQuery
  //       })
  //     )
  //     .then(res => {
  //       if (res.data.success !== "true") {
  //         this.CancelCommentDelete();
  //         NotificationManager.warning(res.data.msg);
  //       } else {
  //         this.CancelCommentDelete();
  //         this.props.filterSubComment(res.data.commentID);
  //       }
  //     });
  // };

  handleCommentDelete = (postID, commentID) =>{
    this.CancelCommentDelete();
    this.props.handleRemove(postID, commentID,);
  }

  render() {
    const {
      commentText,
      commentedDate,
      commentID,
      commentUserName,
      commentUserID,
      parentCommentID,
      userThumbnail,
      postID
      // totalCommentLikes,
      //   totalComments
    } = this.state.commentData;

    // console.log('subcomment state >>', this.state);

    let regex = "/(\b(https?|ftp|file):\/\/[-A-Z0-9+&@#\/%?=~_|!:,.;]*[-A-Z0-9+&@#\/%=~_|])/ig";
    let final= (commentText);
    let match = (commentText).match(/(\b(https?|ftp|file):\/\/[-A-Z0-9+&@#\/%?=~_|!:,.;]*[-A-Z0-9+&@#\/%=~_|])/ig);
    let newURL;
    match && match.map(url=>{
      newURL = url.split('//').pop().split('/')[0] + "..." + url.slice(url.length - 10);
      final = final.replace(url,"<a href=\""+url+"\" target=\"_BLANK\">" + (url.length > 60 ? newURL : url) + "</a>");
    })

    return (
      <>
        {this.state.deleteAlert && (
          <AlertDialog
            postID={this.state.postID}
            commentID={commentID}
            commentAlert={true}
            parentCommentID={parentCommentID}
            deleteAlert={this.state.deleteAlert}
            CancelCommentDelete={this.CancelCommentDelete}
            handleCommentDelete={this.handleCommentDelete}
          />
        )}
        {this.state.editAlert && (
          <EditCommentDialog
            open={this.state.editAlert}
            handleClose={this.RequestSubCommentEditClose}
            editComment={this.editSubComment}
            post={this.state.commentData}
            subComment={true}
          />
        )}

        <div
          className="media flex-nowrap jr-wall-user-info mb-3"
          key={this.props.key}
        >
          <Avatar
            alt={commentUserName}
            className="mr-3 jr-size-40"
            src={userThumbnail}
          />
          <div className="media-body">
            <h5 className="jr-wall-user-title">
              {commentUserName}{" "}
              {commentUserID === this.state.userID && (
                <>
                <IconButton
                  size="small"
                  onClick={this.RequestCommentDelete}
                  style={{ float: "right" }}
                >
                  <XCircle />
                </IconButton>
              {/* {featureServer &&  */}
                <IconButton
                  size="small"
                  onClick={this.RequestSubCommentEdit}
                  style={{ float: "right" }}
                >
                  <Edit fontSize="small" style={{ fontSize:"17px" }} />
                </IconButton>
              {/* } */}
              </>
              )}
            </h5>
            <p
              style={{
                fontSize: "12px",
                fontWeight: "bold",
                color: "#ababab"
              }}
            >
              {moment(commentedDate).format(socialData && socialData.timeFormat === 24 ? "MMMM Do YYYY, HH:mm" : "MMMM Do YYYY, h:mm a")}
            </p>
            <div style={{ wordBreak: "break-word" }}>
            <p className="mt-2" style={{ fontSize: "16px" }}>
                {commentText.substring(0, 5) === "https" ? (
                    <a href={parse(commentText)} target="_blank">
                        {/* {parse(commentText)} */}
                        {commentText.length > 60 ?
                        commentText.split('//').pop().split('/')[0] + "..." + commentText.slice(commentText.length - 10)
                        : parse(commentText)
                        }
                      </a>
                  ) : (
                    parse(final)
                )}
            </p>
            </div>

            {
            // featureServer && 
            this.state.commentData.imageList &&
                  <CommentImageBox 
                    imageData={this.state.commentData.imageList && this.state.commentData.imageList}
                  />
            }
            
            <div className="d-flex flex-row">
              {/*---------------------LIKE SECTION--------------------------*/}
              {/* <Button
              variant="contained"
              color="primary"
              className="mr-3 mb-1"
              size="small"
              onClick={this.handleLikeToggle.bind(this)}
            >
              {isLike === true ? "Like" : "UnLike"}
            </Button>
             <p
              className="jr-fs-sm pointer mr-3 text-grey"
              onClick={this.handleLikeToggle.bind(this)}
            >
              {isLike === true ? ( */}
                {/* <i
                  className="zmdi zmdi-favorite jr-fs-lg mr-2 d-inline-flex align-middle"
                  style={{ color: "blue" }}
                /> */}
               {/* ) : (
                 <i className="zmdi zmdi-favorite-outline jr-fs-lg mr-2 d-inline-flex align-middle" />
               )} */}
              {/* <span className="d-inline-flex vertical-align-middle"> */}
                {/* {likeCount > 0 ? likeCount + " Likes" : "Likes"} */}
                {/* 1 like */}
              {/* </span> */}
              {/* </p> */}
              {/*--------------------***********--------------------------*/}

              {/*---------------------COMMENT - SECTION--------------------------*/}
              {/* <p className="jr-fs-sm pointer mr-3 text-grey">
                <i className="zmdi zmdi-comment-more jr-fs-lg mr-2 d-inline-flex align-middle" />
                <span className="d-inline-flex align-middle">
                  {totalComments !== "0"
                    ? totalComments + " Comments"
                    : "0 Comments"}
                </span>
              </p> */}

              {/* <Button
              size="small"
              style={{ background: "#e6e6e6" }}
              onClick={() => this.handleCommentToggle(commentID)}
            >
              Add Comment
            </Button> */}

              {/* <span
              className="text-primary jr-fs-md pointer d-block "
              onClick={e => this.handleCommentToggle(e, commentID)}
            >
              Add Comment +
            </span> */}
            </div>
            {/*--------------------***********--------------------------*/}
          </div>
        </div>
        <NotificationContainer />
      </>
    );
  }
}

export default CommentBox;
