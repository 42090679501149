import React from 'react';
import {Redirect, Route, Switch} from 'react-router-dom';
import asyncComponent from '../../../util/asyncComponent';

const Bookings = ({match}) => (
  <div className="app-wrapper">
    <Switch>
      <Redirect exact from={`${match.url}/`} to={`${match.url}/overview`}/>
      <Route path={`${match.url}/overview`} component={asyncComponent(() => import('./routes/Overview'))}/>
      <Route path={`${match.url}/newDiary`} component={asyncComponent(() => import('./routes/quickbook'))}/>
	  <Route path={`${match.url}/listDiary`} component={asyncComponent(() => import('./routes/quickbooklist/index'))}/>
      <Route path={`${match.url}/newbook`} component={asyncComponent(() => import('./routes/newbook'))}/>
      <Route path={`${match.url}/activebook`} component={asyncComponent(() => import('./routes/activebook/index'))}/>
      <Route path={`${match.url}/futurebook`} component={asyncComponent(() => import('./routes/futurebook/index'))}/>
      <Route path={`${match.url}/completedbook`} component={asyncComponent(() => import('./routes/completedbook/index'))}/>
	  <Route path={`${match.url}/recurringbook`} component={asyncComponent(() => import('./routes/recurringBookings/index'))}/>
	  <Route path={`${match.url}/cancelledRecurringbook`} component={asyncComponent(() => import('./routes/cancelledRecurringBookings/index'))}/>

      <Route path={`${match.url}/recurringbook_v1`} component={asyncComponent(() => import('./routes/recurringBookings_v1/index'))}/>
	  <Route path={`${match.url}/cancelledbook`} component={asyncComponent(() => import('./routes/cancelledbook/index'))}/>
	  <Route path={`${match.url}/archivedbook`} component={asyncComponent(() => import('./routes/archivedbook/index'))}/>
	  <Route path={`${match.url}/petList`} component={asyncComponent(() => import('./routes/petsList/index'))}/>


       <Route component={asyncComponent(() => import('app/routes/extraPages/routes/404'))}/>

    </Switch>
  </div>
);

export default Bookings;