import React, { useState } from "react";
import Card from "@material-ui/core/Card";
import { history } from "../../../store/index";
import Button from "@material-ui/core/Button";
import { ResponsiveContainer } from "recharts";
import TextField from "@material-ui/core/TextField";
import { httpClient } from "appUtility/Api";
// import Select from "react-select";
import ToggleButton from "@material-ui/lab/ToggleButton";
import ToggleButtonGroup from "@material-ui/lab/ToggleButtonGroup";
// import Avatar from "@material-ui/core/Avatar";
//---For privacy select element---//
import FormControl from "@material-ui/core/FormControl";
import Select from "@material-ui/core/Select";
import InputLabel from "@material-ui/core/InputLabel";
import MenuItem from "@material-ui/core/MenuItem";
//-------------------------------//
// import ImageUpload from "../../../app/routes/socialApps/routes/Profile/imageUpload";
import Dropzone from "react-dropzone";
import AddPhotoAlternateIcon from "@material-ui/icons/AddPhotoAlternate";
import {
  NotificationManager,
  NotificationContainer,
} from "react-notifications";
import AlertDialog from "./AlertDialog";
import { CircularProgress } from "@material-ui/core";
// import AccountBoxIcon from "@material-ui/icons/AccountBox";

const socialData = JSON.parse(window.localStorage.getItem("login"));

const GroupEditCard = (props) => {
  const { groupInfo } = props;
  const [blockAlert, setBlockAlert] = useState(false);

  console.log("props in group infor >>", props);

  const [groupData, setGroupData] = useState({
    userID: socialData && socialData.uid,
    userCode: socialData && socialData.memtoken,
    appID: 1,
    companyID: socialData && socialData.cid,
    groupName: groupInfo.groupName,
    groupDescription: groupInfo.groupDescription,
    groupID: groupInfo.groupID,
    isPublic: groupInfo.groupPublic,
    isGroupActive: groupInfo.isGroupActive,
    previewImage: groupInfo.groupImage,
    selectedFiles: null,
    previewVisible: true,
    loading: false,
  });

  // const handleImagePreview = info => {
  //   console.log("received Image preview as props:::>>>", info);
  //   setGroupData({ ...groupData, selectedFiles: info });
  // };

  // const CancelPhotoUpload = () => {
  //   setGroupData({ ...groupData, selectedFiles: null });
  // };

  const onDrop = (file) => {
    console.log("ondrop >", file);
    setGroupData({
      ...groupData,
      previewImage: file,
      previewVisible: true,
    });
    // return file[0];
  };

  const handleImageReset = () => {
    setGroupData({
      ...groupData,
      previewImage: null,
      previewVisible: false,
    });
  };

  const handleActiveGroup = (e, mode) => {
    setGroupData({ ...groupData, isGroupActive: mode });
    console.log(mode);
    if (mode === 0) {
      setBlockAlert(true);
    }
  };

  const closeBlockAlert = () => {
    setBlockAlert(false);
  };

  const handleDeactive = () => {
    console.log("group deactivatedd");
    setBlockAlert(false);
  };

  const handlePrivacy = (e) => {
    setGroupData({ ...groupData, isPublic: e.target.value });
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    setGroupData({ ...groupData, [name]: value });
  };

  const handleSaveSettings = () => {
    setGroupData({
      ...groupData,
      loading: true,
    });
    var NewGroupData = new FormData();
    if (groupData.previewImage !== null) {
      groupData.previewImage.length === 1 &&
        NewGroupData.append(
          "image",
          groupData.previewImage[0],
          groupData.previewImage[0].name
        );

      groupData.previewImage &&
        groupData.previewImage.length === null &&
        NewGroupData.append("image", groupData.previewImage);
    }
    NewGroupData.append("userID", groupData.userID);
    NewGroupData.append("userCode", groupData.userCode);
    NewGroupData.append("appID", groupData.appID);
    NewGroupData.append("companyID", groupData.companyID);
    NewGroupData.append("groupName", groupData.groupName);
    NewGroupData.append("groupDescription", groupData.groupDescription);
    NewGroupData.append("isPublic", groupData.isPublic);
    NewGroupData.append("isGroupActive", groupData.isGroupActive);
    NewGroupData.append("groupID", groupData.groupID);

    httpClient.post("api/social/groups_save.php", NewGroupData).then((res) => {
      if (res.data.success !== "true") {
        NotificationManager.warning(res.data.msg);
        setGroupData({
          ...groupData,
          loading: false,
        });
      } else {
        NotificationManager.success(res.data.msg);
        // console.log("upload data in >>", res.data);
        setTimeout(() => {
          // history.push(
          //   `/app/social/group/${groupData.groupName}/${groupData.groupID}`
          // );
          history.push(
            `/app/social/wall`
          );
        }, 1000);
        setGroupData({
          ...groupData,
          loading: false,
        });
      }
    });
  };

  console.log("group data is ??", groupData);

  return (
    <>
      {blockAlert && (
        <AlertDialog
          blockAlert={blockAlert}
          closeBlockAlert={closeBlockAlert}
          handleDeactive={handleDeactive}
        />
      )}
      {groupData.loading ? (
        <div
          style={{
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            height: "100%",
            width: "100%",
          }}
        >
          <CircularProgress />
        </div>
      ) : (
        <ResponsiveContainer>
          <Card>
            <div className="GroupEdit-card">
              <div className="img-upload-box" style={{ paddingTop: "1%" }}>
                <Dropzone onDrop={onDrop}>
                  {groupData.previewVisible && groupData.previewImage !== "" ? (
                    <img
                      alt={groupData.groupName}
                      className="image_drop"
                      src={
                        groupData.previewImage[0].preview
                          ? groupData.previewImage[0].preview
                          : groupData.previewImage
                      }
                    />
                  ) : (
                    <div className="upload_image">
                      <AddPhotoAlternateIcon color="primary" />
                      <p>Upload Image here</p>
                    </div>
                  )}
                </Dropzone>
                {groupData.previewVisible && (
                  <div className="drop_button">
                    <Button
                      variant="contained"
                      color="primary"
                      onClick={handleImageReset}
                    >
                      Reset Image
                    </Button>
                  </div>
                )}
              </div>

              <form style={{ width: "60%" }}>
                <div style={{ display: "flex", flexWrap: "wrap" }}>
                  <div className="col-md-6 col-12">
                    <TextField
                      id="outlined-basic"
                      variant="outlined"
                      label="Group Name"
                      name="groupName"
                      value={groupData.groupName}
                      onChange={(e) => handleChange(e)}
                      margin="normal"
                      fullWidth
                    />
                  </div>

                  <div className="col-md-6 col-12">
                    <FormControl
                      variant="outlined"
                      style={{ width: "100%", marginTop: "10px" }}
                    >
                      <InputLabel id="demo-simple-select-outlined-label">
                        Privacy
                      </InputLabel>
                      <Select
                        label="Privacy"
                        value={groupData.isPublic}
                        onChange={(e) => handlePrivacy(e)}
                      >
                        <MenuItem value="0">Private</MenuItem>
                        <MenuItem value="1">Public</MenuItem>
                      </Select>
                    </FormControl>
                  </div>

                  <div className="col-md-6 col-12" style={{ paddingTop: "1%" }}>
                    <ToggleButtonGroup
                      value={groupData.isGroupActive}
                      exclusive
                      onChange={handleActiveGroup}
                      aria-label="text alignment"
                    >
                      <ToggleButton
                        name="isGroupActive"
                        value={1}
                        aria-label="left aligned"
                        style={{
                          background:
                            groupData.isGroupActive === "1"
                              ? "#f1f1f1"
                              : "inherit",
                        }}
                      >
                        Active
                      </ToggleButton>
                      <ToggleButton
                        name="isGroupActive"
                        value={0}
                        aria-label="centered"
                        style={{
                          background:
                            groupData.isGroupActive === "0"
                              ? "#f1f1f1"
                              : "inherit",
                        }}
                      >
                        Inactive
                      </ToggleButton>
                    </ToggleButtonGroup>
                  </div>
                </div>
                <br />

                <div className="col-md-12" style={{ marginBottom: "2%" }}>
                  <TextField
                    id="outlined-multiline-static"
                    label="Group Description"
                    name="groupDescription"
                    value={groupData.groupDescription}
                    onChange={(e) => handleChange(e)}
                    multiline
                    rows={6}
                    variant="outlined"
                    fullWidth
                  />
                </div>

                <div
                  style={{
                    display: "flex",
                    flexWrap: "wrap",
                    marginBottom: "2%",
                    justifyContent: "center",
                  }}
                >
                  <Button
                    variant="contained"
                    color="primary"
                    className="jr-btn text-white"
                    onClick={handleSaveSettings}
                  >
                    Save
                  </Button>
                  <Button
                    variant="contained"
                    className="jr-btn bg-blue-grey text-white"
                    onClick={() => props.handleGroupEditMode(false)}
                  >
                    Cancel
                  </Button>
                </div>
              </form>
            </div>
          </Card>
        </ResponsiveContainer>
      )}
      <NotificationContainer />
    </>
  );
};
export default GroupEditCard;
