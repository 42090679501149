import React, {Component} from 'react';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';


class FooterAlert extends Component {
  state = {
    open: true,
	useraction:'',
      logoutRequired:'',
      refreshRequired:'',
   };

  handleRequestClose = () => {
    this.setState({
		open: false,
		useraction:false,
	},this.sendData);
	 
  };

handleRequestRemove = () => {
    this.setState({
		open: false,
		useraction:true,
        refreshRequired:false,
        logoutRequired:true,
	},this.sendData);
	 
  };

handleRequestRefresh = () => {
    this.setState({
		open: false,
		useraction:true,
        refreshRequired:true,
        logoutRequired:false,
	},this.sendData);
	 
  };

sendData = () => {
         this.props.parentCallback(this.state);
    };


	  

  render() {
  	  if(this.props.useraction==false && this.props.useraction!='' ) 
		  {
			 this.setState({
				 open: true,
				 useraction:'',
			 });
              
              

		  }
	   
      //console.log("logout required = " + this.props.passToChild.logoutRequired);
       //       console.log("Refresh required = " + this.props.passToChild.refreshRequired);
	  
	  return (
      <div>
         <Dialog open={this.state.open} onClose={this.handleRequestClose}>
          <DialogTitle>
            {"Version Upgrade?"}
          </DialogTitle>
          <DialogContent>
            <DialogContentText>
            Newer version of this app has recently been released which includes enhancements & bug fixes. Choose one of the option below to proceed.<br/> Make sure you save your data before taking any actions.
 		     	  
            </DialogContentText>
          </DialogContent>
          <DialogActions>
            <Button onClick={this.handleRequestClose} color="secondary">
              Cancel for now
            </Button>
            { this.props.passToChild.logoutRequired==1?
            <Button onClick={this.handleRequestRemove} color="primary">
               Logout and Refresh Now
            </Button>: 
          <Button onClick={this.handleRequestRefresh} color="primary">
               Refresh Now
            </Button>
          }
          </DialogActions>
        </Dialog>
      </div>
    );
   }
}

export default FooterAlert;