import React, { Component } from "react";
import { NavLink, withRouter } from "react-router-dom";
import Button from "@material-ui/core/Button";
import GlobalURL from "appUtility/global";
import featureServer from "./../../appUtility/featureServer";

import IntlMessages from "util/IntlMessages";
import CustomScrollbars from "util/CustomScrollbars";

const loginData = JSON.parse(localStorage.getItem("login"));

class SidenavContent extends Component {
  constructor() {
    super();
    this.state = {
      showButton: false,
    };
  }
  componentDidMount() {

    this.checkTime();

    this.intervalId = setInterval(this.checkTime, 60000);

    const { history } = this.props;
    const that = this;
    const pathname = `${history.location.pathname}`; // get current path

    const menuLi = document.getElementsByClassName("menu");
    for (let i = 0; i < menuLi.length; i++) {
      menuLi[i].onclick = function (event) {
        for (let j = 0; j < menuLi.length; j++) {
          const parentLi = that.closest(this, "li");
          if (
            menuLi[j] !== this &&
            (parentLi === null || !parentLi.classList.contains("open"))
          ) {
            menuLi[j].classList.remove("open");
          }
        }
        this.classList.toggle("open");
      };
    }

    const activeLi = document.querySelector('a[href="' + pathname + '"]'); // select current a element
    try {
      const activeNav = this.closest(activeLi, "ul"); // select closest ul
      if (activeNav.classList.contains("sub-menu")) {
        this.closest(activeNav, "li").classList.add("open");
      } else {
        this.closest(activeLi, "li").classList.add("open");
      }
    } catch (error) { }
  }

  componentWillUnmount() {
    // Cleanup interval on component unmount
    clearInterval(this.intervalId);
  }

  checkTime = () => {
    // Get the current date and time in Australian Eastern Time (Sydney)
    const now = new Date();
    const options = { timeZone: 'Australia/Sydney', year: 'numeric', month: '2-digit', day: '2-digit', hour: '2-digit', minute: '2-digit', second: '2-digit', hour12: false };
    const formatter = new Intl.DateTimeFormat('en-AU', options);
    const parts = formatter.formatToParts(now);
    const dateParts = parts.reduce((acc, part) => {
      acc[part.type] = part.value;
      return acc;
    }, {});

    const currentDate = new Date(
      `${dateParts.year}-${dateParts.month}-${dateParts.day}T${dateParts.hour}:${dateParts.minute}:${dateParts.second}+10:00` // +10:00 for AEST
    );

    // Define the target date and time: June 28, 2024, 6:30 PM in Sydney
    const targetDate = new Date('2024-06-30T15:25:00+10:00'); // +10:00 for AEST

    // Check if the current date and time is after the target date and time
    if (currentDate >= targetDate) {
      this.setState({ showButton: true });
    } else {
      this.setState({ showButton: false });
    }
  };

  componentWillReceiveProps(nextProps) {
    const { history } = nextProps;
    const pathname = `${history.location.pathname}`; // get current path

    const activeLi = document.querySelector('a[href="' + pathname + '"]'); // select current a element
    try {
      const activeNav = this.closest(activeLi, "ul"); // select closest ul
      if (activeNav.classList.contains("sub-menu")) {
        this.closest(activeNav, "li").classList.add("open");
      } else {
        this.closest(activeLi, "li").classList.add("open");
      }
    } catch (error) { }
  }

  closest(el, selector) {
    try {
      let matchesFn;
      // find vendor prefix
      [
        "matches",
        "webkitMatchesSelector",
        "mozMatchesSelector",
        "msMatchesSelector",
        "oMatchesSelector",
      ].some(function (fn) {
        if (typeof document.body[fn] == "function") {
          matchesFn = fn;
          return true;
        }
        return false;
      });

      let parent;

      // traverse parents
      while (el) {
        parent = el.parentElement;
        if (parent && parent[matchesFn](selector)) {
          return parent;
        }
        el = parent;
      }
    } catch (e) { }

    return null;
  }

  handleOpenNewPage = () => {
    window.open("https://bluewheelers.opcentral.com.au", "_blank")
  }

  render() {
    return (
      <CustomScrollbars className=" scrollbar">
        <ul className="nav-menu" id="nav__menu__display">
          <li className="nav-header">
            <IntlMessages id="sidebar.main" />
          </li>

          <li className="menu no-arrow">
            <NavLink to="/app/dashboard/overview">
              <i className="zmdi zmdi-view-dashboard zmdi-hc-fw" />
              <span className="nav-text">
                <IntlMessages id="sidebar.dashboard" />
              </span>
            </NavLink>
          </li>

          {loginData && loginData.exMember != "1" && (
            <li className="menu no-arrow">
              <NavLink to="/app/calendar/dnd">
                <i className="zmdi zmdi-calendar zmdi-hc-fw" />
                <span className="nav-text">
                  <IntlMessages id="sidebar.dashboard.calendar" />
                </span>
              </NavLink>
            </li>
          )}
          {/* {window.location.hostname === "localhost" ? (
            <li className="menu collapse-box">
              <Button>
                <i className="zmdi zmdi-book zmdi-hc-fw" />
                <span className="nav-text">
                  <IntlMessages id="sidebar.leads" />
                </span>
              </Button>
              <ul className="sub-menu">
                <li>
                  <NavLink className="prepend-icon" to="/app/leads/newleads">
                    <span className="nav-text">
                      <IntlMessages id="sidebar.leads.newleads" />
                    </span>
                  </NavLink>
                </li>

                <li>
                  <NavLink
                    className="prepend-icon"
                    to="/app/leads/completedleads"
                  >
                    <span className="nav-text">
                      <IntlMessages id="sidebar.leads.completedleads" />
                    </span>
                  </NavLink>
                </li>

                <li>
                  <NavLink
                    className="prepend-icon"
                    to="/app/leads/ignoredleads"
                  >
                    <span className="nav-text">
                      <IntlMessages id="sidebar.leads.ignoredleads" />
                    </span>
                  </NavLink>
                </li>
              </ul>
            </li>
          ) : (
            ""
          )} */}

          {loginData && loginData.leadsActive == "1" ? (
            <li className="menu collapse-box">
              <Button>
                <i className="zmdi zmdi-book zmdi-hc-fw" />
                <span className="nav-text">
                  <IntlMessages id="sidebar.leads" />
                </span>
              </Button>
              <ul className="sub-menu">
                <li>
                  <NavLink className="prepend-icon" to="/app/leads/newleads">
                    <span className="nav-text">
                      <IntlMessages id="sidebar.leads.newleads" />
                    </span>
                  </NavLink>
                </li>

                <li>
                  <NavLink
                    className="prepend-icon"
                    to="/app/leads/completedleads"
                  >
                    <span className="nav-text">
                      <IntlMessages id="sidebar.leads.completedleads" />
                    </span>
                  </NavLink>
                </li>

                <li>
                  <NavLink
                    className="prepend-icon"
                    to="/app/leads/ignoredleads"
                  >
                    <span className="nav-text">
                      {/* <IntlMessages id="sidebar.leads.ignoredleads" /> */}
                      <IntlMessages id="Snooze Leads" />

                    </span>
                  </NavLink>
                </li>
              </ul>
            </li>
          ) : (
            ""
          )}

          {/* <span className="nav-text"><IntlMessages id="sidebar.forum.menu"/></span> */}
          {/* {featureServer ? */}
          {/* <IntlMessages id="sidebar.oldForum.menu" /> */}
          {/* :  <IntlMessages id="sidebar.forum.menu" /> */}
          {/* } */}
          {/* {
            <li className="menu no-arrow">
              <NavLink to="/app/forum/forum">
                <i className="zmdi zmdi-calendar zmdi-hc-fw" />
               
                <span className="nav-text">
                  Old Forum
                  
                </span>
              </NavLink>
            </li>
          } */}
          {loginData &&
            loginData.exMember != "1" &&
            loginData.socialActive == "1" && (
              <li className="menu no-arrow">
                <NavLink to="/app/social/wall">
                  <i className="zmdi zmdi-collection-folder-image zmdi-hc-fw" />
                  {/* <span className="nav-text"><IntlMessages id="sidebar.social.menu"/></span> */}
                  <span className="nav-text">
                    {/* <IntlMessages id="sidebar.forum.menu" /> */}
                    Forum
                  </span>
                </NavLink>
              </li>
            )}

          {loginData && loginData.exMember != "1" && (
            <li className="menu no-arrow">
              <NavLink to="/app/dashboard/news">
                <i className="zmdi zmdi-comment-alert zmdi-hc-fw" />
                <span className="nav-text">
                  <IntlMessages id="sidebar.dashboard.news" />
                </span>
              </NavLink>
            </li>
          )}

          {loginData && loginData.exMember != "1" && (
            <li className="menu collapse-box">
              <Button>
                <i className="zmdi zmdi-book zmdi-hc-fw" />
                <span className="nav-text">
                  <IntlMessages id="sidebar.bookings" />
                </span>
              </Button>
              <ul className="sub-menu">
                <li>
                  <NavLink className="prepend-icon" to="/app/bookings/newbook">
                    <span className="nav-text">
                      <IntlMessages id="sidebar.bookings.new" />
                    </span>
                  </NavLink>
                </li>
                <li>
                  <NavLink
                    className="prepend-icon"
                    to="/app/bookings/activebook"
                  >
                    <span className="nav-text">
                      <IntlMessages id="sidebar.bookings.active" />
                    </span>
                  </NavLink>
                </li>
                {/*<li>
						<NavLink className="prepend-icon" to="/app/bookings/futurebook">
						  <span className="nav-text"><IntlMessages id="sidebar.bookings.future"/></span>
						</NavLink>
					  </li>*/}
                <li>
                  <NavLink
                    className="prepend-icon"
                    to="/app/bookings/completedbook"
                  >
                    <span className="nav-text">
                      <IntlMessages id="sidebar.bookings.completed" />
                    </span>
                  </NavLink>
                </li>

                <li>
                  <NavLink
                    className="prepend-icon"
                    to="/app/bookings/recurringbook"
                  >
                    <span className="nav-text">
                      <IntlMessages id="sidebar.bookings.recurring" />
                    </span>
                  </NavLink>
                </li>

                <li>
                  <NavLink
                    className="prepend-icon"
                    to="/app/bookings/cancelledRecurringbook"
                  >
                    <span className="nav-text">
                      <IntlMessages id="sidebar.bookings.cancelledRecurring" />
                    </span>
                  </NavLink>
                </li>

                {/* <li>
                  <NavLink
                    className="prepend-icon"
                    to="/app/bookings/recurringbook_v1"
                  >
                    <span className="nav-text">
                      <IntlMessages id="sidebar.bookings.recurringv1" />
                    </span>
                  </NavLink>
                </li> */}

                <li>
                  <NavLink
                    className="prepend-icon"
                    to="/app/bookings/cancelledbook"
                  >
                    <span className="nav-text">
                      <IntlMessages id="sidebar.bookings.cancelled" />
                    </span>
                  </NavLink>
                </li>
                <li>
                  <NavLink
                    className="prepend-icon"
                    to="/app/bookings/archivedbook"
                  >
                    <span className="nav-text">
                      <IntlMessages id="sidebar.bookings.archived" />
                    </span>
                  </NavLink>
                </li>

                {/*<li>
						<NavLink className="prepend-icon" to="/app/blockout/newblock">
						  <span className="nav-text"><IntlMessages id="sidebar.blockout.new"/></span>
						</NavLink>
					  </li>
				<li>
						<NavLink className="prepend-icon" to="/app/blockout/listblocks">
						  <span className="nav-text"><IntlMessages id="sidebar.blockout.list"/></span>
						</NavLink>
					  </li>*/}
              </ul>
            </li>
          )}

          {/*<li className="menu collapse-box">
            <Button>
              <i className="zmdi zmdi-calendar-note zmdi-hc-fw"/>
              <span className="nav-text">
                <IntlMessages id="sidebar.bookings.quick"/>
              </span>
            </Button>
            <ul className="sub-menu">
 				<li>
						<NavLink className="prepend-icon" to="/app/bookings/newDiary">
						  <span className="nav-text"><IntlMessages id="sidebar.bookings.quicknew"/></span>
						</NavLink>
					  </li>
 				<li>
						<NavLink className="prepend-icon" to="/app/bookings/listDiary">
						  <span className="nav-text"><IntlMessages id="sidebar.bookings.quicklist"/></span>
						</NavLink>
					  </li>
             </ul>
          </li>*/}

          {loginData && loginData.exMember != "1" && (
            <li className="menu collapse-box">
              <Button>
                <i className="zmdi zmdi-bookmark zmdi-hc-fw" />
                <span className="nav-text">
                  <IntlMessages id="blockout.menu" />
                </span>
              </Button>
              <ul className="sub-menu">
                <li>
                  <NavLink className="prepend-icon" to="/app/blockout/newblock">
                    <span className="nav-text">
                      <IntlMessages id="sidebar.blockout.new" />
                    </span>
                  </NavLink>
                </li>
                <li>
                  <NavLink
                    className="prepend-icon"
                    to="/app/blockout/listblocks"
                  >
                    <span className="nav-text">
                      <IntlMessages id="sidebar.blockout.list" />
                    </span>
                  </NavLink>
                </li>
                <li>
                  <NavLink className="prepend-icon" to="/app/blockout/listrecurringblocks">
                    <span className="nav-text"><IntlMessages id="sidebar.blockout.listrecurring" /></span>
                  </NavLink>
                </li>
              </ul>
            </li>
          )}

          {loginData && loginData.exMember != "1" && (
            <li className="menu collapse-box">
              <Button>
                <i className="zmdi zmdi-account-box zmdi-hc-fw" />
                <span className="nav-text">
                  <IntlMessages id="sidebar.appModule.contact" />
                </span>
              </Button>
              <ul className="sub-menu">
                <li>
                  <NavLink className="prepend-icon" to="/app/newcontact">
                    <span className="nav-text">
                      <IntlMessages id="sidebar.customers.add" />
                    </span>
                  </NavLink>
                </li>
                <li>
                  <NavLink className="prepend-icon" to="/app/contact">
                    <span className="nav-text">
                      <IntlMessages id="sidebar.customers.list" />
                    </span>
                  </NavLink>
                </li>

                {featureServer &&
                  <li>
                    <NavLink className="prepend-icon" to="/app/inactive_customers">
                      <span className="nav-text">
                        Inactive Customers
                        {/* <IntlMessages id="sidebar.customers.list" /> */}
                      </span>
                    </NavLink>
                  </li>
                }

                <li>
                  <NavLink className="prepend-icon" to="/app/archivedcontacts">
                    <span className="nav-text">
                      <IntlMessages id="sidebar.customers.archivelist" />
                    </span>
                  </NavLink>
                </li>
              </ul>
            </li>
          )}

          {loginData && loginData.exMember != "1" && (
            <li className="menu collapse-box">
              <Button>
                <i className="zmdi zmdi-account-box-phone zmdi-hc-fw" />
                <span className="nav-text">
                  <IntlMessages id="sidebar.comm" />
                </span>
              </Button>
              <ul className="sub-menu">
                <li>
                  <NavLink
                    className="prepend-icon"
                    to="/app/communication/buycredits"
                  >
                    <span className="nav-text">
                      <IntlMessages id="sidebar.comm.buycredit" />
                    </span>
                  </NavLink>
                </li>

                <li>
                  <NavLink
                    className="prepend-icon"
                    to="/app/communication/sendsms"
                  >
                    <span className="nav-text">
                      <IntlMessages id="sidebar.comm.sendsms" />
                    </span>
                  </NavLink>
                </li>

                <li>
                  <NavLink
                    className="prepend-icon"
                    to="/app/communication/sendemail"
                  >
                    <span className="nav-text">
                      <IntlMessages id="sidebar.comm.sendemail" />
                    </span>
                  </NavLink>
                </li>
                <li>
                  <NavLink
                    className="prepend-icon"
                    to="/app/communication/smshistory"
                  >
                    <span className="nav-text">
                      <IntlMessages id="sidebar.comm.smshistory" />
                    </span>
                  </NavLink>
                </li>
                <li>
                  <NavLink
                    className="prepend-icon"
                    to="/app/communication/messagetemplate"
                  >
                    <span className="nav-text">
                      <IntlMessages id="sidebar.comm.messagetemplate" />
                    </span>
                  </NavLink>
                </li>
              </ul>
            </li>
          )}

          {loginData && loginData.exMember != "1" && (
            <li className="menu collapse-box">
              <Button>
                <i className="zmdi zmdi-group zmdi-hc-fw" />
                <span className="nav-text">
                  <IntlMessages id="sidebar.inventory" />
                </span>
              </Button>
              <ul className="sub-menu">
                <li>
                  <NavLink
                    className="prepend-icon"
                    to="/app/inventory/PlaceOrder"
                  >
                    <span className="nav-text">
                      {/* <IntlMessages id="sidebar.inventory.placeorder" /> */}
                      Office Order
                    </span>
                  </NavLink>
                </li>
                {/* {featureServer && ( */}
                <li>
                  <NavLink
                    className="prepend-icon"
                    to="/app/inventory/PlaceOrderB"
                  >
                    <span className="nav-text">
                      Shampoo Order

                    </span>
                  </NavLink>
                </li>
                {/* <IntlMessages id="sidebar.inventory.placeorder" /> */}
                {/* )} */}

                <li>
                  <NavLink
                    className="prepend-icon"
                    to="/app/inventory/PlaceOrderTreat"
                  >
                    <span className="nav-text">
                      <IntlMessages id="sidebar.inventory.placeorderTreat" />
                    </span>
                  </NavLink>
                </li>

                {/* {featureServer && */}
                <li>
                  <NavLink
                    className="prepend-icon"
                    to="/app/inventory/PlaceOrderUniform"
                  >
                    <span className="nav-text">
                      Place Uniform Orders
                    </span>
                  </NavLink>
                </li>
                {/* } */}
                {/* {featureServer && */}
                <li>
                  <NavLink
                    className="prepend-icon"
                    to="/app/inventory/PlaceBusinessCard"
                  >
                    <span className="nav-text">
                      Place Business Card, Magnet, Flyers
                    </span>
                  </NavLink>
                </li>
                {/* } */}

                {(featureServer || this.state.showButton) &&
                  <li>
                    <NavLink
                      className="prepend-icon"
                      to="/app/inventory/stock_take"
                    >
                      <span className="nav-text">
                        Stock Take
                      </span>
                    </NavLink>
                  </li>
                }

                {featureServer &&
                  <li>
                    <NavLink
                      className="prepend-icon"
                      to="/app/inventory/stockonhand"
                    >
                      <span className="nav-text">
                        Stock On Hand
                      </span>
                    </NavLink>
                  </li>
                }

                <li>
                  <NavLink
                    className="prepend-icon"
                    to="/app/inventory/InwardGoods"
                  >
                    <span className="nav-text">
                      <IntlMessages id="sidebar.inventory.inwardgoods" />
                    </span>
                  </NavLink>
                </li>
                <li>
                  <NavLink
                    className="prepend-icon"
                    to="/app/inventory/CompletedOrders"
                  >
                    <span className="nav-text">
                      <IntlMessages id="sidebar.inventory.completedorders" />
                    </span>
                  </NavLink>
                </li>
              </ul>
            </li>
          )}

          <li className="menu collapse-box">
            <Button>
              <i className="zmdi zmdi-money-box zmdi-hc-fw" />
              <span className="nav-text">
                <IntlMessages id="sidebar.income" />
              </span>
            </Button>
            <ul className="sub-menu">
              {/*<li>
                <NavLink className="prepend-icon" to="/app/income/Overview">
                  <span className="nav-text"><IntlMessages id="sidebar.income.overview"/></span>
                </NavLink>
              </li>
			  */}
              <li>
                <NavLink className="prepend-icon" to="/app/income/AddIncome">
                  <span className="nav-text">
                    <IntlMessages id="sidebar.income.addincome" />
                  </span>
                </NavLink>
              </li>

              {loginData && loginData.exMember != "1" && (
                <li>
                  <NavLink
                    className="prepend-icon"
                    to="/app/income/AddIncomeCategory"
                  >
                    <span className="nav-text">
                      <IntlMessages id="sidebar.income.addincomecategory" />
                    </span>
                  </NavLink>
                </li>
              )}
              <li>
                <NavLink
                  className="prepend-icon"
                  to="/app/income/IncomeCategories"
                >
                  <span className="nav-text">
                    <IntlMessages id="sidebar.income.incomecategories" />
                  </span>
                </NavLink>
              </li>

              <li>
                <NavLink className="prepend-icon" to="/app/income/ListIncome">
                  <span className="nav-text">
                    <IntlMessages id="sidebar.income.listincome" />
                  </span>
                </NavLink>
              </li>
              <li>
                <NavLink
                  className="prepend-icon"
                  to="/app/income/ListRecurringIncome"
                >
                  <span className="nav-text">
                    <IntlMessages id="sidebar.income.listrecurringincome" />
                  </span>
                </NavLink>
              </li>
            </ul>
          </li>

          <li className="menu collapse-box">
            <Button>
              <i className="zmdi zmdi-money-off zmdi-hc-fw" />
              <span className="nav-text">
                <IntlMessages id="sidebar.expense" />
              </span>
            </Button>
            <ul className="sub-menu">
              {/*<li>
                <NavLink className="prepend-icon" to="/app/expense/Overview">
                  <span className="nav-text"><IntlMessages id="sidebar.expense.overview"/></span>
                </NavLink>
              </li>
			  */}
              <li>
                <NavLink className="prepend-icon" to="/app/expense/AddExpense">
                  <span className="nav-text">
                    <IntlMessages id="sidebar.expense.addexpense" />
                  </span>
                </NavLink>
              </li>

              <li>
                <NavLink
                  className="prepend-icon"
                  to="/app/expense/ExpenseCategories"
                >
                  <span className="nav-text">
                    <IntlMessages id="sidebar.expense.expensecategories" />
                  </span>
                </NavLink>
              </li>
              <li>
                <NavLink className="prepend-icon" to="/app/expense/ListExpense">
                  <span className="nav-text">
                    <IntlMessages id="sidebar.expense.listexpense" />
                  </span>
                </NavLink>
              </li>

              <li>
                <NavLink
                  className="prepend-icon"
                  to="/app/expense/ListRecurringExpense"
                >
                  <span className="nav-text">
                    <IntlMessages id="sidebar.expense.listrecurringexpense" />
                  </span>
                </NavLink>
              </li>
            </ul>
          </li>

          {loginData && loginData.exMember != "1" && (
            <li className="menu collapse-box">
              <Button>
                <i className="zmdi zmdi-settings-square zmdi-hc-fw" />
                <span className="nav-text">
                  <IntlMessages id="sidebar.documents.menu" />
                </span>
              </Button>
              <ul className="sub-menu">
                <li>
                  <NavLink className="prepend-icon" to="/app/documents/manuals">
                    <span className="nav-text">
                      <IntlMessages id="sidebar.documents.systemManuals" />
                    </span>
                  </NavLink>
                </li>

                <li>
                  <NavLink
                    className="prepend-icon"
                    to="/app/documents/templates"
                  >
                    <span className="nav-text">
                      <IntlMessages id="sidebar.documents.templates" />
                    </span>
                  </NavLink>
                </li>

                <li>
                  <NavLink
                    className="prepend-icon"
                    to="/app/documents/otherfiles"
                  >
                    <span className="nav-text">
                      <IntlMessages id="sidebar.documents.otherfiles" />
                    </span>
                  </NavLink>
                </li>
              </ul>
            </li>
          )}

          <li className="menu collapse-box">
            <Button>
              <i className="zmdi zmdi-trending-up zmdi-hc-fw" />
              <span className="nav-text">
                <IntlMessages id="sidebar.reports" />
              </span>
            </Button>
            <ul className="sub-menu">
              {loginData && loginData.exMember != "1" && (
                <li>
                  <NavLink
                    className="prepend-icon"
                    to="/app/notifications/DailyDiary"
                  >
                    <span className="nav-text">
                      <IntlMessages id="notifications.dailyDiary.menu" />
                    </span>
                  </NavLink>
                </li>
              )}

              {loginData && loginData.exMember != "1" && (
                <li>
                  <NavLink className="prepend-icon" to="/app/reports/Booking">
                    <span className="nav-text">
                      <IntlMessages id="sidebar.reports.bookingreports" />
                    </span>
                  </NavLink>
                </li>
              )}
              {loginData && loginData.exMember != "1" && (
                <li>
                  <NavLink className="prepend-icon" to="/app/reports/Service">
                    <span className="nav-text">
                      <IntlMessages id="sidebar.reports.servicereports" />
                    </span>
                  </NavLink>
                </li>
              )}
              {loginData && loginData.exMember != "1" && (
                <li>
                  <NavLink className="prepend-icon" to="/app/reports/Suburb">
                    <span className="nav-text">
                      <IntlMessages id="sidebar.reports.suburbreports" />
                    </span>
                  </NavLink>
                </li>
              )}
              {loginData && loginData.exMember != "1" && (
                <li>
                  <NavLink className="prepend-icon" to="/app/reports/Customer">
                    <span className="nav-text">
                      <IntlMessages id="sidebar.reports.customerreports" />
                    </span>
                  </NavLink>
                </li>
              )}
              <li>
                <NavLink className="prepend-icon" to="/app/reports/Income">
                  <span className="nav-text">
                    <IntlMessages id="sidebar.reports.incomereports" />
                  </span>
                </NavLink>
              </li>
              {loginData && loginData.exMember != "1" && (
                <li>
                  <NavLink
                    className="prepend-icon"
                    to="/app/reports/IncomeForcast"
                  >
                    <span className="nav-text">
                      <IntlMessages id="sidebar.reports.incomeforcastreports" />
                    </span>
                  </NavLink>
                </li>
              )}

              <li>
                <NavLink className="prepend-icon" to="/app/reports/Expense">
                  <span className="nav-text">
                    <IntlMessages id="sidebar.reports.expensereports" />
                  </span>
                </NavLink>
              </li>
              <li>
                <NavLink className="prepend-icon" to="/app/reports/ProfitLoss">
                  <span className="nav-text">
                    <IntlMessages id="sidebar.reports.profitloss" />
                  </span>
                </NavLink>
              </li>
              <li>
                <NavLink
                  className="prepend-icon"
                  to="/app/reports/ProfitLossGroup"
                >
                  <span className="nav-text">
                    <IntlMessages id="sidebar.reports.profitlossgroup" />
                  </span>
                </NavLink>
              </li>
              <li>
                <NavLink className="prepend-icon" to="/app/reports/GST">
                  <span className="nav-text">
                    {/* <IntlMessages id="sidebar.reports.gstreports" /> */}
                    Gst Report Summary
                  </span>
                </NavLink>
              </li>
              <li>
                <NavLink className="prepend-icon" to="/app/reports/GST_DETAIL">
                  <span className="nav-text">
                    {/* <IntlMessages id="sidebar.reports.gstreports" /> */}
                    Gst Report Detail
                  </span>
                </NavLink>
              </li>
              {featureServer && (
                <li>
                  <NavLink className="prepend-icon" to="/app/reports/GSTCash">
                    <span className="nav-text">
                      Gst Report (Cash)
                    </span>
                  </NavLink>
                </li>
              )}
              {loginData && loginData.exMember != "1" && (
                <li>
                  <NavLink className="prepend-icon" to="/app/reports/Tracing">
                    <span className="nav-text">
                      <IntlMessages id="sidebar.reports.tracingreports" />
                    </span>
                  </NavLink>
                </li>
              )}
            </ul>
          </li>

          {loginData && loginData.exMember != "1" && (
            <li className="menu collapse-box">
              <Button>
                <i className="zmdi zmdi-library zmdi-hc-fw" />
                <span className="nav-text">
                  <IntlMessages id="training" />
                </span>
              </Button>
              <ul className="sub-menu">
                <li className="menu no-arrow">
                  <NavLink to="/app/video/">
                    <span className="nav-text">
                      <IntlMessages id="sidebar.elearning.menu" />
                    </span>
                  </NavLink>
                </li>
                <li className="menu no-arrow">
                  <NavLink to="/app/trainingvideo/">
                    <span className="nav-text">
                      <IntlMessages id="sidebar.trainingvideo.menu" />
                    </span>
                  </NavLink>
                </li>
                <li className="menu no-arrow">
                  <NavLink to="/app/marketingvideo/">
                    <span className="nav-text">
                      <IntlMessages id="sidebar.marketingvideo.menu" />
                    </span>
                  </NavLink>
                </li>
              </ul>
            </li>
          )}

          {loginData && loginData.exMember != "1" && (
            <li className="menu collapse-box">
              <Button>
                <i className="zmdi zmdi-settings-square zmdi-hc-fw" />
                <span className="nav-text">
                  <IntlMessages id="setup" />
                </span>
              </Button>
              <ul className="sub-menu">
                <li>
                  <NavLink
                    className="prepend-icon"
                    to="/app/notifications/bookings"
                  >
                    <span className="nav-text">
                      <IntlMessages id="notifications.bookings.menu" />
                    </span>
                  </NavLink>
                </li>

                <li>
                  <NavLink className="prepend-icon" to="/app/settings">
                    <span className="nav-text">
                      <IntlMessages id="sidebar.dashboard.settings" />
                    </span>
                  </NavLink>
                </li>

                {/* {featureServer && */}
                <li>
                  <NavLink className="prepend-icon" to="/app/inventory_usage">
                    <span className="nav-text">
                      Inventory Usage
                    </span>
                  </NavLink>
                </li>
                {/* } */}

                <li>
                  <NavLink className="prepend-icon" to="/app/myPrices">
                    <span className="nav-text">
                      <IntlMessages id="sidebar.dashboard.myprices" />
                    </span>
                  </NavLink>
                </li>
              </ul>
            </li>
          )}

          {featureServer && (
            <li className="menu collapse-box">
              <Button>
                <i className="zmdi zmdi-chart-donut zmdi-hc-fw" />
                <span className="nav-text">
                  Integraton
                </span>
              </Button>
              <ul className="sub-menu">
                <li>
                  <NavLink className="prepend-icon" to="/app/app_integration">
                    <span className="nav-text">
                      App
                    </span>
                  </NavLink>
                </li>
              </ul>
            </li>
          )}

          {loginData && loginData.exMember != "1" && (
            <li className="menu no-arrow lawaBottomMenu" >
              <NavLink to="/app/version">

                <i className="zmdi zmdi-time-restore zmdi-hc-fw" />
                <span className="nav-text">Version Update</span>
              </NavLink>
            </li>
          )}

          {loginData && loginData.exMember != "1" && (
            <li className="menu no-arrow lawaBottomMenu" style={{ cursor: "pointer" }}>
              <a onClick={this.handleOpenNewPage}>
                <i className="zmdi zmdi-sign-in zmdi-hc-fw" />
                <span className="nav-text">Operations Manual</span>
              </a>
            </li>
          )}

          {loginData && loginData.exMember != "1" && (
            <li className="menu no-arrow lawaBottomMenu" >
              <NavLink to="/app/ticket/ticket">

                <i className="zmdi zmdi-bug zmdi-hc-fw" />
                <span className="nav-text">
                  <IntlMessages id="sidebar.ticket.menu" />
                </span>
              </NavLink>
            </li>
          )}

          {/* {featureServer && (
            <li className="menu no-arrow lawaBottomMenu" >
            <NavLink to="/app/myProfile/profile">

              <i className="zmdi zmdi-account-box zmdi-hc-fw" />
              <span className="nav-text">
                My Profile
              </span>
            </NavLink>
          </li>
          )} */}

          {/*
		
		
          <li className="ui_tooltip menu">
            <Button className="void">
              <i className="zmdi zmdi-folder zmdi-hc-fw"/>
              <span className="nav-text"><IntlMessages id="sidebar.components"/></span>
            </Button>

            <ul className="sub-menu">
              <li>
                <NavLink className="prepend-icon" to="/app/components/alerts">
                  <span className="nav-text"><IntlMessages id="sidebar.components.alerts"/></span>
                </NavLink>
              </li>
              <li>
                <NavLink className="prepend-icon" to="/app/components/appbar">
                  <span className="nav-text"><IntlMessages id="sidebar.components.appbar"/></span>
                </NavLink>
              </li>
              <li>
                <NavLink className="prepend-icon"
                         to="/app/components/auto-complete">
                                    <span className="nav-text"><IntlMessages
                                      id="sidebar.components.autocomplete"/></span>
                </NavLink>
              </li>
              <li>
                <NavLink className="prepend-icon" to="/app/components/avatars">
                  <span className="nav-text"><IntlMessages id="sidebar.components.avatars"/></span>
                </NavLink>
              </li>
              <li>
                <NavLink className="prepend-icon" to="/app/components/badges">
                  <span className="nav-text"><IntlMessages id="sidebar.components.badge"/></span>
                </NavLink>
              </li>
              <li>
                <NavLink className="prepend-icon"
                         to="/app/components/bottom-navigation">
                                <span className="nav-text"><IntlMessages
                                  id="sidebar.components.bottomNavigation"/></span>
                </NavLink>
              </li>
              <li>
                <NavLink className="prepend-icon"
                         to="/app/components/breadcrumbs">
                                    <span className="nav-text"><IntlMessages
                                      id="sidebar.components.breadcrumbs"/></span>
                </NavLink>
              </li>
              <li>
                <NavLink className="prepend-icon" to="/app/components/buttons">
                  <span className="nav-text"><IntlMessages id="sidebar.components.buttons"/></span>
                </NavLink>
              </li>
              <li>
                <NavLink className="prepend-icon"
                         to="/app/components/button-group">
                                    <span className="nav-text"><IntlMessages
                                      id="sidebar.components.buttonGroup"/></span>
                </NavLink>
              </li>
              <li>
                <NavLink className="prepend-icon" to="/app/components/cards">
                  <span className="nav-text"><IntlMessages id="sidebar.components.cards"/></span>
                </NavLink>
              </li>
              <li>
                <NavLink className="prepend-icon"
                         to="/app/components/carousel">
                  <span className="nav-text"><IntlMessages id="sidebar.components.carousel"/></span>
                </NavLink>
              </li>
              <li>
                <NavLink className="prepend-icon" to="/app/components/chips">
                  <span className="nav-text"><IntlMessages id="sidebar.components.chips"/></span>
                </NavLink>
              </li>
              <li>
                <NavLink className="prepend-icon"
                         to="/app/components/color-picker">
                                    <span className="nav-text"><IntlMessages
                                      id="sidebar.components.colorPicker"/></span>
                </NavLink>
              </li>
              <li>
                <NavLink className="prepend-icon" to="/app/components/dialogs">
                  <span className="nav-text"><IntlMessages id="sidebar.components.dialogs"/></span>
                </NavLink>
              </li>
              <li>
                <NavLink className="prepend-icon"
                         to="/app/components/dividers">
                  <span className="nav-text"><IntlMessages id="sidebar.components.dividers"/></span>
                </NavLink>
              </li>
              <li>
                <NavLink className="prepend-icon"
                         to="/app/components/expansion-panel">
                                    <span className="nav-text"><IntlMessages
                                      id="sidebar.components.expansionPanel"/></span>
                </NavLink>
              </li>
              <li>
                <NavLink className="prepend-icon" to="/app/components/drawer">
                  <span className="nav-text"><IntlMessages id="sidebar.components.drawer"/></span>
                </NavLink>
              </li>
              <li>
                <NavLink className="prepend-icon"
                         to="/app/components/grid-list">
                  <span className="nav-text"><IntlMessages id="sidebar.components.gridList"/></span>
                </NavLink>
              </li>
              <li>
                <NavLink className="prepend-icon" to="/app/components/list">
                  <span className="nav-text"><IntlMessages id="sidebar.components.lists"/></span>
                </NavLink>
              </li>
              <li>
                <NavLink className="prepend-icon"
                         to="/app/components/menu-paper">
                  <span className="nav-text"><IntlMessages id="sidebar.components.menusPaper"/></span>
                </NavLink>
              </li>
              <li>
                <NavLink className="prepend-icon" to="/app/components/pickers">
                  <span className="nav-text"><IntlMessages id="sidebar.components.pickers"/></span>
                </NavLink>
              </li>
              <li>
                <NavLink className="prepend-icon"
                         to="/app/components/popovers">
                  <span className="nav-text"><IntlMessages id="sidebar.components.popovers"/></span>
                </NavLink>
              </li>
              <li>
                <NavLink className="prepend-icon"
                         to="/app/components/progressbar">
                  <span className="nav-text"><IntlMessages id="sidebar.components.progress"/></span>
                </NavLink>
              </li>
              <li>
                <NavLink className="prepend-icon" to="/app/components/selects">
                  <span className="nav-text"><IntlMessages id="sidebar.components.selects"/></span>
                </NavLink>
              </li>
              <li>
                <NavLink className="prepend-icon"
                         to="/app/components/selection">
                                <span className="nav-text"><IntlMessages
                                  id="sidebar.components.selectionControl"/></span>
                </NavLink>
              </li>
              <li>
                <NavLink className="prepend-icon"
                         to="/app/components/snackbar">
                  <span className="nav-text"><IntlMessages id="sidebar.components.snackbars"/></span>
                </NavLink>
              </li>
              <li>
                <NavLink className="prepend-icon" to="/app/components/stepper">
                  <span className="nav-text"><IntlMessages id="sidebar.components.stepper"/></span>
                </NavLink>
              </li>
              <li>
                <NavLink className="prepend-icon" to="/app/components/tables">
                  <span className="nav-text"><IntlMessages id="sidebar.components.tables"/></span>
                </NavLink>
              </li>
              <li>
                <NavLink className="prepend-icon" to="/app/components/tabs">
                  <span className="nav-text"><IntlMessages id="sidebar.components.tabs"/></span>
                </NavLink>
              </li>
              <li>
                <NavLink className="prepend-icon"
                         to="/app/components/text-fields">
                  <span className="nav-text"><IntlMessages id="sidebar.components.textFields"/></span>
                </NavLink>
              </li>
              <li>
                <NavLink className="prepend-icon"
                         to="/app/components/tooltips">
                  <span className="nav-text"><IntlMessages id="sidebar.components.tooltips"/></span>
                </NavLink>
              </li>
              <li>
                <NavLink className="prepend-icon"
                         to="/app/components/typography">
                  <span className="nav-text"><IntlMessages id="sidebar.components.typography"/></span>
                </NavLink>
              </li>
            </ul>
          </li>
		
		
		

          <li className="menu collapse-box">
            <Button>
              <i className="zmdi zmdi-widgets zmdi-hc-fw"/>
              <span className="nav-text">
                            <IntlMessages id="sidebar.widgets"/>
                        </span>
            </Button>
            <ul className="sub-menu">
              <li>
                <NavLink className="prepend-icon" to="/app/widgets/classic">
                  <span className="nav-text"><IntlMessages id="sidebar.classic"/></span>
                </NavLink>
              </li>
              <li>
                <NavLink className="prepend-icon" to="/app/widgets/modern">
                  <span className="nav-text"><IntlMessages id="sidebar.modern"/></span>
                </NavLink>
              </li>
            </ul>
          </li>

         
          <li className="nav-header">
            <IntlMessages id="sidebar.inBuiltApp"/>
          </li>
          <li className="menu no-arrow">
            <NavLink to="/app/mail">
              <i className="zmdi zmdi-email zmdi-hc-fw"/>
              <span className="nav-text"><IntlMessages id="sidebar.appModule.mail"/></span>
            </NavLink>
          </li>

          <li className="menu no-arrow">
            <NavLink to="/app/to-do">
              <i className="zmdi zmdi-check-square zmdi-hc-fw"/>
              <span className="nav-text"><IntlMessages id="sidebar.appModule.toDo"/></span>
            </NavLink>
          </li>

          <li className="menu no-arrow">
            <NavLink to="/app/contact">
              <i className="zmdi zmdi-account-box zmdi-hc-fw"/>
              <span className="nav-text"><IntlMessages id="sidebar.appModule.contact"/></span>
            </NavLink>
          </li>

          <li className="menu no-arrow">
            <NavLink to="/app/chat">
              <i className="zmdi zmdi-comment zmdi-hc-fw"/>
              <span className="nav-text"><IntlMessages id="sidebar.appModule.chat"/></span>
            </NavLink>
          </li>

          <li className="nav-header">
            <IntlMessages id="sidebar.inBuiltReduxApp"/>
          </li>
          <li className="menu no-arrow">
            <NavLink to="/app/to-do-redux">
              <i className="zmdi zmdi-check-square zmdi-hc-fw"/>
              <span className="nav-text"><IntlMessages id="sidebar.appModule.toDo"/></span>
            </NavLink>
          </li>

          <li className="menu no-arrow">
            <NavLink to="/app/mail-redux">
              <i className="zmdi zmdi-email zmdi-hc-fw"/>
              <span className="nav-text"><IntlMessages id="sidebar.appModule.mail"/></span>
            </NavLink>
          </li>

          <li className="menu no-arrow">
            <NavLink to="/app/chat-redux">
              <i className="zmdi zmdi-comment zmdi-hc-fw"/>
              <span className="nav-text"><IntlMessages id="sidebar.appModule.chat"/></span>
            </NavLink>
          </li>

          <li className="menu no-arrow">
            <NavLink to="/app/contact-redux">
              <i className="zmdi zmdi-account-box zmdi-hc-fw"/>
              <span className="nav-text"><IntlMessages id="sidebar.appModule.contact"/></span>
            </NavLink>
          </li>

          <li className="nav-header">
            <IntlMessages id="sidebar.social"/>
          </li>
          <li className="menu no-arrow">
            <NavLink to="/app/social-apps/profile">
              <i className="zmdi zmdi-account-box zmdi-hc-fw"/>
              <span className="nav-text"><IntlMessages id="sidebar.profile"/></span>
            </NavLink>
          </li>
          <li className="menu no-arrow">
            <NavLink to="/app/social-apps/wall">
              <i className="zmdi zmdi-account-box zmdi-hc-fw"/>
              <span className="nav-text"><IntlMessages id="sidebar.wall"/></span>
            </NavLink>
          </li>

          <li className="nav-header">
            <IntlMessages id="sidebar.view"/>
          </li>

          <li className="menu collapse-box">
            <Button>
              <i className="zmdi zmdi-view-web zmdi-hc-fw"/>
              <span className="nav-text"><IntlMessages id="sidebar.tables"/></span>
            </Button>

            <ul className="sub-menu">
              <li>
                <NavLink className="prepend-icon" to="/app/table/basic">
                  <span className="nav-text"><IntlMessages id="sidebar.tables.basicTable"/></span>
                </NavLink>
              </li>
              <li>
                <NavLink className="prepend-icon" to="/app/table/data">
                  <span className="nav-text"><IntlMessages id="sidebar.tables.dataTable"/></span>
                </NavLink>
              </li>
            </ul>
          </li>

          <li className="timeline_tooltip menu">
            <Button>
              <i className="zmdi zmdi-swap-alt zmdi-hc-fw zmdi-hc-rotate-90"/>
              <span className="nav-text"><IntlMessages id="sidebar.timeLine"/></span>
            </Button>
            <ul className="sub-menu">

              <li>
                <NavLink className="prepend-icon" to="/app/time-line/default">
                  <span className="nav-text"><IntlMessages id="sidebar.timeLine.default"/></span>
                </NavLink>
              </li>
              <li>
                <NavLink className="prepend-icon"
                         to="/app/time-line/default-with-icon">
                                    <span className="nav-text"><IntlMessages
                                      id="sidebar.timeLine.defaultwithIcons"/></span>
                </NavLink>
              </li>
              <li>
                <NavLink className="prepend-icon"
                         to="/app/time-line/left-align">
                  <span className="nav-text"><IntlMessages id="sidebar.timeLine.leftAligned"/></span>
                </NavLink>
              </li>
              
            </ul>
          </li>

          <li className="menu">
            <Button>
              <i className="zmdi zmdi-view-list zmdi-hc-fw"/>
              <span className="nav-text"><IntlMessages id="sidebar.customViews"/></span>
            </Button>
            <ul className="sub-menu">
              <li>
                <NavLink className="prepend-icon"
                         to="/app/custom-views/simple-list">
                                    <span className="nav-text"><IntlMessages
                                      id="sidebar.customViews.plainListView"/></span>
                </NavLink>
              </li>
              <li>
                <NavLink className="prepend-icon"
                         to="/app/custom-views/strip-list">
                                    <span className="nav-text"><IntlMessages
                                      id="sidebar.customViews.withDivider"/></span>
                </NavLink>
              </li>
              <li>
                <NavLink className="prepend-icon"
                         to="/app/custom-views/card-list">
                                    <span className="nav-text"><IntlMessages
                                      id="sidebar.customViews.cardListView"/></span>
                </NavLink>
              </li>
            </ul>
          </li>

		
		
		
		
          <li className="nav-header">
            <IntlMessages id="sidebar.form"/>
          </li>

          <li className="menu">
            <Button>
              <i className="zmdi zmdi-book-image zmdi-hc-fw"/>
              <span className="nav-text"><IntlMessages id="sidebar.forms"/></span>
            </Button>
            <ul className="sub-menu">
              <li>
                <NavLink className="prepend-icon" to="/app/form/components">
                  <span className="nav-text"><IntlMessages id="sidebar.forms.components"/></span>
                </NavLink>
              </li>
              <li>
                <NavLink className="prepend-icon" to="/app/form/stepper">
                  <span className="nav-text"><IntlMessages id="sidebar.forms.stepper"/></span>
                </NavLink>
              </li>
            </ul>
          </li>
		  

          <li className="nav-header">
            <IntlMessages id="sidebar.extensions"/>
          </li>

	 
		
		
		
          <li className="menu">
            <Button className="void">
              <i className="zmdi zmdi-code-setting zmdi-hc-fw"/>
              <span className="nav-text"><IntlMessages id="sidebar.editors"/></span>
            </Button>

            <ul className="sub-menu">
              <li>
                <NavLink className="prepend-icon" to="/app/editor/ck">
                  <span className="nav-text"><IntlMessages id="sidebar.editors.CKEditor"/></span>
                </NavLink>
              </li>
              <li>
                <NavLink className="prepend-icon" to="/app/editor/wysiswyg">
                                    <span className="nav-text"><IntlMessages
                                      id="sidebar.editors.WYSISWYGEditor"/></span>
                </NavLink>
              </li>
            </ul>

          </li>

          <li className="menu">
            <Button className="void">
              <i className="zmdi zmdi-brush zmdi-hc-fw"/>
              <span className="nav-text"><IntlMessages id="sidebar.pickers"/></span>
            </Button>

            <ul className="sub-menu">
              <li>
                <NavLink className="prepend-icon" to="/app/pickers/date-time">
                                    <span className="nav-text"><IntlMessages
                                      id="sidebar.pickers.dateTimePickers"/></span>
                </NavLink>
              </li>
              <li>
                <NavLink className="prepend-icon" to="/app/pickers/color">
                  <span className="nav-text"><IntlMessages id="sidebar.pickers.colorPickers"/></span>
                </NavLink>
              </li>
            </ul>

          </li>

          <li className="menu">
            <Button className="void">
              <i className="zmdi zmdi-key zmdi-hc-fw"/>
              <span className="nav-text"><IntlMessages id="sidebar.extensions"/></span>
            </Button>

            <ul className="sub-menu">
              <li>
                <NavLink className="prepend-icon" to="/app/extensions/dnd">
                  <span className="nav-text"><IntlMessages id="sidebar.extensions.dragNDrop"/></span>
                </NavLink>
              </li>
              <li>
                <NavLink className="prepend-icon" to="/app/extensions/dropzone">
                  <span className="nav-text"><IntlMessages id="sidebar.extensions.dropzone"/></span>
                </NavLink>
              </li>
              <li>
                <NavLink className="prepend-icon"
                         to="/app/extensions/sweet-alert">
                  <span className="nav-text"><IntlMessages id="sidebar.extensions.sweetAlert"/></span>
                </NavLink>
              </li>
              <li>
                <NavLink className="prepend-icon"
                         to="/app/extensions/notification">
                                    <span className="nav-text"><IntlMessages
                                      id="sidebar.extensions.notification"/></span>
                </NavLink>
              </li>
            </ul>
          </li>

          <li className="menu">
            <Button>
              <i className="zmdi zmdi-chart zmdi-hc-fw"/>
              <span className="nav-text"><IntlMessages id="sidebar.chart"/></span>
            </Button>

            <ul className="sub-menu">
              <li>
                <NavLink className="prepend-icon" to="/app/chart/line">
                  <span className="nav-text"><IntlMessages id="sidebar.chart.line"/></span>
                </NavLink>
              </li>
              <li>
                <NavLink className="prepend-icon" to="/app/chart/bar">
                  <span className="nav-text"><IntlMessages id="sidebar.chart.bar"/></span>
                </NavLink>
              </li>
              <li>
                <NavLink className="prepend-icon" to="/app/chart/area">
                  <span className="nav-text"><IntlMessages id="sidebar.chart.area"/></span>
                </NavLink>
              </li>
              <li>
                <NavLink className="prepend-icon" to="/app/chart/composed">
                                <span className="nav-text"><IntlMessages
                                  id="sidebar.chart.composed"/></span>
                </NavLink>
              </li>
              <li>
                <NavLink className="prepend-icon" to="/app/chart/scatter">
                                <span className="nav-text"><IntlMessages
                                  id="sidebar.chart.scatter"/></span>
                </NavLink>
              </li>
              <li>
                <NavLink className="prepend-icon" to="/app/chart/pie">
                  <span className="nav-text"><IntlMessages id="sidebar.chart.pie"/></span>
                </NavLink>
              </li>
              <li>
                <NavLink className="prepend-icon" to="/app/chart/radial">
                  <span className="nav-text"><IntlMessages id="sidebar.chart.radial"/></span>
                </NavLink>
              </li>
              <li>
                <NavLink className="prepend-icon" to="/app/chart/radar">
                  <span className="nav-text"><IntlMessages id="sidebar.chart.radar"/></span>
                </NavLink>
              </li>
              <li>
                <NavLink className="prepend-icon" to="/app/chart/treemap">
                  <span className="nav-text"><IntlMessages id="sidebar.chart.tree"/></span>
                </NavLink>
              </li>
            </ul>
          </li>

          <li className="map_tooltip menu">
            <Button>
              <i className="zmdi zmdi-google-maps zmdi-hc-fw"/>
              <span className="nav-text"><IntlMessages id="sidebar.map"/></span>
            </Button>

            <ul className="sub-menu">
              <li>
                <NavLink className="prepend-icon" to="/app/map/simple">
                  <span className="nav-text"><IntlMessages id="sidebar.map.simple"/></span>
                </NavLink>
              </li>
              <li>
                <NavLink className="prepend-icon" to="/app/map/styled">
                  <span className="nav-text"><IntlMessages id="sidebar.map.styled"/></span>
                </NavLink>
              </li>
              <li>
                <NavLink className="prepend-icon" to="/app/map/geo-location">
                  <span className="nav-text"><IntlMessages id="sidebar.map.geoLocation"/></span>
                </NavLink>
              </li>
              <li>
                <NavLink className="prepend-icon" to="/app/map/directions">
                  <span className="nav-text"><IntlMessages id="sidebar.map.mapDirection"/></span>
                </NavLink>
              </li>
              <li>
                <NavLink className="prepend-icon" to="/app/map/overlay">
                  <span className="nav-text"><IntlMessages id="sidebar.map.overlay"/></span>
                </NavLink>
              </li>
              <li>
                <NavLink className="prepend-icon" to="/app/map/kml">
                  <span className="nav-text"><IntlMessages id="sidebar.map.kmLayer"/></span>
                </NavLink>
              </li>
              <li>
                <NavLink className="prepend-icon" to="/app/map/popup-info">
                  <span className="nav-text"><IntlMessages id="sidebar.map.popupInfo"/></span>
                </NavLink>
              </li>
              <li>
                <NavLink className="prepend-icon" to="/app/map/traffic">
                  <span className="nav-text"><IntlMessages id="sidebar.map.trafficLayer"/></span>
                </NavLink>
              </li>
              <li>
                <NavLink className="prepend-icon" to="/app/map/street-view">
                  <span className="nav-text"><IntlMessages id="sidebar.map.streetView"/></span>
                </NavLink>
              </li>
              <li>
                <NavLink className="prepend-icon" to="/app/map/event">
                  <span className="nav-text"><IntlMessages id="sidebar.map.eventListener"/></span>
                </NavLink>
              </li>
              <li>
                <NavLink className="prepend-icon" to="/app/map/drawing">
                  <span className="nav-text"><IntlMessages id="sidebar.map.mapDrawing"/></span>
                </NavLink>
              </li>
              <li>
                <NavLink className="prepend-icon" to="/app/map/clustering">
                  <span className="nav-text"><IntlMessages id="sidebar.map.mapClustering"/></span>
                </NavLink>
              </li>
            </ul>
          </li>

          <li className="nav-header">
            <IntlMessages id="sidebar.modules"/>
          </li>

          <li className="menu">
            <Button className="void">
              <i className="zmdi zmdi-calendar zmdi-hc-fw"/>
              <span className="nav-text"><IntlMessages id="sidebar.calendar"/></span>
            </Button>

            <ul className="sub-menu">
              <li>
                <NavLink className="prepend-icon" to="/app/calendar/basic">
                  <span className="nav-text"><IntlMessages id="sidebar.calendar.basic"/></span>
                </NavLink>
              </li>
              <li>
                <NavLink className="prepend-icon" to="/app/calendar/cultures">
                  <span className="nav-text"><IntlMessages id="sidebar.calendar.cultures"/></span>
                </NavLink>
              </li>
              <li>
                <NavLink className="prepend-icon" to="/app/calendar/dnd">
                  <span className="nav-text"><IntlMessages id="sidebar.calendar.dnd"/></span>
                </NavLink>
              </li>
              <li>
                <NavLink className="prepend-icon" to="/app/calendar/popup">
                  <span className="nav-text"><IntlMessages id="sidebar.calendar.popup"/></span>
                </NavLink>
              </li>
              <li>
                <NavLink className="prepend-icon" to="/app/calendar/rendering">
                  <span className="nav-text"><IntlMessages id="sidebar.calendar.rendering"/></span>
                </NavLink>
              </li>
              <li>
                <NavLink className="prepend-icon"
                         to="/app/calendar/selectable">
                  <span className="nav-text"><IntlMessages id="sidebar.calendar.selectable"/></span>
                </NavLink>
              </li>
              <li>
                <NavLink className="prepend-icon" to="/app/calendar/timeslots">
                  <span className="nav-text"><IntlMessages id="sidebar.calendar.timeslots"/></span>
                </NavLink>
              </li>
            </ul>

          </li>

		
		
          <li className="menu">
            <Button>
              <i className="zmdi zmdi-shopping-cart zmdi-hc-fw"/>
              <span className="nav-text text-transform-none"><IntlMessages id="sidebar.eCommerce"/></span>
            </Button>

            <ul className="sub-menu">

              <li>
                <NavLink className="prepend-icon"
                         to="/app/ecommerce/products-list">
                                    <span className="nav-text"><IntlMessages
                                      id="sidebar.eCommerce.productsList"/></span>
                </NavLink>
              </li>
              <li>
                <NavLink className="prepend-icon"
                         to="/app/ecommerce/products-grid">
                                    <span className="nav-text"><IntlMessages
                                      id="sidebar.eCommerce.productsGrid"/></span>
                </NavLink>
              </li>
            </ul>
          </li>

          <li className="menu">
            <Button>
              <i className="zmdi zmdi-collection-item-8 zmdi-hc-fw"/>
              <span className="nav-text"><IntlMessages id="sidebar.appModule"/></span>
            </Button>

            <ul className="sub-menu">
              <li>
                <NavLink className="prepend-icon" to="/app/app-module/login-1">
                  <span className="nav-text"><IntlMessages id="sidebar.appModule.login1"/></span>
                </NavLink>
              </li>
              <li>
                <NavLink className="prepend-icon" to="/app/app-module/login-2">
                  <span className="nav-text"><IntlMessages id="sidebar.appModule.login2"/></span>
                </NavLink>
              </li>

              <li>
                <NavLink className="prepend-icon"
                         to="/app/app-module/login-with-stepper">
                                    <span className="nav-text"><IntlMessages
                                      id="sidebar.appModule.loginStepper"/></span>
                </NavLink>
              </li>

              <li>
                <NavLink className="prepend-icon"
                         to="/app/app-module/sign-up-1">
                  <span className="nav-text"><IntlMessages id="sidebar.appModule.signup1"/></span>
                </NavLink>
              </li>
              <li>
                <NavLink className="prepend-icon"
                         to="/app/app-module/sign-up-2">
                  <span className="nav-text"><IntlMessages id="sidebar.appModule.signup2"/></span>
                </NavLink>
              </li>
              <li>
                <NavLink className="prepend-icon"
                         to="/app/app-module/forgot-password-1">
                                    <span className="nav-text"><IntlMessages
                                      id="sidebar.appModule.forgotPassword1"/></span>
                </NavLink>
              </li>
              <li>
                <NavLink className="prepend-icon"
                         to="/app/app-module/forgot-password-2">
                                    <span className="nav-text"><IntlMessages
                                      id="sidebar.appModule.forgotPassword2"/></span>
                </NavLink>
              </li>
              <li>
                <NavLink className="prepend-icon"
                         to="/app/app-module/lock-screen-1">
                  <span className="nav-text"><IntlMessages id="sidebar.appModule.lock1"/></span>
                </NavLink>
              </li>
              <li>
                <NavLink className="prepend-icon"
                         to="/app/app-module/lock-screen-2">
                  <span className="nav-text"><IntlMessages id="sidebar.appModule.lock2"/></span>
                </NavLink>
              </li>
            </ul>
          </li>

          <li className="nav-header">
            <IntlMessages id="sidebar.extras"/>
          </li>

          <li className="menu">
            <Button>
              <i className="zmdi zmdi-view-web zmdi-hc-fw"/>
              <span className="nav-text"><IntlMessages id="sidebar.icons"/></span>
            </Button>
            <ul className="sub-menu">
              <li>
                <NavLink className="prepend-icon" to="/app/icons/material">
                  <span className="nav-text"><IntlMessages id="sidebar.icons.material"/></span>
                </NavLink>
              </li>
            </ul>
          </li>

          <li className="menu">
            <Button>
              <i className="zmdi zmdi-collection-bookmark zmdi-hc-fw zmdi-hc-rotate-90"/>
              <span className="nav-text"><IntlMessages id="sidebar.extraElements"/></span>
            </Button>
            <ul className="sub-menu">
              <li>
                <NavLink className="prepend-icon"
                         to="/app/extra-elements/pricing-table">
                                <span className="nav-text"><IntlMessages
                                  id="sidebar.extraElements.pricingTable"/></span>
                </NavLink>
              </li>
              <li>
                <NavLink className="prepend-icon"
                         to="/app/extra-elements/callouts">
                                    <span className="nav-text"><IntlMessages
                                      id="sidebar.extraElements.callouts"/></span>
                </NavLink>
              </li>
              <li>
                <NavLink className="prepend-icon"
                         to="/app/extra-elements/testimonials">
                                <span className="nav-text"><IntlMessages
                                  id="sidebar.extraElements.testimonials"/></span>
                </NavLink>
              </li>
            </ul>
          </li>

          <li className="menu">
            <Button>
              <i className="zmdi zmdi-pages zmdi-hc-fw"/>
              <span className="nav-text"><IntlMessages id="sidebar.extraPages"/></span>
            </Button>
            <ul className="sub-menu">
              <li>
                <NavLink className="prepend-icon"
                         to="/app/extra-pages/about-us">
                  <span className="nav-text"><IntlMessages id="sidebar.extraPages.aboutUs"/></span>
                </NavLink>
              </li>
              <li>
                <NavLink className="prepend-icon"
                         to="/app/extra-pages/contact-us">
                  <span className="nav-text"><IntlMessages id="sidebar.extraPages.contactUs"/></span>
                </NavLink>
              </li>
              <li>
                <NavLink className="prepend-icon" to="/app/extra-pages/blog">
                  <span className="nav-text"><IntlMessages id="sidebar.extraPages.blog"/></span>
                </NavLink>
              </li>
              <li>
                <NavLink className="prepend-icon" to="/app/extra-pages/faq">
                  <span className="nav-text"><IntlMessages id="sidebar.extraPages.faq"/></span>
                </NavLink>
              </li>
              <li>
                <NavLink className="prepend-icon"
                         to="/app/extra-pages/portfolio">
                  <span className="nav-text"><IntlMessages id="sidebar.extraPages.portfolio"/></span>
                </NavLink>
              </li>
              <li>
                <NavLink className="prepend-icon"
                         to="/app/extra-pages/error-404">
                  <span className="nav-text"><IntlMessages id="sidebar.extraPages.404"/></span>
                </NavLink>
              </li>
              <li>
                <NavLink className="prepend-icon"
                         to="/app/extra-pages/error-500">
                  <span className="nav-text"><IntlMessages id="sidebar.extraPages.500"/></span>
                </NavLink>
              </li>
            </ul>
          </li>

          <li className="menu">
            <Button>
              <i className="zmdi zmdi-device-hub zmdi-hc-fw"/>
              <span className="nav-text"><IntlMessages id="sidebar.menuLevels"/></span>
            </Button>

            <ul className="sub-menu">
              <li>
                <Button className="prepend-icon">
                  <span className="nav-text"><IntlMessages id="sidebar.menuLevels.level1"/></span>
                </Button>
              </li>
              <li className="menu">
                <Button className="prepend-icon">
                  <span className="nav-text"><IntlMessages id="sidebar.menuLevels.level1"/></span>
                </Button>

                <ul className="sub-menu">
                  <li>
                    <Button href="">
                      <span className="nav-text"><IntlMessages
                        id="sidebar.menuLevels.level2"/></span>
                    </Button>
                  </li>
                  <li className="menu">
                    <Button href="">
                      <span className="nav-text"><IntlMessages
                        id="sidebar.menuLevels.level2"/></span>
                    </Button>

                    <ul className="sub-menu">
                      <li>
                        <Button href="">
                          <span className="nav-text"><IntlMessages
                            id="sidebar.menuLevels.level3"/></span>
                        </Button>
                      </li>
                      <li>
                        <Button href="">
                          <span className="nav-text"><IntlMessages
                            id="sidebar.menuLevels.level3"/></span>
                        </Button>
                      </li>
                    </ul>
                  </li>
                </ul>
              </li>
            </ul>
          </li>


*/}
        </ul>
      </CustomScrollbars>
    );
  }
}

export default withRouter(SidenavContent);
