import { makeStyles } from "@material-ui/core";
import React, { useState } from "react";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import CloseIcon from "@material-ui/icons/Close";
import {
  Backdrop,
  Fade,
  IconButton,
  Modal,
} from "@material-ui/core";
import CustomScrollbars from "../../../util/CustomScrollbars";

const useStyles = makeStyles((theme) => ({
  gridImage: {
    display: "flex",
    flexDirection: "row",
    flexWrap:"wrap",
    [theme.breakpoints.down('md')]: {
      display:"grid",
      gridTemplateColumns: "1fr 1fr",
    },
  },
  imagClass: {
    height: "80px",
    width: "80px",
    margin: "5px",
    borderRadius: "5px",
    cursor:"pointer"
  },
  root: {
    backgroundColor: "transparent",
    width: "100%",
  },
  gridpic: {
    height: "100px",
    width: "100%",
    objectFit: "cover",
    cursor: "pointer",
  },
  modal: {
    padding: "0 50px",
    paddingTop: "40px",
    [theme.breakpoints.down("sm")]: {
      padding: "0 35px",
      paddingTop: "20px",
    },
  },
  sliderComponent: {
    position: "relative",
    padding: "0 100px",
    width: "50% !important",
    display: "flex !important",
    justifyContent: "center",
    margin: "auto",
    [theme.breakpoints.down("sm")]: {
      width: "100% !important",
      padding: "0",
    },
  },
  scrollbar: {
    height: "90vh !important",
  },
  imgSlider: {
    height: "400px !important",
    width: "400px !important",
    objectFit: "cover",
    margin: "0 auto",
  },
  slick_slider: {
    "& .slick-prev": {
      zIndex: "111",
    },
    "& .slick-prev:before": {
      color: "#f1f1f1 !important",
      fontSize: "32px",
    },
    "& .slick-next": {
      zIndex: "111",
    },
    "& .slick-next:before": {
      color: "#f1f1f1 !important",
      fontSize: "32px",
    },
  },
  closeIcon: {
    color: "#fff",
    position: "absolute",
    top: "-8px",
    right: "50px",
    [theme.breakpoints.down("sm")]: {
      zIndex: "1",
      right: "0",
    },
  },
}));

const CommentImageBox = (props) => {
  const classes = useStyles();
  const [imageList, setImageList] = useState({
    imageData: props && props.imageData,
    data: [],
    showCarousel: false,
  });

  const handleSingleCarouselClose = () => {
    setImageList({
      ...imageList,
      showCarousel: false,
    });
  };

  const handleCarouselOpen = (image) => {
    setImageList({
      ...imageList,
      showCarousel: true,
      imageData: image,
    });
  };

  const handleCarouselClose = () => {
    setImageList({
      ...imageList,
      showCarousel: false,
    });
  };

  const handleClickImage = (img) => {
    setImageList((prev) => ({
      ...imageList,
      showCarousel: prev.showCarousel ? false : true,
      data: img,
    }));
  };

  const settings = {
    dots: false,
    infinite: false,
    autoplay: false,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    initialSlide: imageList.data
      ? parseInt(imageList.data || imageList.data, 10) - 1
      : imageList.data,
  };

  return (
    <div>
      <div className={classes.gridImage}>
        {imageList && imageList.imageData.map((img) => (
          <img
            src={img.imageURL}
            className={classes.imagClass}
            onClick={() => handleClickImage(img.imageSequence)}
          />
        ))}
      </div>

      <Modal
        className={classes.modal}
        open={imageList.showCarousel}
        onClose={handleCarouselClose}
        closeAfterTransition
        BackdropComponent={Backdrop}
        BackdropProps={{
          timeout: 500,
        }}
      >
        <Fade in={imageList.showCarousel}>
          <div>
            <Slider {...settings} className={classes.slick_slider}>
              {imageList.imageData.map((image, index) => (
                <div className={classes.sliderComponent} key={image.imageID}>
                  <IconButton
                    className={classes.closeIcon}
                    onClick={handleCarouselClose}
                  >
                    <CloseIcon />
                  </IconButton>
                  <CustomScrollbars className={classes.scrollbar}>
                    <div className={classes.sliderImage}>
                      <img
                        src={image.imageURL || image.url}
                        alt={image.imageURL || image.url}
                        className="img-fluid"
                      />
                    </div>
                  </CustomScrollbars>
                </div>
              ))}
            </Slider>
          </div>
        </Fade>
      </Modal>
    </div>
  );
};

export default CommentImageBox;
