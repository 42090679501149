import React from 'react';
import NotificationContainer from 'react-notifications/lib/NotificationContainer';
import {Redirect, Route, Switch} from 'react-router-dom';
import asyncComponent from '../../../util/asyncComponent';

const Dashboard = ({match}) => (
  <div className="app-wrapper">
    <Switch>
      <Redirect exact from={`${match.url}/`} to={`${match.url}/overview`}/>
	  <Route path={`${match.url}/ProfitLoss`} component={asyncComponent(() => import('./routes/profitloss'))}/>
      <Route path={`${match.url}/Booking`} component={asyncComponent(() => import('./routes/bookings'))}/>  
	<Route path={`${match.url}/Service`} component={asyncComponent(() => import('./routes/services'))}/>  
	<Route path={`${match.url}/Customer`} component={asyncComponent(() => import('./routes/customer'))}/>  
	<Route path={`${match.url}/Income`} component={asyncComponent(() => import('./routes/income'))}/>  
	<Route path={`${match.url}/IncomeForcast`} component={asyncComponent(() => import('./routes/incomeforcast'))}/>  
	<Route path={`${match.url}/Expense`} component={asyncComponent(() => import('./routes/expense'))}/>  
    <Route path={`${match.url}/Suburb`} component={asyncComponent(() => import('./routes/suburb'))}/>  
	 <Route path={`${match.url}/GST`} component={asyncComponent(() => import('./routes/gst'))}/>  
	 <Route path={`${match.url}/GST_DETAIL`} component={asyncComponent(() => import('./routes/gstDetail'))}/>  
	 <Route path={`${match.url}/GSTCash`} component={asyncComponent(() => import('./routes/gstCash'))}/>  

	 <Route path={`${match.url}/Tracing`} component={asyncComponent(() => import('./routes/tracing'))}/>
	  <Route path={`${match.url}/classic`} component={asyncComponent(() => import('./routes/Classic'))}/>
      <Route path={`${match.url}/modern`} component={asyncComponent(() => import('./routes/Modern'))}/>
	  <Route path={`${match.url}/area`} component={asyncComponent(() => import('./routes/area'))}/>
      <Route path={`${match.url}/bar`} component={asyncComponent(() => import('./routes/bar'))}/>
      <Route path={`${match.url}/composed`} component={asyncComponent(() => import('./routes/composed'))}/>
      <Route path={`${match.url}/line`} component={asyncComponent(() => import('./routes/line'))}/>
      <Route path={`${match.url}/pie`} component={asyncComponent(() => import('./routes/pie'))}/>
      <Route path={`${match.url}/radar`} component={asyncComponent(() => import('./routes/radar'))}/>
      <Route path={`${match.url}/radial`} component={asyncComponent(() => import('./routes/radial'))}/>
      <Route path={`${match.url}/scatter`} component={asyncComponent(() => import('./routes/scatter'))}/>
      <Route path={`${match.url}/treemap`} component={asyncComponent(() => import('./routes/treemap'))}/>
	  <Route path={`${match.url}/ProfitLossGroup`} component={asyncComponent(() => import('./routes/profitlossgroup'))}/>
      <Route component={asyncComponent(() => import('app/routes/extraPages/routes/404'))}/>
    </Switch>
    <NotificationContainer />
  </div>
);

export default Dashboard;