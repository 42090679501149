import React from 'react';
import { WebView } from 'react';
import CardBox from 'components/CardBox/index';
import ContainerHeader from 'components/ContainerHeader/index';
import IntlMessages from 'util/IntlMessages';

import TextField from '@material-ui/core/TextField';
import Button from '@material-ui/core/Button';
import Input from '@material-ui/core/Input';
import { Redirect } from 'react-router-dom'
import ReactDOM from 'react-dom';
import ReactPlayer from 'react-player';
import GlobalURL from 'appUtility/global';
import { NotificationContainer } from 'react-notifications';


const MarketingVideos = ({match}) => {
  return (
    <div className="animated slideInUpTiny animation-duration-3 jr-card ">
	<ContainerHeader title={<IntlMessages id="sidebar.marketingvideo.menu"/>} match={match}/>
	  
	  <div className="row jr-card">
          <div className="col-4">
            <h3>Physical Marketing Ideas</h3>
            <ReactPlayer url= {GlobalURL[0].videourl+"Marketing_Ideas.mp4"} width='420' light={GlobalURL[0].videourl+"marketing_ideas.jpg"} controls playing/>
          </div>
          <div className="col-4">
            <h3>Google My Business</h3>
            <ReactPlayer url={GlobalURL[0].videourl+"How_to_google_place.mp4"} width='420' light={GlobalURL[0].videourl+"google_location_marketing.jpg"} controls playing/>
          </div>
          <div className="col-4">
          <h3>Facebook Groups</h3>
            <ReactPlayer url={GlobalURL[0].videourl+"How_to_Facebook.mp4"} width='420' light={GlobalURL[0].videourl+"facebook_marketing.JPG"} controls playing/>
          </div>
	  </div>
      
	 
	    <NotificationContainer />
    </div>

  );
};

export default MarketingVideos;

