import React from "react";

const SearchBox = ({
  styleName,
  placeholder,
  onChange,
  value,
  onKeyPress,
  onKeyDown
}) => {
  return (
    <div
      className={`search-bar right-side-icon ${styleName}`}
      style={{ border: "2px solid #e6e6e6", borderRadius: "8px", marginBottom: "20px" }}
    >
      <div className="form-group">
        <input
          className="form-control border-0"
          type="search"
          placeholder={placeholder}
          onChange={onChange}
          value={value}
          onKeyPress={onKeyPress}
          onKeyDown={onKeyDown}
        />
        <button className="search-icon">
          <i className="zmdi zmdi-search zmdi-hc-lg" />
        </button>
      </div>
    </div>
  );
};
export default SearchBox;

SearchBox.defaultProps = {
  styleName: "",
  value: ""
};
