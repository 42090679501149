import React from 'react';
import { WebView } from 'react';
import CardBox from 'components/CardBox/index';
import ContainerHeader from 'components/ContainerHeader/index';
import IntlMessages from 'util/IntlMessages';

import TextField from '@material-ui/core/TextField';
import Button from '@material-ui/core/Button';
import Input from '@material-ui/core/Input';
import { Redirect } from 'react-router-dom'
import ReactDOM from 'react-dom';
import ReactPlayer from 'react-player';
import GlobalURL from 'appUtility/global';
import { NotificationContainer } from 'react-notifications';


const Videos = ({ match }) => {
  return (
    <div className="animated slideInUpTiny animation-duration-3 jr-card ">
      <ContainerHeader title={<IntlMessages id="sidebar.video.menu" />} match={match} />

      <div className="row jr-card">
        <div className="col-4">
          <h3>Bernese Mountain Dog – Ears</h3>
          <ReactPlayer url={GlobalURL[0].videourl + "Aggie ears.mp4"} width='420' light={GlobalURL[0].videourl + "video-thumbnail.jpg"} controls playing />
        </div>
        <div className="col-4">
          <h3>Bernese Mountain Dog – Feet</h3>
          <ReactPlayer url={GlobalURL[0].videourl + "Aggie feet.mp4"} width='420' light={GlobalURL[0].videourl + "video-thumbnail.jpg"} controls playing />
        </div>
        <div className="col-4">
          <h3>Shi Tzu X – Head Set Up</h3>
          <ReactPlayer url={GlobalURL[0].videourl + "Arnie.mp4"} width='420' light={GlobalURL[0].videourl + "video-thumbnail.jpg"} controls playing />
        </div>
      </div>

      <div className="row jr-card">

        <div className="col-4">
          <h3>King Charles Cavalier – Face Feet & Tail</h3>
          <ReactPlayer url={GlobalURL[0].videourl + "Bella.mp4"} width='420' light={GlobalURL[0].videourl + "video-thumbnail.jpg"} controls playing />
        </div>


        <div className="col-4">
          <h3>Poodle - Nails, Poodle Foot</h3>
          <ReactPlayer url={GlobalURL[0].videourl + "Flynn feet & nails.mp4"} width='420' light={GlobalURL[0].videourl + "video-thumbnail.jpg"} controls playing />
        </div>

        <div className="col-4">
          <h3>Poodle – Poodle Face</h3>
          <ReactPlayer url={GlobalURL[0].videourl + "Flynn head.mp4"} width='420' light={GlobalURL[0].videourl + "video-thumbnail.jpg"} controls playing />
        </div>

      </div>

      <div className="row jr-card">


        <div className="col-4">
          <h3>Poodle – Tail</h3>
          <ReactPlayer url={GlobalURL[0].videourl + "Flynn tail.mp4"} width='420' light={GlobalURL[0].videourl + "video-thumbnail.jpg"} controls playing />
        </div>

        <div className="col-4">
          <h3>Golden Retriever – Feet & Tail</h3>
          <ReactPlayer url={GlobalURL[0].videourl + "Max.mp4"} width='420' light={GlobalURL[0].videourl + "video-thumbnail.jpg"} controls playing />
        </div>

        <div className="col-4">
          <h3>Cavoodle – Head, Face, Ears, Legs & Feet</h3>
          <ReactPlayer url={GlobalURL[0].videourl + "Pepe.mp4"} width='420' light={GlobalURL[0].videourl + "video-thumbnail.jpg"} controls playing />
        </div>

      </div>

      <div className="row jr-card">

        <div className="col-4">
          <h3>West Highland White Terrier – Head, Face & Ears</h3>
          <ReactPlayer url={GlobalURL[0].videourl + "Rosie.mp4"} width='420' light={GlobalURL[0].videourl + "video-thumbnail.jpg"} controls playing />
        </div>
        <div className="col-4">
          <h3>Schnauzer – Head & Body Set</h3>
          <ReactPlayer url={GlobalURL[0].videourl + "Ziggy.mp4"} width='420' light={GlobalURL[0].videourl + "video-thumbnail.jpg"} controls playing />
        </div>

        <div className="col-4">
          <h3>BW Trailer Maintenance</h3>
          <ReactPlayer url={GlobalURL[0].videourl + "BW trailer maintenance.mp4"} width='420' light={GlobalURL[0].videourl + "video-thumbnail.jpg"} controls playing />
        </div>

      </div>

      <div className="row jr-card">

        <div className="col-4">
          <h3>Simple Science of Grooming </h3>
          <ReactPlayer url={GlobalURL[0].videourl + "The_Simple_Science_of_Grooming_Natalie_Ryan.mp4"} width='420' light={GlobalURL[0].videourl + "video-thumbnail.jpg"} controls playing />
        </div>
        <div className="col-4">
          <h3>Basic Recap of Blue Wheelers</h3>
          <ReactPlayer url={GlobalURL[0].videourl + "Basics_Recap_for_Blue_Wheelers.mp4"} width='420' light={GlobalURL[0].videourl + "video-thumbnail.jpg"} controls playing />
        </div>

        {/* <div className="col-4">
          <h3>De-Shed 123</h3>
          <ReactPlayer url={GlobalURL[0].videourl + "BW-DeShed-video.mp4"} width='420' light={GlobalURL[0].videourl + "video-thumbnail.jpg"} controls playing />
        </div> */}
        <div className="col-4">
          <h3>Amber and Sharon Grooming </h3>
          <ReactPlayer url={GlobalURL[0].videourl + "Amber And Sharon Grooming.mp4"} width='420' light={GlobalURL[0].videourl + "video-thumbnail.jpg"} controls playing />
        </div>

      </div>



      { /* 
       <CardBox styleName="col-12 fullWidthLawaNoPadding" cardStyle=" p-0"
               headerOutside>
	  <Iframe iframe={demos["forum1"]} id="myId" className="myClassname" />,
      </CardBox>
	  */}
      <NotificationContainer />
    </div>

  );
};

export default Videos;

