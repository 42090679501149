import React from 'react'
import ContainerHeader from 'components/ContainerHeader/index';
import DataTable from './DataTable';

const ServiceInventoryUseage = () => {
  return (
    <div className="app-wrapper animated slideInUpTiny animation-duration-3">
	  {/* <ContainerHeader title={<IntlMessages id="sidebar.customers.archivelist"/>} match={match}/> */}
	  <ContainerHeader title={"Service Inventory Usage"} />
         <DataTable />
     </div>
  )
}

export default ServiceInventoryUseage