import React from "react";
import Button from "@material-ui/core/Button";
import { history } from "../../../store/index";
import { SettingsInputSvideoOutlined } from "@material-ui/icons";

const socialData = JSON.parse(window.localStorage.getItem("login"));

const FriendsCard = ({
  detail,
  styleName,
  userInvites,
  handleAlertOpen,
  handleInvite,
  handleUnblock,
  blockSuccess,
  inviteData,
  userValid
}) => {
  const {
    firstName,
    lastName,
    userThumbnail,
    address,
    serviceLocation,
    memberID,
    isSubscribed,
    invited,
    isBlocked,
    hasAccepted,
  } = detail;

  if (!styleName) {
    styleName = "col-sm-6 col-12 mb-3";
  }

  const handleCheckProfile = () => {
    //Here member name and memberID are passed so that it navigates to that particular URL and loads that member's profile
    let memberName = firstName + lastName;
    history.push(`/app/social/member/${memberID}/${memberName}`);
  };

  // console.log("detail in team member ??", detail);

  return (
    <div className={`${styleName}`}>
      <div style={{ cursor: "pointer" }}>
        <div className="row no-gutters">
          <div className="col-sm-5 col-6">
            <div className="teams_image" onClick={handleCheckProfile}>
              <img
                className="img-teams rounded"
                src={userThumbnail}
                alt="pro-pic"
              />
            </div>
          </div>
          <div className="col-sm-7 col-6 pl-sm-3 pt-3 pt-sm-0">
            <div className="teams_name">
              <div onClick={handleCheckProfile}>
                <h5 className="mb-1"> {firstName + " " + lastName}</h5>
                <p className="text-light mb-1"> {serviceLocation}</p>
              </div>

              {userValid && (socialData && socialData.uid !== memberID) && (
                <div>
                  {
                    <>
                      {(isSubscribed === 1 || hasAccepted === 1) && 
                      isBlocked === 0 ? (
                        <span
                          style={{
                            color: "red",
                            fontSize: "12px",
                            marginRight: "5px",
                            textDecoration: "underline",
                          }}
                          onClick={() => handleAlertOpen(detail)}
                        >
                          {"Block"}
                        </span>
                      ) : (
                        isBlocked === 1 && (
                          <span
                            style={{
                              color: "#3f51b5",
                              fontSize: "12px",
                              marginRight: "5px",
                              textDecoration: "underline",
                            }}
                            onClick={() => handleAlertOpen(detail)}
                          >
                            {"Unblock"}
                          </span>
                        )
                      )}
                    </>
                  }
                  {userInvites &&
                    isSubscribed === 0 &&
                    hasAccepted !== 1 &&
                    isBlocked !== 1 && (
                      <span
                        style={{
                          color: invited && invited === 1 ? "#999" : "green",
                          textDecoration:
                            invited && invited === 1 ? "none" : "underline",
                          fontSize: "12px",
                          pointerEvents:
                            invited && invited === 1 ? "none" : "block",
                        }}
                        onClick={() => handleInvite(detail)}
                      >
                        {invited && invited === 1 ? "Invited" : "Invite"}
                      </span>
                    )}
                </div>
              )}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default FriendsCard;
