import React, { Component } from "react";
import Button from "@material-ui/core/Button";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogTitle from "@material-ui/core/DialogTitle";
import Slide from "@material-ui/core/Slide";
import { DialogContent } from "@material-ui/core";
// import DialogContent from "@material-ui/core/DialogContent";
// import DialogContentText from "@material-ui/core/DialogContentText";

class AlertDialog extends Component {
  state = {
    open: this.props.deleteAlert,
  };

  handleRequestClose = () => {
    if (this.props.commentAlert) {
      this.props.CancelCommentDelete();
    } else {
      this.props.CancelDeletePost();
    }
  };

  handleDeleteWork = () => {
    const { postID, groupID } = this.props;
    this.props.handleDeletePost(postID, groupID);
  };

  handleDeleteImage = () =>{
    const { postID, imageID } = this.props;
    this.props.handleDeleteImage(postID, imageID);
  }

  handleDeleteComment = () => {
    const { postID, commentID } = this.props;
    this.props.handleCommentDelete(postID, commentID);
  };

  handleImgCommentDelete = () => {
    const { postID, commentID, imageID } = this.props;
    this.props.handleImageCommentDelete(postID, commentID, imageID);
  }

  render() {
    const { postID, groupID, commentID, commentAlert } = this.props;

    // console.log("on ALERT DIALOG::>>", postID, groupID, commentID);
    // console.log('props in alter dialiog >>', this.props);

    return (
      <>
        {commentAlert ? (
          <Dialog
            open={this.state.open}
            TransitionComponent={Slide}
            onClose={this.handleRequestClose}
          >
            <DialogTitle>
              Are you sure you want to delete this Comment ?
            </DialogTitle>
            <DialogContent>This will be irreversible</DialogContent>
            <DialogActions>
              <Button onClick={this.handleRequestClose} color="secondary">
                No
              </Button>
              <Button onClick={this.props.commentDelete === false ? this.handleImgCommentDelete : this.handleDeleteComment} color="primary">
                Yes
              </Button>
            </DialogActions>
          </Dialog>
        ) : (
          <Dialog
            open={this.state.open}
            TransitionComponent={Slide}
            onClose={this.handleRequestClose}
          >
            <DialogTitle>
              Are you sure you want to delete this {this.props.imageId ? "Image" : "Post"} ?
            </DialogTitle>
            <DialogContent>This will be irreversible</DialogContent>
            <DialogActions>
              <Button onClick={this.handleRequestClose} color="secondary">
                No
              </Button>
              <Button onClick={this.props.imageID ? this.handleDeleteImage : this.handleDeleteWork} color="primary">
                Yes
              </Button>
            </DialogActions>
          </Dialog>
        )}
      </>
    );
  }
}

export default AlertDialog;
