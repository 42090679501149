import React from "react";
import { Redirect, Route, Switch } from "react-router-dom";
import asyncComponent from "../../../util/asyncComponent";

const SocialApps = ({ match }) => (
  <div className="app-wrapper">
    <Switch>
      <Redirect exact from={`${match.url}/`} to={`${match.url}/wall`} />
      <Route
        path={`${match.url}/profile`}
        component={asyncComponent(() => import("./routes/Profile"))}
      />
      <Route
        path={`${match.url}/profileDetail`}
        component={asyncComponent(() =>
          import("./routes/Profile/profileDetail")
        )}
      />
      <Route
        path={`${match.url}/group/:groupName/:id`}
        component={asyncComponent(() => import("./routes/Profile/groupDetail"))}
      />

      <Route
        path={`${match.url}/member/:id/:memberName`}
        component={asyncComponent(() =>
          import("./routes/Profile/MemberDetail")
        )}
      />

      <Route
        path={`${match.url}/gallery/:id`}
        component={asyncComponent(() =>
          import("./routes/Profile/GalleryPhotos")
        )}
      />

      <Route
        path={`${match.url}/settings`}
        component={asyncComponent(() =>
          import("./routes/Profile/accountSettings")
        )}
      />

      <Route
        path={`${match.url}/wall`}
        component={asyncComponent(() => import("./routes/Wall"))}
      />
      <Route
        path={`${match.url}/gallery`}
        component={asyncComponent(() => import("./routes/Wall/GallerySection"))}
      />

      <Route
        path={`${match.url}/singlePost`}
        component={asyncComponent(() => import("./routes/Wall/SinglePost"))}
      />

      <Route
        component={asyncComponent(() =>
          import("app/routes/extraPages/routes/404")
        )}
      />
    </Switch>
  </div>
);

export default SocialApps;
