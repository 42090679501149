import React from "react";
import { Route, Switch, withRouter, Redirect} from "react-router-dom";
import { connect } from "react-redux";
import Header from "components/Header/index";
import Sidebar from "containers/SideNav/index";
import Footer from "components/Footer";
import Dashboard from "./routes/dashboard";
import Widgets from "./routes/widgets";
import Metrics from "./routes/metrics";
import Components from "./routes/components";
import Icons from "./routes/icons/index";
import Form from "./routes/form";
import Editors from "./routes/editors";
import Pickers from "./routes/pickers"; 
import Extensions from "./routes/extensions";
import Table from "./routes/table";
import Chart from "./routes/charts";
import Map from "./routes/map";
import Calendar from "./routes/calendar";
import TimeLine from "./routes/timeLine";
import CustomViews from "./routes/customViews";
import ExtraElements from "./routes/extraElements";
import eCommerce from "./routes/eCommerce";
import AppModule from "./routes/appModule";
import ExtraPages from "./routes/extraPages";
import Tour from "../components/Tour/index";
import Settings from "./routes/settings";
import MyPrices from "./routes/myPrices";
import Bookings from "./routes/bookings";
import Leads from "./routes/leads";
import Blockout from "./routes/blockout";
import MyNotifications from "./routes/notifications";
import Reports from "./routes/reports";
import Communications from "./routes/communication";
import Inventory from "./routes/inventory";
import Incomes from "./routes/income";
//import ResetPassword from './routes/resetPassword';
import Expenses from "./routes/expense";
import Documents from "./routes/documents";
import Ticket from "./routes/ticket";
import ChangePassword from "./routes/changepassword";
import Forums from "./routes/forum";
import Operations from "./routes/operations_manual";

import Videos from "./routes/video";
import Versions from "./routes/version";
import TrainingVideos from "./routes/trainingVideo";
import MarketingVideos from "./routes/marketingvideo";
 
//import Test from './routes/test';

import {
  ABOVE_THE_HEADER,
  BELOW_THE_HEADER,
  COLLAPSED_DRAWER,
  FIXED_DRAWER,
  HORIZONTAL_NAVIGATION,
} from "constants/ActionTypes";
import ColorOption from "containers/Customizer/ColorOption";
import { isIOS, isMobile } from "react-device-detect";
import asyncComponent from "../util/asyncComponent";
import TopNav from "components/TopNav";
import SocialApps from "./routes/socialApps";
import CreateGroup from "../components/wall/Groups/CreateGroup";
import MyProfile from "./routes/myProfile";
import ServiceInventoryUseage from "./routes/ServiceInventoryUseage";
import AppIntegration from "./routes/AppIntegrationMenu";
import { history } from "../../src/store"

const loginData = JSON.parse(localStorage.getItem("login"));

class App extends React.Component {
  
  render() {
    
    const {
      match,
      drawerType,
      navigationStyle,
      horizontalNavPosition,
    } = this.props;
    const drawerStyle = drawerType.includes(FIXED_DRAWER)
      ? "fixed-drawer"
      : drawerType.includes(COLLAPSED_DRAWER)
      ? "collapsible-drawer"
      : "mini-drawer";

    //set default height and overflow for iOS mobile Safari 10+ support.
    if (isIOS && isMobile) {
      document.body.classList.add("ios-mobile-view-height");
    } else if (document.body.classList.contains("ios-mobile-view-height")) {
      document.body.classList.remove("ios-mobile-view-height");
    }

    let desktopView = localStorage.getItem("showDesktop");

    if (desktopView == null) desktopView = false;

    //console.log(window.location.pathname);
    const handleRedirect = () => {
      if (loginData && loginData.enable2fa === "0") {
        history.push("/app/myProfile/profile");
      }
    };
    return (
      <div className={`app-container ${drawerStyle}`}>
        {isMobile == true &&
        window.location.pathname == "/app/calendar/dnd" &&
        desktopView == "true" ? (
          <meta name="viewport" content="width=1024" />
        ) : (
          <meta
            name="viewport"
            content="width=device-width,initial-scale=1.0,maximum-scale=1.0,user-scalable=no"
          />
        )}

        {/*<Tour/>*/}
        <Sidebar />
        <div className="app-main-container">
          <div
            className={`app-header ${
              navigationStyle === HORIZONTAL_NAVIGATION
                ? "app-header-horizontal"
                : ""
            }`}
          >
            {navigationStyle === HORIZONTAL_NAVIGATION &&
              horizontalNavPosition === ABOVE_THE_HEADER && (
                <TopNav styleName="app-top-header" />
              )}
            <Header />
            {navigationStyle === HORIZONTAL_NAVIGATION &&
              horizontalNavPosition === BELOW_THE_HEADER && <TopNav />}
          </div>

          <main className="app-main-content-wrapper">
            <div className="app-main-content">
                <>
                <Switch>
                <Route path={`${match.url}/dashboard`} component={Dashboard} />
                {(loginData && loginData.leadsActive == "1") &&
                <Route path={`${match.url}/leads`} component={Leads} />
                }
                <Route path={`${match.url}/bookings`} component={Bookings} />
                <Route path={`${match.url}/blockout`} component={Blockout} />
                <Route
                  path={`${match.url}/notifications`}
                  component={MyNotifications}
                />
                <Route path={`${match.url}/inventory`} component={Inventory} />
                <Route path={`${match.url}/income`} component={Incomes} />
                {/* <Route path={`${match.url}/forum`} component={Forums} /> */}
                <Route path={`${match.url}/operations`} component={Operations} />

                <Route path={`${match.url}/video`} component={Videos} />
                <Route path={`${match.url}/version`} component={Versions} />
                <Route
                  path={`${match.url}/trainingvideo`}
                  component={TrainingVideos}
                />
                <Route
                  path={`${match.url}/marketingvideo`}
                  component={MarketingVideos}
                />
                <Route path={`${match.url}/expense`} component={Expenses} />
                <Route path={`${match.url}/documents`} component={Documents} />
                <Route
                  path={`${match.url}/communication`}
                  component={Communications}
                />
                <Route path={`${match.url}/reports`} component={Reports} />
                {(loginData && loginData.socialActive == "1") &&
                  <Route
                  path={`${match.url}/social`}
                  component={SocialApps}
                />
                }
                <Route
                  path={`${match.url}/myProfile`}
                  component={MyProfile}
                />
                
                <Route
                  path={`${match.url}/createGroup`}
                  component={CreateGroup}
                />
                <Route
                  path={`${match.url}/myPrices`}
                  component={asyncComponent(() =>
                    import("./routes/myPrices/index")
                  )}
                />
                <Route path={`${match.url}/ticket`} component={Ticket} />
                <Route path={`${match.url}/settings`} component={Settings} />
                <Route path={`${match.url}/app_integration`} component={AppIntegration} />
                <Route path={`${match.url}/inventory_usage`} component={ServiceInventoryUseage} />

                <Route
                  path={`${match.url}/components`}
                  component={Components}
                />
                <Route path={`${match.url}/icons`} component={Icons} />
                <Route path={`${match.url}/form`} component={Form} />
                <Route path={`${match.url}/editor`} component={Editors} />
                <Route
                  path={`${match.url}/changepassword`}
                  component={ChangePassword}
                />
                <Route path={`${match.url}/pickers`} component={Pickers} />
                <Route
                  path={`${match.url}/extensions`}
                  component={Extensions}
                />
                <Route path={`${match.url}/table`} component={Table} />
                <Route path={`${match.url}/chart`} component={Chart} />
                <Route path={`${match.url}/map`} component={Map} />
                <Route path={`${match.url}/calendar`} component={Calendar} />
                <Route path={`${match.url}/time-line`} component={TimeLine} />
                <Route
                  path={`${match.url}/custom-views`}
                  component={CustomViews}
                />

                <Route path={`${match.url}/widgets`} component={Widgets} />
                <Route path={`${match.url}/metrics`} component={Metrics} />
                <Route
                  path={`${match.url}/extra-elements`}
                  component={ExtraElements}
                />
                <Route path={`${match.url}/ecommerce`} component={eCommerce} />
                <Route path={`${match.url}/app-module`} component={AppModule} />
                <Route
                  path={`${match.url}/to-do`}
                  component={asyncComponent(() =>
                    import("./routes/todo/basic/index")
                  )}
                />
                <Route
                  path={`${match.url}/to-do-redux`}
                  component={asyncComponent(() =>
                    import("./routes/todo/redux/index")
                  )}
                />
                <Route
                  path={`${match.url}/mail`}
                  component={asyncComponent(() =>
                    import("./routes/mail/basic/index")
                  )}
                />

                <Route
                  path={`${match.url}/contact`}
                  component={asyncComponent(() =>
                    import("./routes/contact/basic/index")
                  )}
                />

                <Route
                  path={`${match.url}/inactive_customers`}
                  component={asyncComponent(() =>
                    import("./routes/contact/inactiveCustomer/index")
                  )}
                />

                <Route
                  path={`${match.url}/newcontact`}
                  component={asyncComponent(() =>
                    import("./routes/contact/new/index")
                  )}
                />
                <Route
                  path={`${match.url}/archivedcontacts`}
                  component={asyncComponent(() =>
                    import("./routes/contact/archived/index")
                  )}
                />

                <Route
                  path={`${match.url}/mail-redux`}
                  component={asyncComponent(() =>
                    import("./routes/mail/redux/index")
                  )}
                />
                <Route
                  path={`${match.url}/chat`}
                  component={asyncComponent(() =>
                    import("./routes/chatPanel/basic/index")
                  )}
                />
                <Route
                  path={`${match.url}/chat-redux`}
                  component={asyncComponent(() =>
                    import("./routes/chatPanel/redux/index")
                  )}
                />
                <Route
                  path={`${match.url}/extra-pages`}
                  component={ExtraPages}
                />
                <Route
                  component={asyncComponent(() =>
                    import("app/routes/extraPages/routes/404")
                  )}
                />
                </Switch>
                </>
            </div>
            <Footer />
          </main>
        </div>
        {/* <ColorOption/> */}
      </div>
    );
  }
}

const mapStateToProps = ({ settings }) => {
  const { drawerType, navigationStyle, horizontalNavPosition } = settings;
  return { drawerType, navigationStyle, horizontalNavPosition };
};
export default withRouter(connect(mapStateToProps)(App));
