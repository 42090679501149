import React, { Component } from "react";
import Avatar from "@material-ui/core/Avatar";
// import Button from "@material-ui/core/Button";
import { Input } from "reactstrap";
import moment from "moment";
import { XCircle } from "react-bootstrap-icons";
import { httpClient } from "appUtility/Api";
import AlertDialog from "./AlertDialog";
import IconButton from "@material-ui/core/IconButton";
import Box from "@material-ui/core/Box";
import Button from "@material-ui/core/Button";

import {
  NotificationManager,
  NotificationContainer,
} from "react-notifications";
import SubCommentBox from "./SubCommentBox";
import parse from "html-react-parser";
import featureServer from "../../../appUtility/featureServer";
import CommentImageBox from "./CommentImageBox";
import { CircularProgress } from "@material-ui/core";
import { Edit } from "@material-ui/icons";
import EditCommentDialog from "./EditCommentDialog";
import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.snow.css';


const qs = require("qs");
const socialData = JSON.parse(window.localStorage.getItem("login"));

class CommentBox extends Component {
  constructor(props) {
    super(props);
    this.state = {
    isComment: false,
    user_name: socialData && socialData.fname + "" + socialData.lname,
    commentData: {},
    userID: socialData && socialData.uid,
    deleteAlert: false,
    editAlert: false,
    subCommentText: "",
    showSubComments: false,
    subCommentList: null,
    userImage: socialData && socialData.img,
    totalComments: "",
    totalSubComments: "",
    isCommentLiked: null,
    likeCount: 0,
    likeLoader: false,
    commentLoader: false,
    commentImage: [],
  };
  this.commentImageRef = React.createRef();
  }

  _handleKeyPress = (e) => {
    e.preventDefault();
    if (e.key === "Enter") {
      this.handleCommentToggle();
    }
  };

  componentWillMount() {
    this.setState({
      ...this.state,
      commentData: this.props.commentData,
      subCommentList: this.props.commentData.subComments || null,
      deleteAlert: this.props.deleteAlert,
      isCommentLiked: this.props.commentData.isLiked,
      likeCount: this.props.commentData.totalCommentLikes
    });
  }

  handleLikeToggle = (data) => {
    // this.setState(previousState => ({
    //   commentData: {
    //     ...previousState.commentData,
    //     isLike: !previousState.commentData.isLike,
    //     likeCount:
    //       previousState.commentData.isLike === true
    //         ? previousState.commentData.likeCount - 1
    //         : previousState.commentData.likeCount + 1
    //   }
    // }));
    this.setState({ ...this.state, likeLoader: true })
    let commentLike = {
      appID: socialData && socialData.aid,
      memberID: socialData && socialData.uid,
      companyID: socialData && socialData.cid,
      userID: socialData && socialData.uid,
      userCode: socialData && socialData.memtoken,
      commentID: this.state.commentData.commentID,
      postID: this.state.commentData.postID
    }

    if (this.state.isCommentLiked == 0 || this.state.isCommentLiked == undefined) {
      httpClient.post('/api/social/comments_likes_save.php', qs.stringify({
        action: "comments_likes_save",
        source: commentLike
      })).then(({ data }) => {
        if (data.success == "true") {
          this.setState({
            ...this.setState,
            likeCount: data.total,
            isCommentLiked: 1,
            likeLoader: false
          })
        } else {
          NotificationManager.warning(data.msg);
        }
      })
    } else {
      httpClient.post('/api/social/comments_likes_save.php', qs.stringify({
        action: "comments_likes_remove",
        source: commentLike
      })).then(({ data }) => {
        if (data.success == "true") {
          this.setState({
            ...this.setState,
            likeCount: data.total,
            isCommentLiked: 0,
            likeLoader: false
          })
        } else {
          NotificationManager.warning(data.msg);
        }
      })
    }

  }

  filterSubComment = (id) => {
    let filteredComment = this.state.subCommentList.filter(
      (item) => item.commentID !== id
    );
    // console.log("Filtered Sub-comment ::", filteredComment);
    // this.setState({ ...this.state, subCommentList: filteredComment });
    this.setState({
      ...this.state,
      subCommentList: this.state.subCommentList.filter(
        (item) => item.commentID !== id
      ),
    });
  };

  //----------------- For Posting Sub-comment ----------------//
  handleSubComment = (e, commentID) => {
    let commentData = new FormData();
    // if (e.key === "Enter") {
    // const newSubComment = {
    //   userID: socialData && socialData.uid,
    //   userCode: socialData && socialData.memtoken,
    //   appID: 1,
    //   companyID: socialData && socialData.cid,
    //   commentText: this.state.subCommentText,
    //   postID: this.state.commentData.postID,
    //   parentCommentID: commentID,
    //   userThumbnail: this.state.commentData.userThumbnail
    // };

    this.setState({ ...this.state, commentLoader: true });

    commentData.append("action", "comments_comments_save");
    commentData.append("userID", socialData && socialData.uid);
    commentData.append("userCode", socialData && socialData.memtoken);
    commentData.append("appID", 1);
    commentData.append("companyID", socialData && socialData.cid);
    commentData.append("commentText", this.state.subCommentText);
    commentData.append("postID", this.state.commentData.postID);
    commentData.append("parentCommentID", commentID);
    commentData.append("userThumbnail", this.state.commentData.userThumbnail);
    this.state.commentImage.forEach((img) =>
      commentData.append("mediaList[]", img)
    );
    commentData.append("imageHeight", 600);
    commentData.append("imageWidth", 600);

    httpClient
      .post(
        "api/social/posts_comments_save.php", commentData
        // qs.stringify({
        //   action: "comments_comments_save",
        //   source: newSubComment,
        // })
      )
      .then((res) => {
        if (res.data.msg !== "Commented Successfully") {
          NotificationManager.warning(res.data.msg);
          this.setState({
            ...this.state,
            commentLoader: false,
          })
        } else {
          // console.log('sub comment response', res.data);
          this.setState({ ...this.state, message: "", totalComments: res.data.totalComments, totalSubComments: res.data.totalSubComments, showSubComments: true });

          let latestSubComment = {
            commentID: res && res.data.commentID.toString(),
            commentUserID: res && res.data.userID,
            postID: res && res.data.postID,
            parentCommentID: res && res.data.parentCommentID,
            commentUserName: this.state.user_name,
            commentText: res && res.data && res.data.commenttext,
            commentedDate: moment(),
            totalCommentLikes: "0",
            totalComments: "0",
            userThumbnail: res && res.data && res.data.userThumbnail,
          };

          if (
            this.state.subCommentList === null ||
            this.state.subCommentList === undefined
          ) {
            this.setState({
              ...this.state,
              subCommentText: "",
              subCommentList: [latestSubComment],
              showSubComments: true,
              commentLoader: false
            });
          } else {
            let UpdatedList = [
              ...this.state.subCommentList,
              latestSubComment,
            ];

            this.setState({
              ...this.state,
              subCommentText: "",
              subCommentList: UpdatedList,
              commentLoader: false
            });

          }
          this.props.getAllComments(this.state.totalComments);


          //----------------**********--------------------//
          this.setState({ commentImage: [] });
          if (this.commentImageRef.current) {
            this.commentImageRef.current.value = ""; // Clear the file input
          }
        }
      });
    // }
  };

  handleCommentRemoveSub = (commentData) => {
    // console.log('comment data <<<', commentData);
    const deleteQuery = {
      userID: socialData && socialData.uid,
      userCode: socialData && socialData.memtoken,
      appID: 1,
      companyID: socialData && socialData.cid,
      postID: this.state.commentData.postID,
      commentID: commentData.commentID,
      parentCommentID: commentData.parrentCommentID
    };

    httpClient
      .post(
        "api/social/posts_comments_save.php",
        qs.stringify({
          action: "comments_delete",
          source: deleteQuery
        })
      )
      .then(res => {
        if (res.data.success !== "true") {

          NotificationManager.warning(res.data.msg);
        } else {
          // console.log('sub comment delete', res.data);
          this.setState({
            ...this.state,
            totalComments: res.data.totalComments,
            totalSubComments: (res.data && res.data.totalSubComments).toString(),
          })
          this.filterSubComment(res.data.commentID);
          this.props.getAllComments(this.state.totalComments);
          this.setState({
            ...this.state,
            showSubComments: true
          })
        }
      });
  };

  //---------------------------***********-----------------------------------//
  RequestCommentDelete = () => {
    this.setState({ ...this.state, deleteAlert: true });
  };

  RequestCommentEdit = () => {

    this.setState({ ...this.state, editAlert: true });
  }

  RequestEditCommentClose = () => {
    this.setState({ ...this.state, editAlert: false });
  }

  editComment = (data) => {
    let commentData = new FormData();
    this.setState({ ...this.state, commentLoader: true });

    commentData.append("action", "edit_comments");
    commentData.append("userID", socialData && socialData.uid);
    commentData.append("userCode", socialData && socialData.memtoken);
    commentData.append("appID", 1);
    commentData.append("companyID", socialData && socialData.cid);
    commentData.append("commentText", data.commentTitle);
    commentData.append("postID", this.state.commentData.postID);
    commentData.append("parentCommentID", 0);
    commentData.append("commentsID", data.commentID)
    commentData.append("userThumbnail", data.userThumbnail);
    // data.fileList != "" && data.fileList.forEach((img) =>
    //   commentData.append("imageID", img.imageID)
    // );
    commentData.append("imageHeight", 600);
    commentData.append("imageWidth", 600);

    httpClient
      .post(
        "api/social/posts_comments_save.php", commentData
        // qs.stringify({
        //   action: "comments_comments_save",
        //   source: newSubComment,
        // })
      )
      .then((res) => {
        if (res.data.success !== "true") {
          NotificationManager.warning(res.data.msg);
          this.setState({
            ...this.state,
            commentLoader: false,
          })
        } else {
          // console.log('sub comment response', res.data);
          NotificationManager.success(res.data.msg);
          this.setState({
            ...this.state, message: "",
            // totalComments: res.data.totalComments, totalSubComments: res.data.totalSubComments, 
            showSubComments: true,
            commentLoader: false,
            editAlert: false
          });

          let latestSubComment = {
            commentID: res && res.data.commentID.toString(),
            commentUserID: res && res.data.userID,
            postID: res && res.data.postID,
            parentCommentID: res && res.data.parentCommentID,
            commentUserName: this.state.user_name,
            commentText: res && res.data && res.data.commenttext,
            commentedDate: moment(),
            // totalCommentLikes: "0",
            // totalComments: "0",
            userThumbnail: res && res.data && res.data.userThumbnail,
          };

          if (
            this.state.subCommentList === null ||
            this.state.subCommentList === undefined
          ) {
            this.setState({
              ...this.state,
              subCommentText: "",
              subCommentList: [latestSubComment],
              showSubComments: true,
              commentLoader: false
            });
          } else {
            let UpdatedList = [
              ...this.state.subCommentList,
              latestSubComment,
            ];

            this.setState({
              ...this.state,
              subCommentText: "",
              subCommentList: UpdatedList,
              commentLoader: false
            });

          }
          this.props.getAllComments(this.state.totalComments);
        }
      });
  }

  handleEditSubComment = (data) => {
    console.log('edit sub comment', data);
    setTimeout(() => {
      this.setState({
        editAlert: false
      })
    }, 5000)
    let commentData = new FormData();
    this.setState({ ...this.state, commentLoader: true });

    commentData.append("action", "comments_comments_edit");
    commentData.append("userID", socialData && socialData.uid);
    commentData.append("userCode", socialData && socialData.memtoken);
    commentData.append("appID", 1);
    commentData.append("companyID", socialData && socialData.cid);
    commentData.append("commentText", data.commentTitle);
    commentData.append("postID", this.state.commentData.postID);
    commentData.append("parentCommentID", this.state.commentData.commentID);
    commentData.append("commentsID", data.commentID)
    commentData.append("userThumbnail", data.userThumbnail);
    // data.fileList != "" && data.fileList.forEach((img) =>
    //   commentData.append("imageID", img.imageID)
    // );
    commentData.append("imageHeight", 600);
    commentData.append("imageWidth", 600);

    httpClient
      .post(
        "api/social/posts_comments_save.php", commentData
        // qs.stringify({
        //   action: "comments_comments_save",
        //   source: newSubComment,
        // })
      )
      .then((res) => {
        if (res.data.success !== "true") {
          NotificationManager.warning(res.data.msg);
          this.setState({
            ...this.state,
            commentLoader: false,
          })
        } else {
          // console.log('sub comment response', res.data);
          NotificationManager.success(res.data.msg);
          this.setState({
            ...this.state, message: "",
            // totalComments: res.data.totalComments, totalSubComments: res.data.totalSubComments, 
            showSubComments: true,
            commentLoader: false,
            editAlert: false
          });

          let latestSubComment = {
            commentID: res && res.data.commentID.toString(),
            commentUserID: res && res.data.userID,
            postID: res && res.data.postID,
            parentCommentID: res && res.data.parentCommentID,
            commentUserName: this.state.user_name,
            commentText: res && res.data && res.data.commenttext,
            commentedDate: moment(),
            // totalCommentLikes: "0",
            // totalComments: "0",
            userThumbnail: res && res.data && res.data.userThumbnail,
          };

          if (
            this.state.subCommentList === null ||
            this.state.subCommentList === undefined
          ) {
            this.setState({
              ...this.state,
              subCommentText: "",
              subCommentList: [latestSubComment],
              showSubComments: true,
              commentLoader: false
            });
          } else {
            let UpdatedList = [
              ...this.state.subCommentList,
              latestSubComment,
            ];

            this.setState({
              ...this.state,
              subCommentText: "",
              subCommentList: UpdatedList,
              commentLoader: false
            });

          }
          this.props.getAllComments(this.state.totalComments);
        }
      });
  }

  CancelCommentDelete = () => {
    this.setState({ ...this.state, deleteAlert: false });
  };


  handleCommentDelete = (commentID, postID) => {
    this.CancelCommentDelete();
    this.props.handleCommentRemove(commentID, postID);
  }

  handleImageCommentDelete = (postID, commentID, imageID) => {
    const deleteQuery = {
      userID: socialData && socialData.uid,
      userCode: socialData && socialData.memtoken,
      appID: 1,
      companyID: socialData && socialData.cid,
      postID: postID,
      commentID: commentID,
      imageID: imageID
    };

    httpClient
      .post(
        "api/social/image_comments_save.php",
        qs.stringify({
          action: "comments_delete_image ",
          source: deleteQuery,
        })
      )
      .then((res) => {
        if (res.data.success !== "true") {
          this.CancelCommentDelete();
          NotificationManager.warning(res.data.msg);
        } else {
          this.CancelCommentDelete();
          this.props.filterDeletedComment(res.data.commentID);
        }
      });
  }

  handleCommentText = (e) => {
    e.preventDefault();
    this.setState({ ...this.state, subCommentText: e.target.value });
  };

  handleCommentTextEditor = (value) => {
    //e.preventDefault();
    this.setState({ ...this.state, subCommentText: value });
  };

  onCommentImageChange = (e) => {
    console.log('e.target >>>', e.target.files)
    if (e.target.files.length > 8) {
      e.preventDefault();
      alert(`Cannot upload files more than 8`);

      e.target.files = null;
    } else {
      // this.state.commentImage = [...e.target.files]
      var comImg = [...e.target.files];
      this.setState({
        ...this.state,
        commentImage: comImg,
      });
    }
  };

  openSubComments = () => {
    this.setState({
      ...this.state,
      showSubComments: !this.state.showSubComments,
    });
  };

  render() {
    const {
      commentText,
      commentedDate,
      commentID,
      commentUserName,
      commentUserID,
      postID,
      // totalCommentLikes,
      totalComments,
      userThumbnail,
      imageID,
    } = this.state.commentData;

    // console.log('commnet image', this.state.commentImage && this.state.commentImage);

    console.log("comment in state >>>", this.state);
    // console.log('socal data >>', socialData);
    // console.log('props comment box >>', this.props);
    let regex = "/(\b(https?|ftp|file):\/\/[-A-Z0-9+&@#\/%?=~_|!:,.;]*[-A-Z0-9+&@#\/%=~_|])/ig";
    let final = (commentText);
    let match = (commentText).match(/(\b(https?|ftp|file):\/\/[-A-Z0-9+&@#\/%?=~_|!:,.;]*[-A-Z0-9+&@#\/%=~_|])/ig);
    let newURL;
    match && match.map(url => {
      newURL = url.split('//').pop().split('/')[0] + "..." + url.slice(url.length - 10);
      final = final.replace(url, "<a href=\"" + url + "\" target=\"_BLANK\">" + (url.length > 60 ? newURL : url) + "</a>");
    })

    return (
      <>
        {this.state.deleteAlert && (
          <AlertDialog
            postID={postID}
            commentID={commentID}
            imageID={imageID}
            commentAlert={true}
            deleteAlert={this.state.deleteAlert}
            CancelCommentDelete={this.CancelCommentDelete}
            handleCommentDelete={() => this.handleCommentDelete(commentID, postID)}
            handleImageCommentDelete={this.handleImageCommentDelete}
            commentDelete={this.props.showSubCommentsImage}
          />
        )}

        {this.state.editAlert && (
          <EditCommentDialog
            open={this.state.editAlert}
            handleClose={this.RequestEditCommentClose}
            editComment={this.editComment}
            post={this.state.commentData}
          />

        )}

        <div
          className="media flex-nowrap jr-wall-user-info mb-3"
          key={this.props.key}
        >
          <Avatar
            alt={commentUserName}
            className="mr-3 jr-size-40"
            src={userThumbnail || this.state.userImage}
          />
          {/* <img
            alt={commentUserName}
            className="mr-3 jr-size-40 img_circular"
            src={userThumbnail}
          /> */}

          <div className="media-body">
            <h5 className="jr-wall-user-title">
              {commentUserName}{" "}
              {commentUserID === this.state.userID && (
                <>
                  <IconButton
                    size="small"
                    onClick={this.RequestCommentDelete}
                    style={{ float: "right" }}
                  >
                    <XCircle />
                  </IconButton>
                  {/* {featureServer && ( */}
                  <IconButton
                    size="small"
                    onClick={this.RequestCommentEdit}
                    style={{ float: "right" }}
                  >
                    <Edit fontSize="small" style={{ fontSize: "17px" }} />
                  </IconButton>
                  {/* )} */}
                </>
              )}
            </h5>{" "}
            <p
              style={{
                fontSize: "12px",
                fontWeight: "bold",
                color: "#ababab",
              }}
            >
              {moment(commentedDate).format(socialData && socialData.timeFormat === 24 ? "MMMM Do YYYY, HH:mm" : "MMMM Do YYYY, h:mm a")}
            </p>
            <div style={{ wordBreak: "break-word" }}>
              <p className="mt-2" style={{ fontSize: "16px" }}>
                {commentText.substring(0, 5) === "https" ? (
                  <a href={parse(commentText)} target="_blank">
                    {/* {parse(commentText)} */}
                    {commentText.length > 60 ?
                      commentText.split('//').pop().split('/')[0] + "..." + commentText.slice(commentText.length - 10)
                      : parse(commentText)
                    }
                  </a>
                ) : (
                  // parse(commentText)
                  parse(final)
                )}
              </p>
            </div>
            {
              // featureServer && 
              this.state.commentData.returnImage &&
              // <Box pt={1} pb={1}>
              //   {this.state.commentData.returnImage && this.state.commentData.returnImage.map(comImage => (
              //     <div style={{ display: "inline-block" }}>
              //     <img src={comImage.imageURL} style={{height: "80px", width:"80px" , margin:"5px", borderRadius:"5px"}} />
              //     </div>
              //   ))}
              // </Box>
              <CommentImageBox
                imageData={this.state.commentData.returnImage && this.state.commentData.returnImage}
              />
            }
            <div className="d-flex flex-row">
              {/*---------------------LIKE SECTION--------------------------*/}
              {/* <Button
              variant="contained"
              color="primary"
              className="mr-3 mb-1"
              size="small"
              onClick={this.handleLikeToggle.bind(this)}
            >
              {isLike === true ? "Like" : "UnLike"}
            </Button> */}
              <p
                className="jr-fs-sm pointer mr-3 text-grey"
                onClick={() => this.handleLikeToggle(this)}
                style={{
                  pointerEvents: this.state.likeLoader ? "none" : "auto",
                }}
              >
                {this.state.isCommentLiked == 1 ? (
                  <i
                    className="zmdi zmdi-favorite jr-fs-lg mr-2 d-inline-flex align-middle"
                    style={{ color: "blue" }}
                  />
                ) : (
                  <i className="zmdi zmdi-favorite-outline jr-fs-lg mr-2 d-inline-flex align-middle" />
                )}
                <span className="d-inline-flex vertical-align-middle">
                  {this.state.likeCount > 0 ? (this.state.likeCount + " Likes") : "0 Likes"}
                </span>
              </p>
              {/*--------------------***********--------------------------*/}

              {/*---------------------COMMENT - SECTION--------------------------*/}
              {this.props.showSubCommentsImage &&
                <p
                  className="jr-fs-sm pointer mr-3 text-blue"
                  onClick={this.openSubComments}
                >
                  <i className="zmdi zmdi-comment-more jr-fs-lg mr-2 d-inline-flex align-middle" />
                  <span
                    className="d-inline-flex align-middle"
                    onClick={this.openSubComments}
                  >
                    {(this.state.totalSubComments ? this.state.totalSubComments : this.state.commentData.totalSubComments) !== "0"
                      ? (this.state.totalSubComments ? this.state.totalSubComments : this.state.commentData.totalSubComments) + " Comments"
                      : "0 Comments"}
                  </span>
                </p>
              }

              {/* <span
              className="text-primary jr-fs-md pointer d-block "
              onClick={e => this.handleCommentToggle(e, commentID)}
            >
              Add Comment +
            </span> */}
            </div>
            <div className="jr-wall-comment-box">
              {this.state.showSubComments &&
                this.state.subCommentList !== null &&
                this.state.subCommentList.length > 0 &&
                this.state.subCommentList.map((commentData, index) => (
                  <SubCommentBox
                    key={commentData.commentID}
                    index={index}
                    postID={postID}
                    commentData={commentData}
                    deleteAlert={this.state.deleteAlert}
                    filterSubComment={this.filterSubComment}
                    handleRemove={() => this.handleCommentRemoveSub(commentData)}
                    editSubComment={(data) => this.handleEditSubComment(data)}
                  />
                ))}
            </div>
            {this.state.showSubComments && (
              <div className="media mt-3">
                <Avatar className="mr-3 size-30" alt={this.state.user_name} src={this.state.userImage} />
                <div className="media-body">
                  {/* <Input
                    id="required"
                    className="border-3"
                    placeholder="Type Comments"
                    value={this.state.subCommentText}
                    onChange={(e) => this.handleCommentText(e)}
                    // onKeyPress={(e) => this.handleSubComment(e, commentID)}
                  /> */}

                  <ReactQuill
                    placeholder="Type Comments"
                    value={this.state.subCommentText}
                    onChange={(value) => this.handleCommentTextEditor(value)}
                    modules={{
                      toolbar: [
                        ['bold', 'italic', 'underline', 'strike'],
                        [{ 'header': 1 }, { 'header': 2 }],
                        [{ 'list': 'ordered' }, { 'list': 'bullet' }],
                        ['link'],
                        // ['image'],
                        ['clean'],
                      ],
                    }}
                    formats={[
                      'header',
                      'bold', 'italic', 'underline', 'strike',
                      'link', 
                      // 'image',
                      'list', 'bullet',
                    ]}
                  />

                  <Box pt={1} pb={1}>
                    <div className="boxarea">
                      <label
                        style={{ fontSize: "11px", marginRight: "10px" }}
                        for="comment_image"
                      >
                        Select Image Files (Max 8):
                      </label>
                      <input
                        type="file"
                        onChange={this.onCommentImageChange}
                        name="commentImage"
                        id="comment_image"
                        style={{ fontSize: "11px" }}
                        multiple
                        ref={this.commentImageRef}
                      />
                    </div>
                    <div className="boxarea">
                      <span style={{ fontSize: "11px" }}>
                        Note: Supported image format: .jpeg, .jpg, .png, .gif{" "}
                      </span>
                    </div>
                  </Box>

                  <Box textAlign="right" pt={1}>
                    <Button
                      disabled={(this.state.subCommentText !== "" || this.state.commentImage.length > 0) ? (this.state.commentPostLoader ? true : false) : true}
                      variant="contained"
                      color="primary"
                      size="small"
                      style={{ fontSize: "10px", minWidth: "50px" }}
                      onClick={(e) => this.handleSubComment(e, commentID)}
                    >
                      POST
                      {/* <ArrowRightSquareFill style={{ color: "blue" }} /> */}
                    </Button>
                    {this.state.commentLoader && (
                      <CircularProgress size={20} style={{ marginLeft: "10px", position: "relative", top: "5px" }} />
                    )}
                  </Box>
                </div>
              </div>
            )}
            {/*--------------------***********--------------------------*/}
          </div>
        </div>
        <NotificationContainer />
      </>
    );
  }
}

export default CommentBox;
