import React, { useEffect, useState } from "react";
import Widget from "../../Widget/index";
import Button from "@material-ui/core/Button";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import TotalFriends from "../../wall/Friends/totalFriends";
// import TotalFriends from "../../../components/wall/Friends/TotalFriends";

function Status(props) {
  const isType = props.isType;
  if (isType === "online") {
    return <span className="badge-status-dot bg-success" />;
  } else if (isType === "away") {
    return <span className="badge-status-dot bg-warning" />;
  } else {
    return <span className="badge-status-dot bg-red" />;
  }
}

const Friends = props => {
  const { friendList, groupMode, groupID, groupInfo } = props;

  const [data, setData] = useState([]);
  const [listState, setListState] = useState({
    allfriends: false,
    allFollowers: false,
    dialogOpen: false,
    userMode: false,
    groupMode: false
  });

  useEffect(() => {
    friendList.filter((item) => (
      (item.isSubscribed === "1") ? item : " "
    ))
  },[])
  // console.log('groupID is', groupID);

  const handleBoxClose = () => {
    setListState({ userMode: false, groupMode: false, dialogOpen: false });
  };

  const handleFollowersList = () => {
    setListState({ groupMode: true, dialogOpen: true });
  };

  const handleFriendsList = () => {
    setListState({ userMode: true, dialogOpen: true });
  };

  const getAllMembers = () => {
    props.getAllMembers();
  }



  return (
    <Widget
      styleName="jr-card-profile-sm"
      title={
        <span style={{ color: "gray", fontSize: "14px" }}>
          {groupMode ? "Members" : "Team Members"} - {friendList === null ? "0": friendList.length}
        </span>
      }
    >
      <div className="pt-2">
        <ul className="jr-fnd-list mb-0">
          {friendList === null ?
            (
             ""
            )
            
          : friendList.slice(0, 6).map((user, index) => (
            
            <li className="mb-2" key={index}>
              <div className="jr-user-fnd">
                <img alt={user.firstName} src={user.userThumbnail} />
                <div className="jr-user-fnd-content">
                  {console.log("user.status", user.status)}
                  <span className="jr-badge">
                    <Status isType={user.status} />
                  </span>
                  <h6>{user.firstName + " " + user.lastName}</h6>
                </div>
              </div>
            </li>
          ))}
        </ul>

        {groupMode && (
          <span
            className="text-primary jr-fs-md pointer jr-d-block mb-4"
            onClick={handleFollowersList}
          >
            See all Members{" "}
            <i
              className={`zmdi zmdi-long-arrow-right jr-fs-xxl ml-2 d-inline-flex align-middle`}
            />
          </span>
        )}

        {!groupMode && (
          <span
            className="text-primary jr-fs-md pointer jr-d-block mb-4"
            onClick={handleFriendsList}
          >
            See all team members{" "}
            <i
              className={`zmdi zmdi-long-arrow-right jr-fs-xxl ml-2 d-inline-flex align-middle`}
            />
          </span>
        )}
 
        <Dialog
          open={listState.dialogOpen}
          onClose={handleBoxClose}
          maxWidth={"sm"}
          fullWidth={true}
        >
          {listState.userMode && (
            <TotalFriends
              friendList={friendList}
              profileMode={true}
              groupSector={false}
            />
          )}

          {listState.groupMode && (
            <TotalFriends
              friendList={friendList}
              profileMode={true}
              groupSector={true}
              groupID={groupID}
              getAllMembers={getAllMembers}
              groupInfo={groupInfo}
            />
          )}
          <DialogActions>
            <Button onClick={handleBoxClose} color="secondary">
              Close
            </Button>
          </DialogActions>
        </Dialog>
      </div>
    </Widget>
  );
};
export default Friends;
