// import classes from "*.module.css";
import { makeStyles } from "@material-ui/core";
import React, { useState } from "react";

const useStyles = makeStyles((theme) => ({
  imageFlex: {
    display: "flex",
    flexWrap: "wrap",
    justifyContent: "center",
  },
  image4: {
    margin: "5px",
    position: "relative",
    cursor: "pointer",
    flex: "0 0 calc(50% - 10px)",
    "& .jr-img-fluid": {
      borderRadius: "5px",
      // width: "100%",
      width:"235px",
    },
  },
  image3: {
    margin: "5px",
    position: "relative",
    cursor: "pointer",
    flex: "0 0 calc(33.33% - 10px)",
    "& .jr-img-fluid": {
      borderRadius: "5px",
      width: "100%",
    },
  },
  imageBack: {
    backgroundColor: "rgba(0, 0, 0, 0.7)",
    cursor: "pointer",
    borderRadius: "5px",
    display: "flex",
    left: "0",
    right: "0",
    top: "0",
    bottom: "0",
    alignItems: "center",
    justifyContent: "center",
    position: "absolute",
  },
}));

const GridImage = (props) => {
  const classes = useStyles();
  const { mediaList } = props;

  const handleCancel = (data) => {
    props.handleToggle(data);
  };

  return (
    <div className={classes.imageFlex}>
      {/* If images if equal to 3 */}
      {mediaList != "" &&
        mediaList.length === 3 &&
        mediaList.map((item, index) => (
          <div
            key={item.imageID}
            onClick={() => handleCancel(index)}
            className={classes.image3}
          >
            <img
              className="jr-img-fluid"
              src={item.imageURL || item.url}
              alt="post"
            />
          </div>
        ))}

      {/* If images if not less than 4 */}

      {mediaList != "" &&
        mediaList.length != 3 &&
        mediaList.length <= 4 &&
        mediaList.map((item, index) => (
          <div
            key={item.imageID}
            onClick={() => handleCancel(index)}
            className={classes.image4}
          >
            <img
              className="jr-img-fluid"
              src={item.imageURL || item.url}
              alt="post"
            />
          </div>
        ))}

      {/* If images if greater than 4 */}

      {mediaList != "" &&
        mediaList.length > 4 &&
        mediaList.map((item, index) => (
          <div key={item.imageID}>
            {index < 3 && (
              <div
                // key={item.imageID}
                onClick={() => handleCancel(index)}
                className={classes.image4}
              >
                <img
                  className="jr-img-fluid"
                  src={item.imageURL || item.url}
                  alt="post"
                />
              </div>
            )}
            {index === 3 && (
              <div
                // key={item.imageID}
                onClick={() => handleCancel(index)}
                className={classes.image4}
              >
                <img
                  className="jr-img-fluid"
                  src={item.imageURL || item.url}
                  alt="post"
                />
                <div className={classes.imageBack}>
                  <h2 className="text-white">+ {mediaList.length - 3}</h2>
                </div>
              </div>
            )}
          </div>
        ))}
    </div>
  );
};

export default GridImage;
