import React, { Component, createContext } from "react";

const CommentContext = createContext({});

export class CommentContextProvider extends Component {
  state = {
    totalComments: "0",
    totalSubComments: "0",
  };
  render() {
    return (
      <CommentContext.Provider value={this.state}>
        {this.props.children}
      </CommentContext.Provider>
    );
  }
}

export default CommentContext;
