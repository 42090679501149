import React from 'react';
import { WebView } from 'react';
import CardBox from 'components/CardBox/index';
import ContainerHeader from 'components/ContainerHeader/index';
import IntlMessages from 'util/IntlMessages';

import TextField from '@material-ui/core/TextField';
import Button from '@material-ui/core/Button';
import Input from '@material-ui/core/Input';
import { Redirect } from 'react-router-dom'
import ReactDOM from 'react-dom';
import ReactPlayer from 'react-player';
import GlobalURL from 'appUtility/global';
import { NotificationContainer } from 'react-notifications';


const TrainingVideos = ({match}) => {
  return (
    <div className="animated slideInUpTiny animation-duration-3 jr-card ">
	<ContainerHeader title={<IntlMessages id="sidebar.trainingvideo.menu"/>} match={match}/>
	  
	  <div className="row jr-card">
          <div className="col-4">
            <h3>Mate - Intro</h3>
            <ReactPlayer url= {GlobalURL[0].videourl+"Mate_intro.mp4"} width='420' light={GlobalURL[0].videourl+"mate-intro.jpg"} controls playing/>
          </div>
          <div className="col-4">
            <h3>Mate - New Booking</h3>
            <ReactPlayer url={GlobalURL[0].videourl+"New_booking.mp4"} width='420' light={GlobalURL[0].videourl+"new-booking.jpg"} controls playing/>
          </div>
          <div className="col-4">
          <h3>Mate - New Customer</h3>
            <ReactPlayer url={GlobalURL[0].videourl+"New_customer.mp4"} width='420' light={GlobalURL[0].videourl+"new-customer.jpg"} controls playing/>
          </div>
	  </div>
      
	 
	    <NotificationContainer />
    </div>

  );
};

export default TrainingVideos;

