import React from 'react';

import NotificationItem from './NotificationItem';
import {notifications} from './data';
import CustomScrollbars from 'util/CustomScrollbars';

var AllNotifications=[];


//const AppNotification = () => {
class AppNotification extends React.Component 
{
    
componentDidMount()
{
   
    AllNotifications=[];
    
    let todaysDate=new Date();
//let futureDate = new Date();
//let numberOfDaysToAdd = 5;


//futureDate.setDate(todaysDate.getDate() + numberOfDaysToAdd); 

let dd = ('0' + todaysDate.getDate()).slice(-2);//todaysDate.getDate();
let mm = ('0' + (todaysDate.getMonth()+1)).slice(-2);//todaysDate.getMonth() + 1;
let y = todaysDate.getFullYear();
let hours = todaysDate.getHours();
let minutes = todaysDate.getMinutes();
    
todaysDate = y + '/'+ mm + '/'+ dd + " "+hours+ ":"+minutes;

let requiredTodaysDate = new Date(todaysDate);
let bookingDate = '';
let requiredBookingDate= '';


//let future_dd = futureDate.getDate();
//let future_mm = futureDate.getMonth() + 1;
//let future_y = futureDate.getFullYear();

//futureDate = future_y + '-'+ future_mm + '-'+ future_dd;
let globalNoticationCount = 0;
let bookingNotificationOBJ,bookingNotificationDetail;

bookingNotificationOBJ = localStorage.getItem('booking_active');

if(bookingNotificationOBJ!=null)
	bookingNotificationDetail = JSON.parse(bookingNotificationOBJ);

var notificationCount_Booking = 0;
  
    
 
//console.log("from Notification "+JSON.stringify(bookingNotificationDetail));
if(bookingNotificationDetail!=null && bookingNotificationDetail!=null  && bookingNotificationDetail!='')
	{
		bookingNotificationDetail.map((activeBookings, index) =>
				{
            
                    //bookingDate = String(activeBookings.bdate + " " + activeBookings.btime);
                   // bookingDate = activeBookings.bdate.replace("-","/") + " "+activeBookings.btime;
                    bookingDate = activeBookings.bdate.split("-").join("/") + " "+activeBookings.btime;
                    requiredBookingDate = new Date(bookingDate);
 					//console.log(requiredTodaysDate + " = "+requiredBookingDate);
                    if(requiredBookingDate<requiredTodaysDate)
 					//if(activeBookings.bdate<todaysDate)
						{
							notificationCount_Booking = parseInt(notificationCount_Booking) + 1;
						}
			},
		 );
	}

 
if(notificationCount_Booking!=0)
	{ 
         
        if(document.getElementById("lawaAlertNotification"))
            {
               // alert('here');
                if(!document.getElementById("lawaAlertNotification").classList.contains('icon-alert'))
                        document.getElementById("lawaAlertNotification").classList.add('icon-alert');
            }
            
        
		globalNoticationCount = parseInt(globalNoticationCount) + 1;
		
		AllNotifications.push({
 			title: notificationCount_Booking + " bookings requires your attention.",
			customlink: "/app/notifications/bookings",
			icon: "zmdi zmdi-arrow-right-top text-blue",
         })
        
        this.props.returnFromChild(AllNotifications.length);
		
	}
    else
    {
          
        if(notificationCount_Booking==0)
            {
                if(document.getElementById("lawaAlertNotification"))
                {
                    if(document.getElementById("lawaAlertNotification").classList.contains('icon-alert'))
                        document.getElementById("lawaAlertNotification").classList.remove('icon-alert');
                }
            }
            
    }
    
    
    
	
}	
    
    render() 
    {
        
        let notificationsAgain;
        notificationsAgain = AllNotifications;

      return (
           
        <CustomScrollbars className="messages-list scrollbar" style={{height: 280}}>
          <ul className="list-unstyled">
            {notificationsAgain!='' ? notificationsAgain.map((notification, index) => <NotificationItem key={index} notification={notification}/>) :''
            }
          </ul>
        </CustomScrollbars>
      )
    }
};

export default AppNotification;

