import React, { useEffect, useState } from "react";
import Widget from "components/Widget/index";
import Slider from "react-slick";
import CloseIcon from "@material-ui/icons/Close";
import {
  Avatar,
  Backdrop,
  Card,
  Fade,
  Grid,
  IconButton,
  makeStyles,
  Modal,
} from "@material-ui/core";
import ImagePost from "../../wall/PostList/ImagePost";
import CustomScrollbars from "../../../util/CustomScrollbars";
import { history } from "../../../store";

const useStyles = makeStyles((theme) => ({
  root: {
    backgroundColor: "transparent",
    width: "100%",
  },
  gridpic: {
    height: "100px",
    width: "100%",
    objectFit: "cover",
    cursor: "pointer",
    [theme.breakpoints.down('sm')]: {
      height: "250px",
    },
  },
  modal: {
    padding: "0 50px",
    paddingTop: "40px",
    [theme.breakpoints.down('sm')]: {
      padding: "0 35px",
      paddingTop: "20px",
    },
  },
  sliderComponent: {
    position: "relative",
    padding: "0 100px",
    width: "50% !important",
    display: "flex !important",
    justifyContent: "center",
    margin: "auto",
    [theme.breakpoints.down('sm')]: {
      width: "100% !important",
      padding: "0",
    },
  },
  scrollbar: {
    height: "90vh !important",
  },
  imgSlider: {
    height: "400px !important",
    width: "400px !important",
    objectFit: "cover",
    margin: "0 auto",
  },
  slick_slider: {
    "& .slick-prev": {
      zIndex: "111",
    },
    "& .slick-prev:before": {
      color: "#f1f1f1 !important",
      fontSize: "32px",
    },
    "& .slick-next": {
      zIndex: "111",
    },
    "& .slick-next:before": {
      color: "#f1f1f1 !important",
      fontSize: "32px",
    },
  },
  closeIcon: {
    color: "#fff",
    position: "absolute",
    top: "0",
    right: "60px",
    [theme.breakpoints.down('sm')]: {
      zIndex: "1",
      right:"0"
    },
  },
}));


const Photos = (props) => {
  const classes = useStyles();

  const [showCarousel, setShowCarousel] = useState(false);
  const [data, setData] = useState([]);

  const handleCarouselOpen = (image) => {
    setShowCarousel(true);
    setData(image);
  };
  const handleCarouselClose = () => {
    setShowCarousel(false);
  };


  const handleGroupGallery = () => {
    history.push(`/app/social/gallery/${props.memberId ? props.memberId : '' }`);
  };

  const settings = {
    dots: false,
    infinite: false,
    autoplay: false,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    initialSlide: parseInt(data.imageSequence || data.sequence, 10),
  };



  return (
    <Widget title="Photos" styleName="jr-card-profile-sm">
      <div className="pt-2">
        {!props.imageData && (
          <p style={{ color: "gray", paddingBottom: "10px" }}>
            No Photos till now ...
          </p>
        )}
        <Grid container spacing={2}>
          {props.imageData &&
            props.imageData.slice(0, 6).map((image, index) => (
              <Grid item sm={4} key={image.imageID}>
                <img
                  onClick={() => handleCarouselOpen(image)}
                  src={image.imageURL}
                  alt={image.postTitle}
                  className={classes.gridpic}
                />
              </Grid>
            ))}
        </Grid>
        <Modal
          className={classes.modal}
          open={showCarousel}
          onClose={handleCarouselClose}
          closeAfterTransition
          BackdropComponent={Backdrop}
          BackdropProps={{
            timeout: 500,
          }}
        >
          <Fade in={showCarousel}>
            <div>
              <Slider {...settings} className={classes.slick_slider}>
                {props.imageData &&
                  props.imageData.slice(0, 6).map((image, index) => (
                      <div className={classes.sliderComponent} key={image.imageID}>
                        <IconButton
                          className={classes.closeIcon}
                          onClick={handleCarouselClose}
                        >
                          <CloseIcon />
                        </IconButton>
                        <CustomScrollbars className={classes.scrollbar}>
                          <div className={classes.sliderImage}>
                            <img
                              src={image.imageURL}
                              alt={image.postTitle}
                              // className={classes.imgSlider}
                              className="img-fluid"
                            />
                          </div>

                          <div className={classes.mainGallery}>
                            <Card className={classes.root}>
                              {/* LIKE COMMENT */}
                              <ImagePost
                                key={image.postID}
                                index={image.postID}
                                postData={image}
                                userProfile={image}
                                imageData={props.imageData}
                              />
                            </Card>
                          </div>
                        </CustomScrollbars>
                      </div>
                  ))}
              </Slider>
            </div>
          </Fade>
        </Modal>
      </div>
      <span
        className="text-primary jr-fs-md pointer jr-d-block pt-2"
        onClick={handleGroupGallery}
      >
        Go to gallery{" "}
        <i
          className={`zmdi zmdi-long-arrow-right jr-fs-xxl ml-2 d-inline-flex align-middle`}
        />
      </span>
    </Widget>
  );
};
export default Photos;
