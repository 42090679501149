import React from 'react';
import {Redirect, Route, Switch} from 'react-router-dom';
import asyncComponent from '../../../util/asyncComponent';

const Inventory = ({match}) => (
  <div className="app-wrapper">
    <Switch>
      <Redirect exact from={`${match.url}/`} to={`${match.url}/overview`}/>
      <Route path={`${match.url}/overview`} component={asyncComponent(() => import('./routes/Overview'))}/>
      <Route path={`${match.url}/placeorder`} component={asyncComponent(() => import('./routes/PlaceOrder'))}/>
      <Route path={`${match.url}/placeorderB`} component={asyncComponent(() => import('./routes/PlaceOrderB'))}/>
	  <Route path={`${match.url}/placeordertreat`} component={asyncComponent(() => import('./routes/PlaceOrderTreat'))}/>
	  <Route path={`${match.url}/placeorderuniform`} component={asyncComponent(() => import('./routes/PlaceOrderUniform'))}/>
    <Route path={`${match.url}/placebusinesscard`} component={asyncComponent(() => import('./routes/PlaceBusinessCard'))}/>
      <Route path={`${match.url}/inwardgoods`} component={asyncComponent(() => import('./routes/InwardGoods/index'))}/>
      <Route path={`${match.url}/stock_take`} component={asyncComponent(() => import('./routes/StockTake/index'))}/>
      <Route path={`${match.url}/completedorders`} component={asyncComponent(() => import('./routes/CompletedOrders/index'))}/>
      <Route path={`${match.url}/stockonhand`} component={asyncComponent(() => import('./routes/StockOnHand/index'))}/>
      <Route component={asyncComponent(() => import('app/routes/extraPages/routes/404'))}/>
    </Switch>
  </div>
);

export default Inventory;