import React from "react";
import { Link } from "react-router-dom";
import { BrowserRouter, Redirect, Route, Switch } from "react-router-dom";
import { connect } from "react-redux";
import TextField from "@material-ui/core/TextField";
import IconButton from "@material-ui/core/IconButton";
import Button from "@material-ui/core/Button";
import {
  NotificationContainer,
  NotificationManager,
} from "react-notifications";
import IntlMessages from "util/IntlMessages";
import Router from "Router";
import GlobalURL from "./../appUtility/global";
import { httpClient } from "appUtility/Api";
import qs from "qs";

//import CircularProgress from '@material-ui/core/CircularProgress';
import {
  //hideMessage,
  //showAuthLoader,
  GetUserVerify,
} from "actions/Auth";
import { history } from "../store";
import featureServer from "../appUtility/featureServer";
import TandCDialog from "./TandCDialog";

//const authUserState = React.createContext('isAuthenticated');
var isAuthenticated = "A";
var localData = {};

class SignIn extends React.Component {
  //const authUserState() => ({:false});
  constructor() {
    super();

    this.state = {
      email: "",
      password: "",
      // openTermsDialog: false,
    };

    this.handleChange = this.handleChange.bind(this);
    this.stopSubmission = this.stopSubmission.bind(this);
  }

  handleChange(evt) {
    this.setState({ [evt.target.name]: evt.target.value });
  }

  stopSubmission(e) {
    //NotificationManager.info('Verifying User Detail');
    // alert('onClickPreventDefault called, form will not submit');
    e.preventDefault();
  }

  handleLogin() {
    // NotificationManager.info("Verifying User Detail1111");
    // setTimeout(function() {
    //   NotificationManager.success("Logged in Successfully.");
    // }, 2000);

    // setTimeout(function() {
    //   window.location = "/app/dashboard/overview";
    // }, 4000);

    if (this.state.email != "" && this.state.password != "") {
      NotificationManager.info("Verifying User Detail.");
    }

    httpClient
      .post(
        "login.php",
        qs.stringify({
          action: "verify_login",
          source: this.state,
        })
      )
      .then(response => {
        const { data, status } = response;

        if (status === 503) {
          NotificationManager.error(`Status code ${status}: Service Unavailable`);
        }
        else if (status !== 200) {
          NotificationManager.error(`Status code ${status}: Server Error`);
        }
        else if (data.verified != "success") {
          NotificationManager.error(data);
        }
        else {
          //Now clear all local storage elements because new values are going to be downloaded soon.
          localStorage.clear();
          // localStorage.setItem("login", JSON.stringify(data));
          localData = data;

          //Indexed Db Api
          // localforage.setItem('login',data);

          // if (featureServer) {

          // console.log('featureServer',featureServer)

          // if (data.acceptTsAndCs == "0") {
          //   if (featureServer) {
          //     this.setState({
          //       openTermsDialog: true,
          //     });
          //   } else {
          //     this.checkGoogleAuth(data);
          //   }
          // } else {
          //   this.checkGoogleAuth(data);
          // }

          this.checkGoogleAuth(data);

          // } else {
          //   localStorage.setItem("login", JSON.stringify(data));

          //   NotificationManager.success("Logged in Successfully.");
          //   setTimeout(function() {
          //     console.log("init");
          //     document.location.href = "/app/dashboard/overview";
          //     // history.push("/app/dashboard/overview")
          //   }, 1000);
          // }
        }
      });
  }

  // componentDidMount() {
  //    setTimeout(() => {
  // 					  if (this.props.GetUserVerify == 1) {
  // 						  alert(this.props.GetUserVerify)
  // 					 //this.props.history.push('/');
  // 						  return ( <Redirect to={'/ForgotPassword'}/> );
  // 					}
  // 			  },1000);
  // }

  checkGoogleAuth = (ldata) => {
    let param = {
      appID: ldata && ldata.aid,
      companyID: ldata && ldata.cid,
      memberID: ldata && ldata.uid,
    };

    httpClient
      .post(
        "2factorauthentication.php",
        qs.stringify({
          action: "expire",
          source: param,
        })
      )
      .then(({ data }) => {
        localStorage.setItem("login", JSON.stringify(localData));

        if (data.isActivated === "true" && data.isExpire === "true") {
          // document.location.href = "/2fa";
          history.push({
            pathname: "/2fa",
            // state: { uniqueCode: data.code },
          });
        } else {
          NotificationManager.success("Logged in Successfully.");
          setTimeout(() => {
            document.location.href = "/app/dashboard/overview";
          }, 1000);
        }
      });
  };

  // callbackTandC = (call) => {
  //   console.log("tand c call", call);

  //   if (call.open === false && call.success === true) {
  //     this.setState({
  //       openTermsDialog: false,
  //     });
  //     // NotificationManager.success(call.message);
  //     localData.acceptTsAndCs = "1";
  //     setTimeout(() => {
  //       this.checkGoogleAuth(localData);
  //     }, 1500);
  //   }
  // };

  render() {
    const { email, password } = this.state;

    // console.log("localData", localData);

    if (isAuthenticated === 1) {
      return <Redirect to={"/ForgotPassword"} />;
    }

    //  const {showMessage, loader, alertMessage} = this.props;
    return (
      <div className="app-login-container d-flex justify-content-center align-items-center animated slideInUpTiny animation-duration-3">
        <div className="app-login-main-content">
          <div className="app-logo-content d-flex align-items-center justify-content-center">
            <Link className="logo-lg" to="/" title="Mate">
              <img
                src={require("assets/images/logo.png")}
                alt="Mate"
                title="Mate"
              />
            </Link>
          </div>

          <div className="app-login-content">
            <div className="app-login-header mb-4">
              <h1>Login to Mate</h1>
            </div>

            <div className="app-login-form">
              <form onSubmit={this.stopSubmission}>
                <fieldset>
                  <TextField
                    label={<IntlMessages id="appModule.email" />}
                    fullWidth
                    defaultValue={email}
                    name="email"
                    value={email}
                    onChange={this.handleChange}
                    margin="normal"
                    className="mt-1 my-sm-3"
                  />
                  <TextField
                    type="password"
                    label={<IntlMessages id="appModule.password" />}
                    fullWidth
                    name="password"
                    defaultValue={password}
                    onChange={this.handleChange}
                    margin="normal"
                    className="mt-1 my-sm-3"
                  />

                  <div className="mb-3 d-flex align-items-center justify-content-between">
                    <Button
                      type="submit"
                      onClick={() => {
                        this.handleLogin();
                        // alert(isAuthenticated)
                        // NotificationManager.info('Verifying User Detail');
                        // GetUserVerify({ email, password });
                        //alert (isAuthenticated)
                      }}
                      variant="contained"
                      color="primary"
                    >
                      <IntlMessages id="appModule.signIn" />
                    </Button>

                    <Link to="/ForgotPassword">
                      <IntlMessages id="signIn.forgotPassword" />
                    </Link>
                  </div>
                  <div
                    className="mt-3"
                    style={{ color: "#d3d3d3", fontSize: "12px" }}
                  >
                    {GlobalURL[0].version}
                  </div>
                </fieldset>
              </form>
            </div>
          </div>
        </div>
        {/*
        {
          loader &&
          <div className="loader-view">
            <CircularProgress/>
          </div>
        }
		

        {showMessage && NotificationManager.error(alertMessage)}
*/}{" "}
        <NotificationContainer />
        {/* {this.state.openTermsDialog && (
          <TandCDialog
            localData={localData}
            callbackTandC={this.callbackTandC}
          />
        )} */}
      </div>
    );
  }
}

const mapStateToProps = ({ auth }) => {
  const { GetUserVerify } = auth;
  return { isAuthenticated };
};

/*
 
const mapStateToProps = ({auth}) => {
  const {loader, alertMessage, showMessage, authUser} = auth;
  return {loader, alertMessage, showMessage, authUser}
};

export default connect(mapStateToProps, {
  userSignIn,
  hideMessage,
  showAuthLoader  
})(SignIn);
 */
/*
export default({
 GetUserVerify  
})(SignIn);
*/

export default SignIn;
