import React, { useState, useEffect } from "react";
import Biography from "components/profile/Biography/index";
import Friends from "components/profile/Friends/index";
import Photos from "components/profile/Photos/index";
import ProfileHeader from "../../../../../components/profile/ProfileHeader/index";
import Auxiliary from "../../../../../util/Auxiliary";
import Timeline from "../../../../../components/profile/Timeline";
import { PatchCheckFill } from "react-bootstrap-icons";
import { httpClient } from "appUtility/Api";
import {
  NotificationManager,
  NotificationContainer,
} from "react-notifications";
import {
  Button,
  CircularProgress,
  IconButton,
  makeStyles,
  Dialog, DialogActions
} from "@material-ui/core";
import PropTypes from "prop-types";
import AppBar from "@material-ui/core/AppBar";
import Tabs from "@material-ui/core/Tabs";
import Tab from "@material-ui/core/Tab";
import Box from "@material-ui/core/Box";
import { Typography } from "@material-ui/core";
import KeyboardBackspaceIcon from "@material-ui/icons/KeyboardBackspace";
import CloseIcon from "@material-ui/icons/Close";
import { history } from "../../../../../store";
import Search from "../../../../../components/wall/Search";
import PostItem from "../../../../../components/wall/PostList/PostItem";

import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';

const qs = require("qs");
const socialData = JSON.parse(window.localStorage.getItem("login"));

// TABS
function TabPanel(props) {
  const { children, value, index, ...other } = props;
  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`nav-tabpanel-${index}`}
      aria-labelledby={`nav-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box>
          <div>{children}</div>
        </Box>
      )}
    </div>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.any.isRequired,
  value: PropTypes.any.isRequired,
};

function a11yProps(index) {
  return {
    id: `nav-tab-${index}`,
    "aria-controls": `nav-tabpanel-${index}`,
  };
}

function LinkTab(props) {
  return (
    <Tab
      component="a"
      onClick={(event) => {
        event.preventDefault();
      }}
      {...props}
    />
  );
}

const useStyles = makeStyles((theme) => ({
  root: {
    "& .MuiBox-root": {
      padding: `${theme.spacing(2)}px 0`,
    },
    "& .MuiTab-textColorInherit.Mui-selected": {
      textDecoration: "none",
    },
  },
  appbar: {
    backgroundColor: "#fff",
    position: "absolute",
    top: "0px",
    left: "0",
    zIndex: "5",
  },
  desktopView: {
    display: "block",
    [theme.breakpoints.down("sm")]: {
      display: "none",
    },
    [theme.breakpoints.between("sm", "md")]: {
      display: "block",
    },
  },
  backIcon: {
    color: "#3f51b5",
  },
}));

// TABS

const GroupDetail = (props) => {
  console.log("group-----props", props);
  const classes = useStyles();
  const [groupInfo, setGroupInfo] = useState([]);
  const [imageData, setImageData] = useState([]);
  const [members, setMembers] = useState([]);
  const [inviteMembers, setInviteMembers] = useState([]);
  const [search, setSearch] = useState(false);
  const [searchValue, setSearchValue] = useState("");
  const [searchData, setSearchData] = useState([]);
  const [loading, setLoading] = useState(false);

  const [dataSearch, setDataSearch] = useState({
    pages: 0,
  });

  const [value, setValue] = React.useState(2);

  const [groupQuery] = useState({
    userID: socialData && socialData.uid,
    userCode: socialData && socialData.memtoken,
    appID: 1,
    companyID: socialData && socialData.cid,
    //groupID: props.match.params.id,
    groupID: props.groupID,
    request: "detail",
  });

  // const [galleryState, setGalleryState] = useState(false);

  useEffect(() => {
    getGroupDetail();
    getAllImages();
    getAllMembers();
  }, []);

  const getGroupDetail = () => {
    httpClient
      .post(
        "api/social/groups_get.php",
        qs.stringify({
          action: "groups_get",
          source: groupQuery,
        })
      )
      .then((res) => {
        if (res.data.data === null) {
          NotificationManager.warning(
            "Sorry, Cannot load Group Info right now !"
          );
        } else {
          setGroupInfo(res.data.data);
        }
      });
  };

  const handleModalOpen = () => {
    setSearch(true);
  };

  const handleModalClose = () => {
    setSearch(false);
  };

  const onChangeSearch = (e) => {
    setSearchValue(e.target.value);
  };

  const handleResetSearch = () => {
    setSearchData([]);
  };

  const handleSearch = () => {
    search ? setSearch(false) : setSearch(true);
  };

  const getSearch = () => {
    setLoading(true);
    const getSearchQuery = {
      userID: socialData && socialData.uid,
      userCode: socialData && socialData.memtoken,
      appID: 1,
      companyID: socialData && socialData.cid,
      //groupID: props.match.params.id,
      groupID: props.groupID,
      page: dataSearch.pages,
      searchFor: searchValue,
      imageWidth: 600,
      imageHeight: 600,
    };

    httpClient
      .post(
        "api/social/posts_search.php",
        qs.stringify({
          action: "posts_search",
          source: getSearchQuery,
        })
      )
      .then((res) => {
        if (res.data === "" || null) {
          NotificationManager.warning(
            "Sorry there was some problem fetching Search list"
          );
          setSearch(false);
          setLoading(false);
        } else {
          setSearchData(res.data.data);
          setSearch(false);
          setLoading(false);
        }
      });
  };

  const onKeyPress = (e) => {
    setDataSearch({ ...dataSearch, pages: 0 });

    if (e.key === "Enter") {
      e.preventDefault();
      getSearch();
    }
  };

  const handleLoadPost = () => {
    setLoading(true);


    setDataSearch({ ...dataSearch, pages: dataSearch.pages + 1 });

    const getSearchQuery = {
      userID: socialData && socialData.uid,
      userCode: socialData && socialData.memtoken,
      appID: 1,
      companyID: socialData && socialData.cid,
      //groupID: props.match.params.id,
      groupID: props.groupID,
      page: dataSearch.pages + 1,
      searchFor: searchValue,
      imageWidth: 600,
      imageHeight: 600,
    };

    httpClient
      .post(
        "api/social/posts_search.php",
        qs.stringify({
          action: "posts_search",
          source: getSearchQuery,
        })
      )
      .then((res) => {
        if (res.data === "" || null) {
          NotificationManager.warning(
            "Sorry there was some problem fetching Search list"
          );
          setSearch(false);
          setLoading(false);
        } else {
          let sData = searchData.concat(res.data.data);
          setSearchData(sData);
          setSearch(false);
          setLoading(false);
        }
      });
  };
  const getAllImages = () => {
    const getImagesQuery = {
      userID: socialData && socialData.uid,
      userCode: socialData && socialData.memtoken,
      appID: 1,
      companyID: socialData && socialData.cid,
      //groupID: 0 || props.match.params.id,
      groupID: 0 || props.groupID,
      page: 0,
      imageWidth: 600,
      imageHeight: 600,
      //postedByUserID: props.match.params.id,
      postedByUserID: props.groupID,
    };

    httpClient
      .post(
        "api/social/images_get.php",
        qs.stringify({
          action: "images_get",
          source: getImagesQuery,
        })
      )
      .then((res) => {
        if (res.data === "" || null) {
          NotificationManager.warning(
            "Sorry there was some problem fetching Image List"
          );
        } else {
          setImageData(res.data.data);
        }
      });
  };

  const getAllMembers = () => {
    const membersQuery = {
      userID: socialData && socialData.uid,
      userCode: socialData && socialData.memtoken,
      appID: 1,
      companyID: socialData && socialData.cid,
      imageWidth: 150,
      imageHeight: 150,
      //groupID: props.match.params.id,
      groupID: props.groupID,
    };

    httpClient
      .post(
        "api/social/get_Teammembers.php",
        qs.stringify({
          action: "member_get",
          source: membersQuery,
        })
      )
      .then((res) => {
        if (res.data === null) {
          NotificationManager.warning("Sorry couldn't load Members List");
        } else {
          const arr = [];
          const arr2 = [];
          res.data.data.map(
            (item, index) => (
              (item.isSubscribed === 1 || item.isSubscribed === "1") &&
              arr.push(item),
              arr2.push(item)
            )
          );
          setMembers(arr);
          setInviteMembers(arr2);
        }
      });
  };

  // console.log('members', members)
  // console.log('invite memvbers', inviteMembers)

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  const handleBackButton = () => {
    //history.push("/app/social");

    props.handleEtiquetteClose();
  };

  // console.log('group detail 1>>', groupInfo);


  return (
    <>
      <div>
        <Dialog maxWidth="xl" open={props.openEtiquette}>

          <DialogContent>
            <Box pl={2} pt={2} width={"99%"}>
            
            <div className="close-icon-container" style={{
              position: "absolute",
              top: "20px",
              right: "20px",
              zIndex: 9999,
            }}>
              <CloseIcon
                className="cross-icon"
                onClick={handleBackButton}
                style={{
                  cursor: 'pointer',
                  color: 'grey',
                }}
              />
            </div>

            <Auxiliary>
              <div className="tabs_bg d-sm-block d-md-none">
                <div className="row">
                  <div className="col-12">
                    <AppBar position="static" className={classes.appbar}>
                      <Tabs
                        variant="fullWidth"
                        value={value}
                        onChange={handleChange}
                        aria-label="nav tabs example"
                        TabIndicatorProps={{
                          style: {
                            backgroundColor: "#3f51b5",
                          },
                        }}
                        style={{
                          color: "#3f51b5",
                        }}
                      >
                        {" "}
                        <IconButton onClick={handleBackButton}>
                          <KeyboardBackspaceIcon
                            className={classes.backIcon}
                            color="primary"
                          />{" "}
                        </IconButton>
                        <LinkTab label="Members" href="/drafts" {...a11yProps(1)} />
                        <LinkTab label="Timeline" href="/trash" {...a11yProps(2)} />
                        <LinkTab label="Photos" href="/spam" {...a11yProps(3)} />
                      </Tabs>
                    </AppBar>
                  </div>
                </div>
              </div>

              <ProfileHeader
                groupMode={true}
                handleBackButton1={handleBackButton}
                groupInfo={groupInfo[0]}
                getGroupDetail={getGroupDetail}
                handleSearch={handleSearch}
                inviteMembers={inviteMembers}
                getAllMembers={getAllMembers}
              />
              {groupInfo[0] &&
                groupInfo[0].groupSubscribe !== "1" &&
                groupInfo[0].isSystemGroup === 0 &&
                groupInfo[0].isRegionalGroup === 0 &&
                groupInfo[0].groupOwnerID !== groupQuery.userID && (
                  <div style={{ position: "relative" }}>
                    <div className="subscribe_overlay">
                      <h2
                        className="please_subscribe"
                        style={{ fontWeight: "bold", color: "#7e7bb9" }}
                      >
                        <PatchCheckFill />
                        {/* {""}Please subscribe in order to check group details ! */}
                        You need to subscribe to visit the group.
                      </h2>
                    </div>
                  </div>
                )}
              <div className="jr-profile-content">
                <div className="row">
                  <div className="col-12">
                    <div className={classes.desktopView}>
                      <div className="row">
                        {(groupInfo[0] && groupInfo[0].groupSubscribe !== "1") ? (
                          <div className="col-xl-8 col-lg-8 col-md-7 col-12" style={{ zIndex: 12 }}>
                            <Biography groupMode={true} groupInfo={groupInfo[0]} />
                          </div>
                        ) : (
                          <>
                            <div className="col-xl-8 col-lg-8 col-md-7 col-12">
                              <Biography groupMode={true} groupInfo={groupInfo[0]} />
                              {searchData !== null && searchData.length > 0 ? (
                                <>
                                  {!loading ? (
                                    <Button
                                      size="small"
                                      style={{
                                        background: "#e6e6e6",
                                        marginBottom: "10px",
                                      }}
                                      onClick={handleResetSearch}
                                    >
                                      Reset Search
                                    </Button>
                                  ) : (
                                    <CircularProgress style={{ marginLeft: "20%" }} />
                                  )}
                                  {searchData.map((item) => {
                                    return (
                                      <PostItem
                                        key={item.postID}
                                        index={item.postID}
                                        postData={item}
                                        DeleteWork=""
                                        userProfile=""
                                      />
                                    );
                                  })}
                                  {!loading ? (
                                    searchData.length % 10 === 0 && (
                                      <Button
                                        size="small"
                                        style={{
                                          background: "#e6e6e6",
                                          marginBottom: "10px",
                                        }}
                                        onClick={handleLoadPost}
                                      >
                                        Load more
                                      </Button>
                                    )
                                  ) : (
                                    <CircularProgress style={{ marginLeft: "20%" }} />
                                  )}
                                </>
                              ) : (
                                <Timeline
                                  groupName={groupInfo[0] && groupInfo[0].groupName}
                                  groupProfile={true}
                                  groupID={groupQuery.groupID}
                                  groupPublic={groupInfo[0] && groupInfo[0].groupPublic}
                                  groupSubscribe={groupInfo[0] && groupInfo[0].groupSubscribe}
                                />
                              )}
                            </div>
                            <div className="col-xl-4 col-lg-4 col-md-5 col-12">
                              <div className="row">
                                <div className="col-12">
                                  <Friends
                                    friendList={members}
                                    groupMode={true}
                                    groupID={groupQuery.groupID}
                                    getAllMembers={getAllMembers}
                                    groupInfo={groupInfo[0]}
                                  />
                                </div>
                                <div className="col-12">
                                  <Photos
                                    imageData={imageData}
                                    title="Photos"
                                    memberId={groupQuery.groupID}
                                  />
                                </div>
                              </div>
                            </div>
                          </>
                        )}
                      </div>
                    </div>
                  </div>

                  {/* <div className="row"> */}
                  <div className="col-12">
                    <div className="d-md-none d-sm-block">
                      <div className={classes.root}>
                        <TabPanel
                          value={value}
                          index={1}
                          className="d-sm-block d-md-none"
                        >
                          <div className="row">
                            <div className="col-12">
                              <Biography
                                groupMode={true}
                                groupInfo={groupInfo[0]}
                              />
                              {groupInfo[0] &&
                                groupInfo[0].groupSubscribe === "1" && (
                                  <Friends
                                    friendList={members}
                                    groupMode={true}
                                    groupID={groupQuery.groupID}
                                    getAllMembers={getAllMembers}
                                    groupInfo={groupInfo[0]}
                                  />
                                )}
                            </div>
                          </div>
                        </TabPanel>

                        <TabPanel
                          value={value}
                          index={2}
                          className="d-sm-block d-md-none"
                        >
                          <div className="row">
                            {groupInfo[0] && groupInfo[0].groupSubscribe !== "1" ? (
                              <div className="col-12">
                                <Biography
                                  groupMode={true}
                                  groupInfo={groupInfo[0]}
                                />
                              </div>
                            ) : (
                              <div className="col-12">
                                <Biography
                                  groupMode={true}
                                  groupInfo={groupInfo[0]}
                                />
                                {searchData !== null && searchData.length > 0 ? (
                                  <>
                                    {!loading ? (
                                      <Button
                                        size="small"
                                        style={{
                                          background: "#e6e6e6",
                                          marginBottom: "10px",
                                        }}
                                        onClick={handleResetSearch}
                                      >
                                        Reset Search
                                      </Button>
                                    ) : (
                                      <CircularProgress
                                        style={{ marginLeft: "20%" }}
                                      />
                                    )}
                                    {searchData.map((item) => {
                                      return (
                                        <PostItem
                                          key={item.postID}
                                          index={item.postID}
                                          postData={item}
                                          DeleteWork=""
                                          userProfile=""
                                        />
                                      );
                                    })}
                                    {!loading ? (
                                      searchData.length % 10 === 0 && (
                                        <Button
                                          size="small"
                                          style={{
                                            background: "#e6e6e6",
                                            marginBottom: "10px",
                                          }}
                                          onClick={handleLoadPost}
                                        >
                                          Load more
                                        </Button>
                                      )
                                    ) : (
                                      <CircularProgress
                                        style={{ marginLeft: "20%" }}
                                      />
                                    )}
                                  </>
                                ) : (
                                  <Timeline
                                    groupName={groupInfo[0] && groupInfo[0].groupName}
                                    groupProfile={true}
                                    groupID={groupQuery.groupID}
                                    groupPublic={groupInfo[0] && groupInfo[0].groupPublic}
                                    groupSubscribe={groupInfo[0] && groupInfo[0].groupSubscribe}
                                  />
                                )}
                              </div>
                            )}
                          </div>
                        </TabPanel>

                        <TabPanel
                          value={value}
                          index={3}
                          className="d-sm-block d-md-none"
                        >
                          <div className="row">
                            <div className="col-xl-8 col-lg-8 col-md-7 col-12">
                              <Biography
                                groupMode={true}
                                groupInfo={groupInfo[0]}
                              />
                              {groupInfo[0] &&
                                groupInfo[0].groupSubscribe === "1" && (
                                  <Photos
                                    imageData={imageData}
                                    title="Photos"
                                    memberId={groupQuery.groupID}
                                  />
                                )}
                            </div>
                          </div>
                        </TabPanel>
                      </div>
                    </div>
                  </div>
                  {/* </div> */}
                </div>
              </div>
            </Auxiliary>
            </Box>
          </DialogContent>
        </Dialog>
      </div>
      <Search
        open={search}
        handleOpen={handleModalOpen}
        handleClose={handleModalClose}
        value={searchValue}
        onChange={onChangeSearch}
        onKeyPress={onKeyPress}
      />
      <NotificationContainer />
    </>
  );
};
export default GroupDetail;
