import React, { useState, useEffect } from "react";
import PostItem from "./PostItem";
import WriteBox from "../WriteBox/index";
import Auxiliary from "../../../util/Auxiliary";
import Button from "@material-ui/core/Button";
import { httpClient } from "appUtility/Api";
import CircularProgress from "@material-ui/core/CircularProgress";
import {
  NotificationManager,
  NotificationContainer,
} from "react-notifications";
import { CheckCircle, ArrowDown } from "react-bootstrap-icons";
import moment from "moment-timezone";
import { CommentContextProvider } from "./CommentContext";

const qs = require("qs");
const socialData = JSON.parse(window.localStorage.getItem("login"));

const PostList = (props) => {
console.log("propsss", props);
  const [postState, setPostState] = useState({
    userProfile: props.userProfile,
    postsPerPage: 0,
  });

  const [allPosts, setAllPosts] = useState([]);

  const [emptyPosts, setEmptyPosts] = useState(false);

  const [zeroPosts, setZeroPosts] = useState(false);

  const [deletedPost, setDeletedPost] = useState(false);

  const [postLoader, setPostLoader] = useState(false);


  // useEffect(() => {
  //   getAllPosts();
  //   setDeletedPost(false);
  // }, [postState.postsPerPage, deletedPost]);

  // useEffect(() => {
  //   props.refresh == true && getAllPosts();
  // },[props.refresh])



  useEffect(() => {
    if (props.userProfile || props.memberProfile) {
        getAllPosts();
    }
}, [props.userProfile, props.memberProfile]);


  const DeleteWork = (info) => {
    setDeletedPost(info);
  };


  const getAllPosts = () => {
    const postQuery = {
      userID: socialData && socialData.uid,
      userCode: socialData && socialData.memtoken,
      appID: 1,
      companyID: socialData && socialData.cid,
      groupID: 0,
      page: postState.postsPerPage,
      imageWidth: 600,
      imageHeight: 600,
    };

    const userPostQuery = {
      userID: socialData && socialData.uid,
      userCode: socialData && socialData.memtoken,
      appID: 1,
      companyID: socialData && socialData.cid,
      profileOfUser: socialData && socialData.uid,
      page: postState.postsPerPage,
      imageWidth: 600,
      imageHeight: 600,
    };

    const memberPostQuery = {
      userID: socialData && socialData.uid,
      userCode: socialData && socialData.memtoken,
      appID: 1,
      companyID: socialData && socialData.cid,
      profileOfUser: socialData && socialData.uid,
      page: postState.postsPerPage,
      groupID: 0,
      postedByUserID: parseInt(props.memberID, 10),
      imageWidth: 600,
      imageHeight: 600,
    };

    if (postState.userProfile) {
      httpClient
        .post(
          "api/social/profile_get.php",
          qs.stringify({
            action: "profile_get",
            source: userPostQuery,
          })
        )
        .then((res) => {
          if (res.data.data === "" || res.data.data === null) {

            if (userPostQuery.page === 0) {
              setZeroPosts(false);
              setAllPosts(res.data.data);
              setEmptyPosts(true);
            } else {
              NotificationManager.warning(
                "Sorry couldn't load posts right now !"
              );
            }
          } else {
            setZeroPosts(false);
            setAllPosts(res.data.data);
            setEmptyPosts(true);
          }
        });
    } else {
      httpClient
        .post(
          "api/social/posts_get.php",
          qs.stringify({
            action: "posts_get",
            source: props.memberProfile ? memberPostQuery : postQuery,
          })
        )
        .then((res) => {
          setZeroPosts(false);
          setEmptyPosts(false);
          if (res.data.data === null) {
            setEmptyPosts(true);
          } else {
            const newDate = moment().tz("Australia/Victoria").format("YYYY-MM-DD, h:mm:ss");
            localStorage.setItem("refreshDate", JSON.stringify(newDate));
            // const newPosts = allPosts.concat(res.data.data);
            let newPosts = [];
            if (postState.postsPerPage == 0) {
              newPosts = res.data.data;
            } else {
              newPosts = allPosts.concat(res.data.data);
            }
            setAllPosts(newPosts);
          }
        });
    }
  };



  const handleLoadMore = () => {
    setPostState({ ...postState, postsPerPage: postState.postsPerPage + 1 });
  };

  const addPost = (post_text, imageList) => {
    setPostLoader(true);
    let fd = new FormData();
    fd.append("userID", socialData && socialData.uid);
    fd.append("userCode", socialData && socialData.memtoken);
    fd.append("appID", 1);
    fd.append("companyID", socialData && socialData.cid);
    fd.append("postText", post_text);
    fd.append("groupID", 0);
    // fd.append("test", 1);
    fd.append("likeCount", 0);
    fd.append("isLike", false);
    fd.append("commentCount", 0);
    fd.append("commentList", []);
    fd.append("type", "post_save");
    fd.append("imageHeight", 1000);
    fd.append("imageWidth", 1000);

    imageList.forEach((data) => fd.append("mediaList[]", data));

    httpClient
      .post(
        "api/social/posts_save.php",
        fd
        // qs.stringify({
        //   action: "post_save",
        //   source: fd
        // })
      )
      .then((res) => {
        if (res.data.success === "false") {
          NotificationManager.warning(res.data.msg);
          setPostLoader(false);

        } else {

          setPostLoader(false);
          setZeroPosts(false);
          let latestPost = {
            postTitle: res && res.data && res.data.posttext,
            postedDate: moment(),
            postedUserName:
              socialData && socialData.fname + "" + socialData.lname,
            postID: res && res.data && res.data.postid,
            userThumbnail: socialData && socialData.img,
            totalLikes: "0",
            isLiked: "0",
            totalComments: 0,
            postedUserID: socialData && socialData.uid,
            groupId: 0,
            groupName: null,
            images: res && res.data && res.data.imageList,
          };

          let updatedPostList = [latestPost].concat(allPosts);
          setAllPosts(updatedPostList);
        }
      });
  };




  return (
    <> {(props.userProfile || props.memberProfile) ? (
      <Auxiliary>
        <WriteBox addPost={addPost} />

        {!postLoader ? (
          allPosts && allPosts.length > 0 ? (
            allPosts.map((item) => {
              return (
                <CommentContextProvider key={item.postID}>
                  <PostItem
                    // key={item.postID}
                    index={item.postID}
                    postData={item}
                    userProfile={postState.userProfile}
                    DeleteWork={DeleteWork}
                    postLoader={postLoader}
                  />
                </CommentContextProvider>
              );
            })
          ) : (
            <div>
              {!emptyPosts && !zeroPosts ? (
                <CircularProgress style={{ marginLeft: "20%" }} />
              ) : null}
            </div>
          )
        ) : (
          <CircularProgress style={{ marginLeft: "20%" }} />
        )}

        {zeroPosts && (
          <strong style={{ color: "gray", fontSize: "20px" }}>
            No posts till now ...
          </strong>
        )}

        {emptyPosts ? (
          <strong style={{ color: "gray" }}>
            <CheckCircle /> You're All Caught Up, No posts to show ...
          </strong>
        ) : (
          <>
            {!postLoader && allPosts && allPosts.length > 0 ? (
              <Button
                size="small"
                style={{ background: "#e6e6e6" }}
                onClick={handleLoadMore}
              >
                Load More {""} <ArrowDown />
              </Button>
            ) : null}
          </>
        )}
      </Auxiliary>
    ) : (

      <Auxiliary>
        <WriteBox addPost={props.addPost} />

        {!props.postLoader ? (
          props.allPosts && props.allPosts.length > 0 ? (
            props.allPosts.map((item) => {
              return (
                <CommentContextProvider key={item.postID}>
                  <PostItem
                    // key={item.postID}
                    index={item.postID}
                    postData={item}
                    userProfile={props.userProfile}
                    DeleteWork={props.DeleteWork}
                    postLoader={props.postLoader}
                  />
                </CommentContextProvider>
              );
            })
          ) : (
            <div>
              {!props.emptyPosts && !props.zeroPosts ? (
                <CircularProgress style={{ marginLeft: "20%" }} />
              ) : null}
            </div>
          )
        ) : (
          <CircularProgress style={{ marginLeft: "20%" }} />
        )}

        {props.zeroPosts && (
          <strong style={{ color: "gray", fontSize: "20px" }}>
            No posts till now ...
          </strong>
        )}

        {props.emptyPosts ? (
          <strong style={{ color: "gray" }}>
            <CheckCircle /> You're All Caught Up, No posts to show ...
          </strong>
        ) : (
          <>
            {!props.postLoader && props.allPosts && props.allPosts.length > 0 ? (
              <Button
                size="small"
                style={{ background: "#e6e6e6" }}
                onClick={props.handleLoadMore}
              >
                Load More {""} <ArrowDown />
              </Button>
            ) : null}
          </>
        )}
      </Auxiliary>
    
    )}
      <NotificationContainer />
    </>
  );
};

export default PostList;
