import React, { useState, useEffect } from "react";
import WriteBox from "../../../../components/wall/WriteBox/index";
import Auxiliary from "../../../../util/Auxiliary";
import Button from "@material-ui/core/Button";
import { httpClient } from "appUtility/Api";
import CircularProgress from "@material-ui/core/CircularProgress";
import {
  NotificationManager,
  NotificationContainer,
} from "react-notifications";
import PostItem from "./PostItem";
import { CheckCircle } from "react-bootstrap-icons";
import moment from "moment";

const qs = require("qs");
const socialData = JSON.parse(window.localStorage.getItem("login"));

const GroupPostList = (props) => {
  const [postState, setPostState] = useState({
    userProfile: props.userProfile,
    groupProfile: props.groupProfile,
    defaultMode: props.defaultMode,
    postsPerPage: 0,
  });

  const [allPosts, setAllPosts] = useState([]);

  const [emptyPosts, setEmptyPosts] = useState(false);

  const [zeroPosts, setZeroPosts] = useState(false);

  const [postLoader, setPostLoader] = useState(false);

  const [editClose, setEditClose] = useState(false);

  const [deleteAlert, setDeleteAlert] = useState(false);

  const [maxLength, setMaxLength] = useState(false);

  console.log('groups here')

  useEffect(() => {
    getAllPosts();
  }, []);

  const getAllPosts = (postID) => {
    const groupPostQuery = {
      userID: socialData && socialData.uid,
      userCode: socialData && socialData.memtoken,
      appID: 1,
      companyID: socialData && socialData.cid,
      groupID: props.groupID,
      page: postState.postsPerPage,
      imageWidth: 600,
      imageHeight: 600
    };

    httpClient
      .post(
        "api/social/posts_get.php",
        qs.stringify({
          action: "posts_get",
          source: groupPostQuery,
        })
      )
      .then((res) => {
        if (res.data.data === "" || res.data.data === null) {
          if (groupPostQuery.page === 0) {
            setZeroPosts(true);
          } else {
            setEmptyPosts(true);
          }
        } else {
          setZeroPosts(false);
          setEmptyPosts(false);
          if (res.data.data === "" || res.data.data === null) {
            setEmptyPosts(true);
          } else {
            console.log('data.length', res.data.data.length);
            if (res.data.data.length === 10) {
              setMaxLength(true)
            } else {
              setMaxLength(false)
            }

            if (postID !== undefined) {

              const newPosts = res.data.data;
              setAllPosts(newPosts);
            } else {

              const newPosts = allPosts.concat(res.data.data);
              setAllPosts(newPosts);
            }
          }
        }
      });
  };

  const handleLoadMore = () => {
    // setPostState({ ...postState, postsPerPage: postState.postsPerPage + 1 });
    postState.postsPerPage = postState.postsPerPage + 1;
    getAllPosts();
  };

  // console.log('post state is', postState);
  // console.log('all daaaa', allPosts);

  const addPost = (post_text, imageList) => {
    // const post = {
    //   userID: socialData && socialData.uid,
    //   userCode: socialData && socialData.memtoken,
    //   appID: 1,
    //   companyID: socialData && socialData.cid,
    //   postText: post_text,
    //   groupID: props.groupID
    //   // mediaList: imageList.map(data => {
    //   //   return { image: data.preview };
    //   // }),
    //   // likeCount: 0,
    //   // isLike: false,
    //   // commentCount: 0,
    //   // commentList: []
    // };
    setPostLoader(true);
    let post = new FormData();
    post.append("userID", socialData && socialData.uid);
    post.append("userCode", socialData && socialData.memtoken);
    post.append("appID", 1);
    post.append("companyID", socialData && socialData.cid);
    post.append("postText", post_text);
    post.append("groupID", props.groupID);
    // post.append("test", 1);
    post.append("likeCount", 0);
    post.append("isLike", false);
    post.append("commentCount", 0);
    post.append("commentList", []);
    post.append("type", "post_save");
    post.append("imageHeight", 600);
    post.append("imageWidth", 600);

    imageList.forEach((data) => post.append("mediaList[]", data));
    httpClient
      .post(
        "api/social/posts_save.php", post
        // qs.stringify({
        //   action: "post_save",
        //   source: post,
        // })
      )
      .then((res) => {
        if (res.data.success === "false") {
          NotificationManager.warning(res.data.msg);
        } else {
          console.log("response after submit is >>", res);
          setPostLoader(false);
          setZeroPosts(false);
          let latestPost = {
            postTitle: res && res.data && res.data.posttext,
            postedDate: moment(),
            postedUserName:
              socialData && socialData.fname + "" + socialData.lname,
            postID: res && res.data && res.data.postid,
            userThumbnail: socialData && socialData.img,
            totalLikes: "0",
            isLiked: "0",
            totalComments: 0,
            postedUserID: socialData && socialData.uid,
            groupId: props.groupID,
            groupName: null,
            images: res && res.data && res.data.imageList,
          };

          let updatedPostList = [latestPost].concat(allPosts);
          setAllPosts(updatedPostList);
        }
      });
  };

  const editPost = (postTitle, imageList, postID, groupID, groupId) => {
    // console.log('edit post >>', this.state.post);
    // console.log(postTitle, imageList, 'edit');
    setPostLoader(true);
    let fd = new FormData();
    fd.append("userID", socialData && socialData.uid);
    fd.append("userCode", socialData && socialData.memtoken);
    fd.append("appID", 1);
    fd.append("companyID", socialData && socialData.cid);
    fd.append("postText", postTitle);
    fd.append("postID", postID);
    fd.append("groupID", (groupID || groupId) != null ? (groupID || groupId) : 0);
    fd.append("type", "post_save");
    fd.append("imageHeight", 600);
    fd.append("imageWidth", 600);

    imageList.forEach((data) => fd.append("mediaList[]", data));

    httpClient.post("api/social/posts_save.php", fd).then((res) => {
      if (res.data.success === "false") {
        NotificationManager.warning(res.data.msg);
      } else {
        NotificationManager.success(res.data.msg);
        setAllPosts([]);
        postState.postsPerPage = 0;
        getAllPosts(postID);
        setPostLoader(false);
        setEditClose(false);
      }
    });
  };

  const handleDeletePost = (postID, groupID) => {
    setPostLoader(true);
    
    let groupIDCheck;

    if (groupID === null) {
      groupIDCheck = 0;
    } else {
      groupIDCheck = groupID;
    }

    const PostQuery = {
      userID: socialData && socialData.uid,
      userCode: socialData && socialData.memtoken,
      appID: 1,
      companyID: socialData && socialData.cid,
      postID: postID,
      groupID: groupIDCheck,
    };

    httpClient
      .post(
        "api/social/posts_save.php",
        qs.stringify({
          action: "post_delete ",
          source: PostQuery,
        })
      )
      .then((res) => {
        if (res.data.success !== "true") {
          NotificationManager.warning(
            "Sorry couldn't delete posts right now !"
          );
          setDeleteAlert(false);
        } else {
          NotificationManager.success(res.data.msg);
          setAllPosts([]);
          postState.postsPerPage = 0;
          getAllPosts(postID);
          setPostLoader(false);
          setDeleteAlert(false);
        }
      });
  };

  // console.log('props in group post >>', props);  
  // console.log('all post >>', allPosts);  
  return (
    <>
      <Auxiliary>
        {/* {props.groupPublic === "1" && props.groupSubscribe === "1" && ( */}
        {props.groupSubscribe === "1" && (
          <WriteBox addPost={addPost} />
        )}

        {!postLoader ? (
          allPosts && allPosts.length > 0 ? (
            allPosts.map((post) => {

              return (
                <PostItem
                  key={post.postID}
                  index={post.postID}
                  postData={post}
                  isPublic={props.groupPublic}
                  groupSubscribe={props.groupSubscribe}
                  editClose={editClose}
                  editPost={editPost}
                  deleteAlert={deleteAlert}
                  handleDeletePost={handleDeletePost}


                />
              );
            })
          ) : (
            <div>
              {!emptyPosts && !zeroPosts ? (
                <CircularProgress style={{ marginLeft: "20%" }} />
              ) : null}
            </div>
          )
        ) : (
          <CircularProgress style={{ marginLeft: "20%" }} />
        )}

        {zeroPosts && (
          <strong style={{ color: "gray", fontSize: "16px" }}>
            No posts till now ...
          </strong>
        )}

        {emptyPosts ? (
          <strong style={{ color: "gray" }}>
            <CheckCircle /> You're All Caught Up, No posts to show ...
          </strong>
        ) : (
          <>
            {maxLength ? (
              <Button
                size="small"
                style={{ background: "#e6e6e6" }}
                onClick={handleLoadMore}
              >
                Load More...
              </Button>
            ) :
              <strong style={{ color: "gray" }}>
                <CheckCircle /> You're All Caught Up, No posts to show ...
              </strong>
            }
          </>
        )}
      </Auxiliary>
      <NotificationContainer />
    </>
  );
};

export default GroupPostList;
