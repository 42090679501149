import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import Modal from "@material-ui/core/Modal";
import Backdrop from "@material-ui/core/Backdrop";
import Fade from "@material-ui/core/Fade";
import { FormControl, Input, TextField } from "@material-ui/core";
import SearchIcon from "@material-ui/icons/Search";
import CloseIcon from '@material-ui/icons/Close';
import { IconButton } from "@material-ui/core";

const useStyles = makeStyles((theme) => ({
  modal: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },
  formContainer: {
    background: "#fff",
    padding: "10px 15px",
    width: "500px",
    borderRadius: "25px",
    "& .MuiInput-underline:before": {
      borderBottom: "none",
    },
    "& .MuiInput-underline:hover:not(.Mui-disabled):before": {
      borderBottom: "none",
    },
    "& .MuiInput-underline:after": {
      border: "none",
    },
    [theme.breakpoints.down('sm')]: {
      width: "300px",
    },
  },
  searchIcon: {
    position: "absolute",
    top: "16px",
  },
  text: {
    paddingLeft: "35px",
  },
  closeIcon:{
    position: "absolute",
    top: "20px",
    right: "20px",
  },
  close:{
    color: "#fff",
    fontSize:"30px"
  }
}));

const Search = (props) => {
  const classes = useStyles();

  return (
    <Modal
      aria-labelledby="transition-modal-title"
      aria-describedby="transition-modal-description"
      className={classes.modal}
      open={props.open}
      onClose={props.handleClose}
      closeAfterTransition
      BackdropComponent={Backdrop}
      BackdropProps={{
        timeout: 500,
      }}
    >
      <Fade in={props.open}>
        <div>
          <form className={classes.root} noValidate autoComplete="off">
            <FormControl className={classes.formContainer}>
              <Input
                variant="outlined"
                placeholder="Search..."
                className={classes.text}
                value={props.value}
                onChange={props.onChange}
                onKeyPress={props.onKeyPress}
              />
              <SearchIcon className={classes.searchIcon} />
            </FormControl>
          </form>
          <IconButton onClick={props.handleClose} className={classes.closeIcon}>
            <CloseIcon className={classes.close} />
          </IconButton>
        </div>
      </Fade>
    </Modal>
  );
};

export default Search;
