import React, { Component } from "react";
import Avatar from "@material-ui/core/Avatar";
import CommentBox from "./CommentBox";
import MediaList from "./MediaList";
// import DisplayDate from "../DisplayDate/index";
// import Button from "@material-ui/core/Button";
import Card from "@material-ui/core/Card";
import { Input } from "reactstrap";
import { PlayFill, Trash } from "react-bootstrap-icons";
import moment from "moment";
import { history } from "../../../store/index";
import { httpClient } from "appUtility/Api";
import IconButton from "@material-ui/core/IconButton";
import AlertDialog from "./AlertDialog";
import {
  NotificationManager,
  NotificationContainer,
} from "react-notifications";
import CircularProgress from "@material-ui/core/CircularProgress";

const qs = require("qs");
const socialData = JSON.parse(window.localStorage.getItem("login"));

class ImagePost extends Component {
  state = {
    message: "",
    totalComments: "",
    likeLoader: false,
    user: {
      name: socialData && socialData.fname + "" + socialData.lname,
      userID: socialData && socialData.uid,
      image: socialData && socialData.img,
    },
    post: {},
    userProfile: "",
    deleteAlert: false,
    showComments: false,
    commentList: [],
    commentLoader: false,
    subCommentData: false,
    reRender: false,
    deleteImageID: ""
  };

  componentWillMount() {
    this.setState({
      post: this.props.postData,
      // totalComments: this.props.postData.totalComments,
      totalLikes: this.props.postData.totalLikes,
      userProfile: this.props.userProfile,
      isLiked: this.props.postData.isLiked,
    });
  }

  componentDidUpdate(prevState) {
    // console.log('prev>>', prevState);
    // if(this.state && this.state.totalComments !== ""){
    //   this.props.getSinglePost()
    // }
  }

  getComments = () => {
    this.setState({
      ...this.state,
      showComments: true,
      commentList: [],
      commentLoader: true,
    });

    const getCommentsQuery = {
      userID: socialData && socialData.uid,
      userCode: socialData && socialData.memtoken,
      appID: 1,
      companyID: socialData && socialData.cid,
      postID: this.state.post.postID,
      imageID: this.state.post.imageID,
    };

    httpClient
      .post(
        "api/social/image_comments_get.php",
        qs.stringify({
          action: "image_comments",
          source: getCommentsQuery,
        })
      )
      .then((res) => {
        if (res.data.data === null) {
          // console.log("No comments for this post !");
          this.setState({ ...this.state, commentLoader: false });
        } else {
          this.props.getSinglePost();
          this.setState({
            ...this.state,
            commentList: res.data.data,
            commentLoader: false,
          });
        }
      });
  };

  handleNewComment = (e) => {
    if (e.key === "Enter") {
      this.setState({ ...this.state, commentLoader: true });
      const newCommentData = {
        userID: socialData && socialData.uid,
        userCode: socialData && socialData.memtoken,
        appID: 1,
        companyID: socialData && socialData.cid,
        commentText: this.state.message,
        postID: this.state.post.postID,
        imageID: this.state.post.imageID,
      };

      httpClient
        .post(
          "/api/social/image_comments_save.php",
          qs.stringify({
            action: "comments_save_image",
            source: newCommentData,
          })
        )
        .then((res) => {
          if (res.data.success === "false") {
            this.setState({ ...this.state, commentLoader: false });
            NotificationManager.warning(res.data.msg);
          } else {
            // console.log("image comments >>>>>>>>>>>>>>", res.data);
            this.setState({
              ...this.state,
              message: "",
              totalComments: res.data.totalComments,
              reRender: true
            });

            if (!this.state.showComments) {
              this.setState({ ...this.state, showComments: true });
            }
            //------Concatinating the latest comment into the commentList Array------//
            let latestComment = {
              commentUserName:
                socialData && socialData.fname + " " + socialData.lname,
              commentText: res && res.data && res.data.commentText,
              commentID: res && res.data && res.data.commentID.toString(),
              postID: res && res.data && res.data.postID,
              imageID: res && res.data && res.data.postID,
              userThumbnail: res && res.data && res.data.userThumbnail,
              commentedDate: moment(),
              totalComments: "0",
              userThumbnail: res && res.data && res.data.userThumbnail,
            };

            if (this.state.commentList.length === 0) {
              this.getComments();
            } else {
              let updatedCommentList = this.state.commentList.concat([
                latestComment,
              ]);
              this.setState({
                ...this.state,
                commentList: updatedCommentList,
                commentLoader: false,
                totalComments: res && res.data && res.data.totalComments,
                reRender: true,
              });
              this.getComments();
            }
          }
        });
    }
  };

  updateCommentValue(evt) {
    this.setState({ ...this.state, message: evt.target.value });
  }

  seeAllComments = () => {
    if (this.state.commentList.length > 0) {
      this.setState({ ...this.state, showComments: !this.state.showComments });
    } else if (this.props.postData.totalComments === "0") {
      this.setState({ ...this.state, showComments: !this.state.showComments });
    } else if (this.props.postData.totalComments !== "0") {
      this.getComments();
    } else {
      return;
    }
  };

  RequestDeletePost = () => {
    this.setState({ ...this.state, deleteAlert: true });
  };

  CancelDeletePost = () => {
    this.setState({ ...this.state, deleteAlert: false });
  };

  filterDeletedComment = (id) => {
    let filteredComment = this.state.commentList.filter(
      (item) => item.commentID !== id
    );
    this.setState({ ...this.state, commentList: filteredComment });
  };

  // handleDeleteImage = () => {
  //   const PostQuery = {
  //     userID: socialData && socialData.uid,
  //     userCode: socialData && socialData.memtoken,
  //     appID: 1,
  //     companyID: socialData && socialData.cid,
  //     postID: this.state.post.postID,
  //     imageID: this.state.post.imageID,
  //   };

  //   httpClient
  //     .post(
  //       "api/social/posts_image_save.php",
  //       qs.stringify({
  //         action: "image_delete",
  //         source: PostQuery,
  //       })
  //     )
  //     .then((res) => {
  //       if (res.data.success !== "true") {
  //         NotificationManager.warning(
  //           "Sorry couldn't delete posts right now !"
  //         );
  //         this.CancelDeletePost();
  //       } else {
  //         NotificationManager.success(res.data.msg);
  //         this.CancelDeletePost();
  //         this.setState({
  //           reRender: true,
  //         });
  //       }
  //     });
  // };

  handleDeleteImage = () => {
    this.props.handleDeleteImage(this.state.post.postID,this.state.post.imageID);
  }

  render() {
    const {
      postedUserName,
      postedDate,
      postTitle,
      totalComments,
      postID,
      userThumbnail,
      imageID,
    } = this.state.post;

    // console.log("state in img post", this.state);

    return (
      <>
        {this.state.deleteAlert && (
          <AlertDialog
            postID={postID}
            imageId={imageID}
            deleteAlert={this.state.deleteAlert}
            CancelDeletePost={this.CancelDeletePost}
            handleDeletePost={this.handleDeleteImage}
            image={this.state.post}
          />
        )}

        <Card style={{ padding: "15px" }}>
          <div className="jr-wall-content">
            <div className="media jr-wall-user-info flex-nowrap align-items-center">
              <Avatar
                src={userThumbnail}
                alt={postedUserName}
                className="mr-3 mb-2 size-50"
              />

              <div className="media-body">
                <h5 className="jr-wall-user-title">
                  {postedUserName}{" "}
                  {/* {groupName && (
                    <span
                      onClick={() => this.handleSeeGroup(groupID, groupName)}
                    >
                      <PlayFill
                        style={{ fontSize: "16px", marginTop: "1px" }}
                      />{" "}
                      {groupName}
                    </span>
                  )} */}
                </h5>
                {/* <DisplayDate date={postedDate} /> */}
                <span
                  style={{
                    fontSize: "12px",
                    fontWeight: "bold",
                    color: "#ababab",
                  }}
                >
                  {moment(postedDate).format("MMMM Do YYYY, h:mm a")}
                </span>
              </div>

              {this.state.post.postedUserID === this.state.user.userID && (
                <>
                  <IconButton
                    className="icon-btn text-light p-1"
                    onClick={this.RequestDeletePost}
                  >
                    <Trash />
                  </IconButton>
                </>
              )}
            </div>

            <p style={{ fontSize: "16px" }}>{postTitle}</p>

            <div className="d-flex flex-row mb-2 mb-xl-3">
              <p
                className="jr-fs-sm pointer mr-3 text-blue"
                onClick={this.seeAllComments}
              >
                <i className="zmdi zmdi-comment-more jr-fs-lg mr-2 d-inline-flex align-middle" />
                <span className="d-inline-flex align-middle">
                  {/* {totalComments > 0
                    ? totalComments + " Comments"
                    : "0 Comments"} */}
                  {this.state.totalComments !== ""
                    ? this.state.totalComments + " Comments"
                    : totalComments + " Comments"}
                </span>
              </p>

              {/* <span className="text-primary jr-fs-md pointer d-block mb-4">
              See All Comments{" "}
              <i
                className={`zmdi zmdi-long-arrow-right jr-fs-xxl ml-2 d-inline-flex align-middle`}
              />
            </span> */}
            </div>

            <div className="jr-wall-comment-box pb-3">
              <div className="media mb-2">
                <Avatar
                  className="mr-3 size-36"
                  src={userThumbnail}
                  alt={postedUserName}
                />
                <div className="media-body">
                  <Input
                    className="border-3"
                    placeholder="Type Comments"
                    type="textarea"
                    id="required"
                    value={this.state.message}
                    onChange={(event) => this.updateCommentValue(event)}
                    onKeyPress={(event) => this.handleNewComment(event)}
                  />

                  {/* <Button
                    variant="contained"
                    style={{ width: "5px" }}
                    onClick={event => this.handleNewComment(event)}
                  >
                    <ArrowRightSquareFill style={{ color: "blue" }} />
                  </Button> */}
                </div>
              </div>
            </div>

            <div className="jr-wall-comment-box">
              {this.state.commentLoader && (
                <CircularProgress style={{ marginLeft: "40%" }} />
              )}

              {this.state.showComments &&
                // !this.state.commentList &&
                this.state.commentList.length > 0 &&
                this.state.commentList.map((commentData, index) => (
                  <CommentBox
                    key={commentData.commentID}
                    index={index}
                    commentData={commentData}
                    deleteAlert={this.state.deleteAlert}
                    filterDeletedComment={this.filterDeletedComment}
                    // handleSubComment={this.handleSubComment}
                    showSubCommentsImage={false}
                    getAllComments={this.getComments}
                  />
                ))}
            </div>
          </div>
        </Card>
        <NotificationContainer />
      </>
    );
  }
}

export default ImagePost;
