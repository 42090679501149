import React from "react";
import ContainerHeader from "components/ContainerHeader/index";
import CardBox from "components/CardBox/index";
import CardBoxNew from "components/CardBoxNew/index";
import TextField from "@material-ui/core/TextField";
import Button from "@material-ui/core/Button";
import Card from "@material-ui/core/Card";
import {
  NotificationContainer,
  NotificationManager,
} from "react-notifications";
import { httpClient } from "appUtility/Api";
import IntlMessages from "util/IntlMessages";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import ListItemIcon from "@material-ui/core/ListItemIcon";
import ListItemSecondaryAction from "@material-ui/core/ListItemSecondaryAction";
import ListItemText from "@material-ui/core/ListItemText";
import ListSubheader from "@material-ui/core/ListSubheader";
import Switch from "@material-ui/core/Switch";
import LinearProgress from "@material-ui/core/LinearProgress";
import featureServer from "../../../appUtility/featureServer";
import { FormControl, Grid, MenuItem, Radio, Select, Tooltip } from "@material-ui/core";
import configureStore from "../../../store";

const loginOBJ = localStorage.getItem("login");
const logindetail = JSON.parse(loginOBJ);
var modifiedData = logindetail && logindetail.syncLastModified;
const qs = require("qs");

var param, settingdetail;

//const Settings = ({match}) => {
class Settings extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      error: null,
      isLoaded: false,
      id: "",
      incometemplate: "",
      incomedescription: "",
      appID: logindetail && logindetail.aid,
      memberID: logindetail && logindetail.uid,
      companyID: logindetail && logindetail.cid,
      mcode: logindetail && logindetail.mCode,
      settingdetail: "",
      checked: [],
      customerName: false,
      bookingTotal: false,
      customerAddress: false,
      petName: false,
      petBreed: false,
      serviceName: false,
      syncModified: "",
      loading: false,
      display_booking_end_time: logindetail && logindetail.display_booking_end_time,
      timeFormat: logindetail && logindetail.timeFormat,
      addressInInvoice: logindetail && logindetail.addressInInvoice,
      enableCancellationPolicy: logindetail && logindetail.enableCancellationPolicy,
      selectedSetting: "",
      confirmationHour: 24,
      loadingConfirmation: false,
    };

    this.handleChange = this.handleChange.bind(this);
  }

  handleEndDate = (e) => {
    const { checked } = e.target;
    this.setState({
      ...this.setState,
      display_booking_end_time: checked ? "1" : "0"
    })

    var dateVals = {
      appID: logindetail && logindetail.aid,
      companyID: logindetail && logindetail.cid,
      memberID: logindetail && logindetail.uid,
      display_booking_end_time: checked ? "1" : "0"
    }
    httpClient
      .post(
        "updateUserSettings.php",
        qs.stringify({
          action: "update_display_end_time",
          source: dateVals,
        })
      ).then(({ data }) => {
        if (data.success == "true") {
          var loginData = JSON.parse(localStorage.getItem("login"));

          // loginData.display_booking_end_time = data.display_booking_end_time;
          loginData.display_booking_end_time = this.state.display_booking_end_time;

          this.setState({
            ...this.setState,
            display_booking_end_time: loginData.display_booking_end_time
          })
          localStorage.setItem("login", JSON.stringify(loginData));
          NotificationManager.info(data.msg);

        }

      })
  }

  handleFormatChange = (e) => {
    const { checked } = e.target;
    this.setState({
      ...this.setState,
      timeFormat: checked ? 24 : 12
    })

    var dateVals = {
      appID: logindetail && logindetail.aid,
      companyID: logindetail && logindetail.cid,
      memberID: logindetail && logindetail.uid,
      timeFormat: checked ? 24 : 12
    }
    httpClient
      .post(
        "updateUserSettings.php",
        qs.stringify({
          action: "timeFormat",
          source: dateVals.appID + '_' + dateVals.companyID + '_' + dateVals.memberID + '_' + 'timeFormat',

          timeFormat: dateVals.timeFormat
        })
      ).then(({ data }) => {
        if (data.success == "true") {
          var loginData = JSON.parse(localStorage.getItem("login"));

          loginData.timeFormat = this.state.timeFormat;
          this.setState({
            ...this.setState,
            timeFormat: loginData.timeFormat
          })
          localStorage.setItem("login", JSON.stringify(loginData));
          NotificationManager.info(data.msg);


        }
      })
  }

  handleAddressInInvoiceChange = (e) => {
    const { checked } = e.target;
    this.setState({
      ...this.setState,
      addressInInvoice: checked ? 1 : 0
    })

    var dateVals = {
      appID: logindetail && logindetail.aid,
      companyID: logindetail && logindetail.cid,
      memberID: logindetail && logindetail.uid,
      addressInInvoice: checked ? 1 : 0
    }
    httpClient
      .post(
        "updateUserSettings.php",
        qs.stringify({
          action: "addressInInvoice",
          source: dateVals.appID + '_' + dateVals.companyID + '_' + dateVals.memberID + '_' + 'addressInInvoice',

          addressInInvoice: dateVals.addressInInvoice
        })
      ).then(({ data }) => {
        if (data.success == "true") {
          var loginData = JSON.parse(localStorage.getItem("login"));

          loginData.addressInInvoice = this.state.addressInInvoice;
          this.setState({
            ...this.setState,
            addressInInvoice: loginData.addressInInvoice
          })
          localStorage.setItem("login", JSON.stringify(loginData));
          NotificationManager.info(data.msg);


        }
      })
  }


  handleCancellationPolicyChange = (e) => {
    const { checked } = e.target;
    this.setState({
      ...this.setState,
      enableCancellationPolicy: checked ? 1 : 0
    })

    var dateVals = {
      appID: logindetail && logindetail.aid,
      companyID: logindetail && logindetail.cid,
      memberID: logindetail && logindetail.uid,
      enableCancellationPolicy: checked ? 1 : 0
    }
    httpClient
      .post(
        "updateUserSettings.php",
        qs.stringify({
          action: "enableCancellationPolicy",
          source: dateVals.appID + '_' + dateVals.companyID + '_' + dateVals.memberID + '_' + 'enableCancellationPolicy',

          enableCancellationPolicy: dateVals.enableCancellationPolicy
        })
      ).then(({ data }) => {
        if (data.success == "true") {
          var loginData = JSON.parse(localStorage.getItem("login"));

          loginData.enableCancellationPolicy = this.state.enableCancellationPolicy;
          this.setState({
            ...this.setState,
            enableCancellationPolicy: loginData.enableCancellationPolicy
          })
          localStorage.setItem("login", JSON.stringify(loginData));
          NotificationManager.info(data.msg);


        }
      })
  }

  handleToggle = (event, value) => {
    var settingParam;
    var aid, cid, uid;
    var btntype;
    aid = logindetail && logindetail.aid;
    cid = logindetail && logindetail.cid;
    uid = logindetail && logindetail.uid;
    btntype = value;

    const { checked } = this.state;
    const currentIndex = checked.indexOf(value);
    const newChecked = [...checked];

    if (currentIndex == -1) {
      newChecked.push(value);
    } else {
      newChecked.splice(currentIndex, 1);
    }

    //this.hideValues(value);

    this.setState({
      checked: newChecked,
    });

    settingParam = aid + "_" + cid + "_" + uid + "_" + value;
    // console.log(settingParam);
    // console.log(btntype);

    httpClient
      .post(
        "updateUserSettings.php",
        qs.stringify({
          action: btntype,
          source: settingParam,
        })
      )
      .then(
        ({ data }) => {
          if (data.success != "true") {
            NotificationManager.info(data.failedMSG);
          } else if (data.success == "true") {
            var loginTempOBJ = JSON.parse(localStorage.getItem("login"));
            var objname = data.actionOn;

            if (objname == "displayCustNotes")
              loginTempOBJ.displayCustNotes = 1 - loginTempOBJ.displayCustNotes;

            if (objname == "hideOldRecurring")
              loginTempOBJ.hideOldRecurring = 1 - loginTempOBJ.hideOldRecurring;

            if (objname == "displayBookingClash")
              loginTempOBJ.displayBookingClash =
                1 - loginTempOBJ.displayBookingClash;

            if (objname == "hidePastBookingsCalendarSearch")
              loginTempOBJ.hidePastBookingsCalendarSearch =
                1 - loginTempOBJ.hidePastBookingsCalendarSearch;

            if (objname == "filterBookingServices") {
              loginTempOBJ.filterBookingServices =
                1 - loginTempOBJ.filterBookingServices;
            }

            //loginTempOBJ.'[objname]' = 1-loginTempOBJ.[objname];
            localStorage.setItem("login", JSON.stringify(loginTempOBJ));
            NotificationManager.info(data.msg);
          }
        },
        // error handler
        (error) => {
          this.setState({
            //isLoaded: true,
            error,
          });
        }
      );
  };

  handleCalendarChange = (e) => {
    const { name, checked } = e.target;
    this.setState({ [name]: checked });
  };

  handleBookingConfirmationChange = (event) => {
    this.setState({ selectedSetting: event.target.value });
  };

  handleChangeConfirmationHour = (e) => {
    this.setState({
      confirmationHour: e.target.value,
    });
  };


  handleSaveSettings() {
    //alert(this.state.incometemplate);
    if (this.state.incometemplate == "" && this.state.incomedescription == "") {
      NotificationManager.info("Nothing changed to save");
    } else {
      //console.log(this.state);

      httpClient
        .post(
          "saveMemberSettings.php",
          qs.stringify({
            action: "save_settings",
            source: this.state,
          })
        )
        .then(
          ({ data }) => {
            if (data.success != "true") {
              NotificationManager.info(data.failedMSG);
            } else {
              NotificationManager.info(data.successMSG);
              this.setState({
                incometemplate: "",
                incomedescription: "",
              });
            }
          },
          // error handler
          (error) => {
            NotificationManager.info(
              "Error Occured: Refresh the page and try again"
            );
          }
        );
    }
  }

  handleChange(evt) {
    this.setState({ [evt.target.name]: evt.target.value });
  }

  componentDidMount() {
    var aid, cid, uid;
    aid = logindetail && logindetail.aid;
    cid = logindetail && logindetail.cid;
    uid = logindetail && logindetail && logindetail.uid;

    param = aid + "_" + cid + "_" + uid;

    httpClient
      .post(
        "getMemberSettings.php",
        qs.stringify({
          action: "member_settings",
          source: param,
        })
      )
      .then(
        ({ data }) => {
          if (data) {
            localStorage.setItem("settings", JSON.stringify(data));
            this.setState(
              {
                isLoaded: false,
                id: data.id,
                settingdetail: data,
                checked: data.flags,
                petName: data.pet_name == "1" ? true : false,
                petBreed: data.pet_breed == "1" ? true : false,
                serviceName: data.services_name == "1" ? true : false,
                customerName: data.customer_name == "1" ? true : false,
                bookingTotal: data.bookingCost == "1" ? true : false,
                customerAddress: data.customer_address == "1" ? true : false,
                confirmationHour: parseInt(data.confirmationHour),
                selectedSetting: data.confirmationBy,
              },
              function () {
                this.setState({
                  isLoaded: true,
                });
              }
            );
            // console.log(this.state.checked);
          } else {
          }
        },
        // error handler
        (error) => {
          this.setState({
            isLoaded: true,
            error,
          });
        }
      );

    const loginValue = JSON.parse(localStorage.getItem("login"));
    this.setState({
      ...this.state,
      // display_booking_end_time: loginValue.display_booking_end_time == "1" ? true : false
      display_booking_end_time: loginValue.display_booking_end_time,
      timeFormat: loginValue.timeFormat,
      addressInInvoice: loginValue.addressInInvoice,
      enableCancellationPolicy: loginValue.enableCancellationPolicy
    })
    console.log('disp', this.state);
  }

  handleSubmitCalendar = (e) => {
    // console.log("sumit");
    e.preventDefault();


    let loginBlur = JSON.parse(localStorage.getItem("login"));
    if (loginBlur && loginBlur.ismobiledevice != 1) {
      document.getElementById("nav__menu__display") !== null &&
        (document.getElementById("nav__menu__display").style.cssText =
          "filter: blur(2px); pointer-events: none");
      // document.getElementById("user_name_1") !== null && (document.getElementById("user_name_1").style.cssText = "display: none");
      // document.getElementById("user_name_100") !== null && (document.getElementById("user_name_100").style.cssText = "display: block");
    }
    document.getElementById("appbar_mainapp__") !== null &&
      (document.getElementById("appbar_mainapp__").style.cssText =
        "filter: blur(2px); pointer-events: none");


    this.setState({
      ...this.state,
      loading: true,
    });
    let parameters = {
      appID: logindetail && logindetail.aid,
      memberID: logindetail && logindetail.uid,
      companyID: logindetail && logindetail.cid,
      customer_name: this.state.customerName,
      bookingCost: this.state.bookingTotal,
      customer_address: this.state.customerAddress,
      pet_name: this.state.petName,
      pet_breed: this.state.petBreed,
      services_name: this.state.serviceName,
    };

    httpClient
      .post(
        "updateUserSettings.php",
        qs.stringify({
          action: "update_calendar_settings",
          source: parameters,
        })
      )
      .then(({ data }) => {
        if (data.success == "true") {
          NotificationManager.success(data.successMSG);
          let calendarData = [];
          let calendarStorage = JSON.parse(localStorage.getItem("settings"));
          // calendarData = calendarStorage;

          calendarData = {
            ...calendarStorage,
            customer_name: data.customer_name,
            bookingCost: data.bookingCost,
            pet_name: data.pet_name,
            services_name: data.services_name,
            customer_address: data.customer_address,
          };

          const cal = { ...calendarData };

          localStorage.setItem("settings", JSON.stringify(cal));

          setTimeout(() => {
            NotificationManager.info("Retrieving Calendar Entries");
          }, 1000);

          this.getCalendarEntry();
        } else {
          NotificationManager.info(data.failedMSG);
        }
      });
  };

  handleSubmitConfirmation = (e) => {
    // console.log("sumit");
    e.preventDefault();

    this.setState({
      ...this.state,
      loadingConfirmation: true,
    });
    let parameters = {
      appID: logindetail && logindetail.aid,
      memberID: logindetail && logindetail.uid,
      companyID: logindetail && logindetail.cid,
    };

    httpClient
      .post(
        "updateUserSettings.php",
        qs.stringify({
          action: "bookingConfirmation",
          source: parameters.appID + "_" + parameters.companyID + "_" + parameters.memberID + "_" + "bookingConfirmation",
          confirmationBy: this.state.selectedSetting,
          confirmationHour: this.state.confirmationHour,
        })
      )
      .then(({ data }) => {
        if (data.success == "true") {
          this.setState({
            ...this.state,
            loadingConfirmation: false,
            selectedSetting: data.data.confirmationBy,
            confirmationHour: parseInt(data.data.confirmationHour),
          });
          NotificationManager.success(data.msg);

        } else {
          this.setState({
            ...this.state,
            loadingConfirmation: false,
            selectedSetting: "",
            confirmationHour: "",
          });
          NotificationManager.info(data.failedMSG);
        }
      });
  };

  getCalendarEntry = () => {
    httpClient
      .post(
        "getCalendarEntries.php",
        qs.stringify({
          action: "calendar_entries",
          source: param,
        })
      )
      .then(
        ({ data }) => {
          if (data) {
            var calendarLastMod = {
              calendarLastModified: data.calendarLastModified,
            };
            var dataIndex =
              modifiedData &&
              modifiedData.findIndex((arr, i) => arr.calendarLastModified);
            modifiedData && (modifiedData[dataIndex] = calendarLastMod);

            localStorage.setItem("calendar_entries", JSON.stringify(data.data));
            this.setState({
              syncModified: modifiedData,
            });

            this.setState({
              ...this.state,
              loading: false,
            });
            if (this.state.syncModified.length > 0) {
              var loginData = JSON.parse(localStorage.getItem("login"));
              loginData.syncLastModified = this.state.syncModified;
              loginData.syncLastModified !== [] &&
                localStorage.setItem("login", JSON.stringify(loginData));
            }

            NotificationManager.success(
              "Calendar Settings Successfully Updated"
            );

            //Blur removed
            let loginBlur = JSON.parse(localStorage.getItem("login"));

            if (loginBlur && loginBlur.ismobiledevice != 1) {
              document.getElementById("nav__menu__display") !== null &&
                (document.getElementById("nav__menu__display").style.cssText =
                  "filter: blur(0px); pointer-events: block");
              // document.getElementById("user_name_1") !== null &&  (document.getElementById("user_name_1").style.cssText = "display: block");
              // document.getElementById("user_name_100") !== null && (document.getElementById("user_name_100").style.cssText = "display: none");
            }
            document.getElementById("appbar_mainapp__") !== null &&
              (document.getElementById("appbar_mainapp__").style.cssText =
                "filter: blur(0px); pointer-events: block");
          } else {
          }
        }
        // error handler
      );
  };


  render() {

    const {
      error,
      isLoaded,
      incomedescription,
      settingdetail,
      incometemplate,
      customerName,
      bookingTotal,
      customerAddress,
      petName,
      petBreed,
      serviceName,

    } = this.state;
    console.log("state", this.state);
    if (error) {
      return <div className="jr-customers">Error: {error.message}</div>;
    } else if (!isLoaded) {
      return (
        <div className="jr-customers">
          <LinearProgress color="secondary" />
        </div>
      );
    } else {
      return (
        <div className="app-wrapper animated slideInUpTiny animation-duration-3">
          <ContainerHeader
            title={<IntlMessages id="sidebar.dashboard.settings" />}
            match={this.props.match}
          />


          <div className="row">
            <CardBox
              heading={<IntlMessages id="dashboard.settings.preferences" />}
            >
              <div className="col-md-12 col-12">
                <List className="LawaList">
                  <ListItem>
                    <ListItemIcon>
                      <i className="zmdi zmdi-calendar-note zmdi-hc-fw zmdi-hc-2x" />
                    </ListItemIcon>
                    <ListItemText primary="Display Customer Notes on Booking" />
                    <ListItemSecondaryAction>
                      <Switch
                        color="primary"
                        onClick={(event) =>
                          this.handleToggle(event, "displayCustNotes")
                        }
                        checked={
                          this.state.checked.indexOf("displayCustNotes") != -1
                        }
                      />
                    </ListItemSecondaryAction>
                  </ListItem>
                  <ListItem>
                    <ListItemIcon>
                      <i className="zmdi zmdi-eye-off zmdi-hc-fw zmdi-hc-2x" />
                    </ListItemIcon>
                    <ListItemText primary="Hide Expired/Completed Recurring Bookings" />
                    <ListItemSecondaryAction>
                      <Switch
                        color="primary"
                        onClick={(event) =>
                          this.handleToggle(event, "hideOldBookings")
                        }
                        checked={
                          this.state.checked.indexOf("hideOldBookings") != -1
                        }
                      />
                    </ListItemSecondaryAction>
                  </ListItem>

                  <ListItem>
                    <ListItemIcon>
                      <i className="zmdi zmdi-notifications-off zmdi-hc-fw zmdi-hc-2x" />
                    </ListItemIcon>
                    <ListItemText primary="Hide Booking Clash Notifications" />
                    <ListItemSecondaryAction>
                      <Switch
                        color="primary"
                        onClick={(event) =>
                          this.handleToggle(event, "displayBookingClash")
                        }
                        checked={
                          this.state.checked.indexOf("displayBookingClash") !=
                          -1
                        }
                      />
                    </ListItemSecondaryAction>
                  </ListItem>

                  <ListItem>
                    <ListItemIcon>
                      <i className="zmdi zmdi-calendar-close zmdi-hc-fw zmdi-hc-2x" />
                    </ListItemIcon>
                    <ListItemText primary="Hide Past Bookings from Calendar Search" />
                    <ListItemSecondaryAction>
                      <Switch
                        color="primary"
                        onClick={(event) =>
                          this.handleToggle(
                            event,
                            "hidePastBookingsCalendarSearch"
                          )
                        }
                        checked={
                          this.state.checked.indexOf(
                            "hidePastBookingsCalendarSearch"
                          ) != -1
                        }
                      />
                    </ListItemSecondaryAction>
                  </ListItem>

                  <ListItem>
                    <ListItemIcon>
                      <i className="zmdi zmdi-evernote zmdi-hc-fw zmdi-hc-2x" />
                    </ListItemIcon>
                    <ListItemText primary="Filter Services based on pet size" />
                    <ListItemSecondaryAction>
                      <Switch
                        color="primary"
                        onClick={(event) =>
                          this.handleToggle(event, "filterBookingServices")
                        }
                        checked={
                          this.state.checked.indexOf("filterBookingServices") !=
                          -1
                        }
                      />
                    </ListItemSecondaryAction>
                  </ListItem>

                  <ListItem>
                    <ListItemIcon>
                      <i className="zmdi zmdi-time-restore zmdi-hc-fw zmdi-hc-2x" />
                    </ListItemIcon>
                    <ListItemText primary="Display Booking End Time" />
                    <ListItemSecondaryAction>
                      <Switch
                        color="primary"
                        // onClick={this.handleEndDate}
                        checked={this.state.display_booking_end_time === "1"}
                        onChange={this.handleEndDate}
                      />
                    </ListItemSecondaryAction>
                  </ListItem>

                  {/* {featureServer && */}
                    <ListItem>
                      <ListItemIcon>
                        <i className="zmdi zmdi-receipt zmdi-hc-fw zmdi-hc-2x" />
                      </ListItemIcon>
                      <ListItemText primary="Show Address In Invoice" />
                      <ListItemSecondaryAction>
                        <Switch
                          color="primary"
                          // onClick={this.handleEndDate}
                          checked={this.state.addressInInvoice === 1}
                          onChange={this.handleAddressInInvoiceChange}
                        />
                      </ListItemSecondaryAction>
                    </ListItem>
                  {/* } */}
                  {/* {featureServer && */}
                  <ListItem>
                    <ListItemIcon>
                      <i className="zmdi zmdi-time-restore zmdi-hc-fw zmdi-hc-2x" />
                    </ListItemIcon>
                    <ListItemText primary="Toggle 24hr Time Format" />
                    <ListItemSecondaryAction>
                      <span style={{ fontSize: 'small' }}><small>12 hr</small></span>
                      <Tooltip title={this.state.timeFormat === 24 ? 'Change to 12 hour format' : 'Change to 24 hour format'}>
                        <Switch
                          color="primary"
                          // onClick={this.handleEndDate}
                          checked={this.state.timeFormat === 24}
                          onChange={this.handleFormatChange}
                        />
                      </Tooltip>
                      <span style={{ fontSize: 'small' }}><small>24 hr</small></span>
                    </ListItemSecondaryAction>

                  </ListItem>
                  {/* } */}
                </List>
              </div>
            </CardBox>

            {/* <CardBox heading={<IntlMessages id="dashboard.settings.sms" />}>
              <div className="col-md-12 col-12">
                <TextField
                  id="smsNumber"
                  label="SMS Sender"
                  value="0495149675"
                  margin="normal"
                  helperText="All your SMS will be sent using this number. You currently are not allowed to change this number."
                  fullWidth
                />
              </div>
            </CardBox> */}
            <div className="col-md-6">
              <Grid container rowSpacing={1} columnSpacing={{ xs: 1, sm: 2, md: 3 }}>
                <Grid item xs={12}>
                  <CardBoxNew heading={<IntlMessages id="dashboard.settings.sms" />}>
                    <div className="col-md-12 col-12">
                      <TextField
                        id="smsNumber"
                        label="SMS Sender"
                        value="0495149675"
                        margin="normal"
                        helperText="All your SMS will be sent using this number. You currently are not allowed to change this number."
                        fullWidth
                      />
                    </div>
                  </CardBoxNew>
                </Grid>
                {/* {featureServer && */}
                <Grid item xs={12}>
                  <CardBoxNew heading={"Cancellation Policy"}>
                    <div className="col-md-12 col-12">
                      <span>PLEASE NOTE: 24 hours notice is required to cancel your appointment.

                        We reserve the right to charge a cancellation fee if you are not home when we call for our confirmed appointment time.</span>
                      <List>
                        <ListItem>
                          <ListItemIcon>
                            <i className="zmdi zmdi-attachment zmdi-hc-fw zmdi-hc-2x" />
                          </ListItemIcon>
                          <ListItemText primary="Attach Cancellation Policy" />
                          <ListItemSecondaryAction>

                            <Switch
                              color="primary"
                              // onClick={this.handleEndDate}
                              checked={this.state.enableCancellationPolicy === 1}
                              onChange={this.handleCancellationPolicyChange}
                            />

                          </ListItemSecondaryAction>

                        </ListItem>
                      </List>
                    </div>
                  </CardBoxNew>
                </Grid>
                {/* } */}
              </Grid>
            </div>






            <CardBox heading={<IntlMessages id="dashboard.settings.booking" />}>
              <form className="row" noValidate autoComplete="off">
                <div className="col-md-12 col-12">
                  <TextField
                    id="incometemplate"
                    name="incometemplate"
                    label="Income Title Template"
                    margin="normal"
                    defaultValue={this.state.settingdetail.title}
                    helperText="##date## and ##customername## will be replaced by actual date and customer name"
                    onChange={this.handleChange}
                    fullWidth
                  />
                </div>
                <div className="col-md-12 col-12">
                  <TextField
                    id="incomedescription"
                    name="incomedescription"
                    label="Income Description"
                    defaultValue={this.state.settingdetail.desc}
                    margin="normal"
                    onChange={this.handleChange}
                    helperText="##date## and ##customername## will be replaced by actual date and customer name"
                    fullWidth
                  />
                </div>
                <div className="col-md-12 col-12">
                  <Button
                    variant="contained"
                    color="primary"
                    className="jr-btn btn-margin-topbottom"
                    position="static"
                    onClick={() => {
                      this.handleSaveSettings();
                    }}
                  >
                    Save
                  </Button>
                </div>
              </form>
            </CardBox>
            {
              featureServer && (
                <CardBox heading="Send Booking Reminder">
                  {this.state.loadingConfirmation && (
                    <div style={{ position: "relative", top: "15px" }}>
                      <LinearProgress color="secondary" />
                    </div>
                  )}
                  <div className="row">
                    <div className="col-md-12 col-12">
                      <List className="LawaList">
                        <ListItem>
                          <ListItemText primary="Do not send booking reminder" />
                          <ListItemSecondaryAction>
                            <Radio
                              color="primary"
                              name="calendarSetting"
                              value=""
                              checked={this.state.selectedSetting === ""}
                              onChange={this.handleBookingConfirmationChange}
                            />
                          </ListItemSecondaryAction>
                        </ListItem>
                        <ListItem>
                          <ListItemText primary="Send email and SMS for all customers" />
                          <ListItemSecondaryAction>
                            <Radio
                              color="primary"
                              name="calendarSetting"
                              value="all"
                              checked={this.state.selectedSetting === "all"}
                              onChange={this.handleBookingConfirmationChange}
                            />
                          </ListItemSecondaryAction>
                        </ListItem>
                        <ListItem>
                          <ListItemText primary="Send email only, do not send SMS" />
                          <ListItemSecondaryAction>
                            <Radio
                              color="primary"
                              name="calendarSetting"
                              value="emailOnly"
                              checked={this.state.selectedSetting === 'emailOnly'}
                              onChange={this.handleBookingConfirmationChange}
                            />
                          </ListItemSecondaryAction>
                        </ListItem>
                        <ListItem>
                          <ListItemText primary="Send SMS only, do not send email" />
                          <ListItemSecondaryAction>
                            <Radio
                              color="primary"
                              name="calendarSetting"
                              value="smsOnly"
                              checked={this.state.selectedSetting === 'smsOnly'}
                              onChange={this.handleBookingConfirmationChange}
                            />
                          </ListItemSecondaryAction>
                        </ListItem>
                        <ListItem>
                          <ListItemText primary="Send by email but if not found, send SMS" />
                          <ListItemSecondaryAction>
                            <Radio
                              color="primary"
                              name="calendarSetting"
                              value="preferedEmail"
                              checked={this.state.selectedSetting === 'preferedEmail'}
                              onChange={this.handleBookingConfirmationChange}
                            />
                          </ListItemSecondaryAction>
                        </ListItem>
                        <ListItem>
                          <ListItemText primary="Send by SMS but if no mobile number, send email" />
                          <ListItemSecondaryAction>
                            <Radio
                              color="primary"
                              name="calendarSetting"
                              value="preferedSMS"
                              checked={this.state.selectedSetting === 'preferedSMS'}
                              onChange={this.handleBookingConfirmationChange}
                            />
                          </ListItemSecondaryAction>
                        </ListItem>
                        <ListItem>
                          <ListItemText primary={`Send confirmation ${this.state.confirmationHour} hours before booking`} />
                          <ListItemSecondaryAction>
                            <FormControl variant="outlined" style={{ width: "100px" }}>
                              <Select
                                // name="inventoryService"
                                placeholder="Select Confirmation Before"
                                name="confirmationHour"
                                value={this.state.confirmationHour}
                                style={{
                                  textAlign: 'center',
                                  paddingLeft: '0',
                                }}
                                inputProps={{
                                  style: {
                                    textAlign: 'center',
                                  },
                                }}
                                onChange={(e) => this.handleChangeConfirmationHour(e)}
                              >

                                <MenuItem value="">
                                  <em>Select Confirmation Before</em>
                                </MenuItem>
                                <MenuItem value={24}>{"24 hrs"}</MenuItem>
                                <MenuItem value={48}>{"48 hrs"}</MenuItem>
                                <MenuItem value={72}>{"72 hrs"}</MenuItem>
                              </Select>
                            </FormControl>
                          </ListItemSecondaryAction>
                        </ListItem>

                        {this.state.loadingConfirmation ? (
                          <Button
                            variant="contained"
                            color="primary"
                            style={{ float: "right", marginTop: "20px" }}
                            onClick={this.handleSubmitConfirmation}
                            disabled={true}
                          >
                            Update Booking Reminder
                          </Button>
                        ) : (
                          <Button
                            variant="contained"
                            color="primary"
                            style={{ float: "right", marginTop: "20px" }}
                            onClick={this.handleSubmitConfirmation}
                          //disabled={!this.state.selectedSetting}
                          >
                            Update Booking Reminder
                          </Button>
                        )}
                      </List>
                    </div>
                  </div>
                </CardBox>
              )
            }


            {
              // featureServer && (
              <CardBox heading="Calendar Settings">
                {this.state.loading && (
                  <div style={{ position: "relative", top: "15px" }}>
                    <LinearProgress
                      color="secondary"
                    // variant="determinate"
                    />
                  </div>
                )}
                <div className="row">
                  <div className="col-md-12 col-12">
                    <List className="LawaList">
                      <ListItem>
                        <ListItemText primary="Show Booking Total" />
                        <ListItemSecondaryAction>
                          <Switch
                            color="primary"
                            name="bookingTotal"
                            checked={bookingTotal}
                            onChange={this.handleCalendarChange}
                          />
                        </ListItemSecondaryAction>
                      </ListItem>
                      <ListItem>
                        <ListItemText primary="Show Customer Name" />
                        <ListItemSecondaryAction>
                          <Switch
                            color="primary"
                            name="customerName"
                            checked={customerName}
                            onChange={this.handleCalendarChange}
                          />
                        </ListItemSecondaryAction>
                      </ListItem>
                      <ListItem>
                        <ListItemText primary="Show Customer Address" />
                        <ListItemSecondaryAction>
                          <Switch
                            color="primary"
                            name="customerAddress"
                            checked={customerAddress}
                            onChange={this.handleCalendarChange}
                          />
                        </ListItemSecondaryAction>
                      </ListItem>

                      <ListItem>
                        <ListItemText primary="Show Pet Name" />
                        <ListItemSecondaryAction>
                          <Switch
                            color="primary"
                            name="petName"
                            checked={petName}
                            onChange={this.handleCalendarChange}
                          />
                        </ListItemSecondaryAction>
                      </ListItem>

                      <ListItem>
                        <ListItemText primary="Show Pet Breed" />
                        <ListItemSecondaryAction>
                          <Switch
                            color="primary"
                            name="petBreed"
                            checked={petBreed}
                            onChange={this.handleCalendarChange}
                          />
                        </ListItemSecondaryAction>
                      </ListItem>

                      <ListItem>
                        <ListItemText primary="Show Services Name" />
                        <ListItemSecondaryAction>
                          <Switch
                            color="primary"
                            name="serviceName"
                            checked={serviceName}
                            onChange={this.handleCalendarChange}
                          />
                        </ListItemSecondaryAction>
                      </ListItem>

                      <div>
                        <h5>Preview</h5>
                      </div>
                      {(bookingTotal ||
                        customerName ||
                        customerAddress ||
                        petName || petBreed ||
                        serviceName) && (
                          <Card
                            style={{
                              padding: "20px",
                              backgroundColor: "#3f51b5",
                              color: "#fff",
                              width: "400px",
                            }}
                          > {bookingTotal && <div>$Total Cost</div>}
                            {customerName && <div>Firstname Lastname</div>}
                            {customerAddress && <div>Street Address, Suburb, Postcode</div>}
                            {petName && <div>Pet Name</div>}
                            {petBreed && <div>Pet Breed</div>}
                            {serviceName && <div>Services</div>}
                          </Card>
                        )}
                    </List>
                    {this.state.loading ? (
                      <Button
                        variant="contained"
                        color="primary"
                        style={{ float: "right", marginTop: "10px" }}
                        onClick={this.handleSubmitCalendar}
                        disabled={true}
                      >
                        Update Calendar Settings
                      </Button>
                    ) : (
                      <Button
                        variant="contained"
                        color="primary"
                        style={{ float: "right", marginTop: "10px" }}
                        onClick={this.handleSubmitCalendar}
                        disabled={
                          bookingTotal ||
                            customerName ||
                            customerAddress ||
                            petName || petBreed ||
                            serviceName
                            ? false
                            : true
                        }
                      >
                        Update Calendar Settings
                      </Button>
                    )}
                  </div>
                </div>
              </CardBox>
              // )
            }
          </div>
          <NotificationContainer />
        </div>
      );
    }
  }
}

export default Settings;
