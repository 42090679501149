import React, { useState } from "react";
import AuthImage from "./../assets/images/authenticator.png";
import { Box, Button, Container, TextField, styled } from "@material-ui/core";
import { httpClient } from "appUtility/Api";
import {
  NotificationContainer,
  NotificationManager,
} from "react-notifications";
import qs from "qs";

const AuthDiv = styled("div")(({ theme }) => ({
  textAlign: "center",
  padding: "40px 0",
  width: "100%",
}));

const TwoFaAuth = (props) => {
  const [code, setCode] = useState("");
  const [loading, setLoading] = useState(false);

  // console.log('location', window.location);
  // console.log('2fa props', props)

  const handleSubmit = () => {
    // console.log('submit 2fa');

    let login = localStorage.getItem("login");
    let loginData = JSON.parse(login);

    let param = {
      appID: loginData && loginData.aid,
      companyID: loginData && loginData.cid,
      memberID: loginData && loginData.uid,
      code: code,
      ...(props.location.state && props.location.state.uniqueCode && { userSecret: props.location.state.uniqueCode })
    };
    

    setLoading(true);
    httpClient
      .post(
        "2factorauthentication.php",
        qs.stringify({
          action: "verify",
          source: param,
        })
      )
      .then(({ data }) => {
        if (data.success === true) {
            NotificationManager.success(data.message);
          setLoading(false);
          setTimeout(() => {
            document.location.href = "/app/dashboard/overview";
          }, [1500]);
        } else {
          setLoading(false);
          NotificationManager.error(data.message);
        }
      });
  };

  return (
    <AuthDiv>
      <Container>
        <Box>
          <img
            src={AuthImage}
            at="google_authenticator"
            style={{ width: "200px", height: "200px", objectFit: "cover" }}
          />
        </Box>
        <Box>
          <p>Securing your app with two-factor authentication.</p>
        </Box>
        <Box mt={2} mb={2}>
          <TextField
            name="code"
            value={code}
            onChange={(e) => setCode(e.target.value)}
            labe="Code"
            variant="outlined"
            placeholder="Enter the Generated Code"
          />
        </Box>
        <Box>
          <Button
            color="primary"
            variant="contained"
            onClick={() => handleSubmit()}
            disabled={loading}
          >
            {loading ? "Verifying ..." : "Verify Code"}
          </Button>
        </Box>
      </Container>

      <NotificationContainer />
    </AuthDiv>
  );
};

export default TwoFaAuth;
