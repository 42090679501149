import React from 'react';
import {Redirect, Route, Switch} from 'react-router-dom';
import asyncComponent from '../../../util/asyncComponent';

const Incomes = ({match}) => (
  <div className="app-wrapper">
    <Switch>
      <Redirect exact from={`${match.url}/`} to={`${match.url}/overview`}/>
      <Route path={`${match.url}/overview`} component={asyncComponent(() => import('./routes/Overview'))}/>
      <Route path={`${match.url}/addincome`} component={asyncComponent(() => import('./routes/AddIncome'))}/>
      <Route path={`${match.url}/incomeCategories`} component={asyncComponent(() => import('./routes/IncomeCategories/index'))}/>
      <Route path={`${match.url}/listincome`} component={asyncComponent(() => import('./routes/ListIncome/index'))}/>
	  <Route path={`${match.url}/listrecurringincome`} component={asyncComponent(() => import('./routes/ListRecurringIncome/index'))}/>
	  <Route path={`${match.url}/editrecurringincome`} component={asyncComponent(() => import('./routes/EditRecurringIncome/index'))}/>
	  <Route path={`${match.url}/addincomecategory`} component={asyncComponent(() => import('./routes/AddIncomeCategory/index'))}/>
      <Route component={asyncComponent(() => import('app/routes/extraPages/routes/404'))}/>
    </Switch>
  </div>
);

export default Incomes;