import React from 'react';
import {Redirect, Route, Switch} from 'react-router-dom';
import asyncComponent from '../../../util/asyncComponent';

const Expenses = ({match}) => (
  <div className="app-wrapper">
    <Switch>
      <Redirect exact from={`${match.url}/`} to={`${match.url}/overview`}/>
      <Route path={`${match.url}/overview`} component={asyncComponent(() => import('./routes/Overview'))}/>
      <Route path={`${match.url}/addexpense`} component={asyncComponent(() => import('./routes/AddExpense'))}/>
      <Route path={`${match.url}/expenseCategories`} component={asyncComponent(() => import('./routes/ExpenseCategories/index'))}/>
      <Route path={`${match.url}/listexpense`} component={asyncComponent(() => import('./routes/ListExpense/index'))}/>
	  <Route path={`${match.url}/listrecurringexpense`} component={asyncComponent(() => import('./routes/ListRecurringExpense/index'))}/>
	  <Route path={`${match.url}/editrecurringexpense`} component={asyncComponent(() => import('./routes/EditRecurringExpense/index'))}/>
      <Route component={asyncComponent(() => import('app/routes/extraPages/routes/404'))}/>
    </Switch>
  </div>
);

export default Expenses;