import {
  Button,
  Checkbox,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  FormControlLabel,
  FormGroup,
  IconButton,
} from "@material-ui/core";
import React, { useEffect, useState } from "react";
import { httpClient } from "../appUtility/Api";
import qs from "qs";
import { Close } from "@material-ui/icons";

const TandCDialog = (props) => {

  const [dialogDetails, setDialogDetails] = useState({
    open: props && props.open,
    accept: false,
    accept: false,
    message: "",
  });
  const [loading, setLoading] = useState(false);
  const [showButton, setShowButton] = useState(false);

  useEffect(() => {
    props.callbackTandC(dialogDetails);
  }, [dialogDetails]);

  const handleCheck = (e) => {
    setDialogDetails({
      ...dialogDetails,
      accept: e.target.checked,
    });
  };

  const handleClose = () => {
    setDialogDetails({
      ...dialogDetails,
      open: false,
    });
  };

  const handleAccept = () => {
    var params = {
      appID: props.localData.aid,
      companyID: props.localData.cid,
      memberID: props.localData.uid,
      TsandCs: dialogDetails.accept === true ? 1 : 0,
    };
    setLoading(true);
    httpClient
      .post(
        "getMyProfileandSave.php",
        qs.stringify({
          action: "tsandcs",
          source: params,
        })
      )
      .then(({ data }) => {
        if (data.status == "success") {
          setLoading(false);
          setDialogDetails({
            ...dialogDetails,
            message: data.message,
            success: true,
            open: false
          });
          setTimeout(() => {
            // props.callbackTandC(dialogDetails);
            handleClose();
          }, 1000);
        }
      });
  };


  useEffect(() => {
    const checkTime = () => {

      const now = new Date();
      const options = { timeZone: 'Australia/Sydney', year: 'numeric', month: '2-digit', day: '2-digit', hour: '2-digit', minute: '2-digit', second: '2-digit', hour12: false };
      const formatter = new Intl.DateTimeFormat('en-AU', options);
      const parts = formatter.formatToParts(now);
      const dateParts = parts.reduce((acc, part) => {
        acc[part.type] = part.value;
        return acc;
      }, {});

      const currentDate = new Date(
        `${dateParts.year}-${dateParts.month}-${dateParts.day}T${dateParts.hour}:${dateParts.minute}:${dateParts.second}+10:00` // +10:00 for AEST
      );

      const targetDate = new Date('2024-08-09T00:00:00+10:00');


      if (currentDate >= targetDate) {
        setShowButton(true);
      } else {
        setShowButton(false);
      }
    };

    checkTime();
    const intervalId = setInterval(checkTime, 60000);
    return () => clearInterval(intervalId);
  }, []);

  return (
    <Dialog
      open={dialogDetails.open}
      onClose={false}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
      maxWidth="sm"
      //   style={{ height:"725px" }}
      fullWidth
    >
      <DialogTitle className="modal-box-header bg-primary text-white">
        <span>Accept Terms and Conditions</span>
        {/* <IconButton onClick={handleClose}>
                <Close />
            </IconButton> */}
      </DialogTitle>
      <DialogContent>
        <div>
          <p align="left">
            <strong>Dear MATE user, based on advice from our Privacy Act lawyers we have been asked to make two small changes to our Terms and Conditions.
              Thanks for your understanding.
            </strong>
          </p>
        </div>
      </DialogContent>
      <DialogContent style={{ height: "580px" }}>
        <div>
          <p align="center">
            <strong>FranchiseCare Software License</strong>
            <br />
            <strong>
              Blue Wheelers Pty Ltd and associated companies Franchisee Licence{" "}
            </strong>
          </p>
          <p>
            <strong>DEFINITIONS</strong>
            <br />
            In this Agreement:
          </p>
          <p>
            {" "}
            <strong>&ldquo;Customer&rdquo; </strong>means a user of the software
            as permitted by the Franchisor or its Master Franchisee;
            <strong> &ldquo;Intellectual Property Rights&rdquo;</strong> means
            all property in the Software and rights to patents, licenses,
            trademarks, trade names, inventions, trade secrets, copyrights, and
            know-how relating to the origin, manufacture, programming, operating
            and/or servicing of the Software and any enhancements or
            modifications relating thereto; &ldquo;Site&rdquo; means the
            location at which the Software is to be used or such other location
            as the Licensor may authorise in writing; &ldquo;Software&rdquo;
            means the combination of programs and supporting documentation
            (including any manuals) including any upgrades or enhancements made
            available by the Licensor to the Customer from time to time.
          </p>
          <p>
            <strong>LICENCE</strong>
          </p>
          <p>
            {" "}
            Subject to payment of all initial and ongoing Licence Fees, the
            Licensor grants to the Customer a non-exclusive, non-transferable
            licence for the Customer to use the Software for its own internal
            business purposes and in accordance with the terms and conditions
            set out in this Agreement. The Licensor may make available to the
            Customer upgrades of the Software at prices to be determined by the
            Licensor in conjunction with the Franchisor and the Customer will be
            obliged to acquire or use such upgrades.
          </p>
          <p>
            {" "}
            The Franchisor or its representative(s) may direct the Licensor or
            its representative(s) to deny access to the Software if the Customer
            is in breach of their obligations to their Master Franchisee and or
            their Franchisor in accordance with the terms of their Franchise
            Agreement. The ongoing use of software by the customer is contingent
            on being up to date in all payments for the use of software; while
            they remain a franchisee within the Franchise System.{" "}
          </p>
          <p>
            {" "}
            All Customers agree that use of the Software and the System grants
            full and unfettered access to the database and use of the
            information therein to the Customers&rsquo; Master Franchisee and
            the Franchisor as is also proscribed in Franchise Agreements signed
            by Customers.{" "}
          </p>
          <p>
            <strong>
              PROTECTION OF LICENSOR&rsquo;S INTELLECTUAL PROPERTY RIGHTS
            </strong>
          </p>
          <p>
            {" "}
            The Customer acknowledges that the Intellectual Property Rights in
            the Software belong to the Licensor. The Customer undertakes not to
            copy, decompile, reverse engineer, translate, adapt, vary, modify,
            distribute or publish the Software except as expressly permitted by
            this Agreement. The Customer will treat all information communicated
            in confidence under or in connection with this Agreement as
            confidential unless: (i) it is published by the Licensor; or (ii)
            enters the public domain other than through breach of this Agreement
            by the Customer. The Customer covenants that it will at all times
            take all reasonable steps to ensure the non-disclosure and
            confidentiality of the Software and all matters relating thereto;
            use its best endeavours to bind its officers and employees and
            agents not to disclose, communicate, copy or reveal for any purpose
            whatsoever the Software or any part thereof except as permitted by
            this clause and the Customer accepts liability for any breach of
            this Agreement by its officers and employees and agents; not
            transfer, assign, sub-licence, charge or otherwise deal in the
            Software other than in accordance with the terms of this Agreement;
            maintain all copyright notices on all copies of the Software; and
            notify the Licensor immediately it becomes aware that any person may
            have unauthorised knowledge, possession or use of the Software. The
            Customer&rsquo;s obligation to ensure non-disclosure and
            confidentiality under this Clause will survive termination of this
            Agreement.
          </p>
          <p>
            <strong>WARRANTY</strong>
          </p>
          <p>
            {" "}
            The Licensor warrants that: it has full power and authority to grant
            the licence. To the best of its knowledge and belief, the Software
            does not infringe any copyright, trade secret or other proprietary
            right of any third party and will take all necessary action to
            protect, and indemnifies, the Customer against all costs, expenses
            and damages incurred in connection with any claim of infringement
            arising out of the Customer&rsquo;s use of the Software. The
            Software will function in accordance with its specification but the
            Customer acknowledges that the Software is of a complicated and
            technical nature and may have minor inherent defects. The Licensor
            will provide reasonable programming and remedial services to correct
            documented code errors which are caused by a defect in an unaltered
            version of the Software. This non-transferable warranty will be
            valid for a period of three months from the date of original
            delivery of the Software.
          </p>
          <p>
            <strong>LIMITATION OF LIABILITY</strong>
          </p>
          <p>
            {" "}
            The warranties above are expressly in lieu of all other
            representation, conditions or warranties (statutory, express or
            implied) and all such representations, conditions and warranties
            (except any which may not lawfully by excluded) are expressly
            excluded. Except as expressly provided above, the Licensor will
            under no circumstances be liable to the Customer under the law of
            tort, contract or otherwise (including negligence) for any loss of
            profits or any indirect or consequential loss or damage, however
            caused, arising out of or in connection with the use of the
            Software. The Licensor&rsquo;s liability to the Customer arising out
            of any claim for damages for any cause whatsoever made under this
            Agreement will under no circumstances exceed in aggregate the total
            amount of sums actually paid by the Customer to the Licensor.
          </p>
          <p>
            <strong>DEFAULT AND TERMINATION</strong>
          </p>
          <p>
            {" "}
            The Licensor may terminate this agreement and the licence granted
            forthwith by notice in writing to the Customer if the Customer: is
            in breach of any of its obligations under this Agreement and fails
            to remedy such breach within 14 days after notice from the Licensor
            requiring the breach to be remedied, or; becomes bankrupt or goes or
            is put into liquidation or has a receiver or statutory manager
            appointed of its assets or any of them or becomes insolvent, ceases
            to carry on its business or makes any composition or arrangement
            with its creditors. Within 7 days of termination of this Agreement
            the Customer will deliver to the Licensor all copies of the Software
            supplied to it by the Customer and will destroy all other copies of
            the Software possessed by it in whatever form. The Customer will
            allow the Supplier or its agents or subcontractors access to the
            Site, and provide reasonable assistance, to ensure compliance with
            this clause.
          </p>
          <p>
            <strong>GENERAL</strong>
          </p>
          <p>
            {" "}
            Any notice under this Agreement will be in writing or by facsimile
            transmission sent to the registered office or principal place of
            business (as stated herein) of the other party or to such other
            address as may be notified by either party to the other from time to
            time. Any communication by facsimile transmission will be deemed to
            be received when transmitted to the correct facsimile transmission
            address of the recipient and any communication in writing will be
            deemed to be received when left at the specified address of the
            recipient or two days following the date of posting. The Customer
            may not assign or transfer this Agreement or any of its rights or
            obligations hereunder without the prior written consent of the
            Licensor. If any provision of this Agreement is found to be invalid
            or unenforceable, the remaining provisions will continue in full
            force and effect. No waiver of any breach of this Agreement by
            either party will be effective except an express waiver in writing
            signed by the party against whom enforcement of the waiver is
            sought. A waiver of either party&rsquo;s rights or remedies will not
            constitute a waiver of any other breach of this Agreement. This
            Agreement embodies the entire understanding of the parties relating
            to the matters referred to and supersedes all other agreements and
            representation (except fraudulent misrepresentations) made by the
            parties whether oral or written.
          </p>
          <p>
            {" "}
            No amendment to this Agreement will be binding on either party
            unless confirmed in writing by both parties. This Agreement shall by
            and construed in accordance with the laws of New Zealand and
            Australia.{" "}
          </p>
        </div>
        <FormGroup>
          <FormControlLabel
            control={
              <Checkbox
                value={dialogDetails.accept}
                checked={dialogDetails.accept}
                onChange={handleCheck}
              />
            }
            label={<b>I agree with the terms and conditions.</b>}
          />
        </FormGroup>
      </DialogContent>
      <DialogActions style={{ display: "flex", alignItems: "center", justifyContent: "flex-end" }}>
        {/*  {!showButton &&
          <Button onClick={handleClose} variant="contained" color="secondary">
            Cancel
          </Button>
        }
        {!showButton && <div>Deadline: 9th August 2024</div>}*/}
        <Button
          onClick={handleAccept}
          variant="contained"
          color="primary"
          disabled={dialogDetails.accept ? false : true}
        >
          Submit
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default TandCDialog;
