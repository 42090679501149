import React from "react";
import Avatar from "@material-ui/core/Avatar";
import Menu from "@material-ui/core/Menu";
import MenuItem from "@material-ui/core/MenuItem";
import IntlMessages from "util/IntlMessages";
import featureServer from "../../appUtility/featureServer";

const loginOBJ = localStorage.getItem("login");
const logindetail = JSON.parse(loginOBJ);

class UserInfo extends React.Component {
  state = {
    anchorEl: null,
    open: false,
    openOther: false,
  };

  handleClick = (event) => {
    this.setState({ open: true, anchorEl: event.currentTarget });
  };
  handleOtherClick = (event) => {
    this.setState({ openOther: true, anchorEl: event.currentTarget });
  }
  handleOtherClose = () => {
    this.setState({ openOther: false });
  };


  handleRequestClose = (callback) => {
    this.setState({ open: false });
  };

  handleLogout() {
    window.location = "/logout";
  }
  handleSettings() {
    window.location = "/app/settings";
  }

  handlepwd() {
    window.location = "/app/changepassword";
  }

  handleUserProfile(){
    window.location = "/app/myProfile/profile"
  }

  render() {
    return (
      <>
      <div className="user-profile d-flex flex-row align-items-center">
        <Avatar
          alt={logindetail && logindetail.fname}
          src={logindetail && logindetail.img}
          className="user-avatar "
        />
        <div className="user-detail">

          <h4 className="user-name" id="user_name_1" onClick={this.handleClick}>
            {logindetail && logindetail.fname} {logindetail && logindetail.lname}
            <i className="zmdi zmdi-caret-down zmdi-hc-fw align-middle" />
          </h4>

          <h4 style={{display:"none"}} className="user-name" id="user_name_100" onClick={this.handleOtherClick}>
            {logindetail && logindetail.fname} {logindetail && logindetail.lname}
            <i className="zmdi zmdi-caret-down zmdi-hc-fw align-middle" />
          </h4>

          <span style={{ color: "#768e9d", fontSize: "12px" }}>
            SMS Credit: $<span id="divsmsCredit">{logindetail && logindetail.smscredit}</span>
          </span>
        </div>
        <Menu
          className="user-info"
          id="simple-menu_101"
          anchorEl={this.state.anchorEl}
          open={this.state.open}
          onClose={this.handleRequestClose}
          PaperProps={{
            style: {
              minWidth: 120,
              paddingTop: 0,
              paddingBottom: 0,
            },
          }}
        >
          {/*<MenuItem onClick={this.handleRequestClose}>
            <i className="zmdi zmdi-account zmdi-hc-fw mr-2"/>
            <IntlMessages id="popup.profile"/>
          </MenuItem>*/}
          {logindetail && logindetail.exMember != "1" && (
          <MenuItem onClick={this.handleRequestClose}>
            <i className="zmdi zmdi-settings zmdi-hc-fw mr-2" />
            <a onClick={this.handleSettings}>
              <IntlMessages id="popup.setting" />
            </a>
          </MenuItem>
          )}

          {/* {featureServer && ( */}
            <MenuItem onClick={this.handleRequestClose}>

              <i className="zmdi zmdi-account-box zmdi-hc-fw mr-2" />
              <a onClick={this.handleUserProfile}>
                My Profile
              </a>
            </MenuItem>
          {/* )} */}

          <MenuItem
            onClick={() => {
              this.handleRequestClose();
            }}
          >
            <i className="zmdi zmdi-key zmdi-hc-fw mr-2" />
            <a onClick={this.handlepwd}>
              <IntlMessages id="popup.changepwd" />
            </a>
          </MenuItem>
          <MenuItem
            onClick={() => {
              this.handleRequestClose();
            }}
          >
            <i className="zmdi zmdi-sign-in zmdi-hc-fw mr-2" />
            <a onClick={this.handleLogout}>
              <IntlMessages id="popup.logout" />
            </a>
          </MenuItem>
        </Menu>

        <Menu
          className="user-info"
          id="simple-menu_100"
          anchorEl={this.state.anchorEl}
          open={this.state.openOther}
          onClose={this.handleOtherClose}
          PaperProps={{
            style: {
              minWidth: 120,
              paddingTop: 0,
              paddingBottom: 0,
            },
          }}
        >
          <MenuItem
            onClick={() => {this.handleOtherClose();}}
          >
            <i className="zmdi zmdi-sign-in zmdi-hc-fw mr-2" />
            <a onClick={this.handleLogout}>
              <IntlMessages id="popup.logout" />
            </a>
          </MenuItem>
        </Menu>
      </div>
      </>
    );
  }
}

export default UserInfo;
