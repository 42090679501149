import { Button, Dialog, DialogActions, DialogContent, DialogTitle, FormControl, Grid, IconButton, InputLabel, MenuItem, Select, TextField, Tooltip } from '@material-ui/core';
import { Close } from '@material-ui/icons';
import React, { useEffect, useState } from 'react'
import {
  NotificationContainer,
  NotificationManager,
} from "react-notifications";
import { httpClient } from "appUtility/Api";
import qs from 'qs'

const InventoryDialog = (props) => {
  const loginOBJ = localStorage.getItem("login");
  const logindetail = JSON.parse(loginOBJ);

  const [dialogDetails, setDialogDetails] = useState({
    open: true,
    success: ''
  })
  const [inventoryService, setInventoryService] = useState({
    service: [''],
    useage: [''],
    weight: [''],
  });
  const [inventoryData, setInventoryData] = useState([])

  useEffect(() => {
    props.sendInventoryData(dialogDetails);
  }, [dialogDetails]);

  useEffect(() => {
    getAllInventoryItems()
  }, [])

  useEffect(() => {
    if (props.inventoryDetails.inventoryUsage.length) {
      inventoryService.service.pop();
      inventoryService.useage.pop();
      inventoryService.weight.pop();

      props.inventoryDetails.inventoryUsage.map(usage => {
        inventoryService.service.push((usage.inventoryID).toString());
        inventoryService.useage.push(usage.stockUsagePercent);
        inventoryService.weight.push(usage.weightPerItem);
      })
    }
  }, [props.inventoryDetails])

  const getAllInventoryItems = () => {
    var params = {
      appID: logindetail.aid,
      companyID: logindetail.cid,
      memberID: logindetail.uid,
    }
    httpClient.post('serviceInventoryUsages.php', qs.stringify({
      action: "getstocks",
      source: params
    })).then(({ data }) => {
      if (data) {
        setInventoryData(data)
      }
    })
  }

  //inventoryService.service[index] = value;

  const handleChangeService = (e, index) => {
    const { value } = e.target;
    const updatedService = [...inventoryService.service];
    updatedService[index] = value;

    const selectedInventory = inventoryData.find(inventory => inventory.inventoryID == value);

    const updatedWeight = [...inventoryService.weight];
    if (selectedInventory) {
      updatedWeight[index] = selectedInventory.weightPerItem;
    }

    setInventoryService({
      ...inventoryService,
      service: updatedService,
      weight: updatedWeight,
    });
  };


  // const handleChangeUseage = (e, index) => {
  //   const { name, value } = e.target;
  //   var newValue = Number(value);
  //   if (newValue >= 100) {
  //     newValue = 100;
  //   }
  //   inventoryService.useage[index] = value
  //   setInventoryService({
  //     ...inventoryService,
  //     useage: inventoryService.useage,
  //   });
  // };

  const handleChangeUseage = (e, index) => {
    var val1 = inventoryService.weight[index];
    var conversionFactor = (inventoryService.service[index] === '2' || inventoryService.service[index] === '6') ? 5 : 30;
    var val = e.target.value;
    const mlValue = val * conversionFactor;
    const percentValue = (mlValue / (1000 * val1)) * 100;

    const roundedPercentValue = parseFloat(percentValue.toFixed(2));

    const updatedUseage = [...inventoryService.useage];
    updatedUseage[index] = roundedPercentValue;

    setInventoryService({
      ...inventoryService,
      useage: updatedUseage,
    });
  };


  console.log("inventory--service", inventoryService);

  const handleKeyPress = (e, index) => {
    if (e.key === '-' || e.key === '+') {
      e.preventDefault();
    }
  }

  const handleAdd = (e, index) => {
    setInventoryService({
      ...inventoryService,
      service: [...inventoryService.service, ''],
      useage: [...inventoryService.useage, ''],
      weight: [...inventoryService.weight, ''],

    });
  };

  const handleRemove = (e, index) => {
    inventoryService.service.splice(index, 1);
    inventoryService.useage.splice(index, 1);
    inventoryService.weight.splice(index, 1);
    setInventoryService({
      ...inventoryService,
      service: inventoryService.service,
      useage: inventoryService.useage,
      weight: inventoryService.weight,
    });
  };

  const handleSave = () => {
    var newData = [];
    inventoryService.service.map((ser, index) => {
      newData[index] = { inventoryID: ser, stockUsagePercentMember: inventoryService.useage[index] }
    })

    var params = {
      appID: logindetail.aid,
      companyID: logindetail.cid,
      memberID: logindetail.uid,
      serviceID: props.inventoryDetails.serviceID,
      data: newData
    }

    httpClient.post('serviceInventoryUsages.php', qs.stringify({
      action: "updateservice",
      source: params
    })).then(({ data }) => {
      if (data.status === "success") {
        NotificationManager.success(data.message);
        setTimeout(() => {
          handleClose();
        }, 500)
      } else {
        NotificationManager.info(data.message);
      }
    })
  }


  const handleClose = () => {
    setDialogDetails({
      ...dialogDetails,
      open: false
    })
  };

  const handleBack = () => {
    handleClose();
  }
  const options = [
    // { value: 0, label: '0 pump' },
    { value: 1, label: '1 pump' },
    { value: 2, label: '2 pumps' },
    { value: 3, label: '3 pumps' },
    { value: 4, label: '4 pumps' },
    { value: 5, label: '5 pumps' },
  ];

  return (
    <Dialog
      open={dialogDetails.open}
      onClose={handleClose}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
      className="modal-box"
      maxWidth="md"
      fullWidth
    >
      <DialogTitle className='modal-box-header bg-primary text-white'>
        Service Inventory Usage
        <IconButton className="text-white" onClick={handleClose}>
          <Close />
        </IconButton>
      </DialogTitle>
      <DialogContent style={{ padding: "30px" }}>
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <InputLabel>Service</InputLabel>
            <TextField
              variant='outlined'
              type='text'
              value={props.inventoryDetails.serviceName}
              disabled
              fullWidth
            />
          </Grid>
          <Grid item xs={12}>
            <h3>Inventory Usage</h3>
          </Grid>
          <Grid item xs={12} md={6}>
            {inventoryService.service &&
              inventoryService.service.map((invent, index) => (
                <div key={index} style={{ marginBottom: "20px" }}>
                  <InputLabel>Inventory Item Name</InputLabel>
                  <FormControl variant="outlined" fullWidth>
                    <Select
                      // name="inventoryService"
                      // label="Select Inventory Item"
                      value={invent}
                      onChange={e => handleChangeService(e, index)}
                      disabled={invent === "2" || invent === "6"}
                    >
                      <MenuItem value="">
                        <em>Select Inventory Name</em>
                      </MenuItem>
                      {inventoryData &&
                        inventoryData.map(inventory => (
                          <MenuItem disabled={inventoryService.service.includes(inventory.inventoryID.toString()) && inventory.inventoryID.toString() !== invent} key={inventory.inventoryID} value={inventory.inventoryID}>
                            {inventory.inventoryName}
                          </MenuItem>
                        ))}
                    </Select>
                  </FormControl>
                </div>
              ))}
          </Grid>

          <Grid item xs={12} md={4}>
            {inventoryService.useage &&
              inventoryService.useage.map((inventUse, index) => {

                const mlValue = (inventUse / 100) * 1000 * inventoryService.weight[index];
                const conversionFactor = (inventoryService.service[index] === '2' || inventoryService.service[index] === '6') ? 5 : 30;
                const pumpsValue = Math.round(mlValue / conversionFactor);

                return (
                  <div key={index} style={{ marginBottom: "20px" }}>
                 
                    
                      {/* <InputLabel>Inventory Usage (in pumps)</InputLabel>
                      <TextField
                        variant="outlined"
                        type="number"
                        placeholder="0 pump"
                        value={pumpsValue}
                        InputLabelProps={{ shrink: true }}
                        // onChange={e => handleChangeUseage(e, index, inventUse)}
                        onChange={e => handleChangeUseage(e, index)}
                        InputProps={{ inputProps: { min: 0, max: 34, inputMode: 'numeric', pattern: '[0-9]*' } }}
                        onKeyPress={e => handleKeyPress(e, index)}
                        fullWidth
                        disabled={inventoryService.service[index] === "2" || inventoryService.service[index] === "6"}
                      /> */}

                      
                        <InputLabel>Inventory Usage (in pumps)</InputLabel>
                        <FormControl fullWidth variant="outlined" disabled={inventoryService.service[index] === "2" || inventoryService.service[index] === "6"}>
                        <Select
                          value={pumpsValue}
                          onChange={(e) => handleChangeUseage(e, index)}
                          onKeyPress={(e) => handleKeyPress(e, index)}
                          // label="Pumps"
                          // inputProps={{ min: 0, max: 5 }}
                          fullWidth
                        >
                          {options.map(option => (
                            <MenuItem key={option.value} value={option.value}>
                              {option.label}
                            </MenuItem>
                          ))}
                        </Select>
                      </FormControl>
                 
                    {/* <Tooltip title="Remove">
                      <IconButton onClick={e => handleRemove(e, index)}>
                        <Close color="primary" />
                      </IconButton>
                    </Tooltip> */}
                  </div>
                );
              })}
          </Grid>
          <Grid item xs={12} md={2}>
            {inventoryService.service &&
              inventoryService.service.map((invent, index) => (

                <div key={index} style={{ marginBottom: "50px" }}>
                  {(invent === "6" || invent === "2") ?
                    <Tooltip title="Remove">
                      <IconButton disabled onClick={e => handleRemove(e, index)}>
                        <Close color="#ffffff" />
                      </IconButton>
                    </Tooltip> :
                    <Tooltip title="Remove">
                      <IconButton onClick={e => handleRemove(e, index)}>
                        <Close color="primary" />
                      </IconButton>
                    </Tooltip>
                  }
                </div>
              ))}
          </Grid>

          <Grid item sm={12} md={12}>
            <Button variant="outlined" color="primary" onClick={handleBack}>
              Back
            </Button>

            <Button variant="outlined" color="secondary" onClick={e => handleAdd(e)} style={{ marginLeft: '10px' }}>
              Add More +
            </Button>

            <Button variant="contained" color="primary" onClick={handleSave} style={{ float: 'right' }}>
              Save
            </Button>
          </Grid>
        </Grid>
      </DialogContent>
      {/* <DialogActions>
          <Button onClick={handleClose} autoFocus>
            Cancel
          </Button>
        </DialogActions> */}
      {/* <NotificationContainer/> */}
    </Dialog>
  )
}

export default InventoryDialog