import React, { useState, useEffect } from "react";
import {
    Dialog,
    DialogContent,
    DialogContentText,
    DialogActions,
    Button,
} from "@material-ui/core";
import featureServer from "../../../../../appUtility/featureServer";

const FirstMessage = (props) => {
    const [details, setDetails] = useState({
        open: props && props.open,
    });


    useEffect(() => {
        props.sendTo(details);
    }, []);

    const handleDismiss = () => {
        setDetails((prevDetails) => ({
            ...prevDetails,
            open: false,
        }));
    };

    const handleDismissForever = () => {
        setDetails((prevDetails) => ({
            ...prevDetails,
            open: false,
        }));
        localStorage.setItem("neveropenfirstmessage", JSON.stringify("true"));
    };

    return (
        <Dialog maxWidth="l" open={details.open} onClose={handleDismiss}>
            <div className="p-2">
                <DialogContent>
                    {/* {featureServer && ( */}
                    <DialogContentText style={{ color: "#444" }}>
                        {/* <h1>Introducing Inventory Usage Tracking: A New Feature to Optimize Your Inventory Management</h1>

                            <p>We're thrilled to introduce a new inventory management feature!</p>

                            <p>Designed to streamline and enhance your inventory control, this advanced tool offers comprehensive monitoring of your inventory levels, utilization, and replenishment needs.</p>

                            <h2>Key Benefits:</h2>
                            <ul>
                                <li><strong>Cost Reduction:</strong> Prevent overstocking or understocking to lower inventory costs.</li>
                                <li><strong>Improved Accuracy:</strong> Keep precise track of inventory movements and adjustments.</li>
                                <li><strong>Enhanced Efficiency:</strong> Optimize inventory replenishment and allocation for better performance.</li>
                                <li><strong>Increased Visibility:</strong> Generate detailed reports and dashboards for a clearer inventory overview.</li>
                            </ul>

                            <p>This feature will be automatically activated on <strong>[activation date]</strong>. To start, we recommend conducting a one-time "Stock Take" to assess your current inventory, helping to accurately forecast future replenishment.</p>

                            <h2>Customization Options:</h2>
                            <ul>
                                <li>Set up and personalize your inventory usage for each service type through the 'Inventory Usage' section in the setup menu.</li>
                                <li>Default values provided by the head office can be adjusted to fit your specific needs.</li>
                            </ul>

                            <p>We believe this feature will significantly aid in your inventory management efforts. Enjoy the new capabilities it brings to your business!</p> */}

                        <p>To protect our MATE data security</p>
                        <p>Two Factor Authentication is now required for all MATE logins.</p>
                        <p>How to instructions can be found under your</p>
                        <p>MATE profile and also on the Dashboard.</p>
                        <p><strong style={{color: "red"}}>Its mandatory to have 2FA enabled from {featureServer ? `1st of Feb 2024` : `Wednesday 7th of Feb 2024`}. You will then not be able to proceed to use Mate unless 2FA is activated.</strong></p>
                        <p>Please contact Sharon (<a href="tel:+61434466175" style={{color: "blue"}}>0434 466 175</a>) if you have any further queries.</p>



                    </DialogContentText>
                    {/* )} */}

                    {/* ) : (
                        <DialogContentText style={{ color: "#444" }}>
                            <h3>Introducing Inventory Usage Tracking: A New Feature to Optimize Your Inventory Management</h3>

                            <p>We are excited to announce that we have added a new feature to our Mate system that helps you track and manage your inventory usage and orders. Inventory usage tracking is a powerful tool that allows you to monitor how much inventory you are using, how much inventory you have left, and how much inventory you need to order.</p>

                            <p><strong>Inventory usage tracking can help you:</strong></p>
                            <ul>
                                <li>Reduce inventory costs by avoiding overstocking or understocking.</li>
                                <li>Improve inventory accuracy by tracking inventory movements and adjustments.</li>
                                <li>Increase inventory efficiency by optimizing inventory replenishment and allocation.</li>
                                <li>Enhance inventory visibility by generating inventory usage reports and dashboards.</li>
                            </ul>

                            <p>This feature will be enabled on <strong>8th of January 2024</strong> automatically and you don’t have to do anything. Once enabled, it will suggest you make a one time “Stock Take” to estimate how much inventory you currently have, to predict your next refill timeline.</p>

                            <p>You can configure your inventory usage per service type via <em>setup - Inventory usage</em>. This will have default values set by head office which you are free to optimize as per your needs.</p>

                            <p>We hope you enjoy this new feature and find it useful for your inventory management.</p>
                        </DialogContentText>

                    )
                    } */}
                </DialogContent>
                <DialogActions>
                    <Button variant="contained" color="primary" onClick={handleDismiss}>
                        Dismiss For Now
                    </Button>
                    {/* <Button variant="contained" color="primary" onClick={handleDismissForever}>
                        Dismiss Forever
                    </Button> */}
                </DialogActions>
            </div>
        </Dialog>
    );
};

export default FirstMessage;
