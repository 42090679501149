import React, { Component } from "react";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
// import GreedImage from "./GridImage";
import Auxiliary from "util/Auxiliary";
import CustomScrollbars from "../../../util/CustomScrollbars";
import CloseIcon from "@material-ui/icons/Close";
import {
  Backdrop,
  Card,
  Fade,
  IconButton,
  withStyles,
  Modal,
  Tooltip,
} from "@material-ui/core";

import ImagePost from "./ImagePost";
import GridImage from "./GridImage";
import { RotateLeft, RotateRight } from "@material-ui/icons";
import { httpClient } from "../../../appUtility/Api";
import { NotificationManager } from "react-notifications";

const useStyles = (theme) => ({
  root: {
    backgroundColor: "#fff",
    width: "100%",
    height:"100%",
    borderRadius:"0",
    zIndex:"111",
    "& .MuiCard-root":{
      height:"100%",
    }
  },
  gridpic: {
    height: "100px",
    width: "100%",
    objectFit: "cover",
    cursor: "pointer",
  },
  modal: {
    padding: "0 50px",
    paddingTop: "40px",
    [theme.breakpoints.down("sm")]: {
      padding: "0 35px",
      paddingTop: "20px",
    },
  },
  sliderComponent: {
    position: "relative",
    padding: "0 100px",
    // width: "50% !important",
    width: "80% !important",
    display: "flex !important",
    justifyContent: "center",
    margin: "auto",
    [theme.breakpoints.down("sm")]: {
      width: "100% !important",
      padding: "0",
    },
  },
  scrollbar: {
    height: "90vh !important",
  },
  imgSlider: {
    height: "400px !important",
    width: "400px !important",
    objectFit: "cover",
    margin: "0 auto",
  },
  slick_slider: {
    "& .slick-prev": {
      zIndex: "111",
    },
    "& .slick-prev:before": {
      color: "#f1f1f1 !important",
      fontSize: "32px",
    },
    "& .slick-next": {
      zIndex: "111",
    },
    "& .slick-next:before": {
      color: "#f1f1f1 !important",
      fontSize: "32px",
    },
  },
  closeIcon: {
    color: "#fff",
    position: "absolute",
    top: "0",
    right: "60px",
    [theme.breakpoints.down("sm")]: {
      zIndex: "1",
      right: "0",
    },
  },
  sliderImage:{
    position:"relative",
    textAlign: "center"
  },
  rotateIcons: {
    position:"absolute",
    zIndex:"1",
    width:"100%",
    "& svg":{
      color:"#f1f1f1"
    }
  },
  mainGallery: {
    zIndex:"1"
  }
});

const qs = require("qs");
const socialData = JSON.parse(window.localStorage.getItem("login"));

class MediaList extends Component {
  state = {
    showCarousel: false,
    imageData: [],
    data: [],
    mediaListData: [],
  };

  componentDidMount(){
    if(this.props.mediaList[0]){
    var allList = this.props.mediaList[0] && this.props.mediaList.map(media => ({
      ...media,
      rotation: 0
    }))

    this.setState({
      ...this.state,
      mediaListData: allList
    })

    }
  }


  handleSingleCarouselClose = () => {
    this.setState({
      showCarousel: false,
    });
  };

  handleCarouselOpen = (image) => {
    this.setState({
      showCarousel: true,
      imageData: image,
    });
  };

  handleCarouselClose = () => {
    this.setState({
      showCarousel: false,
    });
  };

  handleToggle = (val) => {
    this.setState((previousState) => ({
      showCarousel: previousState.showCarousel ? false : true,
      data: val,
    }));
  };

  handleRotateRight = (image) => {
    let newRotation = image.rotation + 90;
    if(newRotation >= 360){
      newRotation =- 360;
    }
    this.state.mediaListData.map(media => {
      if(media.imageSequence === image.imageSequence){
         media.rotation = newRotation
      }
    })

    this.setState({
      ...this.state,
      mediaListData: this.state.mediaListData
    })
  }
 
  handleRotateLeft = (image) => {
    let newRotation = image.rotation - 90;
    if(newRotation >= 360){
      newRotation =- 360;
    }
    this.state.mediaListData.map(media => {
      if(media.imageSequence === image.imageSequence){
         media.rotation = newRotation
      }
    })

    this.setState({
      ...this.state,
      mediaListData: this.state.mediaListData
    })
  }

  handleDeleteImage = (postID, imageID) => {
    const PostQuery = {
      userID: socialData && socialData.uid,
      userCode: socialData && socialData.memtoken,
      appID: 1,
      companyID: socialData && socialData.cid,
      postID: postID,
      imageID: imageID,
    };

    httpClient
      .post(
        "api/social/posts_image_save.php",
        qs.stringify({
          action: "image_delete",
          source: PostQuery,
        })
      )
      .then((res) => {
        if (res.data.success !== "true") {
          NotificationManager.warning(
            "Sorry couldn't delete posts right now !"
          );
        } else {
          NotificationManager.success(res.data.msg);
          var res = this.state.mediaListData.filter(media => {
            return media.imageID !== res.data.imageID
          })

          this.setState({
            ...this.state,
            mediaListData: res
          })

          this.handleCarouselClose();
        }
      });
  };

  render() {
    const { classes } = this.props;
    const settings = {
      dots: false,
      infinite: false,
      autoplay: false,
      speed: 500,
      slidesToShow: 1,
      slidesToScroll: 1,
      initialSlide: this.state.data.imageSequence
        ? parseInt(
            this.state.data.imageSequence || this.state.data.sequence,
            10
          ) - 1
        : this.state.data,
    };

    // console.log("props in medialist >>>>>>>>>>>>", this.props);
    console.log('medialisrt state ??', this.state);

    return (
      <>
        {this.state.mediaListData.length > 0 && (
          <Auxiliary>
            <GridImage
              mediaList={this.state.mediaListData && this.state.mediaListData}
              handleToggle={this.handleToggle}
            />

            <Modal
              className={classes.modal}
              open={
                this.props.showCarousel
                  ? this.props.showCarousel
                  : this.state.showCarousel
              }
              onClose={this.handleCarouselClose}
              closeAfterTransition
              BackdropComponent={Backdrop}
              BackdropProps={{
                timeout: 500,
              }}
            >
              <Fade
                in={
                  this.props.showCarousel
                    ? this.props.showCarousel
                    : this.state.showCarousel
                }
              >
                <div>
                  <Slider {...settings} className={classes.slick_slider}>
                    {this.state.mediaListData &&
                      this.state.mediaListData.map((image, index) => (
                        (image != "") &&
                        <div
                          className={classes.sliderComponent}
                          key={image.imageID}
                        >
                          <IconButton
                            className={classes.closeIcon}
                            onClick={this.handleCarouselClose}
                          >
                            <CloseIcon />
                          </IconButton>
                          <CustomScrollbars className={classes.scrollbar}>
                            <div className="grid-3-1">
                                <div className={classes.sliderImage}>
                                  <div className={classes.rotateIcons}>
                                    <Tooltip title="Rotate Left">
                                      <IconButton onClick={() => this.handleRotateLeft(image)}>
                                        <RotateLeft />
                                      </IconButton>
                                    </Tooltip>

                                    <Tooltip title="Rotate Right">
                                      <IconButton onClick={() => this.handleRotateRight(image)}>
                                        <RotateRight />
                                      </IconButton>
                                    </Tooltip>
                                  </div>
                                    <img
                                      src={image.imageURL.replace("&h=600&w=600","&h=1000&w=1000") ||image.url.replace("&h=600&w=600","&h=1000&w=1000")}
                                      alt={image.imageURL.replace("&h=600&w=600","&h=1000&w=1000") ||image.url.replace("&h=600&w=600","&h=1000&w=1000")}
                                      // className={classes.imgSlider}
                                      style={{transform: `rotate(${image.rotation}deg)`}}
                                      className="img-fluid"
                                    />
                                </div>
                                <div className={classes.mainGallery}>
                                    <Card className={classes.root}>
                                    {/* LIKE COMMENT */}

                                    <ImagePost
                                      key={image.imageID}
                                      index={image.postID}
                                      postData={image}
                                      userProfile={image}
                                      imageData={this.state.mediaListData}
                                      getSinglePost={this.props.getSinglePost}
                                      handleDeleteImage={this.handleDeleteImage}
                                    />
                                  </Card>
                                </div>
                            </div>
                          </CustomScrollbars>
                          
                        </div>
                      ))}
                  </Slider>
                </div>
              </Fade>
            </Modal>
          </Auxiliary>
        )}
      </>
    );
  }
}

export default withStyles(useStyles)(MediaList);
