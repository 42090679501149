import React, { Component } from "react";
import ReactDOM from "react-dom";
import { httpClient } from "appUtility/Api";
//import Snackbar from "react-native-snackbar";
//import Snackbar from '@material-ui/core/Snackbar';
import {
  NotificationContainer,
  NotificationManager,
} from "react-notifications";
import App from "../containers/App";
import asyncComponent from "util/asyncComponent";
//import authUserState from 'components/signIn'
//import {Actions} from 'react-native-router-flux'
// import localforage from 'localforage';

const qs = require("qs");

var param;
//const param = 3;
//this.setState = {param:3 }
export const GetUserVerify = (uname) => {
  //return param;
  //

  if (uname.email != "" && uname.password != "") {
    NotificationManager.info("Verifying User Detail.");
  }

  httpClient
    .post(
      "login.php",
      qs.stringify({
        action: "verify_login",
        source: uname,
      })
    )
    .then(({ data }) => {
      if (data.verified != "success") {
        NotificationManager.error(data);
      } else {
        //Now clear all local storage elements because new values are going to be downloaded soon.
        localStorage.clear();
        localStorage.setItem("login", JSON.stringify(data));

        //Indexed Db Api
        // localforage.setItem('login',data);

        NotificationManager.success("Logged in Successfully.");
        setTimeout(function() {
          document.location.href = "/app/dashboard/overview";
        }, 1000);
      }
    });
};
