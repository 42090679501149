import React, { Component } from "react";
import Avatar from "@material-ui/core/Avatar";
import Button from "@material-ui/core/Button";
import { Card, Modal, ModalHeader } from "reactstrap";
import TextField from "@material-ui/core/TextField";
import Dropzone from "react-dropzone";
import Divider from "@material-ui/core/Divider";
import WidgetHeader from './../../WidgetHeader';
import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.snow.css';


const socialData = JSON.parse(window.localStorage.getItem("login"));

class WriteBox extends Component {
  state = {
    post_text: "",
    previewVisible: false,
    previewImage: "",
    fileList: [],
    rejected: [],
    isOpen: false,
    profileImage: socialData && socialData.img,
    name: socialData && socialData.fname + " " + socialData.lname,
  };


  handleCancel = () => this.setState({ previewVisible: false });

  handlePreview = (file) => {
    console.log("previewImage", file);
    this.setState({
      previewImage: file.url || file.thumbUrl,
      previewVisible: true,
    });
  };

  onDrop = (files) => {
    console.log("file-->", files);
    var imgArr = files.map((img, index) => {
      return img;
    });
    if (this.state.fileList.length > 0) {
      this.setState({
        fileList: imgArr.concat(this.state.fileList),
      });
    } else {
      this.setState({
        fileList: imgArr,
      });
    }
  };

  handleRemoveImage = (file) => {
    var imgArr = (this.state.fileList).filter((list, index) => {
      return (
        list !== file
      );
    });
    this.setState({ fileList: imgArr });
  };

  handleChange = ({ fileList }) => {
    // console.log("fileList", fileList);
    this.setState({ fileList });
  };

  handleClickImage() {
    this.setState((previousState) => ({
      isOpen: !previousState.isOpen,
    }));
  }

  handleAddPost() {
    if (this.state.post_text !== "") {
      this.props.addPost(
        this.state.post_text,
        this.state.fileList
      );
      this.setState({
        post_text: "",
        previewVisible: false,
        previewImage: "",
        fileList: [],
        isOpen: false,
      });
    }
  }

  onChange(value) {
    this.setState({ post_text: value });
  }

  render() {
    const { previewVisible, previewImage, fileList } = this.state;
    const isEnabled = fileList.length === 0 && this.state.post_text === "";


    return (
      <>
        {/* <WidgetHeader title="Latest Posts" /> */}
        <Card className="jr-card">
          <div className="media mb-3">
            <Avatar
              className="size-50 mr-3"
              src={this.state.profileImage}
              alt={this.state.name}
            />
            <div className="media-body">
              {/* <TextField
              id="exampleTextarea"
              placeholder="Start a Post..."
              label="Start a Post..."
              value={
                   this.state.post_text
              }
              onChange={(e) => this.onChange(e)}
              multiline
              fullWidth
              className="jr-wall-textarea"
              margin="none"
              variant="outlined"
              rows="4"
            /> */}
              <ReactQuill
                value={this.state.post_text}
                onChange={(value) => this.onChange(value)}
                placeholder="Start a Post..."
                modules={{
                  toolbar: [
                    ['bold', 'italic', 'underline', 'strike'],
                    [{ 'header': 1 }, { 'header': 2 }],
                    [{ 'list': 'ordered' }, { 'list': 'bullet' }],
                    ['link'],
                    // ['image'],
                    ['clean'],
                  ],
                }}
                formats={[
                  'header',
                  'bold', 'italic', 'underline', 'strike',
                  'link', 
                  // 'image',
                  'list', 'bullet',
                ]}
              />
            </div>
          </div>

          <div className="jr-clearfix">
            {this.state.isOpen === true ? (
              <div className="d-flex flex-row">
                <ul className="list-inline mb-3 mr-2">
                  {fileList.map((user, index) => (
                    <li
                      className="mb-1 mr-0 list-inline-item align-top relative"
                      key={index}
                    >
                      <img
                        alt="..."
                        className="size-60 rounded"
                        src={user.preview}
                      />
                      <span
                        className="cross_icon"
                        onClick={() => this.handleRemoveImage(user)}
                      >
                        <i className="zmdi zmdi-close" />
                      </span>
                    </li>
                  ))
                  }
                </ul>
                <Dropzone
                  className="jr-wall-dropzone"
                  onDrop={this.onDrop}
                  multiple
                >
                  <i className="zmdi zmdi-collection-image" />
                </Dropzone>
              </div>
            ) : null}
            <Divider />

            <Modal isOpen={previewVisible} toggle={this.handleCancel}>
              <ModalHeader toggle={this.toggle}>Slide Show</ModalHeader>
              <img alt="example" style={{ width: "100%" }} src={previewImage} />
            </Modal>
          </div>

          <div className="d-flex flex-row align-items-center mt-2">
            <div className="pointer" onClick={this.handleClickImage.bind(this)}>
              <i className="zmdi zmdi-camera mr-2 jr-fs-xl d-inline-flex align-middle" />
              <span className="jr-fs-sm"> Add Photos/Album </span>
            </div>

            <Button
              color="primary"
              size="small"
              className="ml-auto mb-0"
              // disabled={isEnabled ? "disabled" : ""}
              disabled={isEnabled ? true : false}
              onClick={this.handleAddPost.bind(this)}
            >
              POST
            </Button>
          </div>
        </Card>
      </>
    );
  }
}

export default WriteBox;
