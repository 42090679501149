import React from 'react';

import ContainerHeader from 'components/ContainerHeader/index';
import CardBox from 'components/CardBox/index';
import TextField from '@material-ui/core/TextField';
import Button from '@material-ui/core/Button';
import {NotificationContainer, NotificationManager} from 'react-notifications';
import {httpClient} from 'appUtility/Api';

import IntlMessages from 'util/IntlMessages';
//import { decompress }  from 'lz-string';

// import Input from '@material-ui/core/Input';
// import InputLabel from '@material-ui/core/InputLabel';
import FormControl from '@material-ui/core/FormControl';
// import FormHelperText from '@material-ui/core/FormHelperText';
// import Select from '@material-ui/core/Select';
// import FormControlLabel from '@material-ui/core/FormControlLabel';
// import FormGroup from '@material-ui/core/FormGroup';

let loginOBJ;
let logindetail;


//if(localStorage.getItem('login')!=''){
// loginOBJ = decompress(localStorage.getItem('login'));
//}else{
loginOBJ = localStorage.getItem('login');

if(loginOBJ!==''){
logindetail = JSON.parse(loginOBJ);}

const qs = require('qs');

// var param;


class ChangePassword extends React.Component {

constructor(props) {
    super(props);
    this.state = {
      error: null,
      isLoaded: true,
	  id:'',
	  oldpassword:'',
	  newpassword:'',
	  confirmpassword:'',
 	  appID:logindetail.aid,
	  memberID:logindetail.uid,
	  companyID:logindetail.cid,
	  username:logindetail.uname,
	  mcode:logindetail.mCode,
    };
	
this.handleChange = this.handleChange.bind(this);
	
  }	
	
handleChangeClick() {

	//alert(this.state.incometemplate);
	if(this.state.oldpassword==='' && this.state.newpassword==='' && this.state.confirmpassword==='')
		{
 			   NotificationManager.info("All Fields are Mandatory");
 		}
	else if(this.state.newpassword !== this.state.confirmpassword){
			NotificationManager.info("New Password and Confirm New Password does not match");
			this.setState({
						oldpassword:'',
	  					newpassword:'',
	  					confirmpassword:''
 					});
			this.form.reset();
		}
	else if(this.state.newpassword === this.state.oldpassword){
			NotificationManager.info("Current Password and New Password match. No Change Required");
			this.setState({
						oldpassword:'',
	  					newpassword:'',
	  					confirmpassword:''
 					});
			this.form.reset();
		}
		else {
			//console.log(this.state);
 	
			 httpClient.post('saveChangePassword.php',
				  qs.stringify({
					action: "save_change_password",
					source: this.state
				  })	
							)
			.then(({data}) => {
				 if(data.success!=='true')
					 {
						 
						 NotificationManager.info(data.failedMSG);
						this.setState({
						oldpassword:'',
	  					newpassword:'',
	  					confirmpassword:''
 					});
						 this.form.reset();
					 }
				 else
				 {
					NotificationManager.info(data.successMSG);
					this.setState({
						oldpassword:'',
	  					newpassword:'',
	  					confirmpassword:''
 					});
					this.form.reset();
 				 }
 			},
		  // error handler
        (error) => {
          NotificationManager.info('Error Occured: Refresh the page and try again');
        })
  		}
	
   }	
	
handleChange (evt) {
     this.setState({ [evt.target.name]: evt.target.value });
  }
/*
componentDidMount()
{
 		var aid,cid,uid;
		aid = logindetail.aid;
		cid = logindetail.cid;
		uid = logindetail.uid;
	
		param=aid+"_"+cid+"_"+uid;

	httpClient.post('saveChangePassword.php',
		  qs.stringify({
			action: "member_password",
			source: param
		  })	
					)
	.then(({data}) => {
 		 if(data)
			 {
				 this.setState({
					isLoaded: true,
					//id: JSON.parse(data).id,
					//settingdetail:JSON.parse(data),
					 passdetail:JSON.parse(data),
					//incometemplate:JSON.parse(data).title,
	  				//incomedescription:JSON.parse(data).desc
 				  });
				
 			 } 
		 else
		 {
			 
			 
 		 }
		 
    },
		  // error handler
        (error) => {
          this.setState({
            isLoaded: true,
            error
          });
        })
	
	 
	
}		
	*/
	
	render() {
		
		 const {error, isLoaded} = this.state;
		
	if (error) {
      return (
        <div className="jr-customers">
          Error: {error.message}
        </div>
      );
    } else if (!isLoaded) {
      return (
        <div className="jr-customers">
          Loading ...
        </div>
      );
    } else {	
		
		
	  return (
		  <div className="app-wrapper animated slideInUpTiny animation-duration-3"> 

			  <ContainerHeader title={<IntlMessages id="sidebar.dashboard.changepassword"/>} match={this.props.match}/>

			  <div className="row">
				<CardBox
				  heading={<IntlMessages id="dashboard.changepassword"/>}>

     <form noValidate autoComplete="off" ref={form => this.form = form}>
		 <FormControl className="w-100 mb-2 selectbox-straight-line">
			<TextField
					id="username"
		  			name="username"
					label="Username" 
					margin="normal"
		  			defaultValue={this.state.username}
					fullWidth
		  			disabled
				  />
		</FormControl>
         <FormControl className="w-100 mb-2 selectbox-straight-line">
			<TextField
					id="oldpassword"
		  			name="oldpassword"
					label="Current Password" 
					margin="normal"
		  			onChange={this.handleChange}
		  			type="password"
					fullWidth
				  />
		</FormControl>
		  <FormControl className="w-100 mb-2 selectbox-straight-line">
			<TextField
					id="newpassword"
		  			name="newpassword"
					label="New Password"  
					margin="normal"
		  			onChange={this.handleChange}
		  			type="password"
					fullWidth
				  />
		</FormControl>
		  <FormControl className="w-100 mb-2 selectbox-straight-line">
			<TextField
					id="confirmpassword"
		  			name="confirmpassword"
					label="Confirm New Password" 
					margin="normal"
		  			onChange={this.handleChange}
		  			type="password"
					fullWidth
				  />
		</FormControl>
          <FormControl className="w-100 mb-2 selectbox-straight-line">
        <Button
							variant="contained"
							color="primary"
							className="jr-btn btn-margin-topbottom"
							position="static"
		  					onClick={() => {
								this.handleChangeClick();
 							}}
						  >
							Change Password
						  </Button>
		</FormControl>
      </form>

				</CardBox>

			  </div>
<NotificationContainer/>
		  </div>



	  );
	}
 }
};

export default ChangePassword;
