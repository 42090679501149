import React, { useEffect, useState } from "react";
import ContainerHeader from "components/ContainerHeader/index";
import XeroLogo from "./../../../assets/images/xero.png";
import qs from "qs";
import { Box, Button, Card, Grid, Switch, TextField } from "@material-ui/core";
import { httpClient } from "../../../appUtility/Api";
import {
  NotificationContainer,
  NotificationManager,
} from "react-notifications";

const AppIntegration = () => {
  const login = localStorage.getItem("login");
  const loginData = JSON.parse(login);

  const [integrate, setIntegrate] = useState("");
  const [sendUrl, setSendUrl] = useState("");
  const [clientID, setClientID] = useState("");
  const [clientSecret, setClientSecret] = useState("");
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    getApiValues();
  }, []);

  const getApiValues = () => {
    httpClient
      .get(
        `https://staging.franchise.care/php/xero.php?action=authorization&appID=${loginData.aid}&companyID=${loginData.cid}`
      )
      .then(({ data }) => {
        if (data.success === true) {
          setSendUrl(data.authorizeURL);
          setIntegrate(data.integrated);
        }
      });
  };

  const handleSubmit = () => {
    if(clientID === "" || clientSecret === ""){
      NotificationManager.info("Required Fields are missing!")
    }else{
      setLoading(true);
    httpClient
      .post(
        `xero.php`, qs.stringify({
          action: "save_client",
          appID: loginData.aid,
          companyID: loginData.cid,
          clientID: clientID,
          clientSecret: clientSecret
        })
      )
      .then(({ data }) => {
        if (data.success === true) {
          // setSendUrl(data.authorizeURL);
          // setIntegrate(data.integrated);
          window.open(data.authorizeURL, "_blank");
          setLoading(false);
        }
      });
    }
  };

  const handleDisconnect = () => {
    setLoading(true)
    
    httpClient
    .post(
      `xero.php`, qs.stringify({
        action: "disconnect",
        appID: loginData.aid,
        companyID: loginData.cid,
      })
    )
    .then(({ data }) => {
      if (data.success === true) {
        NotificationManager.success(data.message)
        setLoading(false)
        getApiValues()
      }else{
        NotificationManager.error(data.message)
        setLoading(false)
      
      }
    });
  }

  return (
    <div className="app-wrapper animated slideInUpTiny animation-duration-3">
      <ContainerHeader title={"App Integration"} />

      <Grid container spacing={4}>
        <Grid item xs={12} md={6}>
          <Card style={{ padding: "30px", textAlign: "center" }}>
            <h3>Integrate with Xero</h3>
            <img
              src={XeroLogo}
              at="xero_log"
              style={{
                width: "100px",
                height: "100px",
                objectFit: "cover",
                margin: "20px 0",
              }}
            />

            {/* <div>
        <Switch
          checked={integrate}
          onChange={(e) => setIntegrate(e.target.checked)}
        />
        </div> */}
        <Box mb={2} mt={2}>
          Note: Generate Client Id and Client Secret from <a href="https://developer.xero.com/app/manage"> https://developer.xero.com/app/manage </a>
        </Box>

            <Box mb={2} mt={2}>
              <TextField
                required
                variant="outlined"
                label="Client ID"
                value={clientID}
                onChange={(e) => setClientID(e.target.value)}
                disabled={integrate === "Yes" ? true : false}
                fullWidth
              />
            </Box>

            <Box mb={2} mt={2}>
              <TextField
                required
                variant="outlined"
                label="Client Secret"
                value={clientSecret}
                onChange={(e) => setClientSecret(e.target.value)}
                disabled={integrate === "Yes" ? true : false}
                fullWidth
              />
            </Box>

            <Box mt={2} mb={2}>
              <Button
                color="primary"
                variant="contained"
                onClick={integrate === "Yes" ? handleDisconnect : handleSubmit}
                disabled={loading}
                fullWidth
              >
                {!loading ?
                integrate === "Yes" ? "Disconnect" : "Connect"
                : "Processing..."}
              </Button>
            </Box>
          </Card>
        </Grid>
      </Grid>

      <NotificationContainer />
    </div>
  );
};

export default AppIntegration;
