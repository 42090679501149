import React, { useState } from "react";
import Avatar from "@material-ui/core/Avatar";
// import CardMenu from "../../dashboard/Common/CardMenu";
import { history } from "../../../store/index";
import Fab from "@material-ui/core/Fab";
import { Bell, PencilFill, PatchCheckFill } from "react-bootstrap-icons";
import IconButton from "@material-ui/core/IconButton";
import Chip from "@material-ui/core/Chip";
import GroupEditCard from "../../wall/Groups/GroupEditCard";
import CardMenu from "../../wall/Groups/CardMenu";
import AlertDialog from "../../wall/Groups/AlertDialog";
import { httpClient } from "appUtility/Api";
import {
  NotificationManager,
  NotificationContainer,
} from "react-notifications";
import { Button, makeStyles } from "@material-ui/core";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import KeyboardBackspaceIcon from "@material-ui/icons/KeyboardBackspace";
import SearchIcon from "@material-ui/icons/Search";
import TotalFriends from "../../wall/Friends/totalFriends";
import ClearIcon from '@material-ui/icons/Clear';

const qs = require("qs");
const socialData = JSON.parse(window.localStorage.getItem("login"));

const useStyle = makeStyles((theme) => ({
  btnBack: {
    borderRadius: "50%",
    marginBottom: theme.spacing(3),
    color: "#fff",
    [theme.breakpoints.down("sm")]: {
      display: "none",
    },
  },
  SearchIcon: {
    color: "#fff",
    fontSize: "15px",
  },

  flexContainer: {
    display: "flex",
    flex: "1",
    justifyContent: "flex-end",
    color: "#fff",
    alignItems: "center",
  },
  searchSec: {
    display: "block",
    cursor: "pointer",
  },
  inActive: {
    padding: "5px",
    fontSize: "14px",
    color: "#fff",
    backgroundColor: "red",
    textAlign: "center",
    position: "absolute !important",
    top: "0",
    left: "0",
    width: "100%",
  },
  close: {
    position: "absolute",
    right: "10px",
    cursor: "pointer"
  }
}));

const ProfileHeader = (props) => {
  const classes = useStyle();
  const { groupInfo, groupMode } = props;

  const [cardState, setCardState] = useState({
    anchorEl: undefined,
    menuState: false,
    invite: false,
    profileImage: socialData && socialData.img,
    userName: socialData && socialData.fname + " " + socialData.lname,
    address: socialData && socialData.suburb + "," + " " + socialData.add1,
    serviceLocation: socialData && socialData.serviceLocation,
    userID: socialData && socialData.uid,
    checkSubscribe: false,
  });

  const [GroupEditMode, setGroupEditMode] = useState(false);
  const [blockAlert, setBlockAlert] = useState(false);
  const [userInvites, setUserInvites] = useState(false);
  const [inactive, setInactive] = useState(false);

  const [listState, setListState] = useState({
    allfriends: false,
    allFollowers: false,
    dialogOpen: false,
    groupMode: true,
  });

  const handleCloseInactive = () => {
    setInactive(true);
  }

  const handleBoxClose = () => {
    setListState({
      groupMode: false,
      dialogOpen: false,
    });
    setUserInvites(false);
  };

  const handleOpenInviteDialog = (option) => {
    console.log("dialog open", option);
    setUserInvites(true);
    setCardState({ ...cardState, menuState: false, dialogOpen: true });
    setListState({ groupMode: true, dialogOpen: true });
  };

  const handleRequestClose = () => {
    setCardState({ ...cardState, menuState: false });
  };

  const onOptionMenuSelect = (event) => {
    setCardState({
      ...cardState,
      menuState: true,
      anchorEl: event.currentTarget,
    });
  };

  const handleAccountSettings = () => {
    history.push("/app/social/settings");
  };

  const handleGroupEditMode = (info) => {
    setGroupEditMode(info);
  };

  const handleBackButton = () => {
    if (props.groupMode) {
      props.handleBackButton1();
    } else {
      history.push("/app/social");
    }
  };

  const setSubscribe = () => {
    const subscriptionInfo = {
      userID: socialData && socialData.uid,
      userCode: socialData && socialData.memtoken,
      appID: 1,
      companyID: socialData && socialData.cid,
      groupID: groupInfo && groupInfo.groupID,
    };

    httpClient
      .post(
        "api/social/groups_subscription.php",
        qs.stringify({
          action: "groups_subscribe",
          source: subscriptionInfo,
        })
      )
      .then((res) => {
        if (res.data.success === "false") {
          NotificationManager.warning(res.data.msg);
        } else {
          NotificationManager.success(res.data.msg);
          props.getGroupDetail();
        }
      });
  };

  const handleAcceptInvites = () => {
    const acceptQuery = {
      userID: socialData && socialData.uid,
      userCode: socialData && socialData.memtoken,
      appID: 1,
      companyID: socialData && socialData.cid,
      groupID: groupInfo && groupInfo.groupID,
    };
    httpClient
      .post(
        "api/social/user_group_actions.php",
        qs.stringify({
          action: "accept",
          source: acceptQuery,
        })
      )
      .then((res) => {
        if (res.data === "" || null) {
          NotificationManager.warning(
            "Sorry there was some problem in Accepting request"
          );
        } else {
          NotificationManager.success(res.data.msg);
          props.getGroupDetail();
        }
      });
  };

  const BlockGroup = () => {
    const subscriptionInfo = {
      userID: socialData && socialData.uid,
      userCode: socialData && socialData.memtoken,
      appID: 1,
      companyID: socialData && socialData.cid,
      groupID: groupInfo && groupInfo.groupID,
    };

    httpClient
      .post(
        "api/social/groups_subscription.php",
        qs.stringify({
          action: "groups_block",
          source: subscriptionInfo,
        })
      )
      .then((res) => {
        if (res.data.success === "false") {
          NotificationManager.warning(res.data.msg);
          handleRequestClose();
        } else {
          NotificationManager.success(res.data.msg);
          handleRequestClose();
          setBlockAlert(false);
          history.push("/app/social/wall");
        }
      });
  };

  const CancelBlockMode = () => {
    setBlockAlert(false);
    handleRequestClose();
  };

  const handleSubscription = (mode) => {
    const subscriptionInfo = {
      userID: socialData && socialData.uid,
      userCode: socialData && socialData.memtoken,
      appID: 1,
      companyID: socialData && socialData.cid,
      groupID: groupInfo && groupInfo.groupID,
    };

    if (mode === "groups_block") {
      setBlockAlert(true);
    } else {
      httpClient
        .post(
          "api/social/groups_subscription.php",
          qs.stringify({
            action: mode,
            source: subscriptionInfo,
          })
        )
        .then((res) => {
          if (res.data.success === "false") {
            NotificationManager.warning(res.data.msg);
            handleRequestClose();
          } else {
            NotificationManager.success(res.data.msg);
            handleRequestClose();
            props.getGroupDetail();
          }
        });
    }
  };

  const handleClick = () => {
    props.handleSearch();
  };

  const getAllMembers = () => {
    props.getAllMembers();
  };

  // console.log("group props >", props);
  return (
    <>
      {/*------------------------------For GROUP-PROFILE DETAIL----------------------------------------------*/}
      <Dialog
        open={listState.dialogOpen}
        onClose={handleBoxClose}
        maxWidth={"sm"}
        fullWidth={true}
      >
        {listState.groupMode && (
          <TotalFriends
            friendList={props.inviteMembers}
            profileMode={true}
            groupSector={true}
            userInvites={userInvites}
            groupID={groupInfo && groupInfo.groupID}
            getAllMembers={getAllMembers}
            groupInfo={groupInfo}
          />
        )}
        <DialogActions>
          <Button onClick={handleBoxClose} color="secondary">
            Close
          </Button>
        </DialogActions>
      </Dialog>

      {groupMode ? (
        <div className="jr-profile-banner">
          {groupInfo && groupInfo.isGroupActive === "0" && (inactive === false) &&
            <div className={classes.inActive}>
              <span>This group is currently Inactive.</span> <ClearIcon className={classes.close} fontSize="small" onClick={handleCloseInactive} />
            </div>
          }
          <div className="jr-profile-container">
            <IconButton onClick={handleBackButton} className={classes.btnBack}>
              <KeyboardBackspaceIcon />
            </IconButton>
            {blockAlert && (
              <AlertDialog
                blockAlert={blockAlert}
                CancelBlockMode={CancelBlockMode}
                groupID={groupInfo && groupInfo.groupID}
                BlockGroup={BlockGroup}
              />
            )}

            {GroupEditMode && (
              <GroupEditCard
                groupInfo={groupInfo}
                GroupEditMode={GroupEditMode}
                handleGroupEditMode={handleGroupEditMode}
              />
            )}

            {!GroupEditMode && (
              <div className="jr-profile-banner-top">
                <div className="jr-profile-banner-top-left">
                  <div className="jr-profile-banner-avatar">
                    <Avatar
                      className="size-90"
                      alt="..."
                      src={groupInfo && groupInfo.groupImage}
                    />
                  </div>
                  <div className="jr-profile-banner-avatar-info">
                    <h2 className="mb-2 jr-mb-sm-3 jr-fs-xxl jr-font-weight-light">
                      {groupInfo && groupInfo.groupName}
                      {groupInfo &&
                        groupInfo.groupSubscribe === "1" &&
                        groupInfo.isSystemGroup === 0 &&
                        groupInfo.isRegionalGroup === 0 && (
                          <>
                            <IconButton
                              className="icon-btn text-light p-1"
                              onClick={onOptionMenuSelect}
                            >
                              <i className="zmdi zmdi-more-vert" />
                            </IconButton>
                            {groupInfo.groupOwnerID === cardState.userID ? (
                              <CardMenu
                                menuState={cardState.menuState}
                                anchorEl={cardState.anchorEl}
                                handleRequestClose={handleRequestClose}
                                handleOpenInviteDialog={handleOpenInviteDialog}
                                invite={true}
                                groupID={groupInfo && groupInfo.groupID}
                                handleSubscription={handleSubscription}
                              />
                            ) : (
                              <CardMenu
                                menuState={cardState.menuState}
                                anchorEl={cardState.anchorEl}
                                handleRequestClose={handleRequestClose}
                                groupProfileMode={true}
                                groupID={groupInfo && groupInfo.groupID}
                                handleSubscription={handleSubscription}
                              />
                            )}
                          </>
                        )}
                    </h2>
                    {groupInfo &&
                      groupInfo.groupSubscribe === "1" &&
                      groupInfo.isSystemGroup === 0 &&
                      groupInfo.isRegionalGroup === 0 && (
                        <Chip
                          size="small"
                          icon={<PatchCheckFill />}
                          label={
                            groupInfo.groupOwnerID === cardState.userID
                              ? "Owner"
                              : "Subscribed"
                          }
                          color="primary"
                        />
                      )}
                  </div>
                </div>
                {groupInfo && groupInfo.groupSubscribe !== "0" && (
                  <div className={classes.flexContainer}>
                    <div className={classes.searchSec} onClick={handleClick}>
                      <IconButton className={classes.SearchIcon}>
                        <SearchIcon className={classes.icon} fontSize="small" />
                      </IconButton>
                      <span>Search</span>
                    </div>
                  </div>
                )}
              </div>
            )}

            {!GroupEditMode && (
              <div className="jr-profile-banner-bottom">
                {groupInfo &&
                  groupInfo.groupSubscribe !== "1" &&
                  groupInfo.isSystemGroup === 0 &&
                  groupInfo.isRegionalGroup === 0 &&
                  (groupInfo.groupOwnerID !== cardState.userID) &&
                  (groupInfo.isInvited !== "1" ? (
                    <Fab variant="extended" onClick={setSubscribe}>
                      <IconButton className="icon-btn text-light p-1">
                        <Bell />
                      </IconButton>
                      <span
                        style={{
                          marginLeft: "3%",
                          fontWeight: "bold",
                          color: "#3e31b0",
                        }}
                      >
                        Subscribe
                      </span>
                    </Fab>
                  ) : (
                    <Button
                      variant="contained"
                      color="primary"
                      onClick={handleAcceptInvites}
                    >
                      Accept
                    </Button>
                  ))}
                {groupInfo &&
                  groupInfo.groupOwnerID === cardState.userID &&
                  !GroupEditMode && (
                    <span className="jr-link jr-profile-setting">
                      <Chip
                        avatar={
                          <Avatar>
                            <PencilFill />
                          </Avatar>
                        }
                        label="Edit"
                        onClick={() => handleGroupEditMode(true)}
                      />
                    </span>
                  )}
              </div>
            )}
          </div>
        </div>
      ) : (
        /*------------------------------For USER-PROFILE DETAIL----------------------------------------------*/
        <div className="jr-profile-banner">
          <div className="jr-profile-container">
            <IconButton onClick={handleBackButton} className={classes.btnBack}>
              <KeyboardBackspaceIcon />
            </IconButton>
            <div className="jr-profile-banner-top">
              <div className="jr-profile-banner-top-left">
                <div className="jr-profile-banner-avatar">
                  <Avatar
                    className="size-90"
                    alt="..."
                    src={cardState.profileImage}
                  />
                </div>
                <div className="jr-profile-banner-avatar-info">
                  <h2 className="mb-2 jr-mb-sm-3 jr-fs-xxl jr-font-weight-light">
                    {cardState.userName}
                  </h2>

                  <p className="mb-0 jr-fs-lg">{cardState.serviceLocation}</p>
                </div>
              </div>
              <div className={classes.flexContainer} onClick={handleClick}>
                <IconButton className={classes.SearchIcon}>
                  <SearchIcon className={classes.icon} />
                </IconButton>
                <span>Search</span>
              </div>
            </div>

            <div className="jr-profile-banner-bottom">
              <span
                className="jr-link jr-profile-setting"
                onClick={handleAccountSettings}
              >
                <i className="zmdi zmdi-settings mr-2" />
                <span className="d-inline-flex align-middle ml-1 jr-ml-sm-0">
                  Account settings
                </span>
              </span>
            </div>
          </div>
        </div>
        /*------------------------------************************************----------------------------*/
      )}
      <NotificationContainer />
    </>
  );
};

export default ProfileHeader;
