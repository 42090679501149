import React from 'react';
import {Redirect, Route, Switch} from 'react-router-dom';
import asyncComponent from '../../../util/asyncComponent';

const Communication = ({match}) => (
  <div className="app-wrapper">
    <Switch>
      <Redirect exact from={`${match.url}/`} to={`${match.url}/overview`}/>
      <Route path={`${match.url}/overview`} component={asyncComponent(() => import('./routes/Overview'))}/>
      <Route path={`${match.url}/buycredits`} component={asyncComponent(() => import('./routes/buycredits'))}/>
      <Route path={`${match.url}/sendsms`} component={asyncComponent(() => import('./routes/sendsms'))}/>
      <Route path={`${match.url}/sendemail`} component={asyncComponent(() => import('./routes/sendemail'))}/>
      <Route path={`${match.url}/smshistory`} component={asyncComponent(() => import('./routes/smshistory/index'))}/>
      <Route path={`${match.url}/messagetemplate`} component={asyncComponent(() => import('./routes/messagetemplate/index'))}/>
	  <Route path={`${match.url}/editmessagetemplate`} component={asyncComponent(() => import('./routes/editmessagetemplate'))}/>
      <Route component={asyncComponent(() => import('app/routes/extraPages/routes/404'))}/>
    </Switch>
  </div>
);

export default Communication;