import React from "react";
import PostList from "components/wall/PostList/index";
import GroupPostList from "../../wall/Groups/GroupPostList/index";

const Timeline = props => {
  const {
    groupProfile,
    userProfile,
    groupID,
    groupPublic,
    groupSubscribe,
    memberProfile,
    groupName
  } = props;

  return (
    <>
      <h2 className="card-title mb-2 mb-md-3">{groupName}{" "} Timeline Posts</h2>
      {groupProfile && (
        <GroupPostList
          groupID={groupID}
          groupPublic={groupPublic}
          groupSubscribe={groupSubscribe}
        />
      )}

      {userProfile && <PostList userProfile={props.userProfile} />}

      {memberProfile && <PostList memberProfile={props.memberProfile} memberID={props.memberID} />}
    </>
  );
};

export default Timeline;
