import React, { Component } from "react";
import Avatar from "@material-ui/core/Avatar";
import CommentBox from "./CommentBox";
import MediaList from "./MediaList";
// import DisplayDate from "../DisplayDate/index";
import Button from "@material-ui/core/Button";
import Card from "@material-ui/core/Card";
import { Input } from "reactstrap";
import { ArrowRightSquareFill, PlayFill, Trash } from "react-bootstrap-icons";
import DeleteIcon from "@material-ui/icons/Delete";
import EditIcon from "@material-ui/icons/Edit";
import moment from "moment";
import { history } from "../../../store/index";
import { httpClient } from "appUtility/Api";
import IconButton from "@material-ui/core/IconButton";
import AlertDialog from "./AlertDialog";
import {
  NotificationManager,
  NotificationContainer,
} from "react-notifications";
import CircularProgress from "@material-ui/core/CircularProgress";
import EditPostItem from "./EditPostItem";
import CommentContext from "./CommentContext";
import { Box, Tooltip } from "@material-ui/core";
import parse from "html-react-parser";
import featureServer from "./../../../appUtility/featureServer";
import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.snow.css';
import GroupDetail from "../../../app/routes/socialApps/routes/Profile/groupDetail";

const qs = require("qs");
const socialData = JSON.parse(window.localStorage.getItem("login"));

class PostItem extends Component {
  constructor(props) {
    super(props);
    this.state = {
      message: "",
      totalComments: "",
      totalLikes: "",
      isLiked: null,
      likeLoader: false,
      deleteAlert: false,
      user: {
        name: socialData && socialData.fname + "" + socialData.lname,
        userID: socialData && socialData.uid,
        image: socialData && socialData.img,
      },
      post: {},
      userProfile: "",
      showComments: false,
      commentList: [],
      commentLoader: false,
      commentPostLoader: false,
      singleData: [],
      single: false,
      showCarousel: false,
      open: false,
      commentImage: [],
      openEtiquette: false,
      groupID1: "",
      groupName1: "",
    };
    this.commentImageRef = React.createRef();
  }

  componentWillMount() {
    this.setState({
      post: this.props.postData,
      totalComments: this.props.postData.totalComments,
      totalLikes: this.props.postData.totalLikes,
      userProfile: this.props.userProfile,
      isLiked: this.props.postData.isLiked,
    });
  }

  filterDeletedComment = (id) => {
    let filteredComment = this.state.commentList.filter(
      (item) => item.commentID !== id
    );
    this.setState({ ...this.state, commentList: filteredComment });
  };

  RequestDeletePost = () => {
    this.setState({ ...this.state, deleteAlert: true });
  };

  CancelDeletePost = () => {
    this.setState({ ...this.state, deleteAlert: false });
  };

  handleNewComment = (e) => {
    let comData = new FormData();
    // if (e.key === "Enter") {
    this.setState({
      ...this.state,
      commentLoader: true,
      commentPostLoader: true,
    });
    // console.log('state in comment', this.state);
    comData.append("userID", socialData && socialData.uid);
    comData.append("userCode", socialData && socialData.memtoken);
    comData.append("appID", 1);
    comData.append("companyID", socialData && socialData.cid);
    comData.append("commentText", this.state.message);
    comData.append("postID", this.state.post.postID);
    this.state.commentImage.forEach((img) =>
      comData.append("mediaList[]", img)
    );
    comData.append("action", "comments_save");
    comData.append("imageHeight", 600);
    comData.append("imageWidth", 600);

    // const newCommentData = {
    //   userID: socialData && socialData.uid,
    //   userCode: socialData && socialData.memtoken,
    //   appID: 1,
    //   companyID: socialData && socialData.cid,
    //   commentText: this.state.message,
    //   postID: this.state.post.postID,
    // };

    // featureServer ?
    httpClient
      .post("api/social/posts_comments_save.php", comData)
      .then((res) => {
        if (res.data.msg !== "Commented Successfully") {
          this.setState({
            ...this.state,
            commentLoader: false,
            commentPostLoader: false,
          });
          NotificationManager.warning(res.data.msg);
          this.getAllComments();
        } else {
          this.setState({
            ...this.state,
            message: "",
            totalComments: res.data.totalComments,
            commentPostLoader: false,
          });

          if (!this.state.showComments) {
            this.setState({ ...this.state, showComments: true });
          }
          //------Concatinating the latest comment into the commentList Array------//
          let latestComment = {
            // commentUserImage: socialData && socialData.img,
            commentUserName:
              socialData && socialData.fname + "" + socialData.lname,
            commentText: res && res.data && res.data.commenttext,
            commentID: res && res.data && res.data.commentID.toString(),
            postID: res && res.data && res.data.postID,
            commentUserID: res && res.data && res.data.userID,
            commentedDate: moment(),
            totalComments: "0",
            userThumbnail: res && res.data && res.data.userThumbnail,
          };

          if (this.state.commentList.length === 0) {
            this.getAllComments();
          } else {
            let updatedCommentList = this.state.commentList.concat([
              latestComment,
            ]);
            this.setState({
              ...this.state,
              commentList: updatedCommentList,
              commentLoader: false,
              commentPostLoader: false,
              totalComments: res && res.data && res.data.totalComments,
            });
            this.getAllComments();
          }
          //----------------**********--------------------//
          this.setState({ commentImage: [] });
          if (this.commentImageRef.current) {
            this.commentImageRef.current.value = ""; // Clear the file input
          }
        }
      });
    //   :
    // httpClient
    //   .post(
    //     "api/social/posts_comments_save.php",
    //     qs.stringify({
    //       action: "comments_save",
    //       source: newCommentData,
    //     })
    //   )
    //   .then((res) => {
    //     console.log("comment dataaaaaaa", res.data);

    //     if (res.data.msg !== "Commented Successfully") {
    //       this.setState({ ...this.state, commentLoader: false });
    //       NotificationManager.warning(res.data.msg);
    //       this.getAllComments();
    //     } else {
    //       this.setState({
    //         ...this.state,
    //         message: "",
    //         totalComments: res.data.totalComments,
    //       });

    //       if (!this.state.showComments) {
    //         this.setState({ ...this.state, showComments: true });
    //       }
    //       //------Concatinating the latest comment into the commentList Array------//
    //       let latestComment = {
    //         // commentUserImage: socialData && socialData.img,
    //         commentUserName:
    //           socialData && socialData.fname + "" + socialData.lname,
    //         commentText: res && res.data && res.data.commenttext,
    //         commentID: res && res.data && res.data.commentID.toString(),
    //         postID: res && res.data && res.data.postID,
    //         commentUserID: res && res.data && res.data.userID,
    //         commentedDate: moment(),
    //         totalComments: "0",
    //         userThumbnail: res && res.data && res.data.userThumbnail,
    //       };

    //       if (this.state.commentList.length === 0) {
    //         this.getAllComments();
    //       } else {
    //         let updatedCommentList = this.state.commentList.concat([
    //           latestComment,
    //         ]);
    //         this.setState({
    //           ...this.state,
    //           commentList: updatedCommentList,
    //           commentLoader: false,
    //           totalComments: res && res.data && res.data.totalComments,
    //         });
    //         this.getAllComments();
    //       }
    //       //----------------**********--------------------//
    //     }
    //   });
    // }
  };

  handleCommentRemove = (commentID, postID) => {
    const deleteQuery = {
      userID: socialData && socialData.uid,
      userCode: socialData && socialData.memtoken,
      appID: 1,
      companyID: socialData && socialData.cid,
      postID: postID,
      commentID: commentID,
      parentCommentID: 0,
    };

    httpClient
      .post(
        "api/social/posts_comments_save.php",
        qs.stringify({
          action: "comments_delete",
          source: deleteQuery,
          // userID: socialData && socialData.uid,
          // userCode: socialData && socialData.memtoken,
          // appID: 1,
          // companyID: socialData && socialData.cid,
          // postID: postID,
          // commentID: commentID,
        })
      )
      .then((res) => {
        if (res.data.success !== "true") {
          NotificationManager.warning(res.data.msg);
        } else {
          // console.log('comment deleter response', res.data);
          NotificationManager.success(res.data.msg);
          this.setState({
            totalComments: res.data && res.data.totalComments,
          });
          this.filterDeletedComment(res.data.commentID);
        }
      });
  };

  updateCommentValue(evt) {
    this.setState({ ...this.state, message: evt.target.value });
  }

  updateCommentValueEditor(value) {
    this.setState({ ...this.state, message: value });
  }

  handleLikeToggle() {
    this.setState({
      likeLoader: true,
    });
    if (
      this.state.isLiked === "0" ||
      this.state.isLiked === 0 ||
      this.state.isLiked === undefined
    ) {
      const likeData = {
        userID: socialData && socialData.uid,
        userCode: socialData && socialData.memtoken,
        appID: 1,
        companyID: socialData && socialData.cid,
        postID: this.state.post.postID,
      };

      httpClient
        .post(
          "api/social/likes_save.php",
          qs.stringify({
            action: "likes_save",
            source: likeData,
          })
        )
        .then((res) => {
          if (res.data.success === "true") {
            this.setState({
              ...this.state,
              totalLikes: res.data.total++,
              isLiked: 1,
              likeLoader: false,
            });
          } else {
            NotificationManager.warning(res.data.msg);
          }
        });
    } else {
      const likeData = {
        userID: socialData && socialData.uid,
        userCode: socialData && socialData.memtoken,
        appID: 1,
        companyID: socialData && socialData.cid,
        postID: this.state.post.postID,
      };

      httpClient
        .post(
          "api/social/likes_save.php",
          qs.stringify({
            action: "likes_remove",
            source: likeData,
          })
        )
        .then((res) => {
          if (res.data.success === "true") {
            this.setState({
              ...this.state,
              totalLikes: res.data.total--,
              isLiked: 0,
              likeLoader: false,
            });
          } else {
            NotificationManager.warning(res.data.msg);
          }
        });
    }
  }

  getAllComments = (total) => {
    this.setState({
      ...this.state,
      showComments: true,
      commentList: [],
      commentLoader: true,
    });

    const getCommentsQuery = {
      userID: socialData && socialData.uid,
      userCode: socialData && socialData.memtoken,
      appID: 1,
      companyID: socialData && socialData.cid,
      postID: this.state.post.postID,
    };

    httpClient
      .post(
        "api/social/posts_comments_get.php",
        qs.stringify({
          action: "posts_comments",
          source: getCommentsQuery,
        })
      )
      .then((res) => {
        if (res.data.data === null) {
          console.log("No comments for this post !");
          this.setState({ ...this.state, commentLoader: false });
        } else {
          total !== undefined
            ? this.setState({
              ...this.state,
              commentList: res.data.data,
              commentLoader: false,
              totalComments: total,
              showComments: true,
            })
            : this.setState({
              ...this.setState,
              commentList: res.data.data,
              commentLoader: false,
            });
        }
      });
  };

  seeAllComments = () => {
    if (this.state.commentList.length > 0) {
      this.setState({ ...this.state, showComments: !this.state.showComments });
    } else if (this.props.postData.totalComments === "0") {
      this.setState({ ...this.state, showComments: !this.state.showComments });
    } else if (this.props.postData.totalComments !== "0") {
      this.getAllComments();
    } else {
      return;
    }
  };

  RequestEditPostOpen = () => {
    this.setState({
      open: true,
    });
  };

  RequestEditPostClose = (callback) => {
    // console.log('edit post callback', callback);
    this.setState({
      open: false,
    });
  };

  editPost = (postTitle, imageList) => {
    // console.log('edit post >>', this.state.post);
    // console.log(postTitle, imageList, 'edit');
    let fd = new FormData();
    fd.append("userID", socialData && socialData.uid);
    fd.append("userCode", socialData && socialData.memtoken);
    fd.append("appID", 1);
    fd.append("companyID", socialData && socialData.cid);
    fd.append("postText", postTitle);
    fd.append("postID", this.state.post.postID);
    fd.append("groupID", this.state.post.groupID != null ? this.state.post.groupID : 0);
    fd.append("type", "post_save");
    fd.append("imageHeight", 600);
    fd.append("imageWidth", 600);

    // imageList.forEach((data) => fd.append("mediaList[]", data));

    httpClient.post("api/social/posts_save.php", fd).then((res) => {
      if (res.data.success === "false") {
        NotificationManager.warning(res.data.msg);
      } else {
        NotificationManager.success(res.data.msg);
        this.RequestEditPostClose();
        setTimeout(() => {
          history.push(`/app/social/profileDetail`);
        }, 3000)
      }
    });
  };

  handleDeletePost = (postID, groupID) => {
    let groupIDCheck;

    if (groupID === null) {
      groupIDCheck = 0;
    } else {
      groupIDCheck = groupID;
    }

    const PostQuery = {
      userID: socialData && socialData.uid,
      userCode: socialData && socialData.memtoken,
      appID: 1,
      companyID: socialData && socialData.cid,
      postID: postID,
      groupID: groupIDCheck,
    };

    httpClient
      .post(
        "api/social/posts_save.php",
        qs.stringify({
          action: "post_delete ",
          source: PostQuery,
        })
      )
      .then((res) => {
        if (res.data.success !== "true") {
          NotificationManager.warning(
            "Sorry couldn't delete posts right now ! Please Refresh"
          );
          this.CancelDeletePost();
        } else {
          NotificationManager.success(res.data.msg);
          this.CancelDeletePost();
          this.props.DeleteWork(true);
          setTimeout(() => {
            history.push(`/app/social/profileDetail`);
          }, 3000)
        }
      });
  };

  getSinglePost = () => {
    this.setState({
      showCarousel: true,
    });
    const getPostQuery = {
      userID: socialData && socialData.uid,
      userCode: socialData && socialData.memtoken,
      appID: 1,
      groupID: 0,
      page: 0,
      postedByUserID: socialData && socialData.uid,
      companyID: socialData && socialData.cid,
      postID: this.state.post.postID,
      imageHeight: 600,
      imageWidth: 600,
    };

    httpClient
      .post(
        "api/social/posts_get.php",
        qs.stringify({
          action: "posts_get",
          source: getPostQuery,
        })
      )
      .then((res) => {
        if (res.data !== null) {
          this.setState({
            singleData:
              res.data.data[0].images === "" ? "" : res.data.data[0].images,
            single: true,
            showCarousel: false,
          });
        }
      });
  };

  handleSeeUser = (postedUserID, postedUserName) => {
    history.push(
      `/app/social/member/${postedUserID}/${postedUserName.replace(
        /\s+/g,
        "_"
      )}`
    );
  };

  handleSeeGroup(id, groupName) {
    // history.push(`/app/social/group/${groupName.replace(/\s+/g, "_")}/${id}`);
    this.setState({
      groupID1: id,
      groupName1: groupName,
      openEtiquette: true,

    });

  }

  handleEtiquetteClose = () => {
    this.setState({
      openEtiquette: false,

    });
  }

  onCommentImageChange = (e) => {
    // console.log('e.taget >>>', e.target.files)
    if (e.target.files.length > 8) {
      e.preventDefault();
      alert(`Cannot upload files more than 8`);

      e.target.files = null;
    } else {
      // this.state.commentImage = [...e.target.files]
      var comImg = [...e.target.files];
      this.setState({
        ...this.state,
        commentImage: comImg,
      });
    }
    //  console.log('commnet image', this.state.commentImage && this.state.commentImage);
  };

  render() {
    const {
      postedUserName,
      postedDate,
      images,
      // totalLikes,
      totalComments,
      groupID,
      groupName,
      postTitle,
      postID,
      userThumbnail,
      postedUserID,
      cntCommentCount,
      commentImage,
    } = this.state.post;

    // console.log('props in post', this.props)
    console.log('state for post edit', this.state.post);

    // console.log("post data items >>>>>>>>>>", this.state);
    // console.log('total comments >>', this.state.totalComments);
    const postDate = moment(postedDate);
    const currDate = moment();

    let regex = "/(\b(https?|ftp|file):\/\/[-A-Z0-9+&@#\/%?=~_|!:,.;]*[-A-Z0-9+&@#\/%=~_|])/ig";
    let final = (postTitle);
    let match = (postTitle).match(/(\b(https?|ftp|file):\/\/[-A-Z0-9+&@#\/%?=~_|!:,.;]*[-A-Z0-9+&@#\/%=~_|])/ig);
    // console.log('match', match);
    // console.log('final', final);
    let newURL;
    match && match.map(url => {
      newURL = url.split('//').pop().split('/')[0] + "..." + url.slice(url.length - 10);
      final = final.replace(url, "<a href=\"" + url + "\" target=\"_BLANK\">" + (url.length > 60 ? newURL : url) + "</a>");
    })

    return (
      <>
        {this.state.deleteAlert && (
          <AlertDialog
            postID={postID}
            groupID={groupID}
            deleteAlert={this.state.deleteAlert}
            CancelDeletePost={this.CancelDeletePost}
            handleDeletePost={this.handleDeletePost}
          />
        )}
        {this.state.openEtiquette && (
          <GroupDetail
            open={true}
            handleEtiquetteClose={this.handleEtiquetteClose}
            groupName={this.state.groupName1}
            groupID={this.state.groupID1}
            openEtiquette={this.state.openEtiquette}
          />
        )}
        <Card className="jr-card">
          <div className="jr-wall-content">
            <div className="media jr-wall-user-info flex-nowrap align-items-center">
              <Avatar
                src={userThumbnail}
                alt={postedUserName}
                className="mr-3 mb-2 size-50"
              />
              <div className="media-body">
                <p className="jr-wall-user-title">
                  <span
                    style={{ cursor: "pointer" }}
                    onClick={() =>
                      this.handleSeeUser(postedUserID, postedUserName)
                    }
                  >
                    {postedUserName}{" "}
                  </span>
                  {groupName && (
                    <span
                      onClick={() => this.handleSeeGroup(groupID, groupName)}
                      style={{ cursor: "pointer" }}
                    >
                      <PlayFill
                        style={{
                          fontSize: "16px",
                          position: "relative",
                          top: "3px",
                        }}
                      />{" "}
                      {groupName}
                    </span>
                  )}
                </p>
                {/* <DisplayDate date={postedDate} /> */}
                <span
                  style={{
                    fontSize: "12px",
                    fontWeight: "bold",
                    color: "#ababab",
                  }}
                >
                  {moment(postedDate).format(socialData && socialData.timeFormat === 24 ? "MMMM Do YYYY, HH:mm" : "MMMM Do YYYY, h:mm a")}
                </span>
              </div>

              {this.state.userProfile && (
                <>
                  <Tooltip title="Delete">
                    <IconButton
                      className="icon-btn text-light p-1"
                      onClick={this.RequestDeletePost}
                    >
                      <DeleteIcon fontSize="small" />
                      {/* <Trash /> */}
                    </IconButton>
                  </Tooltip>
                  {/* {(postDate.to(currDate, true) === "5 minutes") && ( */}

                  {/* {(currDate - postDate < 300000) && ( */}
                  <Tooltip title="Edit">
                    <IconButton
                      onClick={this.RequestEditPostOpen}
                      className="icon-btn text-light p-1"
                    >
                      <EditIcon fontSize="small" />
                    </IconButton>
                  </Tooltip>
                  {/* )} */}

                  <EditPostItem
                    open={this.state.open}
                    handleClose={this.RequestEditPostClose}
                    editPost={this.editPost}
                    post={this.state.post}
                  />
                </>
              )}
            </div>
            <div style={{ wordBreak: "break-word" }}>
              <p style={{ fontSize: "16px" }}>


                {postTitle.substring(0, 5) === "https" ? (
                  <a href={parse(postTitle)} target="_blank">
                    {/* {parse(postTitle)} */}
                    {postTitle.length > 60 ?
                      postTitle.split('//').pop().split('/')[0] + "..." + postTitle.slice(postTitle.length - 10)
                      : parse(postTitle)
                    }
                  </a>
                ) : (
                  parse(final)
                )}
              </p>
            </div>
            <div className="jr-wall-medialist">
              {images && images.length >= 0 ? (
                <MediaList
                  mediaList={
                    this.state.single
                      ? this.state.singleData
                      : images === ""
                        ? null
                        : images
                  }
                  getSinglePost={this.getSinglePost}
                  showCarousel={this.state.showCarousel}
                />
              ) : null}
            </div>
            <div className="d-flex flex-row mb-2 mb-xl-3">
              <p
                className="jr-fs-sm pointer mr-3 text-grey"
                onClick={this.handleLikeToggle.bind(this)}
                style={{
                  pointerEvents: this.state.likeLoader ? "none" : "auto",
                }}
              >
                {this.state.isLiked === "1" || this.state.isLiked === 1 ? (
                  <i
                    className="zmdi zmdi-favorite jr-fs-lg mr-2 d-inline-flex align-middle"
                    style={{ color: "blue" }}
                  />
                ) : (
                  <i className="zmdi zmdi-favorite-outline jr-fs-lg mr-2 d-inline-flex align-middle" />
                )}
                <span className="d-inline-flex vertical-align-middle">
                  {/* {this.state.totalLikes ? this.state.totalLikes + " Likes" : "Likes"} */}

                  {this.state.totalLikes === undefined
                    ? "0" + " " + "Likes"
                    : this.state.totalLikes == "1"
                      ? this.state.totalLikes + " " + "Like"
                      : this.state.totalLikes + " " + "Likes"}
                </span>
              </p>

              <p
                className="jr-fs-sm pointer mr-3 text-blue"
                onClick={this.seeAllComments}
              >
                <i className="zmdi zmdi-comment-more jr-fs-lg mr-2 d-inline-flex align-middle" />
                <span className="d-inline-flex align-middle">
                  {this.state && this.state.totalComments > 0
                    ? (this.state && this.state.totalComments) + " Comments"
                    : "0 Comments"}
                </span>
                {cntCommentCount && cntCommentCount != 0 ? (
                  <span
                    style={{
                      background:
                        "linear-gradient(to bottom,  #dd1815 0%,#901419 100%)",
                      boxShadow: "0 3px 6px rgb(0 0 0 / 24%)",
                      borderRadius: "50%",
                      height: "8px",
                      width: "8px",
                      display: "inline-block",
                      marginLeft: "5px",
                    }}
                  ></span>
                ) : (
                  ""
                )}
              </p>

              {/* <span className="text-primary jr-fs-md pointer d-block mb-4">
              See All Comments{" "}
              <i
                className={`zmdi zmdi-long-arrow-right jr-fs-xxl ml-2 d-inline-flex align-middle`}
              />
            </span> */}
            </div>

            <div className="jr-wall-comment-box">
              {this.state.commentLoader && (
                <CircularProgress style={{ marginLeft: "40%" }} />
              )}

              {this.state.showComments &&
                this.state.commentList &&
                this.state.commentList.length > 0 &&
                this.state.commentList.map((commentData, index) => (
                  <CommentBox
                    key={commentData.commentID}
                    index={index}
                    commentData={commentData}
                    deleteAlert={this.state.deleteAlert}
                    filterDeletedComment={this.filterDeletedComment}
                    handleSubComment={this.handleSubComment}
                    showSubCommentsImage={true}
                    handleCommentRemove={this.handleCommentRemove}
                    getAllComments={this.getAllComments}
                  />
                ))}
            </div>

            <div className="jr-wall-comment-box">
              <div className="media mb-2">
                <Avatar
                  className="mr-3 size-36"
                  src={this.state.user.image}
                  alt={this.state.user.name}
                />
                <div className="media-body">
                  {/* <Input
                    className="border-3"
                    placeholder="Type Commentsss"
                    type="textarea"
                    id="required"
                    value={this.state.message}
                    onChange={(event) => this.updateCommentValue(event)}
                    // onKeyPress={(event) => this.handleNewComment(event)}
                  /> */}

                  <ReactQuill
                    placeholder="Type Comments"
                    value={this.state.message}
                    onChange={(value) => this.updateCommentValueEditor(value)}
                    modules={{
                      toolbar: [
                        ['bold', 'italic', 'underline', 'strike'],
                        [{ 'header': 1 }, { 'header': 2 }],
                        [{ 'list': 'ordered' }, { 'list': 'bullet' }],
                        ['link'],
                        // ['image'],
                        ['clean'],
                      ],
                    }}
                    formats={[
                      'header',
                      'bold', 'italic', 'underline', 'strike',
                      'link',
                      // 'image',
                      'list', 'bullet',
                    ]}
                  />

                  {/* {featureServer && this.state.commentList &&
                  <Box pt={1} pb={1}>
                    {this.state.commentList.returnImage && this.state.commentList.returnImage.map(comImage => (
                      <div>
                      <img src={comImage.imageURL} style={{height: "100px", width:"100px" , margin:"5px"}} />
                      </div>
                    ))}
                  </Box>
                  } */}
                  {/* {featureServer &&  */}
                  <Box pt={1} pb={1}>
                    <div className="boxarea">
                      <label
                        style={{ fontSize: "11px", marginRight: "10px" }}
                        for="comment_image"
                      >
                        Select Image Files (Max 8):
                      </label>
                      <input
                        type="file"
                        onChange={this.onCommentImageChange}
                        name="commentImage"
                        id="comment_image"
                        style={{ fontSize: "11px" }}
                        multiple
                        ref={this.commentImageRef}
                      />
                    </div>
                    <div className="boxarea">
                      <span style={{ fontSize: "11px" }}>
                        Note: Supported image format: .jpeg, .jpg, .png, .gif{" "}
                      </span>
                    </div>
                  </Box>
                  {/* } */}
                  <Box textAlign="right" pt={1}>
                    <Button
                      disabled={
                        this.state.message !== "" ||
                          this.state.commentImage.length > 0
                          ? this.state.commentPostLoader
                            ? true
                            : false
                          : true
                      }
                      variant="contained"
                      color="primary"
                      size="small"
                      style={{ fontSize: "10px", minWidth: "50px" }}
                      onClick={(event) => this.handleNewComment(event)}
                    >
                      POST
                      {/* <ArrowRightSquareFill style={{ color: "blue" }} /> */}
                    </Button>
                    {this.state.commentPostLoader && (
                      <CircularProgress
                        style={{
                          marginLeft: "10px",
                          position: "relative",
                          top: "5px",
                        }}
                        size={20}
                      />
                    )}
                  </Box>
                </div>
              </div>
            </div>
          </div>
        </Card>
        <NotificationContainer />
      </>
    );
  }
}

export default PostItem;
