import React from 'react';


let todaysDate=new Date();
//let futureDate = new Date();
//let numberOfDaysToAdd = 5;

var AllNotifications=[];
//futureDate.setDate(todaysDate.getDate() + numberOfDaysToAdd); 

let dd = ('0' + todaysDate.getDate()).slice(-2);//todaysDate.getDate();
let mm = ('0' + (todaysDate.getMonth()+1)).slice(-2);//todaysDate.getMonth() + 1;
let y = todaysDate.getFullYear();

todaysDate = y + '-'+ mm + '-'+ dd;

//let future_dd = futureDate.getDate();
//let future_mm = futureDate.getMonth() + 1;
//let future_y = futureDate.getFullYear();

//futureDate = future_y + '-'+ future_mm + '-'+ future_dd;
let globalNoticationCount = 0;
let bookingNotificationOBJ,bookingNotificationDetail;

bookingNotificationOBJ = localStorage.getItem('booking_active');

if(bookingNotificationOBJ!=null)
	bookingNotificationDetail = JSON.parse(bookingNotificationOBJ);

var notificationCount_Booking = 0;

//alert('here');
//console.log("from Notification "+JSON.stringify(bookingNotificationDetail));
if(bookingNotificationDetail!=null && bookingNotificationDetail!=null  && bookingNotificationDetail!='')
	{
		bookingNotificationDetail.map((activeBookings, index) =>
				{
					if(activeBookings.bdate<todaysDate)
						{
							notificationCount_Booking = parseInt(notificationCount_Booking) + 1;
						}
			},
		 );
	}

 
if(notificationCount_Booking!=0)
	{
		
		globalNoticationCount = parseInt(globalNoticationCount) + 1;
		
		AllNotifications.push({
 			title: notificationCount_Booking + " bookings requires your attention.",
			customlink: "/app/notifications/bookings",
			icon: "zmdi zmdi-arrow-right-top text-blue",
         })
		
	}
 
export const notifications = AllNotifications;
/*[

  {
    title: "<a href='#'>4 bookings created on 30/05/2019</a>",
    time: "4:10 PM",
    icon: "zmdi-thumb-up text-blue",
  }, {
    image: 'https://via.placeholder.com/150x150',
    title: "50 booking incomes added this week ",
    time: "5:18 PM",
    icon: "zmdi-comment-text text-grey",
  }, {
    image:'https://via.placeholder.com/150x150',
    title: "Bookings details updated for Tom",
    time: "5:36 PM",
    icon: "zmdi-card-giftcard text-info",
  } 
];
*/