import React, { version } from "react";
import { Link, withRouter } from "react-router-dom";
import { connect } from "react-redux";
import AppBar from "@material-ui/core/AppBar";
import Avatar from "@material-ui/core/Avatar";
import Toolbar from "@material-ui/core/Toolbar";
import IconButton from "@material-ui/core/IconButton";
import GlobalURL from "appUtility/global";

import { Button, Dropdown, DropdownMenu, DropdownToggle } from "reactstrap";
import {
  BELOW_THE_HEADER,
  COLLAPSED_DRAWER,
  FIXED_DRAWER,
  HORIZONTAL_NAVIGATION,
  INSIDE_THE_HEADER,
} from "constants/ActionTypes";
// import SearchBox from 'components/SearchBox';
// import MailNotification from '../MailNotification/index';
import AppNotification from "../AppNotification/index";
import CardHeader from "components/dashboard/Common/CardHeader/index";
import { switchLanguage, toggleCollapsedNav } from "actions/Setting";
import IntlMessages from "util/IntlMessages";
//import LanguageSwitcher from 'components/LanguageSwitcher/index';
import Menu from "components/TopNav/Menu";
import UserInfoPopup from "components/UserInfo/UserInfoPopup";

import { notifications } from "../AppNotification/data";
import featureServer from "../../appUtility/featureServer";
import { Close } from "@material-ui/icons";
import { history } from "../../store";
import { httpClient } from "appUtility/Api";
const qs = require("qs");

class Header extends React.Component {
  callbackFunctionFromChild = (childData) => {
    //notifications = childData;
    //childData
  };

  onAppNotificationSelect = () => {
    this.setState({
      appNotification: !this.state.appNotification,
    });
  };
  onMailNotificationSelect = () => {
    this.setState({
      mailNotification: !this.state.mailNotification,
    });
  };
  onLangSwitcherSelect = (event) => {
    this.setState({
      langSwitcher: !this.state.langSwitcher,
      anchorEl: event.currentTarget,
    });
  };
  onSearchBoxSelect = () => {
    this.setState({
      searchBox: !this.state.searchBox,
    });
  };
  onAppsSelect = () => {
    this.setState({
      apps: !this.state.apps,
    });
  };
  onUserInfoSelect = () => {
    this.setState({
      userInfo: !this.state.userInfo,
    });
  };
  handleRequestClose = () => {
    this.setState({
      langSwitcher: false,
      userInfo: false,
      mailNotification: false,
      appNotification: false,
      searchBox: false,
      apps: false,
    });
  };
  onToggleCollapsedNav = (e) => {
    const val = !this.props.navCollapsed;
    this.props.toggleCollapsedNav(val);
  };

  constructor() {
    super();
    this.state = {
      anchorEl: undefined,
      searchBox: false,
      searchText: "",
      mailNotification: false,
      userInfo: false,
      langSwitcher: false,
      appNotification: false,
      showSmsBox: false,
    };
  }

  componentDidMount() {
    this.checkSmsError();
    // this.getInventoryNotifications();

    let login = localStorage.getItem("login");
    let loginData = JSON.parse(login);
    // console.log('logindata', loginData);
    !loginData && window.location.reload(false);
    !loginData && (window.location = "/logout");
  }

  checkSmsError = () => {
    let login = localStorage.getItem("login");
    let loginData = JSON.parse(login);
    let param = {
      appID: loginData && loginData.aid,
      companyID: loginData && loginData.cid,
      memberID: loginData && loginData.uid,
    };
    httpClient
      .post(
        "getqueue_SMS.php",
        qs.stringify({
          action: "show_queue",
          source: param,
        })
      )
      .then(({ data }) => {
        if (data == true) {
          this.setState({
            showSmsBox: true,
          });
        } else {
          this.setState({
            showSmsBox: false,
          });
        }
      });
  };

  

  updateSearchText(evt) {
    this.setState({
      searchText: evt.target.value,
    });
  }

  handleCloseSmsBox = () => {
    this.setState({
      showSmsBox: false,
    });
  };

  handleView = () => {
    history.push({
      pathname: "/app/communication/smshistory",
      state: { queue: true },
    });
  };

  Apps = () => {
    return (
      <ul className="jr-list jr-list-half">
        <li className="jr-list-item">
          <Link className="jr-list-link" to="/app/calendar/dnd">
            <i className="zmdi zmdi-calendar zmdi-hc-fw" />
            <span className="jr-list-text">
              <IntlMessages id="sidebar.dashboard.calendar" />
            </span>
          </Link>
        </li>

        <li className="jr-list-item">
          <Link className="jr-list-link" to="/app/bookings/newbook">
            <i className="zmdi zmdi-plus-circle-o zmdi-hc-fw" />
            <span className="jr-list-text">
              <IntlMessages id="sidebar.bookings" />
            </span>
          </Link>
        </li>

        <li className="jr-list-item">
          <Link className="jr-list-link" to="/app/bookings/newDiary">
            <i className="zmdi zmdi-calendar-note zmdi-hc-fw" />
            <span className="jr-list-text">
              <IntlMessages id="sidebar.bookings.quicknew" />
            </span>
          </Link>
        </li>

        <li className="jr-list-item">
          <Link className="jr-list-link" to="/app/contact">
            <i className="zmdi zmdi-account-box zmdi-hc-fw" />
            <span className="jr-list-text">
              <IntlMessages id="sidebar.appModule.contact" />
            </span>
          </Link>
        </li>

        <li className="jr-list-item">
          <Link className="jr-list-link" to="/app/newcontact">
            <i className="zmdi zmdi-plus-circle-o zmdi-hc-fw" />
            <span className="jr-list-text">Customers</span>
          </Link>
        </li>
      </ul>
    );
  };

  render() {
    const {
      drawerType,
      locale,
      navigationStyle,
      horizontalNavPosition,
    } = this.props;
    const drawerStyle = drawerType.includes(FIXED_DRAWER)
      ? "d-block d-xl-none"
      : drawerType.includes(COLLAPSED_DRAWER)
      ? "d-block"
      : "d-none";

    const logindetail = JSON.parse(localStorage.getItem("login"));

    localStorage.setItem("attention_detail", JSON.stringify("true"));
    // localStorage.setItem("stock_attention", JSON.stringify("true"));

    // console.log('state !!!', this.state);
    // console.log('react version', version);

    return (
      <>
        <AppBar
          className={`${
            window.location.hostname === "staging.franchise.care"
              ? "app-secondary-header"
              : "app-main-header"
          }  ${
            navigationStyle === HORIZONTAL_NAVIGATION &&
            horizontalNavPosition === BELOW_THE_HEADER
              ? "app-main-header-top"
              : ""
          }`}
          id="appbar_mainapp__"
        >
          <Toolbar className="app-toolbar lawaNoPrint" disableGutters={false}>
            {navigationStyle === HORIZONTAL_NAVIGATION ? (
              <div
                className="d-block d-md-none pointer mr-3"
                onClick={this.onToggleCollapsedNav}
              >
                <span className="jr-menu-icon">
                  <span className="menu-icon" />
                </span>
              </div>
            ) : (
              <IconButton
                className={`jr-menu-icon mr-3 ${drawerStyle}`}
                aria-label="Menu"
                onClick={this.onToggleCollapsedNav}
              >
                <span className="menu-icon" />
              </IconButton>
            )}

            <Link
              className="app-logo mr-2 d-none d-sm-block"
              to="/app/dashboard/overview"
            >
              <img
                src={require("assets/images/logo.png")}
                alt="Mate"
                title="Mate"
              />
            </Link>

            <div className="versionLawa">
              <span>Ver: </span> <span>{GlobalURL[0].version}</span>
            </div>

            {/*
          <SearchBox styleName="d-none d-lg-block" placeholder=""
                     onChange={this.updateSearchText.bind(this)}
                     value={this.state.searchText}/>
*/}
            {navigationStyle === HORIZONTAL_NAVIGATION &&
              horizontalNavPosition === INSIDE_THE_HEADER && <Menu />}

            <ul
              className="header-notifications list-inline ml-auto"
              style={{ marginRight: featureServer ? "15px" : "0px" }}
            >
              {logindetail && logindetail.exMember != "1" && (
                <li className="list-inline-item whiteFontLawa">
                  <Link className="whiteFontLawa" to="/app/calendar/dnd">
                    <span className="LawaTopHeaderMenu whiteFontLawa">
                      <i className="zmdi zmdi-calendar zmdi-hc-fw zmdi-hc-lg whiteFontLawa" />
                      <span>
                        <IntlMessages id="sidebar.dashboard.calendar" />
                      </span>
                    </span>
                  </Link>
                </li>
              )}

              {logindetail && logindetail.exMember != "1" && (
                <li className="list-inline-item whiteFontLawa">
                  <Link className="whiteFontLawa" to="/app/bookings/newbook">
                    <span className="LawaTopHeaderMenu whiteFontLawa">
                      <i className="zmdi zmdi-plus-circle-o zmdi-hc-fw zmdi-hc-lg whiteFontLawa" />
                      <span>
                        <IntlMessages id="sidebar.bookings" />
                      </span>
                    </span>
                  </Link>
                </li>
              )}

              {/*<li className="list-inline-item whiteFontLawa">
              <Link className="whiteFontLawa" to="/app/bookings/newDiary">
				<span className="LawaTopHeaderMenu whiteFontLawa">
                <i className="zmdi zmdi-calendar-note zmdi-hc-fw zmdi-hc-lg whiteFontLawa"/>
					<span><IntlMessages id="sidebar.bookings.quicknew"/></span>
				</span>
             </Link>
            </li>*/}

              {logindetail && logindetail.exMember != "1" && (
                <li className="list-inline-item whiteFontLawa">
                  <Link className="whiteFontLawa" to="/app/contact">
                    <span className="LawaTopHeaderMenu whiteFontLawa">
                      <i className="zmdi zmdi-account-box zmdi-hc-fw zmdi-hc-lg whiteFontLawa" />
                      <span>
                        <IntlMessages id="sidebar.customers.list" />
                      </span>
                    </span>
                  </Link>
                </li>
              )}

              {logindetail && logindetail.exMember != "1" && (
                <li className="list-inline-item whiteFontLawa">
                  <Link className="whiteFontLawa" to="/app/newcontact">
                    <span className="LawaTopHeaderMenu whiteFontLawa">
                      <i className="zmdi zmdi-plus-circle-o zmdi-hc-fw zmdi-hc-lg whiteFontLawa" />
                      <span>Add Customer</span>
                    </span>
                  </Link>
                </li>
              )}

              {logindetail && logindetail.exMember != "1" && (
                <li className="list-inline-item whiteFontLawa">
                  <Link
                    className="whiteFontLawa"
                    to="/app/notifications/DailyDiary"
                  >
                    <span className="LawaTopHeaderMenu whiteFontLawa">
                      <i className="zmdi zmdi-plus-circle-o zmdi-hc-fw zmdi-hc-lg whiteFontLawa" />
                      <span>Daily Diary</span>
                    </span>
                  </Link>
                </li>
              )}

              {/*     	
            <li className="list-inline-item">
              <Dropdown
                className="quick-menu app-notification"
                isOpen={this.state.apps}
                toggle={this.onAppsSelect.bind(this)}>

                <DropdownToggle
                  className="d-inline-block"
                  tag="span"
                  data-toggle="dropdown">
                  <span className="app-notification-menu">
                    <i className="zmdi zmdi-apps zmdi-hc-fw zmdi-hc-lg"/>
                    <span>Apps</span>
                  </span>
                </DropdownToggle>

                <DropdownMenu>
                  {this.Apps()}
                </DropdownMenu>
              </Dropdown>
            </li>
            <li className="d-inline-block d-lg-none list-inline-item">
              <Dropdown
                className="quick-menu nav-searchbox"
                isOpen={this.state.searchBox}
                toggle={this.onSearchBoxSelect.bind(this)}>

                <DropdownToggle
                  className="d-inline-block"
                  tag="span"
                  data-toggle="dropdown">
                  <IconButton className="icon-btn">
                    <i className="zmdi zmdi-search zmdi-hc-fw"/>
                  </IconButton>
                </DropdownToggle>

                <DropdownMenu right className="p-0">
                  <SearchBox styleName="search-dropdown" placeholder=""
                             onChange={this.updateSearchText.bind(this)}
                             value={this.state.searchText}/>
                </DropdownMenu>
              </Dropdown>
            </li>
              <li className="list-inline-item">
              <Dropdown
                className="quick-menu"
                isOpen={this.state.langSwitcher}
                toggle={this.onLangSwitcherSelect.bind(this)}>

                <DropdownToggle
                  className="d-inline-block"
                  tag="span"
                  data-toggle="dropdown">
                  <IconButton className="icon-btn">
                    <i className={`flag flag-24 flag-${locale.icon}`}/>
                  </IconButton>
                </DropdownToggle>
				
        
<DropdownMenu right className="w-50">
                  <LanguageSwitcher switchLanguage={this.props.switchLanguage}
                                    handleRequestClose={this.handleRequestClose}/>
                </DropdownMenu>

              </Dropdown>


            </li>
*/}

              {logindetail && logindetail.exMember != "1" && (
                <li className="list-inline-item app-tour LawaFloatRight">
                  <Dropdown
                    className="quick-menu"
                    isOpen={this.state.appNotification}
                    toggle={this.onAppNotificationSelect.bind(this)}
                  >
                    <DropdownToggle
                      className="d-inline-block"
                      tag="span"
                      data-toggle="dropdown"
                    >
                      <IconButton className="icon-btn">
                        {notifications.length > 0 ? (
                          <i
                            id="lawaAlertNotification"
                            className="zmdi zmdi-notifications-none icon-alert"
                          />
                        ) : (
                          <i
                            id="lawaAlertNotification"
                            className="zmdi zmdi-notifications-none"
                          />
                        )}
                      </IconButton>
                    </DropdownToggle>

                    <DropdownMenu right>
                      <CardHeader
                        styleName="align-items-center"
                        heading={<IntlMessages id="appNotification.title" />}
                      />
                      <AppNotification
                        returnFromChild={this.callbackFunctionFromChild}
                      />
                    </DropdownMenu>
                  </Dropdown>
                </li>
              )}

              {/*            <li className="list-inline-item mail-tour">
              <Dropdown
                className="quick-menu"
                isOpen={this.state.mailNotification}
                toggle={this.onMailNotificationSelect.bind(this)}
              >
                <DropdownToggle
                  className="d-inline-block"
                  tag="span"
                  data-toggle="dropdown">

                  <IconButton className="icon-btn">
                    <i className="zmdi zmdi-comment-alt-text zmdi-hc-fw"/>
                  </IconButton>
                </DropdownToggle>


                <DropdownMenu right>
                  <CardHeader styleName="align-items-center"
                              heading={<IntlMessages id="mailNotification.title"/>}/>
                  <MailNotification/>
                </DropdownMenu>
              </Dropdown>
            </li>
*/}

              {navigationStyle === HORIZONTAL_NAVIGATION && (
                <li className="list-inline-item user-nav">
                  <Dropdown
                    className="quick-menu"
                    isOpen={this.state.userInfo}
                    toggle={this.onUserInfoSelect.bind(this)}
                  >
                    <DropdownToggle
                      className="d-inline-block"
                      tag="span"
                      data-toggle="dropdown"
                    >
                      <IconButton className="icon-btn size-30">
                        <Avatar
                          alt="..."
                          src={"https://via.placeholder.com/150x150"}
                          className="size-30"
                        />
                      </IconButton>
                    </DropdownToggle>

                    <DropdownMenu right>
                      <UserInfoPopup />
                    </DropdownMenu>
                  </Dropdown>
                </li>
              )}

              {/* <li className="list-inline-item app-tour LawaFloatRight">
              <span>
                <Tooltip title="Check for new Data">
                  <IconButton onClick={this.handleCheckNewData}>
                  <LoopIcon />
                  </IconButton>
                </Tooltip>
              </span>
            </li> */}
            </ul>

            <div className="ellipse-shape"></div>
          </Toolbar>

          {featureServer && this.state.showSmsBox && (
            <div
              style={{ width: "100%", padding: "10px", textAlign: "center" }}
              className="app-secondary-header"
            >
              <span style={{ color: "#fff", fontSize: "15px" }}>
                SMS has not been sent to user. Error!
              </span>
              <span
                style={{ marginLeft: "40px", position: "relative", top: "3px" }}
              >
                <Button
                  outline
                  size="sm"
                  style={{ color: "#fff" }}
                  onClick={this.handleView}
                >
                  View Details
                </Button>
              </span>

              <span
                style={{ float: "right", color: "#fff", cursor: " pointer" }}
              >
                <Close onClick={this.handleCloseSmsBox} />
              </span>
            </div>
          )}
        </AppBar>
      </>
    );
  }
}

const mapStateToProps = ({ settings }) => {
  const {
    drawerType,
    locale,
    navigationStyle,
    horizontalNavPosition,
  } = settings;
  return { drawerType, locale, navigationStyle, horizontalNavPosition };
};

export default withRouter(
  connect(mapStateToProps, { toggleCollapsedNav, switchLanguage })(Header)
);
