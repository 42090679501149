import React from "react";
import Menu from "@material-ui/core/Menu";
import MenuItem from "@material-ui/core/MenuItem";
import {
  Block,
  AddAlert,
  SpeakerNotesOff,
} from "@material-ui/icons";
import PersonAddIcon from '@material-ui/icons/PersonAdd';
import { LockFill, Globe } from "react-bootstrap-icons";

const CardMenu = (props) => {
  const DefaultGroupOptions = [
    { name: "Subscribe", value: "groups_subscribe", icon: <AddAlert /> },
    // {
    //   name: "UnSubscribe",
    //   value: "groups_unsubscribe",
    //   icon: <SpeakerNotesOff />
    // },
    {
      name: "Block",
      value: "groups_block",
      icon: <Block />,
    },
  ];

  const inviteGroupOptions = [
    {
      name: "Invite members",
      value: "invite_members",
      icon: <PersonAddIcon />,
    },
  ];

  const SubscribedGroupOptions = [
    {
      name: "UnSubscribe",
      value: "groups_unsubscribe",
      icon: <SpeakerNotesOff />,
    },
    {
      name: "Block",
      value: "groups_block",
      icon: <Block />,
    },
  ];

  const groupProfileOptions = [
    {
      name: "UnSubscribe",
      value: "groups_unsubscribe",
      icon: <SpeakerNotesOff />,
    },
    {
      name: "Block",
      value: "groups_block",
      icon: <Block />,
    },
  ];

  const visibilityOptions = [
    { name: "Public", icon: <Globe /> },
    { name: "Private", icon: <LockFill /> },
  ];

  const {
    menuState,
    anchorEl,
    handleRequestClose,
    groupVisibility,
    groupOptions,
    handleSubscription,
    groupProfileMode,
    groupSubscribe,
    handleOpenInviteDialog,
    invite,
  } = props;

  return (
    <Menu
      className="ml-4"
      id="long-menu"
      anchorEl={anchorEl}
      open={menuState}
      onClose={handleRequestClose}
      MenuListProps={{
        style: {
          width: 200,
          paddingTop: 0,
          paddingBottom: 0,
        },
      }}
    >
      {groupOptions &&
        groupSubscribe !== "1" &&
        DefaultGroupOptions.map((option, index) => (
          <MenuItem
            key={index}
            onClick={() => handleSubscription(option.value)}
          >
            {option.icon}{" "}
            <span style={{ marginLeft: "5%" }}>{option.name}</span>
          </MenuItem>
        ))}

      {groupOptions &&
        groupSubscribe === "1" &&
        SubscribedGroupOptions.map((option, index) => (
          <MenuItem
            key={index}
            onClick={() => handleSubscription(option.value)}
          >
            {option.icon}{" "}
            <span style={{ marginLeft: "5%" }}>{option.name}</span>
          </MenuItem>
        ))}

      {groupProfileMode &&
        groupProfileOptions.map((option, index) => (
          <MenuItem
            key={index}
            onClick={() => handleSubscription(option.value)}
          >
            {option.icon}{" "}
            <span style={{ marginLeft: "5%" }}>{option.name}</span>
          </MenuItem>
        ))}

      {groupVisibility &&
        visibilityOptions.map((option, index) => (
          <MenuItem key={index} onClick={handleRequestClose}>
            {option.icon}{" "}
            <span style={{ marginLeft: "5%" }}>{option.name}</span>
          </MenuItem>
        ))}

      {invite &&
        inviteGroupOptions.map((option, index) => (
          <MenuItem key={index} onClick={() => handleOpenInviteDialog(option.value)}>
            {option.icon}{" "}
            <span style={{ marginLeft: "5%" }}>{option.name}</span>
          </MenuItem>
        ))}
    </Menu>
  );
};

export default CardMenu;
