import React, { useEffect, useState } from "react";
import { makeStyles } from "@material-ui/core/styles";
import Modal from "@material-ui/core/Modal";
import Backdrop from "@material-ui/core/Backdrop";
import Fade from "@material-ui/core/Fade";
import EditBox from "../EditBox";
import {
  Button,
  Typography,
  Card,
  Avatar,
  TextField,
  Divider,
} from "@material-ui/core";
import { httpClient } from "../../../appUtility/Api";
import AlertDialog from "./AlertDialog";
import {
  NotificationManager,
  NotificationContainer,
} from "react-notifications";


const qs = require("qs");
const socialData = JSON.parse(window.localStorage.getItem("login"));

const useStyles = makeStyles((theme) => ({
  modal: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },
  root: {
    padding: "20px",
    width: "50%",
    [theme.breakpoints.down("sm")]: {
      width: "90%",
    },
  },
  cancelBtn: {
    // marginTop: "10px",
    float: "right",
  },
  edit: {
    marginBottom: "20px",
    color: theme.palette.primary,
  },
  flexDiv: {
    display: "flex",
    flexDirection: "row",
  },
}));

const EditCommentDialog = (props) => {
  const classes = useStyles();
  var imgArr;
  console.log("props inside edit dialog", props);

  const [commentData, setCommentData] = useState({
    commentTitle: "",
    commentUserName: "",
    commentID: "",
    userThumbnail: "",
    fileList: "",
    imageID: "",
    deleteImageAlert: false,
  });

  // useEffect(() => {
  //   setCommentData({
  //     commentTitle: (props.post && props.post.commentText).replaceAll("<br />",""),
  //     commentUserName: props.post && props.post.commentUserName,
  //     commentID: props.post && props.post.commentID,
  //     userThumbnail: props.post && props.post.userThumbnail,
  //     fileList: (props.post && props.post.returnImage) || (props.post && props.post.imageList) ,
  //     imageID: "",
  //     deleteImageAlert: false,
  //   });
  // }, []);

  useEffect(() => {
    const parser = new DOMParser();
    const doc = parser.parseFromString(props.post.commentText, 'text/html');
    const plainText = doc.body.textContent;

    setCommentData({
      commentTitle: plainText,
      commentUserName: props.post && props.post.commentUserName,
      commentID: props.post && props.post.commentID,
      userThumbnail: props.post && props.post.userThumbnail,
      fileList: (props.post && props.post.returnImage) || (props.post && props.post.imageList),
      imageID: "",
      deleteImageAlert: false,
    });
  }, [props.post]);
  
  const onChange = (e) => {
    setCommentData({
      ...commentData,
      [e.target.name]: e.target.value,
    });
  };

  // console.log("comment data inside >>", commentData);

  const handleRemoveImage = (file) => {
    setCommentData({
      ...commentData,
      deleteImageAlert: true,
      imageID: file.imageID,
    });
    console.log("file is", file);
  };

  const CancelDeletePost = () => {
    setCommentData({ ...commentData, deleteImageAlert: false });
  };

  const handleDeleteImage = () => {
    imgArr = commentData.fileList.filter((list, index) => {
      return list.imageID !== commentData.imageID;
    });
    console.log('file llst',props.post.fileList);

    const PostQuery = {
      userID: socialData && socialData.uid,
      userCode: socialData && socialData.memtoken,
      appID: 1,
      companyID: socialData && socialData.cid,
      postID: props.post.postID || props.post.imageList[0].postID,
      imageID: commentData.imageID,
    };

    httpClient
      .post(
        "api/social/posts_image_save.php",
        qs.stringify({
          action: "image_delete",
          source: PostQuery,
        })
      )
      .then((res) => {
        if (res.data.success !== "true") {
          NotificationManager.warning(
            "Sorry couldn't delete image right now !"
          );
          CancelDeletePost();
        } else {
          NotificationManager.success(res.data.msg);
          commentData.fileList = imgArr;

          CancelDeletePost();
        }
      });
  };

  return (
    <Modal
      aria-labelledby="transition-modal-title"
      aria-describedby="transition-modal-description"
      className={classes.modal}
      open={props.open}
      onClose={props.handleClose}
      closeAfterTransition
      BackdropComponent={Backdrop}
      BackdropProps={{
        timeout: 500,
      }}
    >
      <Fade in={props.open}>
        <Card className={classes.root}>
          <Typography className={classes.edit}> Edit Comment</Typography>
          {/* <EditBox addPost={props.editComment} post={props.post} /> */}

          {commentData.deleteImageAlert && (
            <AlertDialog
              postID={props.post.postID}
              imageId={commentData.imageID}
              deleteAlert={commentData.deleteImageAlert}
              CancelDeletePost={CancelDeletePost}
              handleDeletePost={handleDeleteImage}
              // image={this.props.post}
            />
          )}

          <div className={classes.card}>
            <div className={classes.flexDiv}>
              <Avatar
                className="size-50 mr-3"
                src={commentData.userThumbnail}
                alt={commentData.commentUserName}
              />
              <div className="media-body">
                <TextField
                  id="exampleTextarea"
                  placeholder="Edit Comment"
                  label="Edit Comment"
                  name="commentTitle"
                  value={commentData.commentTitle}
                  onChange={(e) => onChange(e)}
                  multiline
                  fullWidth
                  className="jr-wall-textarea"
                  margin="none"
                  variant="outlined"
                  rows="4"
                />
              </div>
            </div>

            <div className="jr-clearfix">
              {//   this.state.isOpen === true ||
              // !props.subComment && 
              props.post ? (
                <div className="d-flex flex-row ml-6 mt-2">
                  <ul className="list-inline mb-3 mr-2">
                    {commentData.fileList != undefined && commentData.fileList.length != 0 &&
                      commentData.fileList.map((user, index) => (
                        <li
                          className="mb-1 mr-0 list-inline-item align-top relative"
                          key={index}
                        >
                          <img
                            alt="..."
                            className="size-60 rounded"
                            src={user.preview || user.imageURL}
                          />
                          <span
                            className="cross_icon"
                            onClick={() => handleRemoveImage(user)}
                          >
                            <i className="zmdi zmdi-close" />
                          </span>
                        </li>
                      ))}
                  </ul>
                  {/* <Dropzone
                className="jr-wall-dropzone"
                onDrop={this.onDrop}
                multiple
              >
                <i className="zmdi zmdi-collection-image" />
              </Dropzone> */}
                </div>
              ) : null}
              <Divider />

              {/* <Modal isOpen={previewVisible} toggle={this.handleCancel}>
            <ModalHeader toggle={this.toggle}>Slide Show</ModalHeader>
            <img alt="example" style={{ width: "100%" }} src={previewImage} />
          </Modal> */}
            </div>

            <div className="d-flex flex-row align-items-center mt-4">
              <Button
                color="primary"
                variant="outlined"
                className="ml-auto mb-0 mr-2"
                // disabled={isEnabled ? true : false}
                onClick={() => props.editComment(commentData)}
              >
                UPDATE
              </Button>
              <Button
                variant="contained"
                color="primary"
                onClick={props.handleClose}
                className={classes.cancelBtn}
              >
                Cancel
              </Button>
            </div>
          </div>
        </Card>
      </Fade>
    </Modal>
  );
};

export default EditCommentDialog;
