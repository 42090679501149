import React, { Component } from "react";
import Button from "@material-ui/core/Button";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogTitle from "@material-ui/core/DialogTitle";
import Slide from "@material-ui/core/Slide";
import { DialogContent } from "@material-ui/core";
// import DialogContent from "@material-ui/core/DialogContent";
// import DialogContentText from "@material-ui/core/DialogContentText";

class AlertDialog extends Component {
  state = {
    open: this.props.alertBlock,
  };

  handleRequestClose = () => {
    if (this.props.alertBlock) {
      this.props.closeAlert();
    }
  };

  render() {

    return (
      <>
        {this.state.open && (
          <Dialog
            open={this.state.open}
            TransitionComponent={Slide}
            onClose={this.handleRequestClose}
          >
            <DialogTitle>
              Are you sure you want block the member ?
            </DialogTitle>
            <DialogContent>This will be irreversible</DialogContent>
            <DialogActions>
              <Button onClick={this.handleRequestClose} color="secondary">
                No
              </Button>
              <Button onClick={this.props.blockSuccess ? () => this.props.handleKick() : () => this.props.handleUnblock()} color="primary">
                Yes
              </Button>
            </DialogActions>
          </Dialog>
        )}
      </>
    );
  }
}

export default AlertDialog;
