import React from 'react';
import {Link} from 'react-router-dom'
import {BrowserRouter,Redirect, Route, Switch} from 'react-router-dom';
import {connect} from 'react-redux';
import TextField from '@material-ui/core/TextField';
import IconButton from '@material-ui/core/IconButton';
import Button from '@material-ui/core/Button';
import {NotificationContainer, NotificationManager} from 'react-notifications';
import IntlMessages from 'util/IntlMessages';
import Router from 'Router';
import {httpClient} from 'appUtility/Api';

import {
  //hideMessage,
  //showAuthLoader,
  GetUserVerify
} from 'actions/Auth';
 
//const authUserState = React.createContext('isAuthenticated');
var isAuthenticated = 'A';

const qs = require('qs');
 
class Reset extends React.Component {
	

 //const authUserState() => ({:false}); 
	constructor() {
    super();
		
	var url_string = window.location.href;
	var url = new URL(url_string);
	var user = url.searchParams.get("u");
	var memid = url.searchParams.get("i");
	var resetcode = url.searchParams.get("rc");

	
    this.state = {
      newpassword: '',
      password: '',
	  username: user,
	  memid: memid,
	  resetcode: resetcode,
    }
	  
	this.handleChange = this.handleChange.bind(this);
	this.stopSubmission = this.stopSubmission.bind(this);
  }

handleChange (evt) {
     this.setState({ [evt.target.name]: evt.target.value });
  }
  
stopSubmission (e){
	 //NotificationManager.info('Verifying User Detail');
	// alert('onClickPreventDefault called, form will not submit');
 	e.preventDefault();
 }

handleResetPwd() {

 	if(this.state.password=='' || this.state.newpassword=='')
 		{
 			   NotificationManager.info("Password Fields are mandatory to Reset Password");
 		}
	else if(this.state.password != this.state.newpassword)
 		{
 			   NotificationManager.info("New Password and Confirm Password does not Match");
 		}
	else
		{
			NotificationManager.info('Verifying User Detail');
  	
			 httpClient.post('ResetPassword.php',
				  qs.stringify({
					action: "reset_password",
					source: this.state
				  })	
							)
			.then(({data}) => {
				 if(data.success!='true')
					 {
						 NotificationManager.info(data.successMSG);
						 this.setState({
						newpassword: "",
      					password: "",

  					});
					 this.form.reset();
					 }
				 else if(data.success=='true')
				 {
 
					//NotificationManager.info("Sending Password Reset Email");
					NotificationManager.info(data.successMSG);
					 
					this.setState({
						newpassword: '',
      					password: '',

  					});
					 this.form.reset();
 				 }
 			},
		  // error handler
        (error) => {
          NotificationManager.info('Error Occured: Refresh the page and try again');
        })
  		}	
}
	
handleLogin() {
	
  	NotificationManager.info('Verifying User Detail1111');
 	setTimeout(function(){
       NotificationManager.info("Logged in Successfully.")
     }, 2000);
	
	
	setTimeout(function(){
        window.location = "/signin"
     }, 4000);
	
   
  }	
	
  render() {
	 
    const {
      newpassword,
      password,
    }   = this.state;
	 
 
	 
      if (isAuthenticated === 1) {
        return ( <Redirect to={'/ForgotPassword'}/> );
      } 
 
	  
	  
	  
  //  const {showMessage, loader, alertMessage} = this.props;
    return (
      <div
        className="app-login-container d-flex justify-content-center align-items-center animated slideInUpTiny animation-duration-3">
        <div className="app-login-main-content">

          <div className="app-logo-content d-flex align-items-center justify-content-center">
            <Link className="logo-lg" to="/" title="Mate">
              <img src={require("assets/images/logo.png")} alt="Mate" title="Mate"/>
            </Link>
          </div>

          <div className="app-login-content">
            <div className="app-login-header mb-4">
              <h1>Reset Password</h1>
            </div>

            <div className="app-login-form">
              <form onSubmit={this.stopSubmission} ref={form => this.form = form}>
                <fieldset>
                  <TextField
                    type="password"
                    label="New Password"
                    fullWidth
					name = "newpassword"
                    defaultValue=""
					onChange={this.handleChange}
                    margin="normal"
                    className="mt-1 my-sm-3"
                  />
                  <TextField
                    type="password"
                    label="Confirm Password"
                    fullWidth
					name = "password"
                    defaultValue=""
					onChange={this.handleChange}
                    margin="normal"
                    className="mt-1 my-sm-3"
                  />

                  <div className="mb-3 d-flex align-items-center justify-content-between">
                    <Button type='submit' onClick={() => { 
						this.handleResetPwd();
                      //GetUserVerify({email, password});
						//alert (isAuthenticated)
                    }} variant="contained" color="primary">
                      Reset
                    </Button>

					<Link to="/signin">
                      Sign In
                    </Link>
  						 
                  </div>

                  

                </fieldset>
              </form>
            </div>
          </div>

        </div>

{/*
        {
          loader &&
          <div className="loader-view">
            <CircularProgress/>
          </div>
        }
		

        {showMessage && NotificationManager.error(alertMessage)}
*/ }       <NotificationContainer/>
      </div>
    );
  }
}

const mapStateToProps = ({auth}) => {
  const {GetUserVerify} = auth;
  return {isAuthenticated}
};

/*
 
const mapStateToProps = ({auth}) => {
  const {loader, alertMessage, showMessage, authUser} = auth;
  return {loader, alertMessage, showMessage, authUser}
};

export default connect(mapStateToProps, {
  userSignIn,
  hideMessage,
  showAuthLoader  
})(SignIn);
 */
/*
export default({
 GetUserVerify  
})(SignIn);
*/

export default Reset;

